import { Flex } from "@chakra-ui/react";

import { IGraph } from "@/features/data-transformation";

import { BarChartCustom } from "./bar-chart/bar-chart.tsx";
import { HistogramChart } from "./histogram/histogram-chart.tsx";
import TimeseriesChart from "./time-series/time-series-chart.tsx";

export const ExploreDataChart = ({ data }: { data: IGraph }) => {
  return (
    <Flex className="box-border h-fit">
      {data?.barChart && <BarChartCustom data={data.barChart} />}
      {data?.histogram && <HistogramChart data={data.histogram} />}
      {data?.timeSeries && <TimeseriesChart data={data.timeSeries} />}
    </Flex>
  );
};
