export const AIPlaygroundEndpoints = {
  getAIPlaygroundBySource: `/api/v1.0/ai_playground`,
  getServiceProviders: `/api/v1.0/service_providers`,
  getTestResults: (runId: string, playgroundId: string) =>
    `/api/v1.0/text_generation/${playgroundId}/test_results/${runId}`,
  getRunDetails: (textGenId: string, runId: string) =>
    `/api/v1.0/text_generation/${textGenId}/run_details/${runId}`,
  getVersionHistory: (playgroundId: string, textGenId: string) =>
    `/api/v1.0/ai_playground/${playgroundId}/text_generation/${textGenId}/history`,
  savePlayground: (playgroundId: string, textGenerationId: string) =>
    `/api/v1.0/ai_playground/${playgroundId}/text_generation/${textGenerationId}`,
  revertToVersion: (playgroundId: string, textGenId: string) =>
    `/api/v1.0/ai_playground/${playgroundId}/text_generation/${textGenId}/revert`,
  getSourceSchemaDetails: (playgroundId: string) =>
    `/api/v1.0/ai_playground/${playgroundId}/source_schema`,
};
