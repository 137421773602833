import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  Button,
  useDisclosure,
  Flex,
  Input,
  Box,
} from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  MdsAddCircleOutlined,
  MdsEditRound,
  MdsRemoveOutlined,
} from "react-icons-with-materialsymbols/mds";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import {
  getCurrentRunResults,
  isLLMEditable,
  selectResultRowCount,
  setCurrentRunResults,
  setResultsRowCount,
  showPromptHistory,
} from "../../redux";
import { RunResults } from "../../types";

const updateCurrentRunResults = (
  results: RunResults,
  count: number
): RunResults => {
  const resultLength = results.result.length;
  if (count === 0 || count === resultLength) return results;
  const newResults = { ...results };
  if (count < resultLength) {
    newResults.result = results.result.slice(0, count);
  }

  if (count > resultLength) {
    const firstItem = results.result[0];
    newResults.result = [
      ...results.result,
      ...Array(count - resultLength).fill(
        //eslint-disable-next-line
        Object.keys(firstItem).map((k) => ({
          [k]: "",
        }))
      ),
    ];
  }
  return newResults;
};

const ResultsRowCount = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const rowCount = useAppSelector(selectResultRowCount);
  const isHistoryOpen = useAppSelector(showPromptHistory);
  const isEditingEnabled = useAppSelector(isLLMEditable);
  const currentRunResults = useAppSelector(getCurrentRunResults);
  const [count, setCount] = useState(3);
  const [error, setError] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCount(rowCount ?? 3);
  }, [rowCount]);

  const onSave = () => {
    if (count < 1) {
      setError("Minimum 1 row is required");
      return;
    }

    if (count > 10) {
      setError("Max 10 rows allowed");
      return;
    }

    setError("");
    if (currentRunResults?.headers) {
      const updatedRunResults = updateCurrentRunResults(
        currentRunResults,
        count
      );
      dispatch(setCurrentRunResults(updatedRunResults));
    }
    dispatch(setResultsRowCount(count));
    onClose();
  };

  const incrementCount = () => {
    if (count && count < 10) setCount(count + 1);
  };

  const decrementCount = () => {
    if (count && count > 1) setCount(count - 1);
  };

  const handleInputChange = (value: number) => {
    if (value < 1) setCount(1);
    else if (value > 10) setCount(10);
    else setCount(value);
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <IconButton
          aria-label="edit"
          colorScheme="dark"
          icon={<MdsEditRound size={22} />}
          isDisabled={isEditingEnabled}
          size="sm"
          variant={"ghost"}
        />
      </PopoverTrigger>
      <PopoverContent pos={"relative"} zIndex="popover" px={4} py={4}>
        <PopoverArrow />
        <Flex className="w-full items-center justify-between mb-4">
          <Box className="font-medium">Test Settings</Box>
          <PopoverCloseButton className="!relative" />
        </Flex>
        <Flex className="flex-col">
          <Box className="pl-2 font-medium">Number of Rows</Box>
          <Flex className="items-stretch gap-3">
            <Flex
              className={clsx(
                "rounded grow px-2 gap-2 items-center border ",
                error ? "border-red-600" : "border-gray-300"
              )}
            >
              <Input
                className="!w-8 font-medium"
                isDisabled={isHistoryOpen}
                max={10}
                min={3}
                onChange={(e) => handleInputChange(Number(e.target.value))}
                type="number"
                value={count}
                variant={"unstyled"}
              />
              <Box className="text-gray-500">rows</Box>
            </Flex>
            <IconButton
              aria-label="Decrease Rows"
              colorScheme="dark"
              icon={<MdsRemoveOutlined size={22} className="stroke-[22]" />}
              onClick={decrementCount}
              variant={"ghost"}
            />
            <IconButton
              aria-label="Increase Rows"
              colorScheme="dark"
              icon={<MdsAddCircleOutlined size={22} className="stroke-[22]" />}
              onClick={incrementCount}
              variant={"ghost"}
            />
          </Flex>
          <Box className="text-sm  text-red-600">{error}</Box>
          <Box className="text-sm mt-5">
            First {count} unique non-null rows of the input will be used for
            testing
          </Box>
          <Button
            className="self-end"
            colorScheme={"dark"}
            isDisabled={isHistoryOpen}
            onClick={onSave}
          >
            Save
          </Button>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};

export default ResultsRowCount;
