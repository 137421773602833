import {
  MenuIcon,
  MenuCommand,
  useMenuItem,
  UseMenuItemProps,
  chakra,
  useMenuStyles,
} from "@chakra-ui/react";
import { cx } from "@chakra-ui/shared-utils";
import { forwardRef, HTMLChakraProps, SystemProps } from "@chakra-ui/system";
import React from "react";

import { MenuItemDescription } from "./menu-item-description";
import { StyledMenuItem } from "./styled-menu-item";

export interface StyledMenuItemProps extends HTMLChakraProps<"button"> {}

interface MenuItemOptions
  extends Pick<
    UseMenuItemProps,
    "isDisabled" | "isFocusable" | "closeOnSelect"
  > {
  /**
   * The icon to render before the menu item's label.
   * @type React.ReactElement
   */
  icon?: React.ReactElement;
  /**
   * Smaller description text under the menu item's label.
   */
  hasDescription?: boolean;
  /**
   * The spacing between the icon and menu item's label.
   * @type SystemProps["mr"]
   */
  iconSpacing?: SystemProps["mr"];
  /**
   * Right-aligned label text content, useful for displaying hotkeys.
   */
  command?: string;
  /**
   * The spacing between the command and menu item's label.
   * @type SystemProps["ml"]
   */
  commandSpacing?: SystemProps["ml"];
}

type HTMLAttributes = React.HTMLAttributes<HTMLElement>;

/**
 * Use prop `isDisabled` instead
 */
type IsDisabledProps = "disabled" | "aria-disabled";

export interface MenuItemProps
  extends Omit<HTMLChakraProps<"button">, IsDisabledProps>,
    MenuItemOptions {}

function isReactElement(arg: any): arg is React.ReactElement {
  // eslint-disable-next-line no-prototype-builtins
  return arg.hasOwnProperty("type");
}

export const MenuItem = forwardRef<MenuItemProps, "button">((props, ref) => {
  const {
    icon,
    hasDescription,
    iconSpacing = "0.5rem",
    command,
    commandSpacing = "0.5rem",
    children,
    ...rest
  } = props;

  const styles = useMenuStyles();
  const menuitemProps = useMenuItem(rest, ref) as HTMLAttributes;

  const shouldWrap = icon || command;

  const childrenArray = React.Children.toArray(children);

  const description = childrenArray.find(
    (child) => isReactElement(child) && child.type === MenuItemDescription,
  );
  const label = childrenArray.filter(
    (child) => child?.type !== MenuItemDescription,
  );

  const _children = shouldWrap ? (
    <span style={{ pointerEvents: "none", flex: 1, color: "inherit" }}>
      {hasDescription ? label : children}
    </span>
  ) : hasDescription ? (
    label
  ) : (
    children
  );

  return (
    <StyledMenuItem
      {...menuitemProps}
      className={cx("chakra-menu__menuitem", menuitemProps.className)}
    >
      <chakra.div __css={styles.itemContainer}>
        {icon && (
          <MenuIcon me={iconSpacing} fontSize="0.8em">
            {icon}
          </MenuIcon>
        )}
        {_children}
        {command && <MenuCommand ms={commandSpacing}>{command}</MenuCommand>}
      </chakra.div>
      {hasDescription && (
        <chakra.div __css={styles.itemContainer}>
          {icon && (
            <MenuIcon me={iconSpacing} fontSize="0.8em" visibility="hidden">
              {icon}
            </MenuIcon>
          )}
          {description}
        </chakra.div>
      )}
    </StyledMenuItem>
  );
});

MenuItem.displayName = "MenuItem";
