import { Box } from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect } from "react";

import {
  showLLMConfigPanel,
  toggleLLMConfigPanel,
} from "@/features/workflow-studio";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { aiPlaygroundAPI } from "../api";
import { resetSlice } from "../redux";

import LLMPromptLayout from "./llm-prompt-layout";

export const LLMPrompt = () => {
  const isLLMConfig = useAppSelector(showLLMConfigPanel);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(toggleLLMConfigPanel({ show: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLLMConfig?.isVisible) {
      dispatch(resetSlice());
      dispatch(aiPlaygroundAPI.util.resetApiState());
    }
  }, [isLLMConfig, dispatch]);

  return (
    <Box
      className={clsx(
        "transition-all absolute z-50 bottom-0 left-0 w-full bg-white overflow-hidden",
        isLLMConfig.isVisible ? "h-full" : "h-0"
      )}
      zIndex={999}
    >
      {isLLMConfig.isVisible && <LLMPromptLayout key={isLLMConfig.nodeId} />}
    </Box>
  );
};
