import { useContext, useEffect, useState } from "react";

import {
  EdaMetaDataContext,
  useGetRequestStatusQuery,
} from "@/features/data-transformation";
import { POLLING_STATUS } from "@/utils/enums.ts";

const POLLING_INTERVAL = 500;

type Props<T> = {
  onSuccess?: (arg: T) => void;
};

export const useStatusApi = <T>({ onSuccess }: Props<T>) => {
  const metaData = useContext(EdaMetaDataContext);

  const [requestId, setRequestId] = useState<string | null>(null);
  const [data, setData] = useState<T | undefined>(undefined);

  const {
    data: runStatusData,
    isError,
    isFetching,
  } = useGetRequestStatusQuery(
    {
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      requestId: requestId!,
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: requestId == null,
    }
  );

  useEffect(() => {
    if (isError) {
      setRequestId(null);
      setData(undefined);
      return;
    }
    const taskStatus = runStatusData?.response.data?.taskStatus;
    const progressCheck = taskStatus != POLLING_STATUS.IN_PROGRESS;

    if (progressCheck) {
      setRequestId(null);
    }

    if (taskStatus === POLLING_STATUS.COMPLETED) {
      const data = runStatusData?.response.data?.taskData as T;
      setData(data);
      onSuccess?.(data);
    }
  }, [runStatusData, isError]);

  const clearCache = () => {
    setRequestId(null);
    setData(undefined);
  };

  return {
    requestId,
    setRequestId,
    isFetching,
    data,
    clearCache,
  };
};
