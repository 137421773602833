import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useAppSelector } from "@/reduxHooks";

import { getInputPrompt, selectColumnsWithError } from "../redux";

export const useLLMValidation = () => {
  const inputPromptText = useAppSelector(getInputPrompt);
  const inputColumns = useAppSelector(selectColumnsWithError);

  const isValid = useMemo(
    () =>
      !isEmpty(inputPromptText) &&
      !isEmpty(inputColumns?.columns) &&
      isEmpty(inputColumns?.errorColumns),
    [inputPromptText, inputColumns]
  );

  return isValid;
};

export const useLLMErrors = () => {
  const inputPromptText = useAppSelector(getInputPrompt);
  const inputColumns = useAppSelector(selectColumnsWithError);
  const columnsInPrompt = inputColumns?.columns;
  const columnsWithError = inputColumns?.errorColumns;

  const llmErrors = [];
  if (isEmpty(inputPromptText)) {
    llmErrors.push({
      error: "Prompt cannot be empty",
      message:
        "Write a prompt and insert Input Data columns to start testing it",
    });
  }

  if (isEmpty(columnsInPrompt)) {
    llmErrors.push({
      error: "No Input Data columns found",
      message:
        "You cannot run a prompt without inserting Input Data.\nType {{ }} to insert input column data in your prompt",
    });
  }
  if (!isEmpty(columnsWithError)) {
    columnsWithError?.forEach((column) => {
      llmErrors.push({
        error: "Column is missing",
        message: `Column {{${column}}} mentioned in the prompt could not be found in the Input Data`,
      });
    });
  }
  return { errors: llmErrors };
};
