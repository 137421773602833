import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { selectedColumn } from "@/features/data-transformation";

export const SuggestionsEmpty = () => {
  const col = useSelector(selectedColumn);

  if (!col)
    return (
      <Flex className="justify-center items-center px-4 py-12 text-center">
        Select a column to view suggestions
      </Flex>
    );

  return (
    <Flex className="justify-center items-center px-4 py-12 text-center">
      No suggestions available for ”{col?.column.name}” column
    </Flex>
  );
};
