import { Flex } from "@chakra-ui/react";
import { MdOutlineCable } from "react-icons/md";
import {
  MdsAccountBoxOutlined,
  MdsFavoriteRound,
  MdsHomeOutlined,
} from "react-icons-with-materialsymbols/mds";

import {
  Tab,
  TabList,
  TabPanel as ChakraTabPanel,
  TabPanels,
  Tabs,
} from "@/design/components/tabs";
import { useGetFlowsQuery } from "@/features/workflow-studio/api";
import { FlowSchema } from "@/features/workflow-studio/types";
import { FLOW_TYPES } from "@/features/workflow-studio/utils/constants";

import FlowList from "./flow-list";
import SearchFlows from "./search-flow";

export const NoFlows = () => (
  <Flex align={"center"} justify="center" gap="2" h={"100%"}>
    <MdOutlineCable className="rotate-90" size={20} />
    <div>No Flows Available</div>
  </Flex>
);

const TabPanel = ({ children, ...props }: any) => (
  <ChakraTabPanel
    p={0}
    h={"100%"}
    flexGrow={0}
    flexWrap={"wrap-reverse"}
    gap={3}
    overflowY={"auto"}
    {...props}
  >
    {children}
  </ChakraTabPanel>
);

export const FlowTabs = () => {
  const { data } = useGetFlowsQuery({
    flowType: FLOW_TYPES.ALL_FLOWS,
    url: null,
  });

  const flows: FlowSchema[] = data?.flows ?? [];

  const flowisNotEmpty = flows.length !== 0;

  return (
    <Tabs
      variant="line"
      w={"100%"}
      h={"max-content"}
      overflow={"hidden"}
      size="sm"
      isLazy={true}
    >
      <TabList className="border-b">
        <Tab key={1} className="grow justify-start gap-1.5 font-medium text-xs">
          <MdsHomeOutlined size={20} className="w-5 h-5" />
          All Flows
        </Tab>
        <Tab key={2} className="grow justify-start gap-1.5 font-medium text-xs">
          <MdsFavoriteRound size={20} className="w-5 h-5" />
          Favorites
        </Tab>
        <Tab key={3} className="grow justify-start gap-1.5 font-medium text-xs">
          <MdsAccountBoxOutlined size={20} className="w-5 h-5" />
          Uploaded by me
        </Tab>
        {flowisNotEmpty && <SearchFlows />}
      </TabList>

      <TabPanels overflow={"hidden"} className="h-full min-h-[300px]">
        <TabPanel>
          <FlowList flowType={FLOW_TYPES.ALL_FLOWS} />
        </TabPanel>
        <TabPanel>
          <FlowList flowType={FLOW_TYPES.FAVORITES} />
        </TabPanel>
        <TabPanel>
          <FlowList flowType={FLOW_TYPES.MY_FLOWS} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
