import { Flex, Input } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Close } from "@/components/icons/close.tsx";
import { Search } from "@/components/icons/search.tsx";
import { Icon } from "@/design/components/icon";
import { setTransformationPanel } from "@/features/data-transformation";

import { ActiveCategoryContext } from "./active-category-context.ts";
import { OperationsCategoryList } from "./operations-category-list.tsx";
import { OperationsDescription } from "./operations-description.tsx";
import { OperationsList } from "./operations-list.tsx";

export const OperationsPanelBody = () => {
  const dispatch = useDispatch();

  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [activeSearch, setActiveSearch] = useState<string | null>(null);
  const [shortName, setShortName] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  const setActive = (name: string) => {
    if (name === activeCategory) {
      setActiveCategory(null);
      return;
    }
    setActiveCategory(name);
  };

  const onChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (isEmpty(value)) {
      setActiveSearch(null);
      return;
    }

    setActiveSearch(value);
  };

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.focus();
  //   }
  // }, []);

  return (
    <Flex
      className="h-full bg-transparent flex-col max-h-full"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex className="items-center w-full border p-2 bg-white">
        <Icon as={Search} color={"gray.600"} size="lg" />
        <Input
          ref={ref}
          onChange={onChange}
          placeholder="Search Transformation"
          variant="unstyled"
        />
        <Icon
          as={Close}
          color={"gray.600"}
          size="lg"
          className="cursor-pointer"
          onClick={() => dispatch(setTransformationPanel(false))}
        />
      </Flex>
      <Flex className="flex-1 max-h-full bg-white">
        <ActiveCategoryContext.Provider
          value={{
            activeCategory,
            setActive,
            activeSearch,
            shortName,
            setShortName,
          }}
        >
          <OperationsCategoryList />
          <OperationsList />
          <OperationsDescription />
        </ActiveCategoryContext.Provider>
      </Flex>
    </Flex>
  );
};
