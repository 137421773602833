import { Flex, Skeleton, Text } from "@chakra-ui/react";
import { MdNoteAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ProtectedComponent } from "@/components/protected-component/protected-component.tsx";
import { Button } from "@/design/components/button";
import { currentProject, currentWs } from "@/features/ws-manager";
import { useGetCurrentWs } from "@/features/ws-manager/hooks/useGetCurrentWs.ts";
import { useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { CATEGORY, PERMISSIONS, USER_ROLES } from "@/utils/enums.ts";

import { AnalysisHeaderTitle } from "./analysis-header-title.tsx";

export const AnalysisHeader = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const project = useSelector(currentProject);
  const ws = useSelector(currentWs);
  const { loading: isLoading } = useGetCurrentWs();
  const userData = useAppSelector(currentUserMetadata);

  const showCreateNewButton = userData?.role !== USER_ROLES.CTK_ADMIN;

  const parentId = project?.id ?? ws?.id;

  const onCreateNewAnalysis = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.ANALYSIS_CREATION,
        modalProps: { workspace: ws, project: project, navigate },
      })
    );
  };

  return (
    <Flex className="!max-w-full w-full overflow-hidden">
      <Flex className="flex-1">
        {routeParams.workspace ? (
          <AnalysisHeaderTitle />
        ) : (
          <Skeleton isLoaded={!isLoading}>
            <Text className="font-semibold text-2xl tracking-tight">
              All Analyses
            </Text>
          </Skeleton>
        )}
      </Flex>
      <Skeleton className="shrink-0" isLoaded={!isLoading}>
        {showCreateNewButton && (
          <ProtectedComponent
            requiredPermission={PERMISSIONS.CREATE}
            type={CATEGORY.Analysis}
            id={parentId}
          >
            <Button
              size="sm"
              colorScheme="orange"
              rightIcon={<MdNoteAdd />}
              onClick={onCreateNewAnalysis}
            >
              Create New Analysis
            </Button>
          </ProtectedComponent>
        )}
      </Skeleton>
    </Flex>
  );
};
