import { Textarea } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  ControlledSelectNoForm,
  CustomOptions,
} from "@/components/controlled-select";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextIcon,
  FormLabel,
} from "@/design/components/form";
import {
  COLUMN_IDENTIFIER,
  convertArrayToLabelValue,
  IViewTransformationParameter,
  LabelValuePair,
  tableColumns,
} from "@/features/data-transformation";

import { StepFormContext } from "../step-form-context.ts";

export const SelectForm = ({
  param,
  errors,
}: {
  param: Partial<IViewTransformationParameter>;
  errors?: string;
}) => {
  const columns = useSelector(tableColumns);
  const { step, editEnabled, register, setValue } =
    useContext(StepFormContext)!;

  const defaultValue = (param.value ?? param.defaultValue ?? "") as string;

  const initialSelectedValue = !isEmpty(defaultValue)
    ? defaultValue
    : undefined;

  useEffect(() => {
    setSelectedValue(initialSelectedValue);
  }, [initialSelectedValue]);

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    initialSelectedValue
  );

  const valueObject: LabelValuePair = {
    label: selectedValue ?? "",
    value: selectedValue ?? "",
  };

  const showColumns = param.identifier === COLUMN_IDENTIFIER;

  const oldColumns = step?.metadata?.onSample?.previousStepColumnInfo;
  const dropdownColumns = isEmpty(oldColumns)
    ? columns.map((item) => item.name)
    : oldColumns;

  const list = showColumns ? dropdownColumns : param.valuesList;

  return (
    <FormControl
      key={param.shortName}
      isRequired={param.isMandatory}
      isInvalid={!!errors}
      isDisabled={!editEnabled}
    >
      <FormLabel className="!text-sm !mb-1.5 font-medium leading-none">
        {param.name}
      </FormLabel>
      <Textarea
        data-shortname={param.shortName}
        id={param.shortName}
        type="string"
        value={selectedValue}
        {...register(param.shortName)}
        hidden={true}
        readOnly
      />
      <ControlledSelectNoForm
        options={convertArrayToLabelValue(list ?? [])}
        value={valueObject}
        placeholder="Select option"
        data-shortname={param.shortName}
        id={param.shortName}
        size="sm"
        onChange={(value) => {
          const val = (value as LabelValuePair).value;
          console.log("VALUE", val);
          setSelectedValue(val);
          setValue(param.shortName, val, {
            shouldDirty: true,
          });
        }}
        selectedOptionStyle="check"
        components={{
          Option: CustomOptions,
        }}
        isRequired={param.isMandatory}
        useBasicStyles
      />
      {!errors && param?.hintText && (
        <FormHelperText className="pl-3 text-[13px]">
          <FormHelperTextIcon className="!self-start" />
          {param?.hintText}
        </FormHelperText>
      )}
      <FormErrorMessage className="pl-3 text-[13px]">
        <FormErrorIcon />
        {errors && errors}
      </FormErrorMessage>
    </FormControl>
  );
};
