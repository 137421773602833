import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { camelCase, isEmpty } from "lodash";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { VALIDATION_STATUS, VALIDATION_TYPES } from "@/utils/enums";
import { getFileExtension } from "@/utils/string-utils";

import { useRunValidationsMutation } from "../../api";
import { useFileUpload } from "../../hooks/useUploadFile";
import { IDataSource } from "../../types";

const DatasetDetailBody = () => {
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);
  const dispatch = useAppDispatch();

  const [datasetName, setDatasetName] = React.useState("");
  const [validateName, { isLoading }] = useRunValidationsMutation();
  const { handleFileUpload } = useFileUpload({});
  const fileName = modalProps.selectedFile?.name.split(".")[0];

  useEffect(() => {
    setDatasetName(fileName?.substring(0, 100) as string);
  }, [fileName]);

  const [errorMessage, setErrorMessage] = React.useState("");

  const validateTitle = async () => {
    const validationType = VALIDATION_TYPES.DUPLICATE_TITLE;
    const res = await validateName({
      analysisId: modalProps.analysisId,
      validationType,
      body: { dataset_title: datasetName },
    }).unwrap();

    const response = res.response.data?.results[0][camelCase(validationType)];
    if (response?.validationStatus === VALIDATION_STATUS.FAILED) {
      setErrorMessage(response?.validationMessage[0] as string);
      return false;
    }
    return true;
  };

  const handleUpload = async () => {
    const completeName = modalProps.selectedFile?.name as string;
    if (
      getFileExtension(completeName) === "xls" ||
      getFileExtension(completeName) === "xlsx"
    ) {
      dispatch(
        openModal({
          modalType: ModalTypes.SELECT_SHEET,
          modalProps: {
            analysisId: modalProps.analysisId,
            selectedFile: modalProps.selectedFile,
            fileName: datasetName,
            source: modalProps.source,
          },
        })
      );
    } else {
      await handleFileUpload({
        selectedFile: modalProps.selectedFile as File,
        fileName: datasetName,
        source: modalProps.source as IDataSource,
        options: {},
      });
    }
  };

  const onSubmit = async () => {
    if (datasetName.trim().length === 0) {
      setErrorMessage("Dataset Name is required");
      return;
    }
    if (datasetName.length > 100) {
      setErrorMessage("Max length for Dataset Name is 100 characters");
      return;
    }
    const isValid = await validateTitle();
    if (isValid) {
      await handleUpload();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setDatasetName(inputValue);
    setErrorMessage("");
    if (inputValue.length > 100) {
      setErrorMessage("Max length for Dataset Name is 100 characters");
    }
  };

  return (
    <Flex className="flex-col gap-4">
      <FormControl isInvalid={!isEmpty(errorMessage)}>
        <FormLabel>Dataset Name</FormLabel>
        <Input
          isDisabled={isLoading}
          onChange={handleInputChange}
          placeholder="Enter Dataset Name"
          value={datasetName}
        />
        <FormErrorMessage className="break-all">
          {errorMessage}
        </FormErrorMessage>
      </FormControl>
      <Button
        className="w-fit self-end"
        colorScheme="dark"
        isDisabled={isLoading || !isEmpty(errorMessage)}
        isLoading={isLoading}
        onClick={onSubmit}
      >
        Save Changes
      </Button>
    </Flex>
  );
};

export default DatasetDetailBody;
