import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import { EditorNavbar } from "./navbar";
import { WorkflowSideNav } from "./sidebar";

export const WorkFlowLayout = () => {
  return (
    <Flex className="w-screen max-h-screen h-screen">
      <Flex className="w-full h-full max-h-full overflow-hidden">
        <WorkflowSideNav />
        <Flex className="flex flex-col w-full h-full overflow-hidden">
          <EditorNavbar />
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};
