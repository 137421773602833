// create new slice for analysis
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state

interface SettingsState {}

// Define the initial state using that type
const initialState: SettingsState = {};

const userSettingsSlice = createSlice({
  name: "user-settings-slice",
  initialState,
  reducers: {},
});

// export const {  } = userSettingsSlice.actions;
// export const currentUser = (state: RootState) => state..userAccess;

export const userSettingsReducer = userSettingsSlice.reducer;
