import { chakra, useMenuStyles } from "@chakra-ui/react";
import React from "react";

type HTMLAttributes = React.HTMLAttributes<HTMLElement>;
export const MenuItemDescription = (props: HTMLAttributes) => {
  const { children } = props;

  const styles = useMenuStyles();

  return <chakra.span __css={styles.description}>{children}</chakra.span>;
};

MenuItemDescription.displayName = "MenuItemDescription";
