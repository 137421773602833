import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { ModalSubHeader } from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { ExportBody } from "./export-body";

interface ExportDatasetProps {}

export const ExportDataset: React.FC<ExportDatasetProps> = () => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isCentered isOpen={true} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent className="!pb-0 !gap-0">
        <ModalHeader className="!font-normal">Export Data Via API</ModalHeader>
        <ModalSubHeader className="!pl-6 text-sm text-gray-800">
          Access your datasets from anywhere using Export API
        </ModalSubHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!flex !flex-col !p-5 gap-5">
          <ExportBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
