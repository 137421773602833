import {
  dataTransformationAPI,
  DataTransformationEndpoints,
  ITableDataQuery,
  IAnalysisEdaMetaData,
  ITableDataResponse,
  IViewFiltersRequest,
  IExploreDataStatsRequest,
  IExploreTaskResponse,
} from "@/features/data-transformation";
import { ApiResponse } from "@/types";
import { keysToCamel, keysToSnake } from "@/utils/snakeCaseConverter.ts";

export const edaTableAPI = dataTransformationAPI.injectEndpoints({
  endpoints: (build) => ({
    getTableData: build.query<
      ApiResponse<ITableDataResponse>,
      ITableDataQuery & IAnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.getTableData(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
        camelCase: false,
      }),
      transformResponse: (response: ApiResponse<ITableDataResponse>) => {
        return keysToCamel(response, "records");
      },
      extraOptions: {
        maxRetries: 2,
      },
    }),
    getFilterStats: build.mutation<
      ApiResponse<{ requestId: string }>,
      IAnalysisEdaMetaData & {
        column: string;
        viewFilters: IViewFiltersRequest[];
        transformationStepId?: string;
      }
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.filterStats(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
      }),
    }),
    getDataStats: build.mutation<
      ApiResponse<{ requestId: string } | IExploreTaskResponse>,
      IAnalysisEdaMetaData & IExploreDataStatsRequest
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.exploreDataStats(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
      }),
      extraOptions: {
        maxRetries: 1,
      },
    }),
    getSuggestions: build.mutation<
      ApiResponse<{ requestId: string }>,
      IAnalysisEdaMetaData & { column: string }
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.getSuggestions(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
      }),
    }),
  }),
});

export const {
  useGetTableDataQuery,
  useLazyGetTableDataQuery,
  useGetFilterStatsMutation,
  useGetDataStatsMutation,
  useGetSuggestionsMutation,
} = edaTableAPI;
