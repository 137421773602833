import { Box, Flex, ModalCloseButton } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

import { ToastType, useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useDeleteUserAccessMutation } from "@/features/user-manager";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";
import { STATUS } from "@/utils/enums";

const RemoveUserAccess: React.FC = () => {
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useAppDispatch();
  const [deleteAccess, { isLoading: isRemoving }] =
    useDeleteUserAccessMutation();
  console.log(modalProps);

  const removeUserAccess = async () => {
    const res = await deleteAccess({
      objectId: modalProps.object.id,
      data: {
        assigneeUserId: modalProps.user.userId,
        scope: modalProps.type,
      },
    }).unwrap();
    if (res.status === STATUS.SUCCESS) {
      toast({
        title: "Access removed for " + modalProps.user.userName,
      });
      if (modalProps.user.userId === modalProps.currentUser.userId) {
        window.location.reload();
      }
      dispatch(closeModal());
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader>Remove user?</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6">
          <Box>
            {modalProps?.user?.userName || "This user"} not be able to access
            this {modalProps.type} anymore.
          </Box>
          <Flex className="mt-6 !flex !justify-between">
            <Button
              onClick={onClose}
              variant="ghost"
              isDisabled={isRemoving}
              colorScheme="secondary"
              size={"sm"}
            >
              Cancel
            </Button>
            <Button
              variant={modalProps.primaryButtonVariant ?? "solid"}
              colorScheme={modalProps.primaryButtonColorScheme ?? "customRed"}
              isLoading={isRemoving}
              isDisabled={isRemoving}
              onClick={removeUserAccess}
              size={"sm"}
            >
              Remove User
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RemoveUserAccess;
