import { Input } from "@chakra-ui/input";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import Tag from "./tag.tsx";
import { useDidUpdateEffect } from "./use-did-update-effect.ts";

export interface TagsInputProps {
  name?: string;
  placeholder?: string;
  onChange?: (tags: string[]) => void;
  onBlur?: any;
  separators?: string[];
  disableBackspaceRemove?: boolean;
  onExisting?: (tag: string) => void;
  onRemoved?: (tag: string) => void;
  disabled?: boolean;
  tagMaxLength?: number;
  setError: any;
  clearErrors: any;
  errorKey?: string;
  helperText?: string;
  size?: "sm" | "md" | "lg";
  isEditOnRemove?: boolean;
  addTagOnBlur?: boolean;
  beforeAddValidate?: (tag: string, existingTags: string[]) => boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  classNames?: {
    input?: string;
    tag?: string;
    inputWrapper?: string;
  };
}
interface ControlledTagInput<FormValues extends FieldValues = FieldValues>
  extends Omit<TagsInputProps, "name" | "defaultValue">,
    UseControllerProps<FormValues> {
  label?: string;
}

const defaultSeparators = ["Enter"];

const checkDuplicate = (tags: string[], text: string) =>
  tags.some((t) => t.toLowerCase() === text.toLowerCase());

const firstLetterCapitalized = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export function TagsInput<FormValues extends FieldValues = FieldValues>({
  name,
  placeholder,
  onChange,
  onBlur,
  separators,
  disableBackspaceRemove,
  onExisting,
  onRemoved,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
  onKeyUp,
  isRequired,
  label,
  control,
  rules,
  shouldUnregister,
  classNames,
  tagMaxLength = 15,
  helperText,
  size = "md",
  setError,
  errorKey,
  clearErrors,
  addTagOnBlur,
  ...inputProps
}: ControlledTagInput<FormValues>) {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const [tags, setTags] = useState<string[]>([]);

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);

  useEffect(() => {
    if (!!field.value && JSON.stringify(field.value) !== JSON.stringify(tags)) {
      setTags(field.value);
      field.onChange(field.value);
    }
  }, [field.value]);

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const text: string = target.value;
    clearErrors?.(errorKey);

    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === "Backspace"
    ) {
      target.value = isEditOnRemove ? `${tags.at(-1)} ` : "";
      setTags([...tags.slice(0, -1)]);
      field.onChange([...tags.slice(0, -1)]);
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();
      if (beforeAddValidate && !beforeAddValidate(text, tags)) return;
      if (checkDuplicate(tags, text)) {
        onExisting && onExisting(text);
        if (setError && errorKey) {
          setError(errorKey, {
            message: "This tag already exists",
          });
        }
        return;
      }

      const capitalizedText = firstLetterCapitalized(text);
      setTags([...tags, capitalizedText]);
      field.onChange([...tags, capitalizedText]);
      target.value = "";
    }
  };

  const onTagRemove = (text: string) => {
    const updatedTags = tags.filter((tag) => tag !== text);
    setTags(updatedTags);
    field.onChange(updatedTags);
    onRemoved && onRemoved(text);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (addTagOnBlur) {
      const inputtext = firstLetterCapitalized(e.target.value);
      if (checkDuplicate(tags, inputtext)) {
        e.target.value = "";
        return;
      }
      if (!isEmpty(inputtext)) {
        setTags([...tags, inputtext]);
        field.onChange([...tags, inputtext]);
        e.target.value = "";
      }
    }
    field.onBlur();
  };

  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      {label && <FormLabel fontSize={size}>{label}</FormLabel>}
      <Box
        className={clsx(
          "flex flex-row rounded-[0.1875rem] px-2 py-1 border border-gray-500 items-center flex-wrap gap-2 transition-all ease-in p-0",
          !isEmpty(error) && "!border-red-500",
          classNames?.inputWrapper
        )}
        aria-labelledby={name}
      >
        {tags?.map((tag) => (
          <Tag
            key={tag}
            className={classNames?.tag}
            text={firstLetterCapitalized(tag)}
            remove={onTagRemove}
            disabled={disabled}
          />
        ))}

        <Input
          className={clsx(
            "!w-fit flex-1 !min-w-[140px] !h-[32px] !p-0 !border-0 !outline-0",
            classNames?.input
          )}
          type="text"
          size={size}
          placeholder={placeholder}
          onKeyDown={handleOnKeyUp}
          disabled={disabled}
          maxLength={tagMaxLength}
          onKeyUp={onKeyUp}
          onBlur={handleBlur}
          name={field.name}
          ref={field.ref}
          {...inputProps}
        />
      </Box>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {helperText && (
        <FormHelperText className="!mt-1">{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
