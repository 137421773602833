import { isEmpty } from "lodash";

import {
  useLazyGetProjectQuery,
  useLazyGetSingleAnalysisQuery,
  useLazyGetWsQuery,
} from "@/features/ws-manager";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { updateProps } from "@/slices/modal-slice";
import { CATEGORY } from "@/utils/enums";

import { useLazyGetRolesQuery } from "../../api";
import { UserRoleSchema } from "../../types";

export const reorderUserList = (
  userList: UserRoleSchema[],
  loggedInUserId: string | null | undefined
): { currentUser: UserRoleSchema | null; updatedlist: UserRoleSchema[] } => {
  if (isEmpty(userList)) {
    return { currentUser: null, updatedlist: [] };
  }
  if (!loggedInUserId) {
    return { currentUser: null, updatedlist: userList };
  }
  let currentUser = null;

  const userListCopy = [...userList]; // create a copy of the array

  const userIndex = userListCopy.findIndex(
    (user) => user.userId === loggedInUserId
  );
  if (userIndex >= 0) {
    currentUser = userListCopy[userIndex];
    userListCopy.splice(userIndex, 1);
  }

  userListCopy.sort((a, b) => a.role.localeCompare(b.role));

  if (currentUser) {
    userListCopy.unshift(currentUser);
  }

  return { currentUser, updatedlist: userListCopy };
};

const useHandleSelfModify = () => {
  const [getCurrentWs] = useLazyGetWsQuery();
  const [getCurrentProject] = useLazyGetProjectQuery();
  const [getCurrentAnalysis] = useLazyGetSingleAnalysisQuery();
  const dispatch = useAppDispatch();
  const [getRoles] = useLazyGetRolesQuery();
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);

  const handleSelfModify = async ({
    type,
    object,
    userRole,
  }: {
    type: string;
    object: any;
    userRole: string;
  }) => {
    try {
      switch (type) {
        case CATEGORY.Workspace:
          await getCurrentWs(object.id as string);
          break;
        case CATEGORY.Project:
          await getCurrentProject(object.id as string);
          break;
        case CATEGORY.Analysis:
          await getCurrentAnalysis({ id: object.id as string });
          break;
        default:
          break;
      }
      const response = await getRoles({
        type: type as CATEGORY,
        objectId: object.id,
      }).unwrap();

      if (response?.response?.data) {
        dispatch(
          updateProps({
            modalProps: {
              ...modalProps,
              currentUser: { ...modalProps.currentUser, role: userRole },
              roles: response.response.data,
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { refetchObject: handleSelfModify };
};

export default useHandleSelfModify;
