import {
  combineFilters,
  dataTransformationAPI,
  DataTransformationEndpoints,
  IAnalysisEdaMetaData,
  IEdaSaveExecuteRequest,
  IGetAllTransformationStepRequest,
  ISaveExecuteResponse,
  IStepForm,
  ITransformationStepRequest,
  ITransformationStepResponse,
} from "@/features/data-transformation";
import { ApiResponse } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter.ts";

export const stepsApi = dataTransformationAPI.injectEndpoints({
  endpoints: (build) => ({
    addSteps: build.mutation<
      ApiResponse<ITransformationStepResponse>,
      Partial<ITransformationStepRequest> & IAnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.addNewStep(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
      }),
      transformResponse: (
        response: ApiResponse<ITransformationStepResponse>
      ) => {
        const updatedResponse = {
          ...response,
          response: {
            ...response.response,
            data: {
              ...response.response.data,
              steps: response.response.data?.steps.map((step) => {
                return {
                  ...step,
                  config: {
                    filters: combineFilters(step.config?.filters ?? []),
                  },
                };
              }),
            },
          },
        } as ApiResponse<ITransformationStepResponse>;
        return updatedResponse;
      },
    }),
    editStep: build.mutation<
      ApiResponse<ITransformationStepResponse>,
      Partial<IStepForm> & IAnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.editStep(
          analysisId,
          edaId,
          props.transformationStepId!
        ),
        method: "PUT",
        data: {
          ...keysToSnake(props),
        },
      }),
      transformResponse: (
        response: ApiResponse<ITransformationStepResponse>
      ) => {
        const updatedResponse = {
          ...response,
          response: {
            ...response.response,
            data: {
              ...response.response.data,
              steps: response.response.data?.steps.map((step) => {
                return {
                  ...step,
                  config: {
                    filters: combineFilters(step.config?.filters ?? []),
                  },
                };
              }),
            },
          },
        } as ApiResponse<ITransformationStepResponse>;
        return updatedResponse;
      },
    }),
    allSteps: build.query<
      ApiResponse<IGetAllTransformationStepRequest>,
      IAnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId }) => ({
        url: DataTransformationEndpoints.getSteps(analysisId, edaId),
        method: "GET",
      }),
      transformResponse: (
        response: ApiResponse<IGetAllTransformationStepRequest>
      ) => {
        const updatedResponse = {
          ...response,
          response: {
            ...response.response,
            data: {
              ...response.response.data,
              transformationSteps:
                response.response.data?.transformationSteps.map((step) => {
                  return {
                    ...step,
                    config: {
                      filters: combineFilters(step.config?.filters ?? []),
                    },
                  };
                }),
            },
          },
        } as ApiResponse<IGetAllTransformationStepRequest>;
        return updatedResponse;
      },
    }),
    saveEDASteps: build.mutation<
      ApiResponse<ISaveExecuteResponse>,
      IAnalysisEdaMetaData & IEdaSaveExecuteRequest
    >({
      query: ({ analysisId, edaId, ...props }) => ({
        url: DataTransformationEndpoints.saveSteps(analysisId, edaId),
        method: "POST",
        data: {
          ...keysToSnake(props),
        },
      }),
    }),
  }),
});

export const {
  useAddStepsMutation,
  useEditStepMutation,
  useLazyAllStepsQuery,
  useAllStepsQuery,
  useSaveEDAStepsMutation,
} = stepsApi;
