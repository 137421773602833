import { Input } from "@chakra-ui/react";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";

import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextIcon,
  FormLabel,
} from "@/design/components/form";
import { IViewTransformationParameter } from "@/features/data-transformation/types";

import { StepFormContext } from "../step-form-context.ts";

export const TextForm = ({
  param,
  errors,
}: {
  param: Partial<IViewTransformationParameter>;
  errors?: string;
}) => {
  const { editEnabled, register } = useContext(StepFormContext)!;

  const defaultValue = param.value ?? param.defaultValue ?? "";
  const [formValue, setFormValue] = useState<string | number>(defaultValue);

  useEffect(() => {
    setFormValue(defaultValue);
  }, [param]);

  const isEnabled = param.isModifiable && editEnabled;

  // Hack to allow custom onchagne handler
  const fieldRegister = register(param.shortName);
  const origOnChange = fieldRegister.onChange;
  fieldRegister.onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const res = origOnChange(e);
    const value = e.target.value;
    setFormValue(value);
    return res;
  };

  const type = useMemo(() => {
    if (param.identifier == "INTEGER") {
      return "number";
    }
    return "text";
  }, [param]);

  return (
    <FormControl
      key={param.name}
      isRequired={param.isMandatory}
      isInvalid={!!errors}
    >
      <FormLabel className="!text-sm !mb-1.5 font-medium leading-none">
        {param.name}
      </FormLabel>
      <Input
        className="disabled:bg-gray-300 disabled:text-gray-900 !p-2 !text-[13px] !leading-[16px]"
        data-shortname={param.shortName}
        id={param.shortName}
        isDisabled={!isEnabled}
        size="sm"
        type={type}
        value={formValue}
        {...fieldRegister}
      />
      {!errors && param?.hintText && (
        <FormHelperText className="pl-3 text-[13px]">
          <FormHelperTextIcon className="!self-start" />
          {param?.hintText}
        </FormHelperText>
      )}
      <FormErrorMessage className="pl-3 text-[13px]">
        <FormErrorIcon className="!self-start !mt-0.5" />
        {errors}
      </FormErrorMessage>
    </FormControl>
  );
};
