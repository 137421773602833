import { ModalCloseButton, ModalHeader } from "@chakra-ui/react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { AddAdminBody } from "./add-admin-body";

export const AddAdminModal = () => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };
  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-[560px] !w-[560px]">
        <ModalHeader className="!text-lg !font-medium">
          Add Admin
        </ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6 !py-2 text-sm w-full leading-[21px] text-gray-800">
          <AddAdminBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
