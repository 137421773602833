import {
  Box,
  Button,
  Divider,
  Flex,
  ModalCloseButton,
  ModalHeader,
  Checkbox,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiFillFolder } from "react-icons/ai";
import { MdInsertDriveFile } from "react-icons/md";

import {
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { StyledIcon } from "@/features/ws-manager/components/styled-icon";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal, updateProps } from "@/slices/modal-slice.ts";
import { CATEGORY } from "@/utils/enums";

import { useGetRolesQuery } from "../../api";
import { UserRoleSchema } from "../../types";

import AddUserInput from "./add-user-input";
import UserList from "./user-list";

const ManageAccessModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { object, type, currentUser, navigate } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  ) as {
    object: any;
    type: CATEGORY;
    roles: string[];
    currentUser: UserRoleSchema;
    navigate: any;
  };

  const { data, isLoading } = useGetRolesQuery({
    objectId: object.id,
    type: type,
  });

  useEffect(() => {
    const roles = data?.response.data ?? [];
    if (roles) {
      dispatch(
        updateProps({
          modalProps: { object, type, roles, currentUser, navigate },
        })
      );
    }
  }, [data, dispatch, object, type, isLoading, currentUser, navigate]);

  const onClose = () => {
    dispatch(closeModal());
  };

  const getCustomIcon = () => {
    if (type === CATEGORY.Project) {
      return AiFillFolder;
    } else if (type === CATEGORY.Analysis) {
      return MdInsertDriveFile;
    }
    return null;
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      isCentered
      size="2xl"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="relative flex items-center justify-between mx-6">
          <Flex className="gap-3" align={"center"}>
            <StyledIcon
              title={type === CATEGORY.Workspace ? object.name : undefined}
              emoji={object.emoji}
              customIcon={getCustomIcon()}
              icon={object.icon}
            />
            <Box className="text-xl font-semibold">{object.name}</Box>
          </Flex>
          <ModalCloseButton className="!mr-0 !relative !top-0 !right-0" />
        </ModalHeader>
        <Divider className="" />
        <ModalBody className="flex flex-col justify-end !mx-6 gap-2 overflow-hidden">
          {isLoading ? (
            <Flex className="items-center justify-center" minH={"40vh"}>
              <Spinner size="sm" />
              <Box className="ml-2">Loading...</Box>
            </Flex>
          ) : (
            <>
              <AddUserInput />
              <UserList />
              {/*
              <Flex justify={"space-between"}>
                <Checkbox defaultChecked={false} isDisabled={true} size="sm">
                  Restrict Workspace & Project Access Viewers
                </Checkbox>
                <Button
                  colorScheme="dark"
                  isDisabled={true}
                  leftIcon={<MdLink size={22} />}
                  size="sm"
                  variant="ghost"
                >
                  Copy Link
                </Button>
              </Flex>
              */}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageAccessModal;
