import { Flex, IconButton } from "@chakra-ui/react";
import clsx from "clsx";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DeleteFilled } from "@/components/icons/delete-filled.tsx";
import { STEP_STATUS, tableLoading } from "@/features/data-transformation";
import { useRestoreRemoveStep } from "@/features/data-transformation/hooks";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";

import { StepContext, StepContextType } from "./step-context.ts";

interface StepConnectorProps {
  isLast: boolean;
}

export const StepConnector = ({ isLast }: StepConnectorProps) => {
  const dispatch = useDispatch();
  const { step, isOpen, hasWriteAccess } = useContext(
    StepContext
  ) as StepContextType;
  const { onRestoreRemoveClicked } = useRestoreRemoveStep(step);
  const isDataLoading = useSelector(tableLoading);
  const type = step.activeStatus
    ? step?.status ?? STEP_STATUS.Active
    : STEP_STATUS.Removed;

  const stepStyles = useMemo(() => {
    let style;
    switch (type) {
      case STEP_STATUS.Removed:
        style = {
          connector: "bg-gray-600 opacity-20",
        };
        break;
      case STEP_STATUS.Failed:
        style = {
          connector: "bg-red-400 group-hover:bg-red-600",
        };
        break;
      default:
        style = {
          connector: "bg-gray-600 opacity-20",
        };
    }
    return style;
  }, [step]);

  const stepStylesOpen = useMemo(() => {
    let style;
    switch (type) {
      case STEP_STATUS.Removed:
        style = {
          connector: "bg-gray-600 opacity-20",
        };
        break;
      case STEP_STATUS.Failed:
        style = {
          connector: "bg-red-400 opacity-20 group-hover:bg-red-600",
        };
        break;
      default:
        style = {
          connector: "bg-gray-600 opacity-20",
        };
    }
    return style;
  }, [step]);

  const isRemoved = type === STEP_STATUS.Removed;

  const showIcon = !isRemoved && isOpen && hasWriteAccess && !isDataLoading;

  const deleteStep = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.DELETE_STEP,
        modalProps: {
          onDelete: () => onRestoreRemoveClicked(false),
        },
      })
    );
  };

  return (
    <Flex className="justify-between">
      <Flex
        className={clsx(
          "w-[1px] transition-all duration-300 ease-in-out group-hover:opacity-100",
          isLast && "!opacity-0",
          isOpen ? "h-3 ml-2 group-hover:h-8" : "h-4 mx-auto ",
          isOpen ? stepStylesOpen.connector : stepStyles.connector
        )}
      />
      {showIcon && (
        <Flex className="p-1">
          <IconButton
            className="!opacity-0 !h-0 transition-all duration-300 ease-in-out group-hover:!opacity-100 group-hover:!h-6"
            w="24px !important"
            minW="24px !important"
            px="0px"
            py="2px"
            color="gray.400"
            aria-label="delete"
            colorScheme="invert"
            icon={<DeleteFilled />}
            onClick={deleteStep}
            size="md"
          />
        </Flex>
      )}
    </Flex>
  );
};
