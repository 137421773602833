import { BaseQueryFn, retry } from "@reduxjs/toolkit/query";
import type { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from "axios";

import { getBaseUrl } from "@/utils/baseUrls.ts";
import { keysToCamel } from "@/utils/snakeCaseConverter.ts";

import { axiosInstance } from "./axios.ts";
import { CacheKey, cacheUtil } from "./localstorage.ts";

export const baseQuery =
  (
    {
      baseUrl,
      defaultHeaders,
      errorHandlerFn,
      prepareUrl,
    }: {
      baseUrl: string;
      defaultHeaders?: object;
      errorHandlerFn?: (error: any) => void;
      prepareUrl?: (url: string, api: { getState: () => unknown }) => string;
    } = {
      baseUrl: getBaseUrl(),
      defaultHeaders: {},
      errorHandlerFn: () => {},
    }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      camelCase?: boolean;
      ignoreBaseUrl?: boolean;
      customAuth?: boolean;
      body?: any;
      onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
      timeout?: number;
    },
    unknown,
    unknown,
    any
  > =>
  async (
    {
      url,
      method,
      data,
      params,
      headers,
      camelCase = true,
      body,
      ignoreBaseUrl = false,
      customAuth = false,
      onUploadProgress,
      timeout,
    },
    _api,
    _t
  ) => {
    try {
      const tenantToken = cacheUtil.getUnParsed(CacheKey.TenantId) ?? {};
      const userId = cacheUtil.getUnParsed(CacheKey.UserId) ?? {};

      let finalUrl = ignoreBaseUrl ? url : baseUrl + url;
      if (prepareUrl && !ignoreBaseUrl) {
        finalUrl = prepareUrl(url, _api);
      }

      const result = await axiosInstance({
        url: finalUrl,
        method,
        data: data ?? body,
        params,
        headers: {
          ...headers,
          ...defaultHeaders,
          "X-TENANT-ID": tenantToken,
          "X-USER-ID": userId,
        },
        signal: _api?.signal,
        customAuth: customAuth,
        onUploadProgress,
        timeout: timeout && timeout > 0 ? timeout : undefined,
      } as AxiosRequestConfig);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return {
        data: camelCase ? keysToCamel(result.data) : result.data,
      };
    } catch (axiosError) {
      //TODO: Error handling can be moved to interceptor
      console.log("Axios Error: \n", axiosError);
      const err = axiosError as AxiosError;
      errorHandlerFn?.(err);

      return {
        error: {
          code: err.code,
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

(window as any).sharedBaseQuery = baseQuery;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });
