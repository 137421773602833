import { isEmpty } from "lodash";

import { useAppSelector } from "@/reduxHooks";
import {
  fetchedPermission,
  permissionsList,
} from "@/slices/permisson-slice.ts";
import { CATEGORY } from "@/utils/enums";

const createPermissionString = (type: CATEGORY, permission: string) => {
  switch (type) {
    case CATEGORY.Workspace:
      return `workspace.${permission}`;
    case CATEGORY.Project:
      return `project.${permission}`;
    case CATEGORY.Analysis:
      return `analysis.${permission}`;
    default:
      return permission;
  }
};

const usePermissions = () => {
  const flattenedPermissions = useAppSelector(permissionsList);
  const hasFetchedPermission = useAppSelector(fetchedPermission);
  const checkPermission = ({
    id,
    requiredPermission,
    type,
  }: {
    id?: string;
    requiredPermission: string;
    type: CATEGORY | undefined;
  }) => {
    if (!type || !id) return false;
    const hasPermissionMap = !isEmpty(flattenedPermissions);
    const permissionString = createPermissionString(type, requiredPermission);
    if (!hasPermissionMap) return false;
    const objPermissions = flattenedPermissions[id]?.permissions;
    if (!objPermissions) return false;
    return objPermissions.includes(permissionString);
  };

  return { checkPermission, hasFetchedPermission };
};

export default usePermissions;
