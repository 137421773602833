import { Box, Button, Flex, Radio, RadioGroup, chakra } from "@chakra-ui/react";
import { camelCase } from "lodash";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { MdArrowBack } from "react-icons/md";
import { Node } from "reactflow";
import { ZodError } from "zod";

import { useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice";

import { useCreateFlowMutation } from "../../api";
import { FlowSchema, NodeType } from "../../types";
import { getCreateFlowPayload } from "../../utils/transform-response";

import { FlowUploadData, flowUploadSchema } from ".";

export const FlowSharingForm = ({
  setActiveStep,
  formState,
}: {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formState: UseFormReturn<FlowUploadData, any, undefined>;
}) => {
  const { register, handleSubmit, setError, getValues } = formState;
  const dispatch = useAppDispatch();
  const { node, setNodes, instance } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );

  const [createFlow, { isLoading }] = useCreateFlowMutation();

  const toast = useShowToast(undefined, undefined, false);

  const uploadFlow = async (flowData: FlowUploadData) => {
    const flowD = { ...node, data: { ...node.data, ...flowData } };
    const payload = getCreateFlowPayload({ flow: flowD, rf: instance });
    try {
      const response = await createFlow({
        data: payload,
      }).unwrap();
      const flowUpdated = response.response.data?.flow as FlowSchema;
      const flowUiIdVsNuiId = response.response.data?.uiIdVsNuiId || {};
      if (flowUpdated) {
        setNodes((prevNodes: Node[]) => {
          const updatedNodes = prevNodes.map((n) => {
            if (n.id === node.id) {
              return {
                ...n,
                data: {
                  ...n.data,
                  ...flowUpdated,
                },
              };
            } else {
              if (flowUiIdVsNuiId[camelCase(n.id)]) {
                return {
                  ...n,
                  data: {
                    ...n.data,
                    nodeUsageInstanceId: flowUiIdVsNuiId[camelCase(n.id)],
                  } as NodeType,
                };
              }
              return n;
            }
          });
          return updatedNodes;
        });
        toast({
          title: "Flow Created",
          description: "Flow has been created successfully",
        });
      }
    } catch (e) {
      toast({
        title: "Error saving flow",
        variant: "subtle",
        status: "error",
      });
    }
  };

  const onSubmit = async (data: FlowUploadData) => {
    try {
      const validatedData = await flowUploadSchema.parseAsync(data);
      await uploadFlow(validatedData);
      dispatch(closeModal());
    } catch (error) {
      if (error instanceof ZodError) {
        error.errors.forEach((err) => {
          setError(err.path[0] as keyof FlowUploadData, {
            message: err.message,
          });
        });
      }
    }
  };

  const defaultScope = getValues("scope");

  return (
    <chakra.form
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      minH={"100%"}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      px={5}
    >
      <Flex direction="column" gap={5}>
        <Box fontWeight="semibold">
          Share this flow with :<chakra.sup color="orange.400">*</chakra.sup>
        </Box>
        <RadioGroup
          className="px-5 flex flex-col gap-3"
          defaultValue={defaultScope}
        >
          <Radio {...register("scope")} name="scope" value="workspace">
            Everyone on this workspace
          </Radio>
          <Radio {...register("scope")} name="scope" value="organization">
            Everyone on this Organization
          </Radio>
        </RadioGroup>
      </Flex>

      <Flex justify={"flex-end"} gap={3}>
        <Button
          colorScheme="dark"
          leftIcon={<MdArrowBack />}
          onClick={() => setActiveStep(0)}
          size="sm"
          variant="outline"
        >
          Back
        </Button>
        <Button
          colorScheme="dark"
          isLoading={isLoading}
          size="sm"
          type="submit"
        >
          Save
        </Button>
      </Flex>
    </chakra.form>
  );
};
