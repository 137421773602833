import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    _invalid: {
      borderColor: "var(--chakra-colors-red-600)",
    },
  },
});

export const numberInputTheme = defineMultiStyleConfig({ baseStyle });
