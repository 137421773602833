import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle(() => {
  return {
    dialog: {
      borderRadius: "3px",
      color: "black.900",
      rowGap: "16px",
      p: "16px 0",
    },
    closeButton: {
      color: "black.900",
      top: "16px",
      borderRadius: "3px",
      marginRight: "24px",
    },
    header: {
      p: "0px",
      color: "inherit",
      fontWeight: "semibold",
      marginLeft: "24px",
    },
    subheader: {
      color: "inherit",
      p: "0px",
    },
    body: {
      padding: "0",
    },
    footer: {
      p: "0px",
    },
  };
});
export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
