import { Flex } from "@chakra-ui/react";
import { HotColumn, HotTable } from "@handsontable/react";
import { captureException } from "@sentry/react";
import { CellCoords } from "handsontable";
import { DropdownMenu } from "handsontable/plugins/dropdownMenu";
import { registerAllModules } from "handsontable/registry";
import { debounce, isEmpty, isEqual } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";

import {
  COLUMN_TYPE_MAP,
  EdaMetaDataContext,
  ERROR_CODE,
  executeInProgress,
  FETCH_TYPE,
  IFailedTask,
  ISort,
  ITableDataColumns,
  ITableDataRows,
  ITableTaskData,
  resetFetchTrigger,
  selectedColumn,
  seperateFilters,
  setColumns,
  setSelectedColumn,
  setSorting,
  setTableLoading,
  setTableSizeMetaData,
  SORT_ORDER,
  sorting,
  TABLE_DATA_STATUS,
  tableLoading,
  triggerFetch,
  triggerTableFetchEvent,
  useGetTableDataQuery,
  useLazyGetTableDataQuery,
  viewFilters,
} from "@/features/data-transformation";
import { useMoveColumn } from "@/features/data-transformation/components/table/useMoveColumn.ts";
import { useIsBackground } from "@/features/data-transformation/hooks/useIsBackground.ts";
import { usePrevious } from "@/hooks/usePrevious.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { STATUS } from "@/utils/enums.ts";
import { keysToCamel } from "@/utils/snakeCaseConverter.ts";

import { useColumnTransformations } from "../../hooks/useColumnTransformations.ts";

import { getEmptyRowIndexAfterVisible } from "./getIndexForVisibleRow.ts";
import { LoadingTable } from "./loading-table.tsx";
import { CustomRenderer } from "./renderer/cell-renderer.tsx";
import { CustomHeaderRenderer } from "./renderer/header-renderer.tsx";
import { useTableContextMenuRenderer } from "./renderer/table-context-menu-renderer.tsx";
import { useTableMenuRenderer } from "./renderer/table-menu-renderer.tsx";

import "./hot-table.css";
registerAllModules();

type TableRequestProps = {
  startIndex?: number;
  endIndex?: number;
};

const PAGINATION_CONSTANT = 100;
const LOADING_TABLE_ROWS = 200;
const POLLING_INTERVAL = 500;

let isAutomaticScroll = false;
export const CustomHotTable = () => {
  const dispatch = useDispatch();

  const metaData = useContext(EdaMetaDataContext);

  const hotRef = useRef<any>(null);
  const failureCountRef = useRef(0);
  const shouldResetTable = useRef<boolean>(true);

  const dataIndexs = useRef<TableRequestProps>({
    startIndex: 0,
    endIndex: PAGINATION_CONSTANT,
  });

  const selectedCol = useSelector(selectedColumn);
  const filters = useSelector(viewFilters);
  const triggerTableApi = useSelector(triggerTableFetchEvent);
  const sort = useSelector(sorting);
  const isTableLoading = useSelector(tableLoading);
  const isExecuting = useSelector(executeInProgress);
  const prevFilters = usePrevious(filters);

  const [data, setData] = useState<ITableDataRows[]>([]);
  const [availableRecords, setAvailableRecords] = useState(LOADING_TABLE_ROWS);
  const [columnData, setColumnData] = useState<ITableDataColumns[]>([]);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [shouldRecreateTable, setShouldRecreateTable] =
    useState<boolean>(false);
  const [fetchedIndex, setFetchedIndex] = useState(new Set<number>());
  const isBg = useIsBackground();

  const queryParams = useMemo(
    () => ({
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      startIndex: dataIndexs.current.startIndex!,
      endIndex: dataIndexs.current.endIndex!,
      viewFilters: seperateFilters(filters),
      sort: sort,
      requestId: requestId!,
    }),
    [
      metaData.analysisId,
      metaData.edaId,
      dataIndexs.current.startIndex,
      dataIndexs.current.endIndex,
      filters,
      requestId,
    ]
  );

  const [getInitialRequest] = useLazyGetTableDataQuery();
  const {
    data: tableData,
    isError: isApiError,
    error: apiError,
  } = useGetTableDataQuery(queryParams, {
    skip: requestId === null || isBg,

    pollingInterval: POLLING_INTERVAL,
    // selectFromResult: ({ data: selectorData }) => ({
    //   data: selectorData?.response.data,
    // }),
  });

  useEffect(() => {
    if (triggerTableApi) {
      resetAndRefetchTable();
      dispatch(resetFetchTrigger());
    }
    return () => {
      setRequestId(null);
    };
  }, [triggerTableApi]);

  useEffect(() => {
    const refreshTable = !isEqual(filters, prevFilters);

    if (refreshTable) {
      resetAndRefetchTable();
    }
    return () => {
      setRequestId(null);
    };
  }, [metaData.edaId, filters]);

  const handleCustomFilerError = (message: any) => {
    dispatch(
      openModal({
        modalProps: {
          header: "Update applied filter",
          body: message,
          showRefresh: false,
        },
        modalType: ModalTypes.FATAL_ERROR,
      })
    );
  };

  const handleCustomSortError = (message: string) => {
    dispatch(setSorting([]));
    dispatch(
      openModal({
        modalProps: {
          header: "Applied sorting was cleared",
          body: message,
          showRefresh: false,
        },
        modalType: ModalTypes.FATAL_ERROR,
      })
    );
    shouldResetTable.current = true;
    resetAndRefetchTable([]);
  };

  const resetLoader = () => {
    failureCountRef.current = 0;
  };

  const handleError = (error: IFailedTask) => {
    console.log("ERROR", error);
    if (error?.errorCode == ERROR_CODE.FILTER_DELETED_COLUMN_ERROR) {
      resetLoader();
      setTimeout(() => {
        dispatch(setTableLoading(false));
        setData([]);
        setColumnData([]);
      }, 100);
      handleCustomFilerError(error?.errorMessage);
      return;
    }

    if (error?.errorCode == ERROR_CODE.SORT_DELETED_COLUMN_ERROR) {
      resetLoader();
      handleCustomSortError(error?.errorMessage);
      return;
    }

    if (failureCountRef.current > 1) {
      failureCountRef.current = 0;
      dispatch(setTableLoading(false));

      raiseFatalError();
      return;
    } else {
      failureCountRef.current += 1;
      requestForTableData({ startIndex: 0 });
    }
  };

  useEffect(() => {
    const _data = tableData?.response?.data?.taskData ?? {};

    const isError =
      tableData?.response?.data?.taskStatus == TABLE_DATA_STATUS.FAILED ||
      tableData?.status == STATUS.FAIL ||
      isApiError;

    if (isError) {
      setRequestId(null);
      captureException(tableData);
      const error = isEmpty(tableData?.response?.data?.taskData)
        ? keysToCamel(apiError?.data?.response?.data?.task_data)
        : tableData?.response?.data?.taskData;

      handleError(error as IFailedTask);
      return;
    }

    if (!isEmpty(_data)) {
      failureCountRef.current = 0;

      setShouldRecreateTable(false);
      try {
        updateTable(_data);
      } catch (e) {
        captureException(e, { extra: { data: _data, sortingError: true } });
        updateTable(_data, true);
      }
      dispatch(setTableLoading(false));
    }
  }, [tableData, isApiError]);

  const raiseFatalError = () => {
    captureException(tableData);
    dispatch(openModal({ modalProps: {}, modalType: ModalTypes.FATAL_ERROR }));
  };

  const updateTable = (_data: ITableTaskData, resetData?: boolean) => {
    let hot: any;
    if (hotRef.current) {
      hot = hotRef.current.hotInstance;
    }
    // IMP Need to destructure the data because of reference issue cause by handson table
    const _dataRecords = [..._data.records!];

    setColumnData(_data.columns!);
    setRequestId(null);
    const maxTableRecords = Math.min(
      Number(_data.availableRecords),
      Number(_data.hardLimitRecords)
    );
    setAvailableRecords(maxTableRecords);

    dispatch(
      setTableSizeMetaData({
        column: _data.columns!.length.toString(),
        rows: _data?.availableRecords ?? "0",
      })
    );

    if (
      shouldResetTable.current ||
      dataIndexs.current.startIndex === 0 ||
      resetData
    ) {
      shouldResetTable.current = false;
      const colData = _data.columns!;

      dispatch(setColumns(colData));
      dispatch(triggerFetch(FETCH_TYPE.EXPLORE));

      setData(_dataRecords);

      updateFetchedIndex(
        dataIndexs.current.startIndex!,
        dataIndexs.current.endIndex!
      );

      updateColumn(colData);

      dispatch(triggerFetch(FETCH_TYPE.SUGGESTIONS));

      if (!hotRef.current) return;
      hot.loadData(_dataRecords);
    } else {
      const startIndex = dataIndexs.current.startIndex!;

      updateFetchedIndex(startIndex, dataIndexs.current.endIndex!);

      hot.batch(() => {
        _dataRecords.forEach((item, idx) => {
          Object.keys(item).forEach((key) => {
            hot.setDataAtRowProp(startIndex + idx, key, item[key]);
          });
        });
      });
    }
  };

  const updateFetchedIndex = (startIndex: number, endIndex: number) => {
    const newIndexes: number[] = [];
    for (let i = startIndex; i < endIndex; i++) {
      newIndexes.push(i);
    }

    setFetchedIndex((prevIndexes) => new Set([...prevIndexes, ...newIndexes]));
  };

  const clearOldData = (startIndex: number) => {
    setFetchedIndex(new Set<number>());
    setAvailableRecords(LOADING_TABLE_ROWS);

    dataIndexs.current.startIndex = startIndex;
    dataIndexs.current.endIndex = startIndex + PAGINATION_CONSTANT;
  };

  const requestForTableData = async ({
    startIndex,
    updatedSort,
  }: {
    startIndex?: number;
    updatedSort?: ISort[];
  }) => {
    if (!metaData.edaId) return;

    try {
      // Handle reset when new sampling is selected
      if (startIndex != undefined) {
        clearOldData(startIndex);
        setShouldRecreateTable(true);
        setData([]);
      }

      const apiData = await getInitialRequest({
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId,
        startIndex: dataIndexs.current.startIndex!,
        endIndex: dataIndexs.current.endIndex!,
        viewFilters: seperateFilters(filters),
        sort: updatedSort ?? sort,
      }).unwrap();

      setRequestId(apiData.response.data!.requestId);
    } catch (e) {
      captureException(e);
      dispatch(
        openModal({ modalProps: {}, modalType: ModalTypes.FATAL_ERROR })
      );
    }
  };

  const resetAndRefetchTable = (updatedSort?: ISort[]) => {
    if (hotRef.current) {
      isAutomaticScroll = true;
      const hot = hotRef.current.hotInstance;
      hot.scrollViewportTo({ row: 0 });
    }

    shouldResetTable.current = true;

    requestForTableData({ startIndex: 0, updatedSort });
    isAutomaticScroll = false;
  };

  const { moveColumn } = useMoveColumn(columnData, setColumnData);
  const {
    onRenameClick,
    onDuplicateClick,
    onChangeDataTypeClick,
    onRemoveClick,
    addFilter,
    onClearFilterClick,
    showFullValueModal,
    findAndReplace,
    openFilter,
    sortData: sortTable,
  } = useColumnTransformations(sort);

  const sortData = (column: string, type: SORT_ORDER) => {
    const updatedSort = sortTable(column, type);
    resetAndRefetchTable(updatedSort);
  };

  const contextMenu = useTableContextMenuRenderer({
    hotRef,
    columnData,
    showFullValueModal,
    findAndReplace,
    addFilter,
  });
  const dropdownMenu = useTableMenuRenderer({
    hotRef,
    columnData,
    addFilter,
    openFilter,
    sortData,
    clearFilter: onClearFilterClick,
    duplicateColumn: onDuplicateClick,
    renameColumn: onRenameClick,
    removeColumn: onRemoveClick,
    changeDataType: onChangeDataTypeClick,
    sorting: sort,
  });

  const isFetched = (firstVisibleIndex: number, lastVisibleIndex: number) => {
    let allFetched = true;
    for (let i = firstVisibleIndex; i <= lastVisibleIndex; i++) {
      if (!fetchedIndex.has(i)) {
        allFetched = false;
        break;
      }
    }
    return allFetched;
  };

  const paginationFn = debounce(() => {
    if (isAutomaticScroll) return;

    if (hotRef.current) {
      const hot = hotRef.current.hotInstance;
      const plugin = hot.getPlugin("autoRowSize");
      const firstVisibleRow: number = Math.max(
        0,
        plugin.getFirstVisibleRow() - 2
      );
      const lastVisibleRow: number = plugin.getLastVisibleRow() + 2;

      if (isFetched(firstVisibleRow, lastVisibleRow)) return;

      const index = getEmptyRowIndexAfterVisible(hot, firstVisibleRow);

      dataIndexs.current.startIndex = index;
      dataIndexs.current.endIndex = index + PAGINATION_CONSTANT;

      requestForTableData({});
    }
  }, 1000);

  const selectColumnWithIndex = (index: number) => {
    setTimeout(() => {
      if (hotRef.current) {
        hotRef.current.hotInstance.selectColumns(index, index);
      }
    }, 100);
  };

  const setCurrentColumn = (
    coords: CellCoords,
    { shouldSkipDeselect }: { shouldSkipDeselect: boolean }
  ) => {
    const index = coords.col;
    const currentSelectedCol = selectedCol?.column.name;
    const dataType = columnData[index].dataType;

    const deselectCol =
      currentSelectedCol == columnData[index].name && !shouldSkipDeselect;
    if (deselectCol) {
      dispatch(setSelectedColumn(null));
      if (hotRef.current) {
        hotRef.current.hotInstance.deselectCell();
      }
      return;
    }

    // Visually select columns when a cell within them is right clicked
    if (hotRef.current) {
      hotRef.current.hotInstance.selectColumns(index, index, coords);
    }

    const columnObj = {
      column: columnData[index],
      index: index.toString(),
      columnType: COLUMN_TYPE_MAP[dataType],
    };
    dispatch(setSelectedColumn(columnObj));
  };
  const checkIfColumnExists = (
    latestColumns: ITableDataColumns[],
    colName: string
  ) => {
    return latestColumns.some((col) => col.name == colName);
  };

  const updateColumn = (latestColumns: ITableDataColumns[]) => {
    if (!selectedCol) return;
    const index = Number(selectedCol.index);

    const previousSelectedColName = selectedCol.column.name;
    const columnExists = checkIfColumnExists(
      latestColumns,
      previousSelectedColName
    );

    if (!columnExists) {
      dispatch(setSelectedColumn(null));
      return;
    }

    selectColumnWithIndex(index);
    const hasIndex = latestColumns[index];
    if (!hasIndex) {
      const colIndex = latestColumns.findIndex(
        (item) => item.name === previousSelectedColName
      );
      const columnObj = {
        column: latestColumns[colIndex],
        index: colIndex.toString(),
        columnType: COLUMN_TYPE_MAP[latestColumns[colIndex].dataType],
      };
      dispatch(setSelectedColumn(columnObj));
      selectColumnWithIndex(colIndex);
      return;
    }

    const shouldUpdate = previousSelectedColName !== latestColumns[index].name;

    if (!shouldUpdate) return;

    const latestIndex = latestColumns.findIndex(
      (item) => item.name === previousSelectedColName
    );
    const dataType = latestColumns[latestIndex].dataType;
    selectColumnWithIndex(latestIndex);
    const columnObj = {
      column: latestColumns[latestIndex],
      index: index.toString(),
      columnType: COLUMN_TYPE_MAP[dataType],
    };
    dispatch(setSelectedColumn(columnObj));
  };

  const hasNoData = isEmpty(data) && isEmpty(columnData);

  if (shouldResetTable.current && isTableLoading) return <LoadingTable />;
  if (hasNoData && !isTableLoading)
    return (
      <Flex className="h-full w-full items-center justify-center">
        The combination of steps and filters resulted in no values
      </Flex>
    );

  if (!isEmpty(columnData) && !shouldRecreateTable)
    return (
      <HotTable
        ref={hotRef}
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        afterScroll={paginationFn}
        data={data}
        minRows={availableRecords}
        maxRows={availableRecords}
        viewportRowRenderingOffset={3}
        rowHeaders={false}
        selectionMode="single"
        width="100%"
        height="100%"
        rowHeights={29}
        autoWrapRow={false}
        autoRowSize={false}
        autoColumnSize={false}
        manualColumnResize={true}
        allowInsertRow={false}
        allowRemoveRow={false}
        allowInsertColumn={false}
        allowRemoveColumn={false}
        stretchH="all"
        colWidths={150}
        editor={false}
        readOnly={true}
        trimWhitespace={false}
        outsideClickDeselects={false}
        manualColumnMove={!isExecuting}
        viewportColumnRenderingOffset={1}
        afterDropdownMenuHide={(instance: DropdownMenu) => {
          instance.updatePlugin();
        }}
        beforeOnCellContextMenu={(event, coords) => {
          if (coords.row < 0) {
            event.stopImmediatePropagation();
          }
        }}
        contextMenu={contextMenu}
        dropdownMenu={dropdownMenu}
        afterColumnMove={(
          movedColumns,
          finalIndex,
          dropIndex,
          movePossible,
          orderChanged
        ) => {
          if (!orderChanged) return;

          const columnMoved = columnData[movedColumns[0]].name;
          moveColumn(columnMoved, movedColumns[0], dropIndex!, finalIndex);
        }}
        colHeaders={(index) => {
          const column = columnData[index];
          const columnName = column?.name;

          const hasFilter = filters?.find(
            (_filter) => _filter.column == columnName
          );

          const str = renderToString(
            <CustomHeaderRenderer
              col={column}
              totalRecords={availableRecords}
              hasFilter={hasFilter}
              onClick={() => {
                addFilter(columnName);
              }}
            />
          );
          return str;
        }}
        afterOnCellMouseOver={(_event, coords, TD) => {
          if (coords.row > -1) TD.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
        }}
        afterOnCellMouseOut={(_event, coords, TD) => {
          if (coords.row > -1) {
            TD.style.backgroundColor = "white";
          }
        }}
        beforeOnCellMouseUp={(_event, coords, _TD) => {
          const target = _event.target as HTMLElement | null;
          if (target?.id == "filterIcon") {
            const col = columnData[coords.col].name;
            openFilter(col);
          } else {
            const isDropdownClicked = target?.className === "changeType";
            const skipDeselect = isDropdownClicked || coords.row != -1;
            setCurrentColumn(coords, {
              shouldSkipDeselect: skipDeselect,
            });
          }
        }}
        // afterDeselect={clearColumnSelection}
        licenseKey="non-commercial-and-evaluation"
        activeHeaderClassName="activeHeader"
        headerClassName="custom_header group"
      >
        {columnData &&
          columnData.map((col, index) => {
            return (
              <HotColumn
                data={col.name}
                key={`${col.name}-${index}`}
                editor={false}
              >
                <CustomRenderer fetchedIndex={fetchedIndex} hot-renderer />
              </HotColumn>
            );
          })}
      </HotTable>
    );
  return <LoadingTable />;
};
