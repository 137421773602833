import { EndpointBuilder } from "@reduxjs/toolkit/query";

import { RecycleListProps, RestoreItem } from "@/features/ws-manager/types";
import { ApiResponse } from "@/types";

import { wsManagerApi } from "./api";
import { WsManagerEndpoints } from "./api-endpoints.ts";

export const recycleBinApi = wsManagerApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    restoreItem: build.mutation<ApiResponse<any>, RestoreItem>({
      query: (body) => {
        const clientId = import.meta.env.VITE_CLIENT_ID;
        return {
          url: WsManagerEndpoints.restoreItem(clientId),
          method: "PUT",
          data: body,
        };
      },
      invalidatesTags: [
        { type: "Recycle", id: "LIST" },
        { type: "Workspaces", id: "LIST" },
        { type: "Project", id: "LIST" },
      ],
    }),
    getRecycleList: build.query<
      ApiResponse<RecycleListProps>,
      void | { query?: string; next?: string }
    >({
      query: (body) => {
        const clientId = import.meta.env.VITE_CLIENT_ID;
        let url = WsManagerEndpoints.getRecycleList(clientId);
        if (body && body.query) {
          url = url + `?query=${body.query}`;
        }
        return {
          url: body?.next ?? url,
          // ignoreBaseUrl: body?.next,
        };
      },

      providesTags: [{ type: "Recycle", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetRecycleListQuery, useRestoreItemMutation } =
  recycleBinApi;
