import { chakra } from "@chakra-ui/react";
import { clsx } from "clsx";

interface TagProps {
  text: string;
  remove?: any;
  disabled?: boolean;
  className?: string;
  onClick?: any;
}

export default function Tag({
  text,
  remove,
  disabled,
  className,
  onClick,
}: TagProps) {
  const handleOnRemove = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    remove?.(text);
  };

  const onSelect = () => {
    onClick?.(text);
  };

  return (
    <chakra.span
      className={clsx(
        "inline-flex items-center justify-center bg-gray-200 rounded-md px-2 py-1",
        className,
        !!onClick && "cursor-pointer",
      )}
      onClick={onSelect}
    >
      <chakra.span className="font-medium capitalize text-xs tracking-tight text-gray-800">
        {text}
      </chakra.span>
      {!disabled && (
        <button
          type="button"
          onClick={handleOnRemove}
          aria-label={`remove ${text}`}
          className="ml-2  text-sm"
        >
          &#10005;
        </button>
      )}
    </chakra.span>
  );
}
