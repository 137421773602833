import { Flex, Text } from "@chakra-ui/react";
import React from "react";

type DetailsRowProps = {
  header: string;
  children: React.ReactNode;
};
export const DetailsRow = ({ header, children }: DetailsRowProps) => {
  return (
    <Flex className="flex-col gap-0.5">
      <Text className="text-[0.75rem] tracking-[0.0375rem] text-gray-600 font-bold uppercase">
        {header}
      </Text>
      <Text className="text-sm2 font-medium">{children}</Text>
    </Flex>
  );
};
