import {
  chakra,
  createStylesContext,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { forwardRef, HTMLChakraProps } from "@chakra-ui/system";
import React, { createContext, useMemo, useState } from "react";

import {
  NavsDescendantsProvider,
  NavsProvider,
  UseNavsProps,
  useNavs,
} from "./use-nav.ts";

const [StylesProvider, useStyles] = createStylesContext("NavigationPanel");

type PanelProps = {
  type: "studio" | "manager";
};

export interface NavigationPanelProps
  extends UseNavsProps,
    ThemingProps<"NavigationPanel">,
    Omit<HTMLChakraProps<"div">, "onChange">,
    PanelProps {
  children: React.ReactNode;
}

export type BoolStateFunction = React.Dispatch<React.SetStateAction<boolean>>;
export interface ContextProps extends PanelProps {
  open: boolean;
  toggle: BoolStateFunction;
}
export const NavigationPanelContext = createContext<ContextProps>(
  {} as ContextProps,
);

export const NavigationPanel = forwardRef<NavigationPanelProps, "div">(
  function Nav(props, ref) {
    const { colorScheme } = props;
    const { children, type, ...rest } = omitThemingProps(props);
    const [open, toggle] = useState<boolean>(true);

    const styles = useMultiStyleConfig("NavigationPanel", {
      variant: type,
      colorScheme,
    });
    const { htmlProps, descendants, ...ctx } = useNavs(rest);
    const context = useMemo(() => ctx, [ctx]);

    const { isFitted: _, ...rootProps } = htmlProps as any;

    return (
      <NavsDescendantsProvider value={descendants}>
        <NavsProvider value={context}>
          <NavigationPanelContext.Provider value={{ type, open, toggle }}>
            <chakra.div
              ref={ref}
              {...rootProps}
              __css={styles.container}
              aria-expanded={open}
            >
              <StylesProvider value={styles}>{children}</StylesProvider>
            </chakra.div>
          </NavigationPanelContext.Provider>
        </NavsProvider>
      </NavsDescendantsProvider>
    );
  },
);

NavigationPanel.displayName = "NavigationPanel";

// eslint-disable-next-line react-refresh/only-export-components
export { useStyles };
