import { Flex } from "@chakra-ui/react";
import { useContext } from "react";

import { ErrorParamProps } from "@/features/data-transformation";
import { FORM_DATA_TYPE } from "@/features/data-transformation/utils";

import { SelectForm, TextForm, MultiSelectSelectForm } from "./form-types";
import { StepFormContext } from "./step-form-context.ts";

export const FormBody = ({ errors }: { errors: ErrorParamProps[] }) => {
  const { step } = useContext(StepFormContext)!;
  const parameters = step?.parameters ?? [];

  return (
    <Flex className="flex-col w-full h-full gap-y-6">
      {parameters.map((param) => {
        const id = param.parameterId ?? param.id;

        switch (param.dataType) {
          case FORM_DATA_TYPE.MULTI_SELECT:
            return (
              <MultiSelectSelectForm
                key={param.parameterId ?? param.id}
                param={param}
                errors={errors.find((error) => error.parameterId === id)?.error}
              />
            );
          case FORM_DATA_TYPE.DROPDOWN:
            return (
              <SelectForm
                key={param.parameterId ?? param.id}
                param={param}
                errors={errors.find((error) => error.parameterId === id)?.error}
              />
            );
          case FORM_DATA_TYPE.TEXT:
          default:
            return (
              <TextForm
                key={param.parameterId ?? param.id}
                param={param}
                errors={errors.find((error) => error.parameterId === id)?.error}
              />
            );
        }
      })}
    </Flex>
  );
};
