import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { MdsInfoRound } from "react-icons-with-materialsymbols/mds";

import { ModelConfig } from "@/features/llm-prompt/types";

interface RangeSelectorProps {
  config: ModelConfig | undefined;
  isHistoryPanelOpen: boolean;
  onChange?: (id: string | undefined, val: number) => void;
}

const RangeSelector = ({
  config,
  isHistoryPanelOpen,
  onChange,
}: RangeSelectorProps) => {
  const defaultValue = useMemo(() => {
    const value = config?.selectedValue ?? config?.defaultParameterValue;
    if (!value) return 0;
    return Number(value);
  }, [config]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(config?.id, Number(e.target.value));
    },
    [onChange, config]
  );

  const handleInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const stepSize = config?.step ?? 0.1;
      const multipledStep = stepSize * 100;
      const clampedValue =
        Math.max(
          config?.minimum as number,
          Math.min(Number(e.target.value), config?.maximum as number)
        ) * 100;
      const flooredValue =
        Math.floor(clampedValue / multipledStep) * multipledStep;
      const nextLowerValue =
        clampedValue % multipledStep ? flooredValue : clampedValue;
      const finalValue =
        nextLowerValue < multipledStep ? nextLowerValue : flooredValue;
      onChange?.(config?.id, finalValue / 100);
    },
    [onChange, config]
  );

  return (
    <FormControl isDisabled={isHistoryPanelOpen} isRequired>
      <input
        className="float-right font-medium text-red-600 text-sm !w-16 text-right !py-0.5 px-2 rounded-md focus:border-gray-700 disabled:hover:border-transparent hover:border-gray-500 border-transparent border focus:outline-none"
        type="number"
        disabled={isHistoryPanelOpen}
        value={defaultValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        min={config?.minimum as number}
        max={config?.maximum as number}
      />
      <FormLabel className="!flex !items-center">
        <span>{config?.displayName}</span>
        {config?.description && (
          <div className="order-3 grid place-items-center ml-1">
            <Tooltip
              className="!bg-gray-900 !rounded-md text-white text-center"
              sx={{
                ".chakra-tooltip__arrow": {
                  backgroundColor: "#232323 !important",
                },
              }}
              hasArrow
              label={config?.description}
              placement="top"
              shouldWrapChildren
            >
              <MdsInfoRound size={18} className="stroke-[20] text-gray-500" />
            </Tooltip>
          </div>
        )}
      </FormLabel>
      <Slider
        aria-label="slider-ex-1"
        colorScheme="orange"
        defaultValue={defaultValue}
        // {...register(name)}
        focusThumbOnChange={false}
        isDisabled={isHistoryPanelOpen}
        max={config?.maximum as number}
        min={config?.minimum as number}
        onChange={(e) => onChange?.(config?.id, e)}
        step={config?.step ?? 0.1}
        value={defaultValue}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </FormControl>
  );
};

export default RangeSelector;
