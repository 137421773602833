import { useState, useEffect, RefObject } from "react";

interface UseOverflowDetectionProps {
  containerRef: RefObject<HTMLElement>;
  contentRef: RefObject<HTMLElement>;
  buttonHeight: number;
  dependencies?: any[];
}

export const useOverflowDetection = ({
  containerRef,
  contentRef,
  buttonHeight,
  dependencies = [],
}: UseOverflowDetectionProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && contentRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const contentHeight = contentRef.current.scrollHeight;
        const bHegiht = isOverflowing ? 0 : 40;
        setIsOverflowing(contentHeight + bHegiht > containerHeight);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [containerRef, contentRef, buttonHeight, ...dependencies]);

  return isOverflowing;
};
