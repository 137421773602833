import { FlexProps, chakra } from "@chakra-ui/react";

import { useStyles } from "./navigation-panel.tsx";

export interface NavigationPanelHeader extends FlexProps {
  label?: string;
}
export const NavigationLabelHeading = (props: NavigationPanelHeader) => {
  const { children, label, ...rest } = props;

  const styles = useStyles();
  return (
    <chakra.div __css={styles.labelHeader} {...rest}>
      {label ?? children}
    </chakra.div>
  );
};
