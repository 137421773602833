import { cloneElement, useContext, useMemo } from "react";

import { TabClose } from "@/components/icons/tab-close.tsx";
import { TabCloseFill } from "@/components/icons/tab-closed-fill.tsx";
import { Icon } from "@/design/components/icon";
import { IconSizes } from "@/design/theme/components";
import { STEP_STATUS } from "@/features/data-transformation";

import { StepContext, StepContextType } from "./step-context.ts";

export const StepIcon = () => {
  const { step, isOpen } = useContext(StepContext) as StepContextType;

  const type = step.activeStatus
    ? step?.status ?? STEP_STATUS.Active
    : STEP_STATUS.Removed;

  const icon = useMemo(() => {
    switch (type) {
      case STEP_STATUS.Removed:
        return (
          <Icon as={TabClose} color="gray.700" className="transition-all" />
        );
      case STEP_STATUS.Failed:
        return (
          <Icon
            as={TabCloseFill}
            size="md"
            className="transition-all"
            color="red.600"
          />
        );
      default:
        return (
          <Icon
            as={TabClose}
            size="md"
            className="transition-all"
            color="gray.900"
          />
        );
    }
  }, [step]);

  if (!isOpen) {
    return (
      <>{cloneElement(icon, { _groupHover: { boxSize: IconSizes.lg } })}</>
    );
  }

  return <>{icon}</>;
};
