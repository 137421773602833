import { Flex } from "@chakra-ui/layout";
import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { MdsContentCopyRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import {
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const ShowFullValueModal: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useShowToast(undefined, undefined, true);

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const copyToClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(modalProps.text as string);
    } else {
      document.execCommand("copy", true, modalProps.text);
    }

    toast({
      ...TOAST_MESSAGES.copyToClipboard,
    });
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!w-fit !max-w-[400px]">
        <ModalBody className="!px-3.5 !py-2.5 text-xs w-fit leading-[20px] text-gray-800">
          <Flex className="text-xs leading-[20px] text-gray-800 ">
            <Box className="pre-space break-all">{modalProps.text}</Box>
          </Flex>
          <Flex className="flex-row-reverse mt-2.5">
            <Button
              colorScheme="secondary"
              leftIcon={<Icon size="xs" as={MdsContentCopyRound} />}
              onClick={copyToClipboard}
              size="xs"
              variant="ghost"
            >
              Copy to Clipboard
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
