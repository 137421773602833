import { createSlice, current, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

import { analysisAPI, projectAPI, workspaceAPI } from "@/features/ws-manager";
import {
  AnalysesSchema,
  PartialAnalysesListSchema,
  ProjectListSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { RootState } from "@/store";

interface PermissionItem {
  id: string;
  permissions: string[];
}

interface PermissionsState {
  flattenedPermissions: Record<string, PermissionItem>;
  fetchedPermission: boolean;
}

const initialState: PermissionsState = {
  flattenedPermissions: {},
  fetchedPermission: false,
};

const matcherList = isAnyOf(
  workspaceAPI.endpoints.getWsList.matchFulfilled,
  workspaceAPI.endpoints.getWsList.matchFulfilled,
  workspaceAPI.endpoints.searchInWs.matchFulfilled,
  workspaceAPI.endpoints.getWs.matchFulfilled,
  workspaceAPI.endpoints.createWs.matchFulfilled,
  projectAPI.endpoints.getProjectList.matchFulfilled,
  projectAPI.endpoints.createProject.matchFulfilled,
  projectAPI.endpoints.getProject.matchFulfilled,
  analysisAPI.endpoints.createAnalyses.matchFulfilled,
  analysisAPI.endpoints.getAnalysisFavourite.matchFulfilled,
  analysisAPI.endpoints.getAnalysisListForOrg.matchFulfilled,
  analysisAPI.endpoints.getAnalysisListForWs.matchFulfilled,
  analysisAPI.endpoints.getSingleAnalysis.matchFulfilled,
  analysisAPI.endpoints.getAnalysisListForProject.matchFulfilled
);

const getDataFromAction = (action: any) => {
  switch (true) {
    case workspaceAPI.endpoints.getWs.matchFulfilled(action):
      return action.payload.response.data?.workspaces as WorkspaceSchema[];

    case workspaceAPI.endpoints.getWsList.matchFulfilled(action):
      return action.payload.response.data?.workspaces as WorkspaceSchema[];

    case workspaceAPI.endpoints.createWs.matchFulfilled(action):
      return action.payload.response.data?.workspaces as WorkspaceSchema[];

    case projectAPI.endpoints.getProjectList.matchFulfilled(action):
      return action.payload.response.data?.projects as ProjectListSchema[];

    case projectAPI.endpoints.createProject.matchFulfilled(action):
      return action.payload.response.data?.projects as ProjectListSchema[];

    case projectAPI.endpoints.getProject.matchFulfilled(action):
      return action.payload.response.data?.projects as ProjectListSchema[];

    case analysisAPI.endpoints.getAnalysisFavourite.matchFulfilled(action):
      return action.payload.response.data?.favorites
        .analyses as PartialAnalysesListSchema[];

    case analysisAPI.endpoints.createAnalyses.matchFulfilled(action):
      return action.payload.response.data?.analyses as AnalysesSchema[];

    case analysisAPI.endpoints.getAnalysisListForOrg.matchFulfilled(action):
      return action.payload.response.data?.analyses as AnalysesSchema[];

    case analysisAPI.endpoints.getAnalysisListForWs.matchFulfilled(action):
      return action.payload.response.data?.analyses as AnalysesSchema[];

    case analysisAPI.endpoints.getSingleAnalysis.matchFulfilled(action):
      return action.payload.response.data?.analyses as AnalysesSchema[];

    case analysisAPI.endpoints.getAnalysisListForProject.matchFulfilled(action):
      return action.payload.response.data?.analyses as AnalysesSchema[];

    case workspaceAPI.endpoints.searchInWs.matchFulfilled(action):
      return action.payload.response.data?.results.map((item) => {
        return item.itemData;
      }) as WorkspaceSchema[] | AnalysesSchema[] | ProjectSchema[];

    default:
      return null;
  }
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    updatePermissions(state, action: PayloadAction<Record<string, any>[]>) {
      const updatedPermissions = getUpdatedPermissions(
        action.payload,
        current(state).flattenedPermissions
      );

      return { ...state, flattenedPermissions: updatedPermissions };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      analysisAPI.endpoints.getSingleAnalysis.matchPending,
      (state) => {
        state.fetchedPermission = false;
      }
    );
    builder.addMatcher(
      analysisAPI.endpoints.getSingleAnalysis.matchFulfilled,
      (state) => {
        state.fetchedPermission = true;
      }
    );
    builder.addMatcher(matcherList, (state, action) => {
      const currentState = current(state).flattenedPermissions;
      const data = getDataFromAction(action) ?? [];

      const updatedPermissions = getUpdatedPermissions(data, currentState);

      state.flattenedPermissions = updatedPermissions;
    });
  },
});

export const { updatePermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;

export const permissionsList = (state: RootState) =>
  state.permissionReducer.flattenedPermissions;

export const fetchedPermission = (state: RootState) =>
  state.permissionReducer.fetchedPermission;

function getUpdatedPermissions(
  payload: Record<string, any>[],
  previousState: Record<string, any>
) {
  const flattenedPermissionItems = createPermissionsArray(payload);
  const updatedPermissions: Record<string, PermissionItem> = {
    ...previousState,
  };

  for (const permissionItem of flattenedPermissionItems) {
    updatedPermissions[permissionItem.id] = permissionItem;
  }
  return updatedPermissions;
}

function createPermissionsArray(items: any[]): PermissionItem[] {
  const itemsWithPermissions: PermissionItem[] = [];

  for (const item of items) {
    if (typeof item === "object" && item !== null) {
      if ("id" in item && "permissions" in item) {
        itemsWithPermissions.push({
          id: item.id,
          permissions: item.permissions,
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      for (const value of Object.values(item)) {
        itemsWithPermissions.push(
          ...createPermissionsArray(Array.isArray(value) ? value : [value])
        ); // Flatten nested arrays and objects
      }
    }
  }

  return itemsWithPermissions;
}
