import { Flex, FlexProps } from "@chakra-ui/react";

import { useStyles } from "./configure-panel.tsx";
export const ConfigurePanelBody = (props: FlexProps) => {
  const { children, ...rest } = props;
  const styles = useStyles();
  return (
    <Flex __css={styles.center} {...rest}>
      {children}
    </Flex>
  );
};
