export const getClipboardText = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLDivElement>
  ): string => {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    return clipboardData.getData("text/plain") || "";
  };
  
  export const insertHtmlAtCaret = (
    html: string,
    selection: Selection | undefined
  ) => {
    if (!selection) return;
    const range = selection.getRangeAt(0);
    range.deleteContents();
    console.log(html);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
  
    const frag = document.createDocumentFragment();
    let node;
    let lastNode;
  
    while ((node = tempDiv.firstChild)) {
      lastNode = frag.appendChild(node);
    }
  
    range.insertNode(frag);
  
    // Move the caret to the end of the inserted content
    if (lastNode) {
      range.setStartAfter(lastNode);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };
  
  export const shouldRemovePrevColumn = (
    range: Range,
    startContainer: Node
  ): boolean | null => {
    return (
      startContainer.nodeType === Node.TEXT_NODE &&
      range.startOffset === 0 &&
      hasPromptColumn(startContainer.previousSibling)
    );
  };
  
  export const shouldRemoveChildColumn = (
    range: Range,
    startContainer: Node
  ): boolean | null => {
    return (
      startContainer.nodeType === Node.ELEMENT_NODE &&
      range.startOffset > 0 &&
      hasPromptColumn(startContainer.childNodes[range.startOffset - 1])
    );
  };
  
  export const hasPromptColumn = (node: Node | null): boolean | null => {
    return (
      node &&
      node.nodeType === Node.ELEMENT_NODE &&
      (node as Element).classList.contains("promptColumn")
    );
  };
  
  // Utility function to remove the previous column
  export const removePrevColumn = (startContainer: Node) => {
    if (startContainer.previousSibling) {
      startContainer.previousSibling.remove();
    }
  };
  
  // Utility function to remove the child column
  export const removeChildColumn = (startContainer: Node, offset: number) => {
    const childNode = startContainer.childNodes[offset - 1];
    if (childNode) {
      childNode.remove();
    }
  };
  
  export const createColumnTagSpan = (
    option: string,
    valid: boolean
  ): HTMLSpanElement => {
    const span = document.createElement("span");
    span.textContent = `{{${option}}}`;
    span.contentEditable = "false";
    span.classList.add("promptColumn", valid ? "valid" : "invalid");
    return span;
  };
  
  export const adjustRange = (
    range: Range,
    searchString: React.MutableRefObject<string>,
    isCustom: boolean
  ) => {
    const endOffset = isCustom ? 3 : 2;
    range.setStart(range.startContainer, range.startOffset - 2);
    range.setEnd(
      range.startContainer,
      range.startOffset + searchString.current.length + endOffset
    );
    range.deleteContents();
  };
  
  export const insertSpanAndAdjustSelection = (
    span: HTMLSpanElement,
    range: Range
  ) => {
    const selection = window.getSelection();
    if (selection) {
      const spaceBefore = document.createTextNode(" ");
      range.insertNode(spaceBefore);
  
      range.setStartAfter(spaceBefore);
      range.insertNode(span);
  
      range.setStartAfter(span);
  
      const newRange = document.createRange();
      newRange.setStartAfter(span);
      newRange.collapse(true);
      selection.removeAllRanges();
      selection.addRange(newRange);
    }
  };
  