import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

import { createStandaloneToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import {
  DataTransformationState,
  edaInitializationAPI,
  edaTableAPI,
  filterStatusTypeForMessage,
  getStepWithWarnings,
  getUpdatedSteps,
  ITransformationStep,
  STATUS_API_TYPE,
  stepsApi,
} from "@/features/data-transformation";
import { POLLING_STATUS } from "@/utils/enums.ts";
const toast = createStandaloneToast();
export const extraReducers = (
  builder: ActionReducerMapBuilder<DataTransformationState>
) => {
  builder.addMatcher(
    stepsApi.endpoints.saveEDASteps.matchFulfilled,
    (state, { payload }) => {
      state.saveEditCalls = 0;
      toast({ ...TOAST_MESSAGES.changesSaved });
      const accessMode = payload.response.data?.edaAccessMode;
      if (accessMode) {
        state.edaAccessMode = accessMode;
      }
    }
  );
  builder.addMatcher(stepsApi.endpoints.addSteps.matchPending, (state) => {
    if (!state.tableLoading) state.tableLoading = true;
    state.saveEditCalls += 1;
  });
  builder.addMatcher(stepsApi.endpoints.editStep.matchPending, (state) => {
    if (!state.tableLoading) state.tableLoading = true;
    state.saveEditCalls += 1;
  });
  builder.addMatcher(
    edaInitializationAPI.endpoints.updateSampling.matchPending,
    (state) => {
      if (!state.tableLoading) state.tableLoading = true;
    }
  );
  builder.addMatcher(
    edaInitializationAPI.endpoints.updateSampling.matchRejected,
    (state) => {
      state.tableLoading = false;
    }
  );
  builder.addMatcher(
    edaInitializationAPI.endpoints.getRequestStatus.matchRejected,
    (state, data) => {
      if (data.meta.condition) return; // Check if this API is not failed because of  UI condition
      state.tableLoading = false;
    }
  );
  builder.addMatcher(
    edaInitializationAPI.endpoints.getRequestStatus.matchFulfilled,
    (state, { payload }) => {
      const data = payload.response.data;

      if (!data) return;
      const isFailed = data.taskStatus === POLLING_STATUS.FAILED;
      if (isFailed) {
        state.tableLoading = false;
        state.edaAccessMode = data.edaAccessMode;
        return;
      }

      const progressMessage = data.taskProgress.message;
      const showMessage = filterStatusTypeForMessage(data.type);
      if (progressMessage && showMessage) {
        state.statusMessage = progressMessage;
      }

      const isPolling = data.taskStatus === POLLING_STATUS.IN_PROGRESS;
      const isExecuteStatus = data.type === STATUS_API_TYPE.EXECUTE;
      if (isExecuteStatus && isPolling) return;

      //Reset edit calls one save and execute has run or failed
      if (isExecuteStatus && !isPolling) {
        state.saveEditCalls = 0;
      }

      state.edaAccessMode = data.edaAccessMode;
    }
  );
  builder.addMatcher(stepsApi.endpoints.allSteps.matchPending, (state) => {
    if (state.stepsFetched) state.stepsFetched = false;
  });
  builder.addMatcher(
    stepsApi.endpoints.allSteps.matchFulfilled,
    (state, { payload }) => {
      const steps = payload.response.data?.transformationSteps ?? [];
      state.compiledTransformationSteps = steps;
      state.transformationSteps = steps;
      state.stepsFetched = true;
      state.samplingAndStepsHash =
        payload.response.data?.stepAndSamplingHash ?? "";

      if (!isEmpty(state.stepsInfo)) {
        const updatedSteps = getUpdatedSteps(steps, state.stepsInfo);

        state.compiledTransformationSteps = updatedSteps;
        state.transformationSteps = updatedSteps;
      }
    }
  );
  builder.addMatcher(
    edaTableAPI.endpoints.getTableData.matchPending,
    (state) => {
      if (!state.tableLoading) state.tableLoading = true;
    }
  );
  builder.addMatcher(
    stepsApi.endpoints.addSteps.matchFulfilled,
    (state, { payload }) => {
      const data = payload.response.data?.steps;
      if (data) {
        const compliedSteps = [...state.compiledTransformationSteps];
        const newValues = data.filter((_filter) => {
          const isPresent = compliedSteps.some(
            (_f) => _f.transformationStepId == _filter.transformationStepId
          );
          return !isPresent;
        });

        state.compiledTransformationSteps = [...compliedSteps, ...newValues];
        state.tempTransformationSteps = data;
      }
    }
  );
  builder.addMatcher(
    stepsApi.endpoints.editStep.matchFulfilled,
    (state, { payload, ...props }) => {
      const data = payload.response.data?.steps;
      if (data) {
        /// Update all steps without losing their order
        state.compiledTransformationSteps = getUpdatedSteps(
          state.compiledTransformationSteps,
          data
        );

        const originalId = props.meta.arg.originalArgs.transformationStepId!;
        const isTempStep = state.tempTransformationSteps.some(
          (_step) => _step.transformationStepId == originalId
        );

        if (isTempStep) {
          state.tempTransformationSteps = getUpdatedSteps(
            state.tempTransformationSteps,
            data
          );
        } else {
          state.transformationSteps = getUpdatedSteps(
            state.transformationSteps,
            data
          );
        }
      }
    }
  );
  builder.addMatcher(
    edaTableAPI.endpoints.getTableData.matchFulfilled,
    (state, { payload }) => {
      const data = payload.response.data?.taskData;

      const progressMessage = payload.response.data?.taskProgress?.message;
      if (progressMessage) {
        state.statusMessage = progressMessage;
      } else {
        state.statusMessage = "Loading table..";
      }

      if (isEmpty(data)) return;

      const stepsWithMetadata =
        (data.stepsInfo as Partial<ITransformationStep>[]) ?? [];

      state.stepsInfo = stepsWithMetadata;

      state.compiledTransformationSteps = getUpdatedSteps(
        state.compiledTransformationSteps,
        stepsWithMetadata
      );
      state.tempTransformationSteps = getUpdatedSteps(
        state.tempTransformationSteps,
        stepsWithMetadata
      );
      state.transformationSteps = getUpdatedSteps(
        state.transformationSteps,
        stepsWithMetadata
      );

      state.stepMemoryWarning = getStepWithWarnings(state.transformationSteps);

      if (state.selectedStep) {
        const currentStepInfo =
          stepsWithMetadata.find(
            (_step) =>
              _step.transformationStepId ===
              state.selectedStep!.transformationStepId
          ) ?? {};
        state.selectedStep = { ...state.selectedStep, ...currentStepInfo };
      }
    }
  );
};
