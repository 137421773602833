import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { clearRunResultsRows } from "../components/llm-results/helpers";
import { AIPlayground, RunResults, TextGenVersion } from "../types";

interface LLMPromptState {
  prompt: string | null;
  showHistory: boolean;
  inputColumns: string[];
  activeVersion: TextGenVersion | null;
  previewVersion: TextGenVersion | null;
  activePlayground: AIPlayground | null;
  isExecuteEnabled: boolean;
  resultRowCount: number | null;
  runResults: {
    [key: string]: RunResults;
  };
  currentRunResults: RunResults | null;
}

const initialState: LLMPromptState = {
  prompt: null,
  showHistory: false,
  inputColumns: [],
  activePlayground: null,
  previewVersion: null,
  activeVersion: null,
  runResults: {},
  currentRunResults: null,
  isExecuteEnabled: false,
  resultRowCount: 3,
};

const llMSlice = createSlice({
  name: "LLM-prompt",
  initialState,
  reducers: {
    setPrompt: (state, action: PayloadAction<string>) => {
      state.prompt = action.payload;
    },
    setShowHistory: (state, action: PayloadAction<boolean>) => {
      state.showHistory = action.payload;
    },
    setActivePlayground: (state, action: PayloadAction<AIPlayground>) => {
      state.activePlayground = action.payload;
    },
    setActiveVersion: (state, action: PayloadAction<TextGenVersion | null>) => {
      state.activeVersion = action.payload;
    },
    setPreviewVersion: (state, action: PayloadAction<TextGenVersion>) => {
      state.previewVersion = action.payload;
    },

    setInputPromptText: (state, action: PayloadAction<string>) => {
      if (state.activeVersion) {
        state.activeVersion.messages[0].content[0].text = action.payload;
      }
      state.isExecuteEnabled = false;
    },
    setInputColumns: (state, action: PayloadAction<string[]>) => {
      state.inputColumns = action.payload;
    },
    changePreviewVersion: (state, action: PayloadAction<TextGenVersion>) => {
      state.previewVersion = action.payload;
    },
    clearPrompt: (state) => {
      state.prompt = null;
    },
    setCurrentRunResults: (state, action: PayloadAction<RunResults>) => {
      state.currentRunResults = action.payload;
      state.resultRowCount = action.payload.result.length;
    },
    updateCurrentRunResults: (state, action: PayloadAction<RunResults>) => {
      state.currentRunResults = action.payload;
    },
    clearCurrentRunResultRows: (state) => {
      if (state.currentRunResults) {
        state.currentRunResults = clearRunResultsRows(state.currentRunResults);
      }
    },
    setRunResults: (
      state,
      action: PayloadAction<{ runId: string; results: RunResults }>
    ) => {
      state.runResults = {
        ...state.runResults,
        [action.payload.runId]: action.payload.results,
      };
    },
    setResultsRowCount: (state, action: PayloadAction<number>) => {
      state.resultRowCount = action.payload;
    },

    setIsExecuteEnabled: (state, action: PayloadAction<boolean>) => {
      state.isExecuteEnabled = action.payload;
    },

    resetSlice: () => {
      return initialState;
    },
  },
});

export const {
  setPrompt,
  setShowHistory,
  clearPrompt,
  setActivePlayground,
  changePreviewVersion,
  setActiveVersion,
  setInputPromptText,
  setPreviewVersion,
  setInputColumns,
  setCurrentRunResults,
  setRunResults,
  updateCurrentRunResults,
  clearCurrentRunResultRows,
  setResultsRowCount,
  setIsExecuteEnabled,
  resetSlice,
} = llMSlice.actions;
export const llmReducer = llMSlice.reducer;
