import { Flex, Text, Tooltip } from "@chakra-ui/react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import { limitDecimalPoints } from "@/utils/helper.ts";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
type GridItemProps = {
  title: string;
  value: string;
};
export const DetailItem = ({ title, value }: GridItemProps) => {
  return (
    <Flex className="flex-col justify-start w-full h-full gap-y-1 max-w-[227px] flex-wrap self-start">
      <Text className="uppercase text-xs leading-none font-semibold tracking-[0.6px] text-gray-600">
        {title}
      </Text>
      <Tooltip label={value}>
        <Text className="text-gray-900 text-base leading-none font-medium cursor-default tracking-[-0.02rem]">
          <ResponsiveEllipsis
            text={limitDecimalPoints(value, 12)}
            maxLine="1"
            style={{ wordBreak: "break-all" }}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Text>
      </Tooltip>
    </Flex>
  );
};
