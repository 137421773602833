import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useEdges, useReactFlow } from "reactflow";

import { selectPanel } from "@/features/workflow-studio/redux";
import { NodeType } from "@/features/workflow-studio/types";
import { WorkflowNodeRelationsSchema } from "@/features/workflow-studio/types/workflow-types";
import { WORKFLOW_PANELS } from "@/features/workflow-studio/utils/constants";
import { useAppSelector } from "@/reduxHooks.ts";

enum MERGE_FILE_SOURCE {
  LEFT = "File Left",
  RIGHT = "File Right",
}

const MergeConfig = () => {
  const [leftFile, setLeftFile] = useState<string | null>();
  const [rightFile, setRightFile] = useState<string | null>();
  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  );
  const { getNode } = useReactFlow();
  const edges = useEdges();
  useEffect(() => {
    if (!nodeConfigPanel.nodeId) return;
    const nodeData: NodeType = getNode(nodeConfigPanel.nodeId)!.data;
    nodeData.inputs.map((input) => {
      const connection = edges.find(
        (edge) =>
          edge.target === nodeConfigPanel.nodeId &&
          (edge.data as WorkflowNodeRelationsSchema).targetNodeIoId ===
            input.ioDetailId
      );
      const ioName = input.name as MERGE_FILE_SOURCE;
      if (connection) {
        const sourceNodeName = (getNode(connection.source)?.data as NodeType)
          .displayName;
        if (ioName === MERGE_FILE_SOURCE.LEFT) {
          setLeftFile(sourceNodeName);
        } else if (ioName === MERGE_FILE_SOURCE.RIGHT) {
          setRightFile(sourceNodeName);
        }
      } else {
        if (ioName === MERGE_FILE_SOURCE.LEFT) {
          setLeftFile(null);
        } else if (ioName === MERGE_FILE_SOURCE.RIGHT) {
          setRightFile(null);
        }
      }
    });
  }, [nodeConfigPanel.nodeId, getNode, edges]);

  return (
    <Flex justify="space-between" direction="column" gap={2}>
      {leftFile && (
        <Flex align="self-start" direction="column" gap={1}>
          <Text fontSize={"xs"}>Left File Source</Text>
          <Box
            className="w-full bg-gray-100 rounded p-2 text-zinc-500"
            fontSize={"sm"}
          >
            {leftFile}
          </Box>
        </Flex>
      )}
      {rightFile && (
        <Flex align="self-start" direction="column" gap={1}>
          <Text fontSize={"xs"}>Right File Source</Text>
          <Box
            className="w-full bg-gray-100 rounded p-2 text-zinc-500"
            fontSize={"sm"}
          >
            {rightFile}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default MergeConfig;
