import { EndpointBuilder } from "@reduxjs/toolkit/query";

import { DataManagerEndpoints } from "@/features/data-manager/api/api-endpoints.ts";
import { IStrapiTransformation } from "@/features/data-transformation";
import { ApiResponse, PaginationBody } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter.ts";

import {
  IDataSetApi,
  DatasetsList,
  OnGoingUploadsList,
  PreviewDataApiResponse,
  IDataSourcesResponse,
} from "../types";

import { dataManagerAPI } from "./api";

const isPaginationBody = (body: any): body is PaginationBody => {
  return typeof body === "object" && "next" in body;
};
export const dataQueryAPI = dataManagerAPI.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    getOngoingUploads: build.query<
      ApiResponse<OnGoingUploadsList>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => {
        return {
          url: DataManagerEndpoints.getOngoingUploads(analysisId),
        };
      },
      providesTags: ["IORecords"],
    }),
    getDataSets: build.query<
      ApiResponse<DatasetsList>,
      IDataSetApi | PaginationBody
    >({
      query: (body) => {
        let url;

        if (isPaginationBody(body)) {
          url = body.next;
        } else {
          url = DataManagerEndpoints.getDatSets(body.analysisId);
        }

        return {
          url: url,
        };
      },
      providesTags: ["Datasets"],
    }),
    getDataPreview: build.query<
      ApiResponse<PreviewDataApiResponse>,
      { analysisId: string; ioRecordId: string }
    >({
      query: ({ analysisId, ioRecordId }) => ({
        url: DataManagerEndpoints.getPreviewData(analysisId, ioRecordId),
        ignoreBaseUrl: true,
      }),
      extraOptions: {
        maxRetries: 2,
      },
      transformResponse: (res: ApiResponse<PreviewDataApiResponse>) => {
        const seralizedRawData = JSON.parse(
          res.response.data!.previewData.rowData as string
        );
        const newResponse = {
          ...res,
          response: {
            data: {
              ...res.response.data,
              previewData: {
                ...res.response.data!.previewData,
                rowData: seralizedRawData,
              },
            },
          },
        } as ApiResponse<PreviewDataApiResponse>;
        return newResponse;
      },
      providesTags: ["DataPreview"],
    }),
    acceptSchema: build.mutation<
      ApiResponse<any>,
      { deltaTablePath: string; analysisId: string; ioRecordId: string }
    >({
      query: ({ deltaTablePath, analysisId, ioRecordId }) => {
        return {
          url: DataManagerEndpoints.acceptSchema(analysisId, ioRecordId),
          method: "PUT",
          data: { delta_table_path: deltaTablePath },
          ignoreBaseUrl: true,
        };
      },
      invalidatesTags: ["IORecords", "Datasets"],
    }),
    getSingleDataset: build.query<
      ApiResponse<DatasetsList>,
      { analysisId: string; datasetId: string }
    >({
      query: ({ analysisId, datasetId }) => {
        return {
          url: DataManagerEndpoints.getSingleDataSet(analysisId, datasetId),
        };
      },
    }),
    deleteDataset: build.mutation<
      ApiResponse<DatasetsList>,
      { analysisId: string; datasetId: string }
    >({
      query: ({ analysisId, datasetId }) => {
        return {
          url: DataManagerEndpoints.deleteDataset(analysisId, datasetId),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Datasets"],
    }),
    deleteIoRecord: build.mutation<
      ApiResponse<DatasetsList>,
      { analysisId: string; ioRecordId: string }
    >({
      query: ({ analysisId, ioRecordId }) => {
        return {
          url: DataManagerEndpoints.deleteIORecord(analysisId, ioRecordId),
          method: "DELETE",
        };
      },
      invalidatesTags: ["IORecords"],
    }),
    sourceFormRecord: build.mutation<any, { actorDefinitionId: string }>({
      query: ({ actorDefinitionId }) => {
        return {
          url: DataManagerEndpoints.sourceForm(actorDefinitionId),
          method: "GET",
          camelCase: false,
        };
      },
    }),
    sourceListRecord: build.mutation<ApiResponse<IDataSourcesResponse>, any>({
      query: () => {
        return {
          url: DataManagerEndpoints.listSources(),
          method: "GET",
        };
      },
    }),
    sourceFormvalidation: build.mutation<
      any,
      {
        actorDefinitionId: string;
        name: string;
        connection_configuration: any;
        analysisId: string;
      }
    >({
      query: ({
        actorDefinitionId,
        name,
        connection_configuration,
        analysisId,
      }) => {
        return {
          url: DataManagerEndpoints.verifySourceForm(analysisId),
          method: "POST",
          body: {
            actor_definition_id: actorDefinitionId,
            name: name,
            connection_configuration: keysToSnake(connection_configuration),
          },
        };
      },
    }),
    filesList: build.mutation<any, { ioId: string; analysisId: string }>({
      query: ({ ioId, analysisId }) => {
        return {
          url: DataManagerEndpoints.getfilesList(ioId, analysisId),
          method: "POST",
          camelCase: false,
        };
      },
    }),
    syncSourceFile: build.mutation<
      any,
      { ioId: string; analysisId: string; body: any }
    >({
      query: ({ ioId, analysisId, body }) => {
        return {
          url: DataManagerEndpoints.syncSource(analysisId, ioId),
          method: "POST",
          body: body,
        };
      },
    }),
    getConnectorDocumentation: build.query<
      IStrapiTransformation,
      { connectorName: string }
    >({
      query: ({ connectorName }) => ({
        url: DataManagerEndpoints.getConnectorDocumentation,
        method: "GET",
        params: {
          "filters[connector_name][$eq]": connectorName,
        },
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_KEY}`,
        },
        ignoreBaseUrl: true,
        customAuth: true,
      }),
      // Permanent caching
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
  }),
});

export const {
  useGetOngoingUploadsQuery,
  useGetDataSetsQuery,
  useLazyGetDataSetsQuery,
  useGetDataPreviewQuery,
  useAcceptSchemaMutation,
  useGetSingleDatasetQuery,
  useLazyGetSingleDatasetQuery,
  useDeleteIoRecordMutation,
  useDeleteDatasetMutation,
  useSourceFormRecordMutation,
  useSourceListRecordMutation,
  useSourceFormvalidationMutation,
  useFilesListMutation,
  useSyncSourceFileMutation,
  useLazyGetConnectorDocumentationQuery,
} = dataQueryAPI;
