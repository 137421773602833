import { Button, Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext } from "react";
import { useSelector } from "react-redux";

import { tableLoading } from "@/features/data-transformation";
import { useRestoreRemoveStep } from "@/features/data-transformation/hooks";

import { StepContext, StepContextType } from "./step-context.ts";
import { StepIcon } from "./step-icon.tsx";

export const StepDeleted = ({ hovered }: { hovered: boolean }) => {
  const { step, isOpen, hasWriteAccess } = useContext(
    StepContext
  ) as StepContextType;
  const { onRestoreRemoveClicked } = useRestoreRemoveStep(step);
  const isDataLoading = useSelector(tableLoading);
  const showIcon = hasWriteAccess && !isDataLoading;

  const undoStep = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    onRestoreRemoveClicked(true);
  };

  return (
    <>
      <Flex className="items-center max-w-full" as={motion.div}>
        {!isOpen && <StepIcon />}
        {isOpen && (
          <Flex className="w-full justify-between items-baseline gap-x-1 ">
            <Text className="!align-middle text-[13px] h-min italic text-gray-600 group-hover:text-gray-800">
              This step has been deleted.
            </Text>

            {showIcon && (
              <Button
                sx={{
                  padding: "4px !important",
                }}
                colorScheme="secondary"
                hidden={!hasWriteAccess}
                isDisabled={!hovered}
                onClick={undoStep}
                size="xs"
                variant="ghost"
              >
                Undo
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};
