import { Table, Tbody, chakra, Tr, Td, Skeleton } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import clsx from "clsx";
import { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { RUN_STATUS } from "@/utils/enums";

import { useGetRunDetailsQuery } from "../../api";
import {
  currentActiveVersion,
  getActivePlayground,
  getActiveRunStatus,
  getAllRunResults,
  getCurrentRunResults,
  getPreviewVersion,
  promptColumns,
  selectResultRowCount,
  showPromptHistory,
  updateCurrentRunResults,
} from "../../redux";
import { RunResults } from "../../types";

import { addMissingColumnsToRunResults } from "./helpers";

const emptyResults = (count: number | null = 3): RunResults => {
  return {
    headers: ["llmResponse", "llmError"] as string[],
    result: Array.from({ length: count ?? 3 }).map(() => ({
      llmError: "",
      llmResponse: "",
    })),
  };
};

const LLMResultsDataTable = () => {
  const dispatch = useAppDispatch();

  const isHistoryOpen = useAppSelector(showPromptHistory);
  const activeVersion = useAppSelector(currentActiveVersion);
  const promptColumnNames = useAppSelector(promptColumns);
  const previewVersion = useAppSelector(getPreviewVersion);
  const currentPlayground = useAppSelector(getActivePlayground);
  const currentRunResults = useAppSelector(getCurrentRunResults);
  const rowCount = useAppSelector(selectResultRowCount);
  const allRunResults = useAppSelector(getAllRunResults);
  const runStatus = useAppSelector(getActiveRunStatus);

  const isRunning = useMemo(
    () => runStatus === RUN_STATUS.RUNNING,
    [runStatus]
  );

  const shouldSkip = useMemo(() => {
    if (isHistoryOpen) {
      return !previewVersion?.runs?.runId || !currentPlayground;
    } else if (currentRunResults && activeVersion?.runs?.runId) {
      return !activeVersion?.runs?.runId && isRunning;
    } else {
      return true;
    }
  }, [
    activeVersion,
    currentPlayground,
    isHistoryOpen,
    previewVersion,
    currentRunResults,
    isRunning,
  ]);

  const { isFetching, error } = useGetRunDetailsQuery(
    {
      textGenId: currentPlayground!.textGeneration[0].id ?? "",
      isActiveVersionResult: !isHistoryOpen,
      runId: isHistoryOpen
        ? previewVersion?.runs?.runId ?? ""
        : activeVersion!.runs?.runId ?? "",
    },
    { skip: shouldSkip, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isFetching) return;
    const updatedRunResults = addMissingColumnsToRunResults(
      promptColumnNames ?? [],
      currentRunResults ?? emptyResults(rowCount)
    );
    dispatch(updateCurrentRunResults(updatedRunResults));
  }, [promptColumnNames, previewVersion]);

  // return headers and rows for current dataTable
  // if isHistoryOpen, use preview version
  // if !isHistoryOpen, use currentRunResults
  // default render empty rows
  const { resultHeaders, resultRows } = useMemo(() => {
    if (isHistoryOpen) {
      const previewResults = allRunResults[previewVersion?.runs?.runId ?? ""];
      if (previewResults)
        return {
          resultHeaders: previewResults.headers,
          resultRows: previewResults.result,
        };
    } else if (currentRunResults) {
      const { headers, result } = currentRunResults;
      return {
        resultHeaders: headers,
        resultRows: result,
      };
    }
    const { headers: emptyHeader, result: emptyRows } = emptyResults(rowCount);
    return {
      resultHeaders: emptyHeader,
      resultRows: emptyRows,
    };
  }, [
    currentRunResults,
    isHistoryOpen,
    previewVersion,
    allRunResults,
    rowCount,
  ]);

  const columns = useMemo(() => {
    if (!resultHeaders) return [];
    return resultHeaders?.map((headerTitle) => ({
      header: headerTitle,
      size: 33,
      accessorKey: headerTitle,
      cell: (info: any) => info.getValue(),
    }));
  }, [resultHeaders]);

  const table = useReactTable({
    data: resultRows ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isFetching && !isRunning)
    return (
      <div className="grow flex p-3 gap-3 flex-col items-center !max-w-full h-full overflow-y-auto">
        <Skeleton className="w-full h-10 rounded-md" />
        <Skeleton className="w-full h-10 rounded-md" />
        <Skeleton className="w-full h-10 rounded-md" />
      </div>
    );

  if (error) {
    return (
      <div className="grow flex p-3 gap-3 flex-col items-center !max-w-full h-full overflow-y-auto">
        Error: {(error as AxiosError).message}
      </div>
    );
  }

  return (
    <div className="grow !max-w-full overflow-y-auto flex-1">
      <Table pos={"relative"} w="100%" variant="unstyled">
        <thead>
          {table.getHeaderGroups().map((headerGroup, hIndex) => (
            <Tr
              className={clsx(
                "!max-w-full !overflow-hidden relative text-wrap"
              )}
              key={headerGroup.id + hIndex}
            >
              {headerGroup.headers.map((h, i) => (
                <Td
                  className={clsx(
                    "!p-0 !pt-3 h-[40px]",
                    h.column.columnDef.header === "llmResponse"
                      ? "bg-white sticky !ri ght-0 !top-0 z-[ 33]"
                      : "bg-[#FCFCFC] sticky top-0"
                  )}
                  key={h.id}
                >
                  <span
                    className={clsx(
                      "border-y border-r  !border-gray-100 !h-full absolute left-0 top-0 font-medium !w-full p-3 !text-gray-800"
                    )}
                  >
                    {h.isPlaceholder
                      ? null
                      : flexRender(h.column.columnDef.header, h.getContext())}
                  </span>
                </Td>
              ))}
            </Tr>
          ))}
        </thead>

        <Tbody w="100%">
          {table.getRowModel().rows.map((row) => (
            <Tr className="w-full !h-full" key={row.id}>
              {row.getVisibleCells().map((cell, idx) => (
                <Td
                  className={clsx(
                    "!p-0 h-full bg-[#FCFCFC] min-w-[300px] !max-w-[30%] !border-gray-100 border-b border-r",
                    cell.column.columnDef.header === "llmResponse" &&
                      "rig ht-0 st icky bg-white"
                  )}
                  key={cell.id}
                >
                  <chakra.span className="flex items-center !h-full min-h-[60px] !w-full p-3 !text-gray-800">
                    {cell.getValue() as string}
                  </chakra.span>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default LLMResultsDataTable;
