import { Instance, Node } from "reactflow";

import { NodeType, WorkflowNodeSchema } from "@/features/workflow-studio/types";

export const updateNodeIdAfterRun = (
  workflowNodes: WorkflowNodeSchema[],
  setNodes: Instance.SetNodes<any>
) => {
  setNodes((currentNodes) => updateNodesAfterSave(workflowNodes, currentNodes));
};

export const updateNodesAfterSave = (
  newNodes: Array<WorkflowNodeSchema>,
  currentNodes: Array<Node<any, string | undefined>>
) => {
  return currentNodes.map((node) => {
    const newNode: WorkflowNodeSchema | null =
      newNodes.find((n: WorkflowNodeSchema) => n.uiNodeId === node.id) ?? null;
    if (newNode) {
      return {
        ...node,
        data: {
          ...node.data,
          workflowNodeId: newNode.workflowNodeId ?? "WORKFLOWNODEID",
          isOutputDataAvailable:
            newNode.isOutputDataAvailable ?? node.data.isOutputDataAvailable,
          isOutput: newNode.isOutput ?? node.data.isOutput,
          nodeStatus: newNode.nodeStatus ?? node.data.nodeStatus,
        } as NodeType,
      };
    }
    return node;
  });
};
