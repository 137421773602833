import { useEffect } from "react";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

export const useBackListener = (clearStateCb?: () => void) => {
  const location: Location = useLocation();

  const navType: NavigationType = useNavigationType();
  const navigate = useNavigate();

  useEffect(() => {
    if (navType !== NavigationType.Pop) return;
    if (
      location.pathname.includes("editor") &&
      location.search.includes("edaId")
    ) {
      clearStateCb?.();

      navigate(location.pathname);
    }
  }, [location]);
};
