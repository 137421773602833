import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

export const DataManagerEndpoints = {
  getSources: `/api/v1.0/sources/`,
  uploadLocalFile: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/?activity_type=new_upload`,
  getOngoingUploads: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/`,
  getDatSets: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/`,
  getSingleDataSet: (analysisId: string, datasetId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/${datasetId}`,
  deleteDataset: (analysisId: string, datasetId: string) =>
    `/api/v1.0/analyses/${analysisId}/datasets/${datasetId}`,
  getPreviewData: (analysisId: string, ioRecordId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.PDS
    )}/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}/`,
  acceptSchema: (analysisId: string, ioRecordId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.PDS
    )}/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}/?action=accept_schema`,
  deleteIORecord: (analysisId: string, ioRecordId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioRecordId}`,
  runValidation: (analysisId: string, validation_type: string) =>
    `/api/v1.0/analyses/${analysisId}/validate/?activity_type=${validation_type}`,
  sourceForm: (actorDefinitionId: string) =>
    `/api/v1.0/actor_definition_specifications/${actorDefinitionId}?actor_type=source`,
  listSources: () => `/api/v1.0/sources/`,
  verifySourceForm: (analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/?activity_type=new_upload`,
  getfilesList: (ioId: string, analysisId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioId}?action=discover_schema`,
  syncSource: (analysisId: string, ioId: string) =>
    `/api/v1.0/analyses/${analysisId}/iorecords/${ioId}?action=sync`,
  uploadFileChunk: `/api/v1.0/local_file_upload/`,
  getConnectorDocumentation:
    import.meta.env.VITE_STRAPI_BASE_URL + "/connector-descriptions",
};
