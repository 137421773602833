import {
  Box,
  Flex,
  Portal,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  chakra,
  Button,
  ButtonProps,
  Tooltip,
} from "@chakra-ui/react";
import clsx from "clsx";

import { memo, useCallback, useMemo, useState } from "react";
import {
  MdClear,
  MdLaunch,
  MdMoreVert,
  MdOutlineCopyAll,
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineStore,
} from "react-icons/md";
import { Node, NodeProps, useReactFlow, useStoreApi } from "reactflow";

import { useShowToast } from "@/components/toast";
import { triggerAutoSave } from "@/features/workflow-studio/redux";
import useDetachNodes from "@/hooks/useDetachNodes";
import { useAppDispatch } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";

import { useCreateFlowMutation, useLazyGetNodeQuery } from "../../api";
import { useCreateFlowNodes } from "../../hooks/useAddFlowToWorkflow";
import { FlowSchema, NodeType } from "../../types";

const PopOverItem = ({
  icon,
  onClick,
  colorScheme = "dark",
  children,
  ...props
}: {
  icon: JSX.Element;
  onClick: () => void;
  colorScheme?: ButtonProps["colorScheme"];
  children: JSX.Element | string;
}) => {
  return (
    <chakra.div
      role="button"
      as={Button}
      variant={"ghost"}
      colorScheme={colorScheme}
      leftIcon={icon}
      className="p-2 !justify-start !text-sm border-b"
      onClick={onClick}
      {...props}
    >
      {children}
      {/* <Flex align="center" justify={"start"} gap={3}>
        {icon}
        <Box>{children}</Box>
      </Flex> */}
    </chakra.div>
  );
};

const FlowNode = memo(({ id, selected }: NodeProps) => {
  const dispatch = useAppDispatch();

  const store = useStoreApi();
  const { deleteElements, getNode, setNodes, toObject } = useReactFlow();
  const detachNodes = useDetachNodes();
  const [expandInfo, setExpandInfo] = useState<boolean>(false);
  const flowNodeData: FlowSchema = store.getState().nodeInternals.get(id)?.data;
  const toast = useShowToast(undefined, undefined, false);
  const { duplicateFlow } = useCreateFlowNodes();
  const [createFlow, { isLoading: isUploadingFlow }] = useCreateFlowMutation();
  const [getNodeDetail, { isLoading: loadingInstanceIds }] =
    useLazyGetNodeQuery();

  const showLoader = isUploadingFlow || loadingInstanceIds;
  const isNotUploadedToFlowStore =
    flowNodeData.nodeUsageInstanceId === null ||
    flowNodeData.nodeUsageInstanceId === undefined;

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const deleteFlow = () => {
    deleteElements({ nodes: [{ id }] });
    autoSave();
  };

  const childNodes = useMemo(() => {
    return Array.from(store.getState().nodeInternals.values()).filter(
      (node) => node.parentNode === id
    );
  }, [store, id]);

  const childNodeIds = useMemo(
    () => childNodes.map((node) => node.id),
    [childNodes]
  );

  const getNodeDetails = useCallback(
    async (node: Node) => {
      const resp = await getNodeDetail({
        nodeId: node.data.nodeId,
        nodeVersionId: node.data.nodeVersionId,
      }).unwrap();
      return resp.response.data?.nodeDetails;
    },
    [getNodeDetail]
  );

  const updateNodeUsageId = useCallback(
    (responses: (NodeType | undefined)[] = []) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (childNodeIds.includes(node.id)) {
            const nodeUsageInstanceId = responses.find(
              (resp) => resp?.nodeVersionId === node.data.nodeVersionId
            )?.nodeUsageInstanceId;
            return { ...node, data: { ...node.data, nodeUsageInstanceId } };
          }
          return node;
        })
      );
    },
    [childNodeIds, setNodes]
  );

  const onDetach = async () => {
    try {
      if (flowNodeData.nodeUsageInstanceId) {
        const responses = await Promise.all(childNodes.map(getNodeDetails));
        setTimeout(() => {
          updateNodeUsageId(responses);
          autoSave();
        }, 100);
      }
      detachNodes(childNodeIds, id);
      autoSave();
    } catch (e) {
      toast({
        title: "Error ungrouping flow",
        status: "error",
        variant: "subtle",
      });
      console.error(e);
    }
  };
  const openFlowEditModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.EDIT_FLOW_DETAILS,
        modalProps: { node: getNode(id), setNodes },
      })
    );
  };

  const openUploadFlowModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.UPLOAD_FLOW,
        modalProps: { node: getNode(id), setNodes, instance: toObject() },
      })
    );
  };

  const copyFlow = () => {
    const node = getNode(id);
    if (node) duplicateFlow(node);
    autoSave();
  };

  return (
    <div
      className={clsx(
        "bg-white border-x border-[#666666] h-full w-full !min-w-[400px]  !z-[3000]",
        selected && "border-x-2",
        !flowNodeData.description && "rounded-b border-b",
        !flowNodeData.description && selected && "border-b-2"
      )}
    >
      <Box
        className={clsx(
          "absolute left-0 border border-b-[#d3d3d3] border-[#666666]",
          "bg-white -top-10 w-full !min-w-[400px] h-10 rounded-t p-2 text-sm",
          "flex justify-between overflow-hidden",
          selected && "border-2 border-b"
        )}
      >
        <Flex className="items-center gap-2 w-fit overflow-hidden">
          {showLoader && <Spinner size={"xs"} />}
          <Tooltip
            className="!bg-gray-800 !text-xs wrap !rounded !py-1"
            label={flowNodeData.displayName}
            openDelay={500}
            placement="top"
          >
            <Box className="text-ellipsis overflow-hidden whitespace-pre">
              {flowNodeData.displayName}
            </Box>
          </Tooltip>
        </Flex>
        <Popover isLazy placement="bottom-end">
          {({ isOpen }) => (
            <>
              <PopoverTrigger>
                <Button
                  colorScheme="dark"
                  leftIcon={isOpen ? <MdClear /> : <MdMoreVert />}
                  variant="link"
                ></Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent className="bg-white !z-[3000] !border !border-gray-400 !w-fit !min-w-[220px] !rounded-sm">
                  <Flex className="flex-col w-full">
                    <PopOverItem
                      icon={<MdOutlineCopyAll className="w-4 h-4" />}
                      onClick={copyFlow}
                    >
                      Copy Flow
                    </PopOverItem>
                    {/* <PopOverItem onClick={() => {}} icon={<MdOutlineCopyAll />}>
                      Copy Flow
                    </PopOverItem>
                    <PopOverItem
                      onClick={() => {}}
                      icon={<MdOutlineFileUpload />}
                    >
                      Export as Flow
                    </PopOverItem> */}
                    {isNotUploadedToFlowStore && (
                      <PopOverItem
                        icon={<MdOutlineStore className="w-4 h-4" />}
                        onClick={openUploadFlowModal}
                      >
                        Upload to Flow Store
                      </PopOverItem>
                    )}
                    <PopOverItem
                      icon={<MdOutlineEdit className="w-4 h-4" />}
                      onClick={openFlowEditModal}
                    >
                      Edit Flow Details
                    </PopOverItem>
                    <PopOverItem
                      icon={<MdLaunch className="w-4 h-4" />}
                      //eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={onDetach}
                    >
                      Ungroup Flow
                    </PopOverItem>
                    <PopOverItem
                      icon={<MdOutlineDelete className="w-4 h-4" />}
                      onClick={deleteFlow}
                      colorScheme="red"
                    >
                      Remove flow
                    </PopOverItem>
                  </Flex>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      </Box>
      {flowNodeData.description && (
        <div
          className={clsx(
            "absolute border border-t-0 border-[#666666] top-[100%] items-center",
            "w-full !min-w-[400px] left-0 rounded-b bg-white flex cursor-pointer pl-2 py-1",
            selected && "border-2"
            // expandInfo ? "h-max" : "h-fit"
          )}
          onClick={() => setExpandInfo(!expandInfo)}
        >
          <div
            className={clsx(
              "text-sm overflow-hidden text-ellipsis grow",
              !expandInfo && " line-clamp-1"
            )}
          >
            {flowNodeData.description}
          </div>
          <div className="self-start p-2 h-min grid place-items-center">
            {expandInfo ? (
              <MdOutlineExpandLess size={18} />
            ) : (
              <MdOutlineExpandMore size={18} />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default FlowNode;
