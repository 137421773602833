import { Flex } from "@chakra-ui/react";
import clsx from "clsx";

import { useAppSelector } from "@/reduxHooks";

import { showPromptHistory } from "../redux";

import LLMPromptInput from "./llm-prompt-input/llm-prompt-input";
import LLMTestResult from "./llm-results/llm-results";
import LLMSettings from "./llm-settings/llm-settings";
import ResizableRows from "./ResizableDiv";

const LLMConfigBody = () => {
  const isHistoryOpen = useAppSelector(showPromptHistory);

  return (
    <Flex
      className={clsx(
        "grow max-w-full h-full bg-white border overflow-hidden border-gray-100 rounded-md",
        "transition-all duration-200",
        isHistoryOpen ? "m-2" : "m-0"
      )}
    >
      <Flex className="flex-col shrink-0 min-w-[400px] max-w-[25%]">
        <ResizableRows>
          <LLMPromptInput />
          <LLMSettings />
        </ResizableRows>
      </Flex>
      <Flex className="flex-1 border-l overflow-hidden">
        <LLMTestResult />
      </Flex>
    </Flex>
  );
};

export default LLMConfigBody;
