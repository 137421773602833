import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar("form-error-color");

const baseStyleText = defineStyle({
  [$fg.variable]: `colors.red.600`,
  fontSize: "0.8125rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "140%",
  letterSpacing: "-0.01625rem",
});

const baseStyleIcon = defineStyle({
  [$fg.variable]: `colors.red.600`,
  color: $fg.reference,
  marginStart: "0.125rem",
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
  icon: baseStyleIcon,
});

export const formErrorTheme = defineMultiStyleConfig({
  baseStyle,
});
