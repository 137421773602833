import { Button, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { useShowToast } from "@/components/toast";
import {
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { DATASET_USAGE } from "@/utils/enums";

import { useDeleteDatasetMutation } from "../api";
import { DATASET_USAGE_MESSAGE } from "../utils/constants";

const UsageConfirmationModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const {
    dataset,
    onClose: onModalClose,
    errType,
  } = useAppSelector((state) => state.rootReducer.modals.modalProps);

  const [deleteDataset, { isLoading: isDeletingDataset }] =
    useDeleteDatasetMutation();

  const onClose = () => {
    if (isDeleting) return;
    if (onModalClose) onModalClose();
    dispatch(closeModal());
  };

  const onDelete = async () => {
    try {
      await deleteDataset({
        analysisId: dataset.analysisId,
        datasetId: dataset.id,
      }).unwrap();
      toast({
        title: "Dataset deleted",
        status: "success",
      });
    } catch (e) {
      const errMsg =
        (e as any).data?.errors[0].detail || "Failed to delete dataset";
      toast({
        title: "Error: Failed to delete dataset",
        description: errMsg,
        status: "error",
      });
    }
    dispatch(closeModal());
  };

  const isDeleting = isDeletingDataset;
  const warningMessage = useMemo(() => {
    return DATASET_USAGE_MESSAGE[errType as DATASET_USAGE];
  }, [errType]);
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      isCentered
      size="lg"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="flex flex-col justify-end !px-5 gap-3">
          <Flex className="justify-between items-center w-full">
            <Text className="text-xl font-semibold grow">
              Dataset currently in use. Confirm Delete ?
            </Text>
            <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
          </Flex>
          <Text className="">{warningMessage}</Text>
          <Flex className={"justify-between w-full mt-7"}>
            <Button
              mr={2}
              colorScheme="secondary"
              isDisabled={isDeleting}
              onClick={onClose}
              variant="link"
            >
              Cancel
            </Button>
            <Button
              colorScheme="customRed"
              isDisabled={isDeleting}
              isLoading={isDeleting}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onDelete}
            >
              Delete
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UsageConfirmationModal;
