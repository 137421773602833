import { Box, Flex, Icon } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { MdsErrorRound, MdsInfoRound } from "react-icons-with-materialsymbols/mds";

import { useGetExportTokenMutation } from "@/features/user-manager";
import { useAppSelector } from "@/reduxHooks";
import { getBaseUrl } from "@/utils/baseUrls";

import { getDatasetIconColor } from "../../components/ongoing-uploads/get-icon";
import { DatasetsSchema } from "../../types";

import { CopyTextBox } from "./copy-text-box";
import { ExportTitle } from "./export-title";

export const ExportBody = () => {
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);
  const [getExportToken, { data, isLoading, isError, error }] =
    useGetExportTokenMutation();

  useEffect(() => {
    try {
      getExportToken({
        analysisId: modalProps?.analysisId as string,
        datasetId: modalProps?.dataset?.id as string,
      })
        .unwrap()
        .catch((e) => console.log(e));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const dataset = modalProps?.dataset as DatasetsSchema;
  const iconColor = getDatasetIconColor(dataset?.ioRecord?.ioType || "input");
  const { token, url, daysLeft } = useMemo(() => {
    if (data) {
      const baseUrl = getBaseUrl().replace("backend", "api");
      const tokenResp = data?.response.data?.token;
      const days = data?.response.data?.daysLeft;

      const urlResp =
        baseUrl +
        "/data-export/pds-mgmt/api/v1.0/analyses/" +
        modalProps?.analysisId +
        "/datasets/" +
        dataset.id +
        "/exports/";

      return { token: tokenResp, url: urlResp, daysLeft: days };
    }
    return { token: "", url: "" };
  }, [data]);

  if (isLoading) {
    return (
      <Flex className="h-full w-full flex-col items-center gap-4">
        <Box className="animate-pulse w-full bg-gray-100 h-14 p-3 border rounded"></Box>
        <Box className="animate-pulse w-full bg-gray-100 h-14 p-3 border rounded"></Box>
      </Flex>
    );
  }

  if (isError) {
    return (
      <Flex className="gap-3 items-center border border-red-500 text-red-500 p-3 bg-red-50 rounded">
        <Icon as={MdsErrorRound} fontSize={24} />
        {error?.data?.errors[0]?.detail ??
          "Error fetching token. Please try again later."}
      </Flex>
    );
  }

  return (
    <Flex className="flex-col w-full gap-3">
      <ExportTitle iconColor={iconColor} title={dataset.displayName} />
      <CopyTextBox
        text={token}
        title="Token"
        description="Paste this token in Source"
      />
      <CopyTextBox
        text={url}
        title="URL"
        description="Use this URL as source connection"
      />
      <Flex className="text-sm items-center font-medium text-gray-800 gap-2 mt-3">
        <Icon as={MdsInfoRound} fontSize={18} strokeWidth={22} />
        <Box className="">Token will expire in {daysLeft} days</Box>
      </Flex>
    </Flex>
  );
};
