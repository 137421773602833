import { captureMessage } from "@sentry/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { createStandaloneToast } from "@/components/toast";
import {
  clearAll,
  currentProject,
  currentWs,
  setCurrentProject,
  setCurrentWs,
  useLazyGetWsQuery,
  isLoading,
  setIsLoading,
  useLazyGetWsListQuery,
  setPreviewAnalysis,
  useLazyGetProjectQuery,
} from "@/features/ws-manager";
import { ProjectSchema, WorkspaceSchema } from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";

export const useGetCurrentWs = () => {
  const { project: projectId, workspace: workspaceId } = useParams();
  const { query: analysisId, params } = useCustomQuery("analysis");
  const [getSingleProject] = useLazyGetProjectQuery();
  const navigate = useNavigate();
  const toast = createStandaloneToast();

  const shownProject = useSelector(currentProject);
  const shownWs = useSelector(currentWs);
  const loading = useSelector(isLoading);

  const [getWs] = useLazyGetWsQuery();
  const [getWsList] = useLazyGetWsListQuery();

  const dispatch = useDispatch();

  const checkAnalysisId = () => {
    if (analysisId) {
      dispatch(setPreviewAnalysis({ id: analysisId }));
    }
  };
  const checkIfOnlyProjectId = async () => {
    if (projectId && !workspaceId) {
      dispatch(setIsLoading(true));
      try {
        const ws = await getWsList().unwrap();
        const wsData = ws.response.data!.workspaces as WorkspaceSchema[];
        const matchingWs = wsData.find(
          (item) => item.projects?.some((project) => project.id === projectId)
        );
        if (matchingWs) {
          let url = `/home/${matchingWs.id}/project/${projectId}`;
          let paramList: string[][] = [];
          for (const entry of params.entries()) {
            paramList.push(entry);
          }
          paramList = paramList.filter((item) => item[0] !== "search");
          paramList.forEach((item) => {
            url += `?${item[0]}=${item[1]}`;
          });
          navigate(url, {
            replace: true,
          });
        }
      } catch (e) {
        captureMessage("Failed to fetch single Workspace", {
          extra: { error: e },
        });
      }
      dispatch(setIsLoading(false));
    }
  };

  const checkToClearValues = () => {
    if (!workspaceId) {
      dispatch(clearAll());
    }

    if (!projectId) {
      dispatch(setCurrentProject(null));
    }
  };

  const setProject = async () => {
    if (!projectId) return;
    try {
      const projectData = await getSingleProject(projectId).unwrap();
      const proj = projectData?.response.data?.projects[0] as ProjectSchema;
      if (!proj) return;
      dispatch(setCurrentProject(proj));
    } catch (e) {
      navigate("/home", { replace: true });
      return;
    }
  };

  const checkToSetValues = async () => {
    const urlWsIdDoesNotMatchShownWsId =
      !!workspaceId && workspaceId !== shownWs?.id;

    const wsDataDoesNotExist = !!workspaceId && !shownWs;
    const hasOnlyProjectId = !!projectId && !workspaceId;

    const shouldFetchWsData =
      !hasOnlyProjectId && (wsDataDoesNotExist || urlWsIdDoesNotMatchShownWsId);

    const shouldUpdateProject =
      !hasOnlyProjectId && !!projectId && projectId !== shownProject?.id;

    if (shouldFetchWsData) {
      dispatch(setIsLoading(true));
      try {
        const ws = await getWs(workspaceId).unwrap();
        const wsData = ws.response.data!.workspaces[0] as WorkspaceSchema;

        dispatch(setCurrentWs(wsData));
        await setProject();
      } catch (e) {
        // TODO : Handle Wrong workspace ID error
        navigate("/home", { replace: true });
        captureMessage("Failed to fetch single Workspace", {
          extra: { error: e },
        });
      } finally {
        dispatch(setIsLoading(false));
      }
    } else if (shouldUpdateProject) {
      dispatch(setIsLoading(true));
      await setProject();
      dispatch(setIsLoading(false));
    }
    checkAnalysisId();
  };

  useEffect(() => {
    checkIfOnlyProjectId();
    checkToClearValues();
    checkToSetValues();
  }, [projectId, workspaceId, analysisId]);

  return { loading, shownWs, shownProject };
};
