import clsx from "clsx";
import { useMemo } from "react";
import { MdReport, MdSettings } from "react-icons/md";
import { useStore } from "reactflow";

import { validateNodeConfig } from "../../utils/validations";

export const NodeErrors = ({ id }: { id: string }) => {
  const nodes = useStore((store) => store.getNodes());
  const edges = useStore((store) => store.edges);
  const currentNode = useStore((store) => store.nodeInternals.get(id)!);

  const { configErrors, connectionErrors } = useMemo(
    () => validateNodeConfig(currentNode, nodes, edges),
    [edges, currentNode, nodes]
  );

  return (
    <>
      {(configErrors.length > 0 || connectionErrors.length > 0) && (
        <div
          className={clsx(
            "group absolute -bottom-3.5 -right-2 rounded-full cursor-pointer",
            "border border-[#666666] bg-white px-0.5 py-0.5 text-sm",
            "flex items-center justify-between"
          )}
        >
          {connectionErrors.length > 0 && (
            <div className="group/nodeErr hover:bg-gray-200 relative rounded-full py-1 px-1 text-red-500">
              <MdReport className="h-5 w-5 text-gray-800" />
              <span className="absolute -right-2 top-10 hidden w-max rounded border border-red-500 bg-red-50 px-3 py-2 h-max group-hover/nodeErr:flex flex-col">
                {connectionErrors.map((error, i) => (
                  <div key={i}>{error}</div>
                ))}
              </span>
            </div>
          )}
          {configErrors.length > 0 && (
            <div className="group/configErr relative py-0.5 px-0.5 hover:bg-gray-200 rounded-full text-gray-700">
              <MdSettings className="h-5 w-5 text-gray-800" />
              <span className="absolute -left-2 top-10 hidden w-max rounded border border-gray-700 bg-gray-50 px-3 py-2 group-hover/configErr:flex">
                Please complete the node configuration
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
