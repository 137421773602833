import { Flex, IconButton, Tag, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import { MdsChevronRightRound } from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";

import { selectedColumn } from "@/features/data-transformation";
import { PanelStateContext } from "@/features/data-transformation/components";

export const SuggestionsHeader = () => {
  const col = useSelector(selectedColumn);
  const { isOpen, onToggle } = useContext(PanelStateContext);
  return (
    <AnimatePresence>
      <Flex
        className={clsx(
          "w-full items-center overflow-hidden max-w-full bg-white p-0.5 pr-2 gap-x-1",
          isOpen ? "justify-between" : "justify-center"
        )}
      >
        <Flex className="flex-shrink-0 gap-x-1 w-fit justify-start items-center overflow-hidden max-w-full">
          <IconButton
            as={motion.button}
            color="gray.500"
            animate={{
              rotate: !isOpen ? 180 : 0,
              transition: { duration: 0.2, type: "spring" },
            }}
            aria-label="collapse"
            colorScheme="secondary"
            icon={<MdsChevronRightRound />}
            onClick={onToggle}
            size="lgSm"
            variant="ghost"
          />
          {isOpen && (
            <Text
              className={clsx(
                "text-gray-800 text-[15px] leading-[16px] tracking-[-0.3px] font-medium max-w-full truncate"
              )}
              as={motion.span}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 1 }}
            >
              Suggestions
            </Text>
          )}
        </Flex>
        {isOpen && col && (
          <Tag
            as={motion.span}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 1 }}
          >
            “
            <Text className={clsx("font-inconsolata max-w-full truncate")}>
              {col.column?.name}
            </Text>
            ”
          </Tag>
        )}
      </Flex>
    </AnimatePresence>
  );
};
