import { Flex } from "@chakra-ui/react";
import { useMemo } from "react";

// the available renderer-related props are:
// - `row` (row index)
// - `col` (column index)
// - `prop` (column property name)
// - `TD` (the HTML cell element)
// - `cellProperties` (the `cellProperties` object for the edited cell)
interface CustomRendererProps {
  row: number; // row index
  col: number; // column index
  prop: string; // column property name
  TD: HTMLElement; // HTML cell element
  cellProperties: any; // cellProperties object for the edited cell
  fetchedIndex: Set<number>; // Set of fetched indices
}

export function CustomRenderer(props: any & CustomRendererProps) {
  const value = useMemo(
    () => (typeof props?.value == "boolean" ? props?.value + "" : props?.value),
    [props?.value]
  );

  if (!props?.fetchedIndex.has(props?.row)) {
    return <Flex>Loading...</Flex>;
  }

  return (
    <div
      className="truncate overflow-ellipsis max-w-full h-full w-full"
      title={value}
    >
      <pre className="!h-3 !font-inconsolata overflow-ellipsis max-w-full w-full">
        {value}
      </pre>
    </div>
  );
}
