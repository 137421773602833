import { captureException } from "@sentry/react";
import { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast, ToastType } from "@/components/toast";
import {
  EdaMetaDataContext,
  executeInProgress,
  setIsExecuteInProgress,
  useSaveEDAStepsMutation,
  useGetRequestStatusQuery,
  ACTIVE_REQUEST_TYPE,
  activeRequests,
  ACCESS_MODE,
  setEdaAccessMode,
  useLazyAllStepsQuery,
  triggerFetch,
  FETCH_TYPE,
} from "@/features/data-transformation";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { POLLING_STATUS, STATUS } from "@/utils/enums.ts";

const POLLING_INTERVAL = 1000;

export const useTaskExecution = () => {
  const dispatch = useDispatch();
  const toast = useShowToast();
  const { analysisId, edaId, executeEda } = useContext(EdaMetaDataContext);
  const isExecuting = useSelector(executeInProgress);
  const requests = useSelector(activeRequests);

  const [isLoadingExecute, setIsLoadingExecute] = useState(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [taskMessage, setTaskMessage] = useState<string | null>(null);

  const [saveSteps] = useSaveEDAStepsMutation();
  const [fetchAllSteps] = useLazyAllStepsQuery();

  useEffect(() => {
    const executionRequest = requests?.find(
      (_req) => _req.requestType === ACTIVE_REQUEST_TYPE.EXECUTE
    );
    if (!executionRequest) return;
    setRequestId(executionRequest.id);
    dispatch(setIsExecuteInProgress(!!executionRequest));
  }, [requests, dispatch]);

  const { data: runStatusData, isError } = useGetRequestStatusQuery(
    {
      analysisId: analysisId!,
      edaId: edaId!,
      requestId: requestId!,
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: requestId == null,
    }
  );

  const fetchSavedSteps = useCallback(async () => {
    try {
      if (!edaId) return;
      await fetchAllSteps({
        analysisId: analysisId!,
        edaId: edaId,
      });
      dispatch(triggerFetch(FETCH_TYPE.TABLE));
    } catch (e) {
      captureException(e);
      dispatch(
        openModal({ modalProps: {}, modalType: ModalTypes.FATAL_ERROR })
      );
    }
  }, [edaId, analysisId, fetchAllSteps, dispatch]);

  useEffect(() => {
    if (!runStatusData) return;
    const _data = runStatusData.response?.data;
    const message = _data?.taskProgress?.message ?? null;
    setTaskMessage(message);

    if (_data?.taskStatus === POLLING_STATUS.COMPLETED) {
      resetTask();
      fetchSavedSteps();
    }

    const isFailedGracefully =
      _data?.taskStatus === POLLING_STATUS.FAILED ||
      runStatusData.status === STATUS.FAIL;

    if (isFailedGracefully) {
      resetTask();
      toast({
        title: "Save and execute failed",
        status: ToastType.Error,
        duration: 5000,
      });
      return;
    }

    if (isError) {
      captureException(runStatusData);
      dispatch(
        openModal({ modalProps: {}, modalType: ModalTypes.FATAL_ERROR })
      );
    }
  }, [runStatusData, isError]);

  const resetTask = () => {
    setRequestId(null);
    dispatch(setIsExecuteInProgress(false));
  };

  const onExecute = async () => {
    if (!edaId) return;

    try {
      setIsLoadingExecute(true);
      const res = await saveSteps({
        analysisId: analysisId!,
        edaId,
        execute: true,
        confirm: true,
      }).unwrap();
      executeEda?.();
      setRequestId(res.response?.data?.requestId ?? null);
      dispatch(setIsExecuteInProgress(true));
      dispatch(setEdaAccessMode(ACCESS_MODE.READ));
    } catch (e) {
      console.error(e);
      toast({
        title: "Failed to execute",
        status: ToastType.Error,
        duration: 2000,
      });
    } finally {
      setIsLoadingExecute(false);
    }
  };

  return {
    isExecuting,
    isLoadingExecute,
    taskMessage,
    requestId,
    setRequestId,
    onExecute,
  };
};
