export const CtkLoader = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 53 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="section1"
      d="M50 26.5C50 33.1865 47.148 39.2207 42.5703 43.5C36 43.5 32.4261 43.5 26 43.5C19.5739 43.5 17 43.5 9.42975 43.5C4.85196 39.2207 2 33.1865 2 26.5C2 13.5213 12.7452 3 26 3C39.2548 3 50 13.5213 50 26.5Z"
      fill="#E7491A"
    />
    <path
      id="section2"
      d="M14.5 55.9726C10.8303 55.7715 6.80742 47.9996 9.50001 47.9999H43C45.1214 47.9998 41 55.5 37 56.0001C36.5039 56.0622 15 56 14.5 55.9726Z"
      fill="#E7491A"
    />
    <path
      id="section3"
      d="M18.7085 66.9569C16.3126 66.9569 13.8693 59.9997 15.5631 60H36.6371C37.9716 59.9999 35.379 66.5445 32.8627 66.9809C32.5506 67.035 19.5084 66.9569 18.7085 66.9569Z"
      fill="#E7491A"
    />
  </svg>
);
