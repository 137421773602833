import { Box } from "@chakra-ui/react";
import clsx from "clsx";

import { useCustomEditor } from "../../hooks/useCustomEditor";

import ColumnDropdown from "./column-dropdown";

export interface ISuggestedColumns {
  id: string;
  display: string;
}

const PromptInputBox = () => {
  const {
    showProptError,
    isDisabled,
    parentRef,
    inputRef,
    handleInputChange,
    onKeyDownHandle,
    handlePaste,
    showDropdown,
    filteredOptions,
    coordinates,
    handleDropdownSelect,
    handleBlur,
    dropdownRef,
  } = useCustomEditor();

  return (
    <Box
      className={clsx(
        "relative rounded-t pl-2 py-2 overflow-hidden border",
        showProptError ? "border-red-600 " : "border-[#ececec] ",
        isDisabled ? "cursor-not-allowed bg-[#ececec]" : "bg-white cursor-text"
      )}
      ref={parentRef}
    >
      <Box
        className={clsx(
          "max-h-[250px] min-h-[230px] pr-2 text-base font-normal focus:outline-none",
          "select-text overflow-auto !relative"
        )}
        ref={inputRef}
        aria-disabled={isDisabled}
        aria-label="LLM Prompt"
        aria-multiline="true"
        contentEditable={isDisabled ? "false" : "true"}
        onInput={handleInputChange}
        onKeyDown={(e) => onKeyDownHandle(e)}
        onPaste={handlePaste}
        tabIndex={0}
      ></Box>
      {showDropdown && (
        <ColumnDropdown
          options={filteredOptions}
          coordinates={coordinates}
          onSelect={handleDropdownSelect}
          onBlur={handleBlur}
          dropdownRef={dropdownRef}
        />
      )}
    </Box>
  );
};

export default PromptInputBox;
