import { Button } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { MdsDoneRound } from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemDescription,
  MenuList,
} from "@/design/components/menu";
import {
  currentSampling,
  EXPLORE_DESCRIPTION,
  EXPLORE_STATS_TYPE,
  FULL_DATA_SAMPLING,
} from "@/features/data-transformation";

export const ExploreDropdown = ({
  onChange,
  exploreType,
  disabled = false,
}: {
  onChange: (arg0: EXPLORE_STATS_TYPE) => void;
  exploreType: EXPLORE_STATS_TYPE;
  disabled: boolean;
}) => {
  const sampling = useSelector(currentSampling);

  const [menu, setMenu] = useState(false);

  const isFullData = useMemo(() => {
    return sampling?.shortName == FULL_DATA_SAMPLING;
  }, [sampling]);

  const closeMenu = () => {
    setMenu(false);
  };

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const handleTypeChange = (method: EXPLORE_STATS_TYPE) => {
    onChange(method);
  };
  return (
    <Menu isOpen={menu} placement="bottom-start" onClose={closeMenu} isLazy>
      <MenuButton
        as={Button}
        onClick={toggleMenu}
        className="!cursor-pointer ml-3"
        size="xs"
        variant="outline"
        colorScheme="secondary"
        isDisabled={isFullData || disabled}
        disabled={isFullData || disabled}
        rightIcon={<Icon as={TbChevronDown} size="xs" color="gray.600" />}
      >
        {isFullData ? `Explore ${EXPLORE_STATS_TYPE.FULL}` : exploreType}
      </MenuButton>
      <MenuList className="w-[288px] !z-10">
        {[EXPLORE_STATS_TYPE.SAMPLE, EXPLORE_STATS_TYPE.FULL].map(
          (method, index) => {
            const isSelected = exploreType === method;
            return (
              <MenuItem
                key={index}
                hasDescription={true}
                onClick={handleTypeChange.bind(null, method)}
              >
                Explore {method}
                {isSelected && (
                  <Icon
                    as={MdsDoneRound}
                    size="sm"
                    color="gray.900"
                    className="absolute right-2"
                  />
                )}
                <MenuItemDescription className="mt-1">
                  {EXPLORE_DESCRIPTION[method]}
                </MenuItemDescription>
              </MenuItem>
            );
          }
        )}
      </MenuList>
    </Menu>
  );
};
