/**
 * @function
 * Converts a `snake_case` string to a `Capital Case` string.
 * @param {string} str - string to convert
 * @returns {string} Converted string
 * @example
 * // returns 'Hello World'
 * snakeCaseToCapital('hello_world');
 */
export const snakeCaseToCapital = (str: string): string => {
  const words = str.split("_");
  const capitalWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
  });
  return capitalWords.join(" ");
};

/**
 * @function
 * Converts any string to a capitalized `SNAKE_CASE` string.
 * @param {string} str - string to convert
 * @returns {string} Converted string
 * @example
 * // returns 'HELLO_WORLD'
 * toSnakeCase('hello world');
 *
 */
export const toCaptialSnakeCase = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.toUpperCase())
    .join("_");
};

export const getFileName = (name: string): string => {
  return name.substring(0, name.lastIndexOf("."));
};

export const getFileExtension = (name: string): string => {
  return name.substring(name.lastIndexOf(".") + 1);
};

/**
 * Formats a string representing a number with commas every 3 digits.
 *
 * @param {string} numberString The string representation of a number.
 * @returns {string} The formatted string with commas for every 3 digits, or the original string if conversion fails.
 *
 * @example
 * ```typescript
 * const formattedNumber = formatNumberWithCommas("1234567890");
 * console.log(formattedNumber); // Output: 1,234,567,890
 * ```
 */
export function formatNumberWithCommas(numberString: string): string {
  // Type check for number conversion
  const number = Number(numberString);
  if (isNaN(number)) {
    return numberString; // Return original string if not a number
  }

  // Formatting with Intl.NumberFormat
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0, // No decimals
    maximumFractionDigits: 0,
  });

  return formatter.format(number);
}

export const safeNumber = (value: any, fallback: number): number => {
  const parsed = Number(value);
  return isNaN(parsed) ? fallback : parsed;
};

export function isValidEmail(email: string) {
  const regex = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regex.test(email.toLowerCase());
}