import { Box, Button, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState, useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  formatTooltipLabel,
  groupFormattedData,
  groupTimeSeriesData,
  IDataPoint,
  TIME_FRAMES,
  TimeFrame,
} from "@/features/data-transformation";

interface TimeseriesChartProps {
  data: IDataPoint[];
}

const TimeseriesChart: React.FC<TimeseriesChartProps> = ({ data }) => {
  const [timeFrame, setTimeFrame] = useState<TimeFrame>("day");

  const groupedData = useMemo(() => {
    return groupTimeSeriesData(data, timeFrame);
  }, [data, timeFrame]);

  const chartData = useMemo(() => {
    return groupFormattedData(groupedData);
  }, [groupedData]);

  const formatXAxis = (tickItem: string) => {
    const date = dayjs(tickItem);
    switch (timeFrame) {
      case "week":
        return `${date.format("MMM D")} - ${date
          .endOf("week")
          .format("MMM D")}`;
      case "month":
        return date.format("MMM YYYY");
      case "year":
        return date.format("YYYY");
      default:
        return date.format("MMM D");
    }
  };

  const formatLabel = (label: string) => {
    return formatTooltipLabel(label, timeFrame);
  };

  const yAxisDomain = useMemo(() => {
    const values = chartData.map((item) => item.value);
    const min = Math.min(...values);
    const max = Math.max(...values);

    if (min === max) {
      const padding = max === 0 ? 1 : max * 0.5; // 50% padding
      return [max - padding, max + padding];
    } else {
      const padding = (max - min) * 0.1; // 10% padding
      return [Math.max(0, min - padding), max + padding];
    }
  }, [chartData]);

  return (
    <Box className="w-full h-full flex flex-col">
      <Flex className="justify-between items-center mb-4">
        <Flex className="border-b border-gray-200">
          {TIME_FRAMES.map((frame) => (
            <Button
              key={frame}
              color={timeFrame === frame ? "orange.500" : "gray.500"}
              borderColor="orange.500"
              borderBottom={timeFrame === frame ? "2px solid" : "none"}
              borderRadius="0"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              onClick={() => setTimeFrame(frame)}
              variant="ghost"
            >
              {frame.charAt(0).toUpperCase() + frame.slice(1)}
            </Button>
          ))}
        </Flex>
      </Flex>
      <Box className="flex-1 min-h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 5, right: 40, left: -48, bottom: 5 }}
          >
            <XAxis
              dataKey="label"
              tickFormatter={formatXAxis}
              interval={"preserveStartEnd"}
            />
            <YAxis
              domain={yAxisDomain}
              axisLine={true}
              tickLine={false}
              tick={false}
            />
            <Tooltip
              labelFormatter={formatLabel}
              formatter={(value: number) => [value.toFixed(2), "Value"]}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              activeDot={{ r: 1 }}
              dot={{ r: 1 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default TimeseriesChart;
