import {
  Avatar as ChakraAvatar,
  AvatarProps,
  AvatarBadgeProps,
  AvatarBadge as ChakraAvatarBadge,
} from "@chakra-ui/react";

export const Avatar = (props: AvatarProps) => {
  return <ChakraAvatar {...props} />;
};

type BadgeProps = Omit<AvatarBadgeProps, "boxSize">;

export const AvatarBadge = (props: BadgeProps) => {
  return <ChakraAvatarBadge {...props} boxSize="1.25em" />;
};
