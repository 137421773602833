import { Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { Reorder } from "framer-motion";
import { MdsDragIndicatorRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";
import { ITransformationStep } from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";

import { StepContext } from "./step-context.ts";
import { StepWrapper } from "./step-wrapper.tsx";

type StepProps = {
  step: ITransformationStep;
  isOpen: boolean;
  index: number;
  totalSteps: number;
  canReorder: boolean;
  reorder: (step: ITransformationStep) => void;
  updateStepCallback: () => void;
};
export const Step = ({
  step,
  isOpen,
  index,
  totalSteps,
  canReorder,
  reorder,
  updateStepCallback,
}: StepProps) => {
  const isDisabled = !step.activeStatus;
  const { hasWriteAccess } = useReadOnlyMode();

  return (
    <Reorder.Item
      key={step.transformationStepId}
      value={step}
      dragListener={canReorder}
      onDragStart={() => {
        reorder(step);
      }}
      onDragEnd={updateStepCallback}
    >
      <StepContext.Provider value={{ step, isOpen, hasWriteAccess }}>
        <Flex className="relative cursor-pointer" role="group">
          <StepWrapper key={index} isLast={index === totalSteps - 1} />
          {!isDisabled && canReorder && (
            <Icon
              as={MdsDragIndicatorRound}
              className={clsx(
                "icon !hidden absolute top-1 -left-4 translate-y-full cursor-grab active:cursor-grabbing",
                "active:!block active:absolute peer-hover:!block peer-hover:absolute",
                "hover:!block hover:absolute"
              )}
            />
          )}
        </Flex>
      </StepContext.Provider>
    </Reorder.Item>
  );
};
