import { Flex, Spinner } from "@chakra-ui/react";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";

import { useGetTransformationsQuery } from "@/features/data-transformation";

import { ActiveCategoryContext } from "./active-category-context.ts";
import { OperationTile } from "./opertation-tile.tsx";

const fuseOptions = {
  keys: ["name", "description"],
};

export const OperationsList = () => {
  const { data, isLoading } = useGetTransformationsQuery({});
  const operations = data?.response?.data?.transformations || [];
  const allParameters = data?.response?.data?.parameters || {};

  const category = useContext(ActiveCategoryContext)!;

  const filteredOperations = operations.filter(
    (operation) => operation.category?.includes(category.activeCategory!)
  );

  const list = category.activeCategory ? filteredOperations : operations;
  const fuse = new Fuse(list, fuseOptions);

  const searchedList = useMemo(() => {
    if (!isEmpty(category.activeSearch)) {
      return fuse.search(category.activeSearch ?? "").map((res) => res.item);
    }

    return list;
  }, [category.activeSearch, list]);

  return (
    <Flex className="flex-col flex-[1] min-w-[280px] w-full px-2 py-3 pb-12 gap-y-3 overflow-auto max-h-full border-r">
      {searchedList.map((operation, idx) => (
        <OperationTile
          key={idx}
          operation={operation}
          allParameters={allParameters}
        />
      ))}
      {isLoading && <Spinner />}
    </Flex>
  );
};
