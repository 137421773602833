import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { useMemo } from "react";
import { MdsChevronRightRound } from "react-icons-with-materialsymbols/mds";
import { useNavigate } from "react-router-dom";

import { Button } from "@/design/components/button";

export const SourceListFilesFooter = ({
  selectedFiles,
  isLoading,
  onUpload,
  onCancel,
}: {
  selectedFiles: any;
  isLoading: boolean;
  onUpload: () => Promise<void>;
  onCancel: (e: { preventDefault: () => void }) => void;
}) => {
  const navigate = useNavigate();

  const fileName = useMemo(() => {
    const name = selectedFiles?.stream?.name as string;
    if (name.length > 20) {
      return name.slice(0, 10) + "..." + name.slice(-10);
    }
    return name;
  }, [selectedFiles]);

  return (
    <>
      <Flex className="w-full border-t items-center justify-between bg-white px-6 py-4">
        <Button
          mr={3}
          onClick={onCancel}
          variant="outline"
          colorScheme="outline"
          className="shrink-0"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Flex className="flex-[0.5] items-center justify-end text-brand-500 gap-3 max-w-[50%]">
          {selectedFiles && (
            <Tooltip
              isDisabled={fileName.length < 20}
              label={selectedFiles?.stream?.name}
            >
              <Flex className="max-w-full gap-2">
                <Box className="max-w-full overflow-hidden line-clamp-1 font-bold">
                  "{fileName}"
                </Box>
                <span>selected</span>
              </Flex>
            </Tooltip>
          )}

          <Button
            mr={3}
            onClick={onUpload}
            className="shrink-0"
            colorScheme="brand"
            isLoading={isLoading}
            disabled={isLoading}
            rightIcon={<MdsChevronRightRound />}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
