/* eslint-disable */
import camelCase from "lodash/camelCase";

export function keysToCamel(snakeCaseJson: any, skipIfKey?: string): any {
  if (
    snakeCaseJson === Object(snakeCaseJson) &&
    !Array.isArray(snakeCaseJson) &&
    typeof snakeCaseJson !== "function"
  ) {
    const n: { [key: string]: any } = {};
    Object.keys(snakeCaseJson).forEach((key: string) => {
      const camelKey = camelCase(key);
      const shouldSkipObject = skipIfKey === camelKey;

      n[camelKey] = shouldSkipObject
        ? snakeCaseJson[key]
        : keysToCamel(snakeCaseJson[key], skipIfKey);
    });
    return n;
  } else if (Array.isArray(snakeCaseJson)) {
    return snakeCaseJson.map((i) => {
      return keysToCamel(i);
    });
  }
  return snakeCaseJson;
}

export function keysToSnake(input: any): any {
  if (typeof input !== "object" || input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(keysToSnake);
  }

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [
      key.replace(/([A-Z])/g, "_$1").toLowerCase(),
      keysToSnake(value),
    ])
  );
}
