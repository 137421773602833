import { useMemo } from "react";
import { useSelector } from "react-redux";

import { compiledTransformationSteps } from "@/features/data-transformation";

export const useOldColumns = () => {
  const stepsList = useSelector(compiledTransformationSteps);

  const firsStepWithOldCols = useMemo(
    () =>
      stepsList?.find(
        (step) => step.metadata?.onSample?.previousStepColumnInfo
      ),
    [stepsList]
  );
  return firsStepWithOldCols?.metadata?.onSample?.previousStepColumnInfo;
};
