import { formAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...parts.keys, "icon"]);

const baseStyleHelperText = defineStyle({
  color: "gray.600",
  fontSize: "0.8125rem",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "140%",
  letterSpacing: "-0.01625rem",
});
const iconStyle = defineStyle({
  marginEnd: "0.5em",
  marginStart: "0.125rem",
  // transform: "rotate(180deg)",
});

const baseStyle = definePartsStyle({
  helperText: baseStyleHelperText,
  icon: iconStyle,
});

export const formTheme = defineMultiStyleConfig({
  baseStyle,
});
