import { createContext } from "react";

import {
  ISamplingTechnique,
  ISamplingTechniquesResponse,
  ITableDataColumns,
} from "@/features/data-transformation";

type FilterSubmenuContextType = {
  isOpen: boolean;
  sampling: ISamplingTechniquesResponse | null;
  selectedSamplingMethod: ISamplingTechnique | null;
  onSamplingChange: (
    method: ISamplingTechnique | null,
    samplingNumber: string,
    selectedColumn: Partial<ITableDataColumns> | null
  ) => void;
  numberOfRows: string | undefined;
  setNumberOfRows: (value: string) => void;
  selectedColumn: Partial<ITableDataColumns> | null;
  setSelectedColumn: (column: Partial<ITableDataColumns>) => void;
  error: string;
  setError: (error: string) => void;
};

export const FilterSubmenuContext = createContext<
  FilterSubmenuContextType | undefined
>(undefined);
