import { Divider } from "@chakra-ui/react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import InviteAndShareBody from "./invite-share-body";

export const InviteMemberManageAccess = () => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite new members?</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody className="px-6">
          <InviteAndShareBody onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
