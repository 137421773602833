import { Flex } from "@chakra-ui/react";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";

import { IconButton } from "@/design/components/icon-button";

export const ImportExportButtons = () => {
  return (
    <Flex gap={1}>
      <IconButton
        aria-label="undo"
        variant="ghost"
        size="sm"
        title="Import workflow"
        colorScheme="black"
        isDisabled={true}
        icon={<MdOutlineFileUpload />}
      />
      <IconButton
        aria-label="undo"
        variant="ghost"
        size="sm"
        title="Export workflow"
        colorScheme="dark"
        isDisabled={true}
        icon={<MdOutlineFileDownload />}
      />
    </Flex>
  );
};
