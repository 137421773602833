import { Flex, Text } from "@chakra-ui/react";
import {
  Bar,
  BarChart,
  Cell,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { IChart } from "@/features/data-transformation";

import { CustomizedLabel } from "./custom-label.tsx";
type CustomTooltipProps = {
  active?: boolean;
  payload?: { value: string | number; name?: string }[];
  label?: string;
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <Flex className="bg-gray-800 rounded p-1">
        <Text className="text-white text-sm">{label}</Text>
      </Flex>
    );
  }

  return null;
};

export const HistogramChart = ({ data }: { data: IChart[] }) => {
  const maxValue = Math.max(...data.map((item) => item.value));

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="label" type="category" scale="auto" interval={0} hide />
        <YAxis domain={[0, maxValue]} hide />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
        <Bar
          dataKey="value"
          fill="var(--chakra-colors-green-100)"
          label={<CustomizedLabel short={true} />}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} aria-label={entry.label} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
