import { AlertStatus } from "@chakra-ui/react";

import { TOAST_DURATION, ToastType } from "../components/toast";

interface ToastMessage {
  title: string;
  description?: string;
  type: string | undefined;
  status?: AlertStatus | undefined;
  action?: string;
  duration?: number;
}

export type ToastFunction = (title: string) => ToastMessage;
type ToastMessageOrFunction = ToastMessage | ToastFunction;

export interface ToastMessages {
  [key: string]: ToastMessageOrFunction;

  projectDeleted: ToastFunction;
  analysisDeleted: ToastFunction;
  workspaceDeleted: ToastFunction;
  projectCreated: ToastFunction;
  workspaceCreated: ToastFunction;
  analysisCreated: ToastFunction;
  transformStepApplied: ToastFunction;
}

export const TOAST_MESSAGES: ToastMessages = {
  addToFavorites: {
    title: "Analysis added to Favorites",
    type: undefined,
    status: undefined,
  },
  removeFromFavorites: {
    title: "Analysis removed from Favorites",
    type: undefined,
    status: undefined,
  },
  analysisDetailsUpdated: {
    title: "Analysis details updated",
    type: undefined,
    status: undefined,
  },
  projectDetailsUpdated: {
    title: "Project details updated",
    type: undefined,
    status: undefined,
  },
  workspaceDetailsUpdated: {
    title: "Workspace details updated",
    type: undefined,
    status: undefined,
  },
  projectDeleted: (title: string) => ({
    title: `${title} deleted`,
    type: undefined,
    status: undefined,
    duration: TOAST_DURATION.MEDIUM,
  }),
  analysisDeleted: (title: string) => ({
    title: `${title} Analysis deleted`,
    type: undefined,
    status: undefined,
    duration: TOAST_DURATION.MEDIUM,
  }),
  workspaceDeleted: (title: string) => ({
    title: `${title} Workspace deleted`,
    type: undefined,
    status: undefined,
    duration: TOAST_DURATION.MEDIUM,
  }),
  projectCreated: (title: string) => ({
    title: `${title} Project created`,
    type: ToastType.Success,
    status: ToastType.Success,
    duration: TOAST_DURATION.MEDIUM,
  }),
  workspaceCreated: (title: string) => ({
    title: `New Workspace created`,
    type: ToastType.Success,
    status: ToastType.Success,
    duration: TOAST_DURATION.MEDIUM,
  }),
  analysisCreated: (title: string) => ({
    title: `New Analysis created`,
    type: ToastType.Success,
    status: ToastType.Success,
    duration: TOAST_DURATION.MEDIUM,
  }),
  processingSteps: {
    title: "Data is being processed,Please wait until data is processed",
    status: ToastType.Info,
    type: ToastType.Info,
  },
  saveStepsSampling: {
    title: "Unsaved steps, Please save the steps before sampling",
    status: ToastType.Error,
    duration: TOAST_DURATION.MEDIUM,
    type: ToastType.Error,
  },
  recoveredStep: {
    title: "Transform Step Recovered",
    status: ToastType.Success,
    type: ToastType.Success,
  },
  stepDeleted: {
    title: "Transform step deleted",
    status: ToastType.Success,
    duration: TOAST_DURATION.MEDIUM,
    type: ToastType.Success,
  },
  terminateFailed: {
    title: "Terminate execution failed",
    status: ToastType.Error,
    duration: TOAST_DURATION.MEDIUM,
    type: ToastType.Error,
  },
  suggestionApplied: {
    title: "Suggestion applied",
    status: ToastType.Success,
    type: ToastType.Success,
  },
  transformStepApplied: (title: string) => ({
    title: `${title} applied`,
    type: ToastType.Success,
    status: ToastType.Success,
  }),
  allFiltersCleared: {
    title: "All filters cleared",
    status: ToastType.Success,
    duration: TOAST_DURATION.MEDIUM,
    type: ToastType.Success,
  },
  sampleUpdated: {
    title: "Sample updated",
    status: ToastType.Success,
    type: ToastType.Success,
  },
  sampleFulLData: {
    title: "Full data loaded",
    status: ToastType.Success,
    type: ToastType.Success,
  },
  changesSaved: {
    title: "Changes saved successfully",
    status: ToastType.Success,
    type: ToastType.Success,
  },
  copyToClipboard: {
    title: "Copied to clipboard",
    status: ToastType.Success,
    type: ToastType.Success,
  },
};
