import { Flex, Spinner } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useGetTransformationDescriptionsQuery } from "@/features/data-transformation";

import { ActiveCategoryContext } from "./active-category-context.ts";

export const OperationsDescription = () => {
  const { shortName } = useContext(ActiveCategoryContext)!;
  const { data } = useGetTransformationDescriptionsQuery(
    { shortName: shortName! },
    { skip: !shortName }
  );

  const hasNoData = useMemo(() => !data && isEmpty(data), [data]);
  if (hasNoData)
    return (
      <Flex
        className="h-full overflow-auto w-fullh-full w-full justify-center items-center"
        sx={{
          flex: "1.8 0 0",
        }}
      >
        Hover over a transformation to view more
      </Flex>
    );
  if (shortName && hasNoData)
    return (
      <Flex
        className="h-full overflow-auto w-full justify-center items-center"
        sx={{
          flex: "1.8 0 0",
        }}
      >
        <Spinner />
      </Flex>
    );

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      className="rich-text py-5 px-6 mb-12 h-auto gap-y-2 flex flex-col overflow-auto flex-[1.8] flex-shrink-0"
    >
      {data?.data?.[0]?.attributes?.description}
    </Markdown>
  );
};
