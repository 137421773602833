import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useRef } from "react";
import {
  MdArrowRight,
  MdCancel,
  MdOutlineErrorOutline,
  MdOutlineMessage,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import { Edge, ReactFlowInstance, Node, useReactFlow } from "reactflow";

import { useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { IconButton } from "@/design/components/icon-button";
import { NodeType } from "@/features/workflow-studio/types";
import { updateNodeIdAfterRun } from "@/features/workflow-studio/utils";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { STATUS } from "@/utils/enums";

import { showPanel, triggerRunButton } from "../..";
import {
  useLazyGetWorkflowQuery,
  useGetWorkflowRunStatusQuery,
  useRunWorkflowMutation,
  useTerminateWorkflowMutation,
} from "../../api/workflow-api";
import {
  currentWorkflowId,
  currentWorkflowRunId,
  setEditingAllowed,
  setWorkflowRunId,
  setWorkflowRunStatus,
  setworkflowStatus,
  workflowRunningStatus,
  workflowStatus as wfRunStatus,
} from "../../redux/workflow-slice";
import {
  WorkflowNodeSchema,
  WorkflowSchema,
  WorkflowStatusSchema,
} from "../../types/workflow-types";
import {
  NODE_STATUS,
  NODE_STATUS_ICONS,
  WORKFLOW_PANELS,
} from "../../utils/constants";
import { ConvertFlowtoWorkflowPayload } from "../../utils/transform-response";
import { validateNodeConfig } from "../../utils/validations";

export const RunAndLogButtons = ({
  nodes,
  edges,
}: {
  nodes: Node[];
  edges: Edge[];
  reactFlowInstance: ReactFlowInstance;
}) => {
  const dispatch = useAppDispatch();
  const { setNodes, toObject } = useReactFlow();
  const toast = useShowToast();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const params = useParams();
  const triggerRun = useAppSelector(triggerRunButton);
  const workflowId = useAppSelector(currentWorkflowId);
  const workflowRunId = useAppSelector(currentWorkflowRunId);
  const workflowRunStatus = useAppSelector(workflowRunningStatus);
  const wfnodeStatus = useAppSelector(wfRunStatus);

  const [runWorkflow, { isLoading }] = useRunWorkflowMutation();
  const [terminateWFApi, { isLoading: isterminating }] =
    useTerminateWorkflowMutation();
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();

  const workflowErrors = nodes.filter(
    (node: Node) => !validateNodeConfig(node, nodes, edges).isValid
  );
  const hasError = useMemo(() => workflowErrors.length > 0, [workflowErrors]);

  const isDisabled = useMemo(
    () => isLoading || nodes.length === 0,
    [isLoading, nodes.length]
  );

  const isTerminateEnabled = wfnodeStatus?.terminateWorkflowEnabled ?? false;

  const shouldSkip =
    workflowId !== undefined &&
    workflowId !== null &&
    workflowRunStatus !== NODE_STATUS.RUNNING;

  const { data } = useGetWorkflowRunStatusQuery(
    { workflowId: workflowId!, workflowRunId: workflowRunId ?? "" },
    {
      skip: shouldSkip,
      pollingInterval: 2000,
    }
  );

  useEffect(() => {
    if (!triggerRun) return;

    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, [triggerRun]);

  const updateNodesAfterRunSuccess = async () => {
    const response = await getWorkflowDetails({
      workflowId: params.editorId!,
    }).unwrap();
    const wf = response.response.data?.workflows[0] as WorkflowSchema;
    const workflowNodes = wf.workflowNodes;
    if (wf.workflowStatus === NODE_STATUS.RUNNING || workflowNodes.length <= 0)
      return;
    setNodes((nds) =>
      nds.map((node) => {
        const newNode = workflowNodes.find(
          (n: WorkflowNodeSchema) =>
            n.workflowNodeId === (node.data as NodeType).workflowNodeId
        );
        if (newNode) {
          return {
            ...node,
            data: {
              ...node.data,
              nodeStatus: newNode.nodeStatus,
              isOutput: newNode.isOutput,
              outputName: newNode.outputName,
              outputState: newNode.outputState,
            } as NodeType,
          };
        }
        return node;
      })
    );
  };

  // update store on data change
  useEffect(() => {
    if (data) {
      const workflowStatus = data?.response?.data
        ?.workflowAndNodeStatus as WorkflowStatusSchema;

      switch (workflowStatus?.workflowRunStatus) {
        case NODE_STATUS.SUCCESS:
          toast({
            title: "Workflow run completed successfully",
            status: "success",
          });
          dispatch(setEditingAllowed(true));
          updateNodesAfterRunSuccess().catch((e) => console.error(e));
          break;
        case NODE_STATUS.FAILED:
          toast({
            title: "Workflow Run Failed",
            status: "error",
          });
          dispatch(setEditingAllowed(true));
          break;
      }
      dispatch(setworkflowStatus(workflowStatus));
    }
  }, [data, dispatch, toast]);

  const openworkflowErrorsModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKFLOW_ERRORS,
        modalProps: {
          nodes: nodes,
          edges: edges,
        },
      })
    );
  };

  const handleRunWorkflow = () => {
    const obj = toObject();
    dispatch(setEditingAllowed(false));
    runWorkflow({
      analysisId: params.analysisId!,
      workflowId: workflowId!,
      workflow: ConvertFlowtoWorkflowPayload(obj),
    })
      .unwrap()
      .then((res) => {
        const wfStatus = res.response.data?.workflows[0] as WorkflowSchema;
        if (wfStatus.workflowStatus === NODE_STATUS.RUNNING) {
          updateNodeIdAfterRun(wfStatus.workflowNodes, setNodes);
          toast({
            title: "Workflow run initiated successfully",
            status: "success",
          });
          dispatch(setWorkflowRunStatus(wfStatus.workflowStatus));
          dispatch(setWorkflowRunId(wfStatus.workflowRunId as string));
        }
      })
      .catch((err) => {
        dispatch(setEditingAllowed(true));
        console.log(err);
      });
  };

  const onTerminate = () => {
    toast({
      title: "Workflow terminated Successfully",
      status: "success",
    });

    dispatch(setEditingAllowed(true));
    dispatch(
      setworkflowStatus({
        ...wfnodeStatus!,
        workflowRunStatus: NODE_STATUS.CANCELLED,
      })
    );
    updateNodesAfterRunSuccess().catch((e) => console.error(e));
  };

  const terminateWorkflow = async () => {
    dispatch(setEditingAllowed(true));
    try {
      const terminateResp = await terminateWFApi({
        workflowId: workflowId!,
        workflowRunId: workflowRunId!,
      }).unwrap();
      if (terminateResp.status === STATUS.SUCCESS) {
        onTerminate();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const openLogsPanel = () => {
    dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
  };

  return (
    <Flex align="center" gap={1}>
      <IconButton
        aria-label="undo"
        variant="ghost"
        size="sm"
        colorScheme="dark"
        onClick={openLogsPanel}
        icon={<MdOutlineMessage />}
      />
      {workflowRunStatus === NODE_STATUS.RUNNING ? (
        <Flex className="gap-2 pr-2" align="center" justify={"end"}>
          <Tooltip
            isDisabled={isTerminateEnabled}
            label="Building workflow, please wait..."
            placement="bottom"
          >
            <Button
              className="ml-2"
              onClick={terminateWorkflow}
              size="sm"
              isDisabled={!isTerminateEnabled}
              variant="outline"
              isLoading={isLoading || isterminating}
              color="red.600"
              rightIcon={<MdCancel />}
            >
              Terminate Run
            </Button>
          </Tooltip>
          <Box className="text-gray-800 pb-1">
            {NODE_STATUS_ICONS[workflowRunStatus as NODE_STATUS].icon}
          </Box>
          <Box className="text-gray-800 text-xs">Running Workflow</Box>
        </Flex>
      ) : (
        <Button
          ref={buttonRef}
          onClick={hasError ? openworkflowErrorsModal : handleRunWorkflow}
          size="sm"
          className="disabled:cursor-not-allowed"
          variant={hasError ? "outline" : "solid"}
          isLoading={isLoading}
          isDisabled={isDisabled}
          colorScheme="dark"
          rightIcon={hasError ? <MdOutlineErrorOutline /> : <MdArrowRight />}
        >
          Run WorkFlow
        </Button>
      )}
    </Flex>
  );
};
