import { Route, Routes } from "react-router-dom";

import { HomeLayout } from "@/features/ws-manager/layout/home-layout.tsx";
import { Home } from "@/features/ws-manager/routes/home.tsx";
import { RecycleBinRoute } from "@/features/ws-manager/routes/recycle-bin.tsx";

export const WorkspaceManagement = () => {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/:workspace?/:project?" element={<Home />} />
        <Route path="/project/:project?" element={<Home />} />
        <Route path="/:workspace?/project/:project?" element={<Home />} />
        <Route path="/recycle-bin" element={<RecycleBinRoute />} />
      </Route>
    </Routes>
  );
};
