import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    _checked: {
      bg: "white",
      borderColor: "var(--chakra-colors-orange-400)",
      _hover: {
        borderColor: "var(--chakra-colors-red-600)",
        bg: "white",
      },
      _before: {
        content: `""`,
        display: "inline-block",
        pos: "relative",
        w: "50%",
        h: "50%",
        borderRadius: "50%",
        bg: "var(--chakra-colors-orange-400)",
      },
    }, // change the border color
  },
});

const sizes = {
  // define custom styles for xl size
  xl: definePartsStyle({
    control: { w: "6", h: "6" },
    label: { fontSize: "xl" },
  }),
};

// define custom variant
const variants = {
  groove: definePartsStyle({
    control: {
      borderRadius: 0,
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "purple.500",
      background: "purple.500",

      _checked: {
        bg: "var(--chakra-colors-orange-400)",
        borderColor: "var(--chakra-colors-orange-400)",
        _hover: {
          borderColor: "var(--chakra-colors-red-600)",
          bg: "var(--chakra-colors-red-600)",
        },
      },
      _dark: {
        borderColor: "purple.200",
        background: "purple.200",

        _hover: {
          bg: "purple.400",
          borderColor: "purple.400",
        },
      },
      _hover: {
        bg: "purple.700",
        borderColor: "purple.700",
      },
    },
  }),
};

// export the component theme
export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
});
