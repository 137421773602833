import { Flex, Text, Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import { IconSizes } from "@/design/theme/components";
import {
  ISuggestion,
  selectedColumn,
  setToastMessage,
  suggestionIconMap,
} from "@/features/data-transformation";
import { useColumnTransformations } from "@/features/data-transformation/hooks/useColumnTransformations.ts";
import { formatNumberWithCommas } from "@/utils/string-utils.ts";

export const SuggestionTile = ({ ...suggestion }: ISuggestion) => {
  const col = useSelector(selectedColumn);
  const dispatch = useDispatch();
  const { createAndDispatchStep } = useColumnTransformations([]);
  const toast = useShowToast(undefined, undefined, true);

  const onClick = () => {
    const parameterArrayToMap = suggestion.parameters
      .map((param) => ({
        [param.shortName!]: param.value,
      }))
      .reduce((acc, curr) => Object.assign(acc, curr), {});

    createAndDispatchStep(
      col!.column.name,
      suggestion.shortName,
      parameterArrayToMap
    );
    dispatch(setToastMessage(TOAST_MESSAGES.suggestionApplied));
  };

  const icon =
    suggestionIconMap[suggestion.shortName]?.icon ??
    suggestionIconMap["default"].icon;

  const rows = isEmpty(suggestion.potentialImpact.count)
    ? suggestionIconMap["default"].rows
    : suggestion.potentialImpact.count;
  return (
    <Tooltip
      aria-label="Suggestion Message"
      label={suggestion.message}
      openDelay={1000}
      placement="top"
    >
      <Flex
        className="group flex-col justify-center w-[280px] max-w-full items-start gap-y-1 p-2.5 border border-gray-200 rounded cursor-pointer hover:shadow-suggestionsHover"
        onClick={onClick}
        role="group"
      >
        <Flex className="items-center gap-x-1 max-w-full">
          <Icon
            as={icon}
            size="sm"
            color="gray.800"
            _groupHover={{ boxSize: IconSizes.md }}
          />
          <Text className="text-gray-800 leading-[20px] font-medium text-[13px] max-w-full truncate">
            {suggestion.message}
          </Text>
        </Flex>
        <Flex className="font-inconsolata items-center gap-1 text-[13px] leading-[15px]">
          <Text
            className={clsx(
              "text-gray-700 font-bold",
              "group-hover:text-gray-900 group-hover:leading-[15px]"
            )}
          >
            {formatNumberWithCommas(rows)}
          </Text>
          <Text className="text-gray-600 font-medium">rows found</Text>
        </Flex>
      </Flex>
    </Tooltip>
  );
};
