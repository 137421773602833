import {
  chakra,
  IconProps,
  useFormControlContext,
  useFormControlStyles,
} from "@chakra-ui/react";
import { cx } from "@chakra-ui/shared-utils";
import { forwardRef, HTMLChakraProps } from "@chakra-ui/system";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { Icon } from "../icon";

export interface FormHelperTextProps extends HTMLChakraProps<"div"> {}

/**
 * FormHelperText
 *
 * Assistive component that conveys additional guidance
 * about the field, such as how it will be used and what
 * types in values should be provided.
 */
export const FormHelperText = forwardRef<FormHelperTextProps, "div">(
  function FormHelperText(props, ref) {
    const field = useFormControlContext();
    const styles = useFormControlStyles();
    const className = cx("chakra-form__helper-text", props.className);
    return (
      <chakra.div
        {...field?.getHelpTextProps(props, ref)}
        __css={{
          display: "flex",
          alignItems: "center",
          ...styles.helperText,
        }}
        className={className}
      />
    );
  },
);

FormHelperText.displayName = "FormHelperText";

export const FormHelperTextIcon = forwardRef<IconProps, "svg">((props, ref) => {
  const styles = useFormControlStyles();

  const _className = cx("chakra-form__helper-icon", props.className);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Icon
      ref={ref}
      aria-hidden
      {...props}
      className={_className}
      __css={styles.icon}
      size="sm"
      as={AiOutlineInfoCircle}
    />
  );
});

FormHelperTextIcon.displayName = "FormHelperTextIcon";
