import { Flex, Text } from "@chakra-ui/react";

import { limitDecimalPoints } from "@/utils/helper.ts";
import { formatNumberWithCommas } from "@/utils/string-utils.ts";

type NullValuesRowProps = {
  nullPercentage: number;
  nullValues: number | undefined;
};

export const NullValuesRow = ({
  nullPercentage,
  nullValues,
}: NullValuesRowProps) => {
  const nullValueString = nullValues?.toString() ?? "0";
  return (
    <Flex className="justify-between items-center mb-5">
      <Text className="uppercase text-xs leading-none font-semibold tracking-[0.6px] text-gray-600">
        Null Values
      </Text>
      <Text>
        {formatNumberWithCommas(nullValueString)} (
        {limitDecimalPoints(nullPercentage, 2)}%)
      </Text>
    </Flex>
  );
};
