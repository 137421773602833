import React, { useContext, ReactNode } from "react";

interface IOpenStateContext {
  isOpen: boolean;
  openPopOver: () => void;
  closePopOver: () => void;
}

const OpenStateContext = React.createContext<IOpenStateContext | undefined>(
  undefined
);

export const OpenStateProvider = OpenStateContext.Provider;

export const useIsOpenContext = (): IOpenStateContext => {
  const context = useContext(OpenStateContext);
  if (!context) {
    throw new Error("useIsOpenContext must be used within a IsOpenProvider");
  }
  return context;
};
