import { Flex } from "@chakra-ui/react";

import { ErrorFill } from "@/components/icons/error-fill.tsx";
import { Icon } from "@/design/components/icon";

export const AllFiltersError = ({ error }: { error: boolean }) => {
  if (!error) return null;

  return (
    <Flex className="text-[13px] text-red-600 font-medium tracking-26 leading-[140%]">
      <Icon
        as={ErrorFill}
        className="self-start mr-0.5 mt-0.5"
        size="sm"
        color="red.600"
      />
      No filter applied since all unique values were selected.
    </Flex>
  );
};
