import { AnyAction, current, ThunkDispatch } from "@reduxjs/toolkit";

import { userApi } from "@/features/user-manager";
import { ApiResponse } from "@/types";

import {
  AnalysesListSchema,
  ProjectListSchema,
  WorkspaceListSchema,
} from "../types";

import { analysisAPI } from "./analysis";
import { wsManagerApi } from "./api";

export const addWorkspace = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: ApiResponse<WorkspaceListSchema>
) => {
  dispatch(
    wsManagerApi.util.updateQueryData("getWsList", null, (draft) => {
      const currentState: ApiResponse<WorkspaceListSchema> = current(draft);
      const workspaces = currentState.response.data?.workspaces ?? [];
      const newWs = data.response.data?.workspaces[0];

      return {
        ...currentState,
        response: {
          ...currentState.response,
          data: {
            ...currentState.response.data,
            workspaces: [newWs, ...workspaces],
          },
        },
      };
    })
  );
  dispatch(
    userApi.util.invalidateTags([
      { type: "WorkspaceAccessList", id: "project" },
      { type: "WorkspaceAccessList", id: "analysis" },
    ])
  );
};

export const removeWorkspace = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  id: string
) => {
  dispatch(
    wsManagerApi.util.updateQueryData("getWsList", null, (draft) => {
      const currentState: ApiResponse<WorkspaceListSchema> = current(draft);
      const workspaces = currentState.response.data?.workspaces ?? [];

      return {
        ...currentState,
        response: {
          ...currentState.response,
          data: {
            ...currentState.response.data,
            workspaces: workspaces.filter((ws) => ws.id !== id),
          },
        },
      };
    })
  );
  dispatch(
    userApi.util.invalidateTags([
      { type: "WorkspaceAccessList", id: "project" },
      { type: "WorkspaceAccessList", id: "analysis" },
    ])
  );
};

export const updateWorkspace = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: ApiResponse<WorkspaceListSchema>
) => {
  dispatch(
    wsManagerApi.util.updateQueryData("getWsList", null, (draft) => {
      const currentState: ApiResponse<WorkspaceListSchema> = current(draft);
      const workspaces = currentState.response.data?.workspaces ?? [];
      const updatedWs = data.response.data?.workspaces[0];

      return {
        ...currentState,
        response: {
          ...currentState.response,
          data: {
            ...currentState.response.data,
            workspaces: workspaces.map((ws) => {
              if (ws.id === updatedWs?.id) {
                return updatedWs;
              }
              return ws;
            }),
          },
        },
      };
    })
  );
};

export const addProjectToWorkspace = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  body: { workspaceId: string },
  res: ApiResponse<ProjectListSchema>
) => {
  dispatch(
    wsManagerApi.util.updateQueryData("getWsList", null, (draft) => {
      const currentState: ApiResponse<WorkspaceListSchema> = current(draft);
      const workspaces = currentState.response.data?.workspaces ?? [];
      const newProject = res.response.data?.projects[0];
      //update the workspace list with the new project
      return {
        ...currentState,
        response: {
          ...currentState.response,
          data: {
            ...currentState.response.data,
            workspaces: workspaces.map((ws) => {
              if (ws.id === body.workspaceId) {
                return {
                  ...ws,
                  projects: [newProject, ...ws.projects!],
                };
              }
              return ws;
            }),
          },
        },
      };
    })
  );
  dispatch(
    userApi.util.invalidateTags([
      { type: "WorkspaceAccessList", id: "project" },
      { type: "WorkspaceAccessList", id: "analysis" },
    ])
  );
};

export const removeProjectFromWorkspace = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  body: { id: string; wsId: string }
) => {
  dispatch(
    wsManagerApi.util.updateQueryData("getWsList", null, (draft) => {
      const currentState: ApiResponse<WorkspaceListSchema> = current(draft);
      const workspaces = currentState.response.data?.workspaces ?? [];
      //update the workspace list with the new project
      return {
        ...currentState,
        response: {
          ...currentState.response,
          data: {
            ...currentState.response.data,
            workspaces: workspaces.map((ws) => {
              if (ws.id === body.wsId) {
                return {
                  ...ws,
                  projects: ws.projects?.filter(
                    (project) => project.id !== body.id
                  ),
                };
              }
              return ws;
            }),
          },
        },
      };
    })
  );
  dispatch(
    userApi.util.invalidateTags([
      { type: "WorkspaceAccessList", id: "project" },
      { type: "WorkspaceAccessList", id: "analysis" },
    ])
  );
};

export const updateProjectInWorkspace = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  body: { id: string; wsId: string },
  res: ApiResponse<ProjectListSchema>
) => {
  dispatch(
    wsManagerApi.util.updateQueryData("getWsList", null, (draft) => {
      const currentState: ApiResponse<WorkspaceListSchema> = current(draft);
      const workspaces = currentState.response.data?.workspaces ?? [];
      const updatedProject = res.response.data?.projects[0];
      //update the workspace list with the new project
      return {
        ...currentState,
        response: {
          ...currentState.response,
          data: {
            ...currentState.response.data,
            workspaces: workspaces.map((ws) => {
              if (ws.id === body.wsId) {
                return {
                  ...ws,
                  projects: ws.projects?.map((project) => {
                    if (project.id === body.id) {
                      return updatedProject;
                    }
                    return project;
                  }),
                };
              }
              return ws;
            }),
          },
        },
      };
    })
  );
};

export const removeAnalysis = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  body: {
    id: string;
    parent: string | undefined;
    parentId: string | undefined;
  },
  res: any
) => {
  let type = "getAnalysisListForOrg";
  if (body.parent === "workspace") {
    type = "getAnalysisListForWs";
  } else if (body.parent === "project") {
    type = "getAnalysisListForProject";
  }
  dispatch(
    analysisAPI.util.updateQueryData(
      type as any,
      { wsId: body.parentId, projectId: body.parentId },
      (draft) => {
        const currentState = current(draft) as ApiResponse<AnalysesListSchema>;
        const analyses = currentState.response.data?.analyses ?? [];
        
        return {
          ...currentState,
          response: {
            ...currentState.response,
            data: {
              ...currentState.response.data,
              analyses: analyses?.filter((a) => a.id !== body.id),
            },
          },
        };
      }
    )
  );
};
