import { MdArrowRight } from "react-icons/md";

import { Button } from "@/design/components/button";
import { ConfigurePanelFooter } from "@/design/components/configure-panel";

export const NodeConfigPanelFooter = ({ onClose }: { onClose: () => void }) => {
  return (
    <ConfigurePanelFooter display="flex" gap="5">
      <Button onClick={onClose} variant="outline" colorScheme="dark">
        Save
      </Button>
      {/* <Button
        rightIcon={<MdArrowRight size={24} />}
        variant="solid"
        colorScheme="dark"
      >
        Save & Run
      </Button> */}
    </ConfigurePanelFooter>
  );
};
