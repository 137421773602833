import { Select } from "@chakra-ui/react";
import React from "react";

import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import { NodeParameter } from "@/features/workflow-studio/types";

export const MultiSelectConfig = ({
  param,
  updateParameter,
}: {
  param: NodeParameter;
  updateParameter: (paramId: string, value: any, error: any) => void;
}) => {
  const changeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateParameter(param.parameterId, e.target.value, undefined);
  };

  return (
    <FormControl
      key={param.name}
      isRequired={param.isMandatory}
      isInvalid={param.errors && param.errors.length > 0}
    >
      <FormLabel>{param.name}</FormLabel>
      <Select
        defaultValue={param.value ?? param.defaultValue ?? ""}
        isDisabled={!param.isModifiable}
        onChange={(e) => changeHandler(e)}
        placeholder="Select option"
      >
        {param.valuesList.map((value: string) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        <FormErrorIcon />
        {param.errors?.map((err) => <div key={err}>{err}</div>)}
      </FormErrorMessage>
    </FormControl>
  );
};
