import { Box, Flex, Icon, Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import React from "react";

import { DataTableIcon } from "@/components/icons/data-table";

interface DownloadTitleProps {
  iconColor?: string;
  title: string;
}

export const DownloadTitle: React.FC<DownloadTitleProps> = ({
  iconColor,
  title,
}) => {
  const showTooltip = title.length > 40;
  return (
    <Tooltip isDisabled={!showTooltip} label={title} placement="top">
      <Flex className="rounded h-full items-center p-3 border gap-3 font-medium">
        <Box
          className={clsx("grid place-items-center h-9 w-9 rounded", iconColor)}
        >
          <Icon as={DataTableIcon} fontSize={24} />
        </Box>
        <Box className="max-w-full overflow-hidden text-ellipsis whitespace-pre">
          {title}
        </Box>
      </Flex>
    </Tooltip>
  );
};
