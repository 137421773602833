import { Button, Flex, FormControl } from "@chakra-ui/react";
import { useState } from "react";

import { ToastType, useShowToast } from "@/components/toast";
import {
  useCreateOrgAdminMutation,
  useLazyGetUsersListQuery,
} from "@/features/user-manager";
import { UsersSchema } from "@/features/user-manager/types";
import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";
import { PaginationBody } from "@/types";

import SuggestedAdminList from "./suggested-admin-list";

export const AddAdminBody = () => {
  const [addAdmin] = useCreateOrgAdminMutation();
  const [fetchUsers] = useLazyGetUsersListQuery();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useShowToast(undefined, undefined, true);
  const [userList, setuserList] = useState<UsersSchema[]>([]);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  console.log(error)
  const handleAddOrgAdmin = async () => {
    try {
      if (userList.length === 0) {
        setError("Please add atleast one user");
        return;
      }
      setIsLoading(true);
      const promises = userList.map((u) => {
        return addAdmin({ userId: u.id }).unwrap();
      });
      await Promise.all(promises);
      setuserList([]);
      toast({
        title: "Admins added successfully",
        status: ToastType.Success,
      });
      dispatch(closeModal());
      await fetchUsers({} as PaginationBody);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Flex className="flex-col gap-10">
      <FormControl size="lg">
        <SuggestedAdminList
          isLoading={isLoading}
          addedUsers={userList}
          setAddedUsers={setuserList}
          setIsLoading={setIsLoading}
          error={error}
          setError={setError}
        />
      </FormControl>
      <Flex className="w-full justify-between">
        <Button
          colorScheme="dark"
          isDisabled={isLoading}
          loadingText="Sending Invites"
          onClick={onClose}
          size="sm"
          variant="ghost"
        >
          Cancel
        </Button>
        <Button
          colorScheme="dark"
          isDisabled={isLoading || userList.length === 0}
          isLoading={isLoading}
          loadingText="Adding admin"
          //eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleAddOrgAdmin}
          size="sm"
          variant="solid"
        >
          Add Admin
        </Button>
      </Flex>
    </Flex>
  );
};
