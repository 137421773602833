import { Flex } from "@chakra-ui/react";
import clsx from "clsx";

import { useAppSelector } from "@/reduxHooks.ts";

import { bottombarVisible } from "../..";

import AddText from "./add-text";
import ViewFlows from "./flows";
import NodeCategories from "./node-categories";
import SearchNodes from "./search-nodes";


const BottomBar = () => {
  const bottomBarVisible = useAppSelector(bottombarVisible);

  return (
    <Flex
      className={clsx(
        `p-4 bg-white border border-gray-600 absolute left-1/2`,
        `gap-x-2 w-fit h-fit -translate-x-1/2 z-[3] rounded transition-all`,
        bottomBarVisible ? "-bottom-1" : "-bottom-40"
      )}
    >
      <SearchNodes />
      {/* <AddText /> */}
      <NodeCategories />
      <ViewFlows />
    </Flex>
  );
};

export default BottomBar;
