import { Flex, FlexProps } from "@chakra-ui/react";

import { useStyles } from "./title-bar";
export const TitleBarHeader = (props: FlexProps) => {
  const { children, ...rest } = props;
  const styles = useStyles();
  return (
    <Flex __css={styles.title} {...rest}>
      {children}
    </Flex>
  );
};

TitleBarHeader.displayName = "TitleBarHeader";
