import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect } from "react";
import { MdsArrowBackRound } from "react-icons-with-materialsymbols/mds";

import {
  showLLMConfigPanel,
  toggleLLMConfigPanel,
} from "@/features/workflow-studio";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import useWorkflowPlayground from "../hooks/useAiPlayground";
import { showPromptHistory } from "../redux";

import LLMConfigBody from "./llm-config-body";
import { LLMConfigFooter } from "./llm-footer";
import LLMHistoryPanel from "./llm-run-history/llm-history-panel";

const LLMPromptLayout = () => {
  const { isInitializing, initializePlayground, hasErrors } =
    useWorkflowPlayground();

  const dispatch = useAppDispatch();

  const isHistoryOpen = useAppSelector(showPromptHistory);
  const llmConfig = useAppSelector(showLLMConfigPanel);

  useEffect(() => {
    initializePlayground().catch((e) => console.log(e));
  }, [llmConfig, initializePlayground]);

  const onCancel = () => {
    dispatch(toggleLLMConfigPanel({ show: false }));
  };
  if (isInitializing) {
    return (
      <Flex className="flex-col max-w-full overflow-hidden h-full justify-center items-center ">
        <Spinner />
      </Flex>
    );
  } else if (hasErrors) {
    return (
      <Flex className="relative flex-col max-w-full overflow-hidden h-full justify-center items-center ">
        <Text>{hasErrors ?? "Couldn't load data"}</Text>
        <Button
          colorScheme="dark"
          leftIcon={<MdsArrowBackRound />}
          onClick={onCancel}
          size={"sm"}
          variant={"ghost"}
        >
          Back
        </Button>
      </Flex>
    );
  } else {
    return (
      <Flex className="flex-col h-full max-w-full overflow-hidden">
        <Flex className="flex-col grow bg-gray-50 max-w-full h-full overflow-hidden">
          <Box
            className={clsx(
              "transition-all w-full bg-white",
              isHistoryOpen ? "h-14" : "h-0"
            )}
          >
            <Box minH={"50px"}>{isHistoryOpen && <LLMHistoryPanel />}</Box>
          </Box>
          <LLMConfigBody />
        </Flex>
        <LLMConfigFooter />
      </Flex>
    );
  }
};

export default LLMPromptLayout;
