import { IconButton, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
  MdsCheckRound,
  MdsContentCopyRound,
} from "react-icons-with-materialsymbols/mds";

interface CopyButtonProps {
  title: string;
  textToCopy?: string;
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  title,
  textToCopy,
}) => {
  const [copying, setCopying] = useState(false);

  useEffect(() => {
    if (copying) {
      setTimeout(() => {
        setCopying(false);
      }, 1000);
    }
  }, [copying]);

  const handleCopy = () => {
    if (!textToCopy) return;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopying(true);
      })
      .catch((err) => {
        console.error("Error copying", err);
      });
  };

  return (
    <Tooltip
      className="top-2 !bg-black-900"
      isDisabled={copying || !textToCopy}
      label={copying ? "Copied" : title}
      placement="top"
    >
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: copying ? 360 : 0 }}
        transition={{ type: "just", stiffness: 1000 }}
      >
        <IconButton
          className={"shrink-0 cursor-pointer"}
          as={copying ? MdsCheckRound : MdsContentCopyRound}
          aria-label={title}
          colorScheme={isEmpty(textToCopy) ? "gray" : "dark"}
          isDisabled={isEmpty(textToCopy)}
          onClick={() => handleCopy()}
          size={"lg"}
          title={title}
          variant={"unstyled"}
        />
      </motion.div>
    </Tooltip>
  );
};
