import { Flex, useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { executeInProgress } from "@/features/data-transformation";
import {
  ExploreDataPanel,
  SuggestionsHeader,
  SuggestionsList,
} from "@/features/data-transformation/components";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";

import { PanelStateContext } from "./panel-context";

const RightPanel = () => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { hasWriteAccess } = useReadOnlyMode();
  const isExecuting = useSelector(executeInProgress);

  const showSuggestions = hasWriteAccess && !isExecuting;

  return (
    <PanelStateContext.Provider value={{ isOpen, onToggle }}>
      <Flex
        className="flex flex-col h-full w-[17.5rem] bg-white overflow-hidden border-l border-l-gray-300"
        as={motion.div}
        animate={{ width: isOpen ? "17.5rem" : "3.75rem" }}
      >
        {showSuggestions && (
          <Flex className="flex flex-col h-1/2 flex-1 flex-shrink-0 flex-grow-0">
            <SuggestionsHeader />
            <SuggestionsList />
          </Flex>
        )}

        <ExploreDataPanel />
      </Flex>
    </PanelStateContext.Provider>
  );
};

export { RightPanel };
