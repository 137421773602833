import {
  Button,
  Flex,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Icon,
} from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty, set } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdChevronRight, MdClear, MdError } from "react-icons/md";
import { useDebounce } from "use-debounce";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserId, currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { ACCESS_LEVEL, CATEGORY, USER_ROLES } from "@/utils/enums";
import { isValidEmail } from "@/utils/string-utils";

import {
  useCreateUserAccessMutation,
  useLazyGetUserFromQueryQuery,
  useLazyGetUserListForObjectQuery,
  useLazyValidaterUserMailsQuery,
} from "../../api";
import { UsersSchema } from "../../types";

import useHandleSelfModify from "./manage-access-utils";
import { UserSuggestions } from "./user-suggestions";

export interface UserProps extends UsersSchema {
  userId?: string;
}

const AddUserInput = () => {
  const [selectedRole, setSelectedRole] = useState<string>();
  const [suggestedUsers, setSuggestedUsers] = useState<
    UsersSchema[] | undefined
  >();
  const [inputValue, setInputValue] = useState("");
  const [addedUsers, setAddedUsers] = useState<Array<Partial<UsersSchema>>>([]);
  // const [nonInvitedUsers, setNonInvitedUsers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const loggedInUserId = useAppSelector(currentUserId);
  const userMetaData = useAppSelector(currentUserMetadata);
  const dispatch = useAppDispatch();

  const toast = useShowToast(undefined, undefined, true);

  const { object, type, roles } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  ) as {
    object: any;
    type: CATEGORY;
    roles: ACCESS_LEVEL[] | undefined;
  };

  const [getUsers, { isFetching }] = useLazyGetUserFromQueryQuery();
  const [getUserForObject] = useLazyGetUserListForObjectQuery();
  const [createAccess, { isLoading: isCreatingAccess }] =
    useCreateUserAccessMutation();
  const [verifyUserMails] = useLazyValidaterUserMailsQuery();

  const { refetchObject } = useHandleSelfModify();
  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    if (warning) setWarning("");
    if (isEmpty(event.target.value)) setSuggestedUsers(undefined);
    setInputValue(event.target.value);
  };

  const [debouncedValue] = useDebounce(inputValue, 300);

  useEffect(() => {
    if (!roles) return;
    setSelectedRole(ACCESS_LEVEL.VIEWER);
  }, [roles]);

  // make api call to search for user
  useEffect(() => {
    if (!debouncedValue || debouncedValue.length === 0) {
      return;
    }
    getUsers({ query: debouncedValue })
      .unwrap()
      .then((res) => {
        const users = res.response.data ?? [];
        const nonAdminUsers = users.filter(
          (u) =>
            u.orgRole !== USER_ROLES.ADMIN && u.orgRole !== USER_ROLES.CTK_ADMIN
        );
        setSuggestedUsers(nonAdminUsers);
        // setSuggestedUsers(res.response.data ?? []);
      })
      .catch(() => setSuggestedUsers(undefined));
  }, [debouncedValue, getUsers]);

  const addToAddedUsers = (user: Partial<UserProps>) => {
    if (addedUsers.findIndex((u) => u.id === user.id) === -1) {
      setAddedUsers([...addedUsers, user]);
    } else {
      setWarning("User already selected");
    }
    setSuggestedUsers(undefined);
    setInputValue("");
    inputRef.current?.focus();
    // setIsInputFocused(false);
    // inputRef.current?.focus();
  };

  const removeFromAddedUsers = (user: Partial<UserProps>) => {
    setAddedUsers(addedUsers.filter((u) => u.email !== user.email));
    setInputValue("");
  };

  const handleShare = async ({ users }: { users: UserProps[] }) => {
    try {
      console.log(users);
      const promises = users.map((u) => {
        const payload = {
          assigneeUserId: u.userId!,
          objectId: object.id!,
          role: selectedRole!.toLowerCase(),
          scope: type,
        };
        return createAccess(payload).unwrap();
      });
      await Promise.all(promises);
      if (users.find((u) => u.userId === loggedInUserId)) {
        await refetchObject({
          type,
          object,
          userRole: selectedRole!,
        });
      }
      setAddedUsers([]);
      setInputValue("");
      setIsLoading(false);
      toast({
        title: `${users.length} ${selectedRole}(s) added`,
        status: ToastType.Success,
      });
      await getUserForObject({
        objectType: type,
        objectId: object.id,
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const InviteAndShare = ({
    invitedUsers,
    nonInvitedUsers,
  }: {
    invitedUsers: UsersSchema[];
    nonInvitedUsers: string[];
  }) => {
    dispatch(
      openModal({
        modalType: ModalTypes.INVITE_MEMBER_MANAGE_ACCESS,
        modalProps: {
          object,
          type,
          selectedRole,
          userName: userMetaData?.displayName,
          newUserMails: nonInvitedUsers,
          selectedUsers: invitedUsers,
        },
      })
    );
  };

  const verifyInvitedUsers = async (emails: string[]) => {
    try {
      const resp = await verifyUserMails({ emails }).unwrap();
      return {
        invitedUsers: resp?.response?.data?.members ?? [],
        nonInvitedUsers: resp?.response?.data?.nonMembers ?? [],
      };
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      return {
        invitedUsers: [],
        nonInvitedUsers: emails,
      };
    }
  };

  const onShare = async () => {
    setIsLoading(true);
    console.log(addedUsers);
    const { invitedUsers, nonInvitedUsers } = await verifyInvitedUsers(
      addedUsers.map((u) => u.email!)
    );
    try {
      if (nonInvitedUsers.length > 0) {
        InviteAndShare({ invitedUsers, nonInvitedUsers });
      } else {
        await handleShare({ users: invitedUsers });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsInputFocused(false);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "escape") {
      setIsInputFocused(false);
      return;
    }
    if (
      event.key === "Backspace" &&
      inputValue === "" &&
      addedUsers.length > 0
    ) {
      setAddedUsers(addedUsers.slice(0, addedUsers.length - 1));
    }
    if (event.key === "Enter") {
      const trimmedValue = inputValue.trim();
      if (addedUsers.some((u) => u.email === trimmedValue)) {
        setWarning("User already selected");
        setInputValue("");
        return;
      }
      if (suggestedUsers && suggestedUsers.length > 0) {
        const user = suggestedUsers.find((u) => u.email === trimmedValue);
        if (user) {
          addToAddedUsers(user);
          setInputValue("");
          return;
        }
      }
      if (!isEmpty(trimmedValue)) {
        if (isValidEmail(trimmedValue)) {
          setAddedUsers([...addedUsers, { email: trimmedValue }]);
          setInputValue("");
        } else {
          setWarning("Invalid email");
        }
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    event.preventDefault();
    const newEmails = pastedText.split(",").map((email) => ({
      email: email.trim(),
    }));
    const validEmails = newEmails.filter((u) => isValidEmail(u.email));
    if (validEmails.length !== newEmails.length) {
      setInputValue(pastedText);
      setWarning("Invalid email");
      return;
    }
    const uniqueEmails = [...addedUsers, ...validEmails].filter(
      (u, i, self) => self.findIndex((t) => t.email === u.email) === i
    );
    setAddedUsers(uniqueEmails);
    console.log(uniqueEmails);
    inputRef.current?.focus();
    setInputValue("");
  };

  const hasAddedUsers = addedUsers.length > 0;

  return (
    <Flex className="flex-col gap-1 mb-2">
      <Flex align={"center"} gap={2}>
        <FormControl
          className={`flex grow border relative border-gray-500 rounded w-full h-max min-h-[3rem] pr-1 ${
            isCreatingAccess ? "bg-gray-100" : ""
          }`}
        >
          <Flex
            className="!w-full flex-wrap no-scrollbar"
            align={"center"}
            gap={2}
            overflowY={"auto"}
            maxH={"100px"}
            px={hasAddedUsers ? 2 : 0}
            py={2}
            onClick={() => {
              inputRef.current?.focus();
              setIsInputFocused(true);
            }}
          >
            {addedUsers.map((user) => {
              const name = isEmpty(user.firstName)
                ? user.email
                : user.firstName;
              return (
                <Flex
                  className="rounded-full whitespace-pre text-xs gap-1 border border-gray-400 hover:bg-gray-100 cursor-pointer px-2 py-1 w-max"
                  key={user.email}
                  align={"center"}
                  onClick={() => removeFromAddedUsers(user)}
                >
                  <span>{name}</span>
                  <Icon as={MdClear} />
                </Flex>
              );
            })}
            <Box className="min-w-[50px] grow" ref={wrapperRef}>
              <Input
                className={clsx(
                  "!w-full !h-full !border-none ",
                  addedUsers.length !== 0 && "!pl-0"
                )}
                ref={inputRef}
                autoComplete="off"
                isDisabled={isCreatingAccess}
                onChange={handleInputChange}
                onFocus={() => setIsInputFocused(true)}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                placeholder={hasAddedUsers ? "" : "Enter name or email"}
                type="text"
                value={inputValue}
              />

              {isInputFocused && !isEmpty(inputValue) && isEmpty(warning) && (
                <UserSuggestions
                  inputValue={inputValue}
                  addToAddedUsers={addToAddedUsers}
                  suggestedUsers={suggestedUsers}
                  isFetching={isFetching}
                />
              )}
            </Box>
          </Flex>
          <Menu placement="bottom-end">
            <MenuButton
              className="!no-underline shrink-0"
              as={Button}
              colorScheme="dark"
              rightIcon={<MdChevronRight className="rotate-90" />}
              size="sm"
              variant={"link"}
            >
              {selectedRole}
            </MenuButton>
            <MenuList className="-mr-2 !min-w-[11rem]">
              {roles &&
                roles.map((role) => (
                  <MenuItem
                    key={role}
                    fontSize={"sm"}
                    onClick={() => setSelectedRole(role)}
                  >
                    {role}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </FormControl>
        <Button
          color={"white"}
          colorScheme="secondary"
          isDisabled={!hasAddedUsers}
          isLoading={isLoading}
          //eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onShare}
          size="lg"
        >
          Share
        </Button>
      </Flex>
      {!isEmpty(warning) && (
        <Flex className="text-red-600 items-center gap-1 pl-3">
          <Icon as={MdError} />
          <Box className="text-sm">{warning}</Box>
        </Flex>
      )}
    </Flex>
  );
};
export default AddUserInput;
