import { Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { setSelectedStep, STEP_STATUS } from "@/features/data-transformation";

import { StepActive } from "./step-active.tsx";
import { StepConnector } from "./step-connector.tsx";
import { StepContext, StepContextType } from "./step-context.ts";
import { StepDeleted } from "./step-deleted.tsx";

interface StepProps {
  isLast: boolean;
}

export const StepWrapper = ({ isLast }: StepProps) => {
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(false);

  const { step, isOpen } = useContext(StepContext) as StepContextType;

  const type = step.activeStatus
    ? step?.status ?? STEP_STATUS.Active
    : STEP_STATUS.Removed;
  const isDisabled = !step.activeStatus;

  const stepStyles = useMemo(() => getCollapsedStyle(type), [type, isDisabled]);
  const stepStylesOpen = useMemo(() => getOpenStyle(type), [type, isDisabled]);

  const onStepClick = () => {
    if (!isOpen) return;
    dispatch(setSelectedStep(step));
  };

  return (
    <Flex
      className="w-full flex-col group relative peer"
      onMouseOut={setHovered.bind(null, false)}
      onMouseOver={setHovered.bind(null, true)}
      role="group"
    >
      <Flex
        className={clsx(
          "flex-col border rounded cursor-pointer",
          isOpen && "group-hover:rounded-br-none",
          !isOpen &&
            "px-2 p-3 group-hover:px-1 justify-center items-center h-[44px] w-[44px]",
          isOpen ? stepStylesOpen.step : stepStyles.step,
          isDisabled && isOpen && "!px-2 !p-1.5"
        )}
        onClick={onStepClick}
      >
        {isDisabled ? (
          <StepDeleted hovered={hovered} />
        ) : (
          <StepActive hovered={hovered} />
        )}
      </Flex>
      <StepConnector isLast={isLast} />
    </Flex>
  );
};

const getCollapsedStyle = (type: STEP_STATUS) => {
  let style;
  switch (type) {
    case STEP_STATUS.Removed:
      style = {
        step: "text-gray-700 bg-transparent border-gray-300 group-hover:border-gray-400",
      };
      break;
    case STEP_STATUS.Failed:
      style = {
        step: "text-red-600 bg-red-50 group-hover:border-[2px] border-red-400 group-hover:border-red-500 group-hover:shadow-stepsHover",
      };
      break;
    default:
      style = {
        step: "text-gray-900 bg-white group-hover:border-[2px] border-gray-300 group-hover:border-gray-400 group-hover:shadow-stepsHover",
      };
  }
  return style;
};

const getOpenStyle = (type: STEP_STATUS) => {
  let style;
  switch (type) {
    case STEP_STATUS.Removed:
      style = {
        step: "bg-transparent border-gray-300 group-hover:border-gray-100 group-hover:bg-gray-100",
      };
      break;
    case STEP_STATUS.Failed:
      style = {
        step: "text-red-600 bg-red-50 border-red-400 group-hover:border-red-500 group-hover:shadow-stepsHover",
      };
      break;
    default:
      style = {
        step: "text-gray-900 bg-white border-gray-300 group-hover:shadow-stepsHover",
      };
  }
  return style;
};
