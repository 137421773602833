import { DependencyList, useEffect, useRef } from "react";

export function useDidUpdateEffect(
  fn: () => void,
  inputs: DependencyList | undefined,
) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
}