import { FlexProps, chakra } from "@chakra-ui/react";
import { useContext } from "react";
import { MdChevronRight } from "react-icons/md";

import { Icon } from "../icon";

import { NavigationPanelContext, useStyles } from "./navigation-panel.tsx";

export const NavigationPanelToggle = (props: Omit<FlexProps, "children">) => {
  const { ...rest } = props;
  const { open, toggle } = useContext(NavigationPanelContext);

  const styles = useStyles();
  return (
    <chakra.button
      __css={styles.switcher}
      {...rest}
      onClick={() => {
        toggle((prev) => !prev);
      }}
      aria-expanded={open}
    >
      <Icon as={MdChevronRight} height="2rem" width={"2rem"} />
    </chakra.button>
  );
};
