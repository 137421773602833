import { CellCoords } from "handsontable";
import { createRoot } from "react-dom/client";
import {
  MdsContentCopyRound,
  MdsFilterAltRound,
  MdsFindReplaceRound,
  MdsFormatLetterSpacing2Round,
} from "react-icons-with-materialsymbols/mds";

import { createStandaloneToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { ITableDataColumns } from "@/features/data-transformation";

import { CustomDropdownRender } from "./dropdown-render.tsx";

const toast = createStandaloneToast();

export const useTableContextMenuRenderer = ({
  hotRef,
  columnData,
  showFullValueModal,
  findAndReplace,
  addFilter,
}: {
  hotRef: React.MutableRefObject<any>;
  columnData: ITableDataColumns[];
  showFullValueModal: (text: string) => void;
  findAndReplace: (text: string, col: string) => void;
  addFilter: (col: string, filterValue?: string) => void;
}) => {
  const getRange = () => {
    const hot = hotRef.current.hotInstance;
    const range = hot.getSelectedRange()[0].highlight; // This gets the latest value compared to getSelected
    return range;
  };

  const getSelectedValue = () => {
    const hot = hotRef.current.hotInstance;
    const range = getRange();
    const val = hot.getDataAtCell(range.row, range.col);

    return val;
  };

  return {
    items: {
      find: {
        name: "Find & replace on",
        callback: function (
          _hot: any,
          _selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const range = getRange();
          const column = columnData[range.col].name;

          const text: string = getSelectedValue();
          findAndReplace(text, column);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _row: any,
          _col: any,
          _: any,
          itemValue: any
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={`${itemValue} ${getSelectedValue()}`}
              icon={MdsFindReplaceRound}
            />
          );
          return wrapper;
        },
      },
      filter: {
        name: "Filter on",
        callback: function (
          _key: any,
          _selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const range = getRange();
          const column = columnData[range.col].name;

          const text: string = getSelectedValue();
          addFilter(column, text);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _row: any,
          _col: any,
          _: any,
          itemValue: any
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={`${itemValue} ${getSelectedValue()}`}
              icon={MdsFilterAltRound}
            />
          );
          return wrapper;
        },
      },
      sep1: { name: "---------" },
      show: {
        name: "Show complete value",
        callback: function (
          _key: any,
          _selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const text: string = getSelectedValue();
          showFullValueModal(text);
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsFormatLetterSpacing2Round}
            />
          );
          return wrapper;
        },
      },
      copy: {
        name: "Copy value to clipboard",
        callback: function (
          _key: any,
          _selection: { end: CellCoords; start: CellCoords }[]
        ) {
          const text: string = getSelectedValue();

          if ("clipboard" in navigator) {
            navigator.clipboard.writeText(text);
          } else {
            document.execCommand("copy", true, text);
          }
          toast({
            ...TOAST_MESSAGES.copyToClipboard,
          });
        },
        renderer(
          _core: any,
          wrapper: HTMLElement,
          _: any,
          _row: any,
          _col: any,
          itemValue: string
        ) {
          createRoot(wrapper).render(
            <CustomDropdownRender
              title={itemValue}
              icon={MdsContentCopyRound}
            />
          );
          return wrapper;
        },
      },
    },
  };
};
