import { useEffect, useCallback } from "react";

export const useNavigationWarning = (shouldWarn: boolean) => {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (shouldWarn) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    },
    [shouldWarn]
  );

  const handlePopState = useCallback(
    (event: PopStateEvent) => {
      if (
        shouldWarn &&
        !window.confirm(
          "Are you sure you want to leave? Your changes may not be saved."
        )
      ) {
        // Push the current state back to the history to prevent navigation
        history.pushState(null, "", window.location.href);
        // Prevent the default action
        event.preventDefault();
      }
    },
    [shouldWarn]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload, handlePopState]);
};
