import { Input } from "@chakra-ui/input";
import {
  Flex,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import {
  MdsDoneRound,
  MdsSearchRound,
} from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";

import { FilterDetailsContext } from "./filter-details-context.ts";

export const FilterByValue = () => {
  const {
    selectedValues,
    allValues,
    onSelectValue,
    isLoading,
    filterItems,
    setFilterItems,
  } = useContext(FilterDetailsContext)!;

  const [search, setSearch] = useState<string>("");

  const searchedValues = (
    availableValues: string[] | null,
    searchString: string
  ): string[] => {
    if (!availableValues) return [];

    // Trim the search string and convert to lowercase
    const trimmedSearchString = searchString.trim().toLowerCase();

    // If the search string is empty after trimming, return all values
    if (trimmedSearchString === "") return availableValues;

    return availableValues.filter((item) => {
      if (!item) return false;

      const lowerItem = item.toLowerCase();

      // Check if the item starts with the entire search string
      if (lowerItem.startsWith(trimmedSearchString)) return true;

      // Split the item and search string into words
      const itemWords = lowerItem.split(/\s+/);
      const searchWords = trimmedSearchString.split(/\s+/);

      // Check for sequence match
      for (let i = 0; i <= itemWords.length - searchWords.length; i++) {
        if (
          searchWords.every((searchWord, index) =>
            itemWords[i + index].startsWith(searchWord)
          )
        ) {
          return true;
        }
      }

      return false;
    });
  };

  useEffect(() => {
    if (!isEmpty(search)) {
      const filteredItems = searchedValues(allValues, search);
      setFilterItems(filteredItems);
      return;
    }

    setFilterItems(allValues);
  }, [allValues]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);

    if (isEmpty(value)) {
      setFilterItems(allValues);
      return;
    }
    const filteredItems = searchedValues(allValues, value);

    setFilterItems(filteredItems);
  };

  const isBool = (value: any) => typeof value == "boolean";

  const emptyCheck = (value: any) => (isEmpty(value) ? "(Blanks)" : value);

  const loading = isLoading || !filterItems;

  return (
    <Flex className="flex-col w-full">
      <InputGroup size="md">
        <Input
          placeholder="Search"
          className="!rounded-[3px] !border-gray-500 placeholder-gray-500 "
          onChange={onChange}
          value={search}
          size="sm"
        />
        <InputRightElement boxSize="31px">
          <Icon size="sm" as={MdsSearchRound} color="gray-500" />
        </InputRightElement>
      </InputGroup>
      <Flex
        className={clsx(
          "flex-col w-full h-[180px] overflow-auto",
          !filterItems && "justify-center items-center"
        )}
      >
        {loading && <Spinner className="self-center justify-self-center" />}
        {!isLoading &&
          filterItems?.map((value, index) => {
            const isSelected = selectedValues.includes(value);

            return (
              <Flex
                className="items-center  border-b border-b-gray-100 relative p-2.5 cursor-pointer"
                key={index}
                onClick={onSelectValue.bind(null, value)}
              >
                <Text
                  className={clsx(
                    "flex-wrap break-words text-[13px] min-h-[14px] tracking-tight text-gray-900 leading-[120%] mr-8",
                    !isBool(value) && isEmpty(value) && "italic"
                  )}
                >
                  {isBool(value) ? `${value}` : emptyCheck(value)}
                </Text>
                {isSelected && (
                  <Icon
                    as={MdsDoneRound}
                    size="sm"
                    color="gray.900"
                    className="absolute right-2"
                  />
                )}
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};
