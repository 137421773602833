import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  ACCESS_MODE,
  edaAccessMode,
  userHasWriteAccess,
} from "@/features/data-transformation";

export const useReadOnlyMode = () => {
  const canWrite = useSelector(userHasWriteAccess);
  const mode = useSelector(edaAccessMode);

  const canEdit = useMemo(() => mode === ACCESS_MODE.READ_WRITE, [mode]);

  const isReadOnly = useMemo(() => mode === ACCESS_MODE.READ, [mode]);

  const hasWriteAccess = !isReadOnly && canWrite;

  const isMounted = useMemo(() => mode !== null, [mode]);

  return { isReadOnly, canEdit, isMounted, canWrite, hasWriteAccess };
};
