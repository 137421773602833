import { Box, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdsErrorRound } from "react-icons-with-materialsymbols/mds";

import { CopyButton } from "./copy-button";

interface CopyTextBoxProps {
  text?: string;
  title: string;
  description: string;
}

export const CopyTextBox: React.FC<CopyTextBoxProps> = ({
  title,
  text,
  description,
}) => {
  return (
    <Flex className="flex-col h-full gap-1">
      <Box className="pl-1">{title}</Box>
      <Flex className="bg-gray-100 text-sm justify-between rounded h-full items-center pl-3 font-mono border gap-3 font-medium">
        <Tooltip
          className="!bg-gray-800 !rounded-md !p-2.5"
          isDisabled={!text}
          label={text}
          openDelay={600}
          placement="top-start"
        >
          <Box className="overflow-hidden text-ellipsis whitespace-nowrap">
            {text}
          </Box>
        </Tooltip>
        <CopyButton textToCopy={text} title={"Copy " + title} />
      </Flex>
      <Flex className="items-center pl-3 gap-1 text-gray-700 text-xs">
        <Icon as={MdsErrorRound} />
        <Box>{description}</Box>
      </Flex>
    </Flex>
  );
};
