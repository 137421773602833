import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

// define custom styles for funky variant
const variants = {
  primary: definePartsStyle({
    container: {
      p: "0",
      shadow: "none",
      borderRadius: "3px",
      border: "1px solid var(--chakra-colors-gray-300)",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      px: "20px",
      py: "20px",
      ".chakra-heading": {
        fontWeight: "semibold",
        fontSize: "1.2rem",
      },
    },
    body: {
      overflowY: "scroll",
      p: "0",
    },
  }),
};

// export variants in the component theme
export const cardTheme = defineMultiStyleConfig({ variants });
