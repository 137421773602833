import {
  Box,
  BoxProps,
  createStylesContext,
  useMultiStyleConfig,
} from "@chakra-ui/react";

const [StylesProvider, useStyles] = createStylesContext("ConfigurePanel");

export const ConfigurePanel = (props: BoxProps) => {
  const { children, ...rest } = props;

  const styles = useMultiStyleConfig("ConfigurePanel");

  return (
    <Box __css={styles.container} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
};

ConfigurePanel.displayName = "ConfigurePanel";

// eslint-disable-next-line react-refresh/only-export-components
export { useStyles };
