import { FlexProps, chakra } from "@chakra-ui/react";

import { useStyles } from "./navigation-panel.tsx";

export const NavigationPanelFooter = (props: FlexProps) => {
  const { children, ...rest } = props;

  const styles = useStyles();
  return (
    <chakra.div __css={styles.footer} {...rest}>
      {children}
    </chakra.div>
  );
};
