// import { NodeStates } from "./utils";

import { NODE_STATUS } from "../../utils/constants";

export const MarkerColors: Record<NODE_STATUS | string, string> = {
  [NODE_STATUS.SUCCESS]: "#38A169",
  [NODE_STATUS.RUNNING]: "#C6C6C6",
  [NODE_STATUS.FAILED]: "#E53E3E",
  [NODE_STATUS.SKIPPED]: "#38A169",
  [NODE_STATUS.PENDING]: "#C6C6C6",
  [NODE_STATUS.MANUAL_INTERVENTION]: "#C6C6C6",
  [NODE_STATUS.WAITING]: "#C6C6C6",
  [NODE_STATUS.CANCELLED]: "#C6C6C6",
  [NODE_STATUS.DEFAULT]: "#C6C6C6",
  Selected: "#666666",
};

export function CustomMarkers() {
  return (
    <>
      <svg
        style={{ left: "-100vh", position: "absolute", top: 0 }}
        xmlns="http://www.w3.org/2000/svg"
      >
        {Object.keys(MarkerColors).map((key: string) => (
          <defs key={`startMarker-${key}`}>
            <marker
              id={`startMarker-${key}`}
              markerHeight="8"
              markerWidth="8"
              refX="250"
              refY="250"
              viewBox="0 0 600 500"
            >
              <path
                d="M600 250C600 388.071 474.804 500 320.367 500C222.721 500 0 500 0 500C0 500 0.000470362 50.7722 0.000470362 0C0.000470362 0 165.93 1.23854e-05 320.367 1.23854e-05C474.804 1.23854e-05 600 111.929 600 250Z"
                fill={MarkerColors[key as NODE_STATUS]}
              />
            </marker>
            <marker
              id={`endMarker-${key}`}
              markerWidth="25"
              markerHeight="25"
              viewBox="-10 -10 20 20"
              markerUnits="strokeWidth"
              orient="auto-start-reverse"
              refX="0"
              refY="0"
            >
              <polyline
                stroke={MarkerColors[key as NODE_STATUS]}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                fill="none"
                points="-5,-4 0,0 -5,4"
              ></polyline>
            </marker>
          </defs>
        ))}
      </svg>
    </>
  );
}
