import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IconType } from "react-icons";

interface ContextMenuItem {
  label: string;
  action: any;
  icon?: IconType | React.ReactNode; // Name of the icon (e.g., "edit", "delete")
  color?: string; // Chakra UI color name (e.g., "blue.500", "red.400")
  extraOptions?: any;
  isProtected?: boolean;
  isVisible?: boolean;
  isDisabled?: boolean;
  toolTip?: {
    hasArrow?: boolean;
    label?: string | undefined;
    placement?: string;
  };
}

interface ContextMenuState {
  x: number;
  y: number;
  items: ContextMenuItem[];
  show: boolean;
  windowWidth: number;
  windowHeight: number;
}
const initialState: ContextMenuState = {
  x: 0,
  y: 0,
  items: [],
  show: false,
  windowWidth: typeof window !== "undefined" ? window.innerWidth : 0,
  windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
};

const contextMenuSlice = createSlice({
  name: "contextMenu",
  initialState,
  reducers: {
    showContextMenu: (
      state,
      action: PayloadAction<{ x: number; y: number; items: ContextMenuItem[] }>
    ) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
      state.items = action.payload.items;
      state.show = true;
      state.windowWidth = window.innerWidth;
      state.windowHeight = window.innerHeight;
    },
    hideContextMenu: (state) => {
      state.show = false;
    },
    executeAction: (state, action: PayloadAction<string>) => {
      console.log(`Executing action: ${action.payload}`);
      state.show = false; // Hide menu after executing an action
    },
  },
});

export const { showContextMenu, hideContextMenu, executeAction } =
  contextMenuSlice.actions;
export default contextMenuSlice.reducer;
