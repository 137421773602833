export enum CacheKey {
  UserId = "UserId",
  TenantId = "TenantId",
}

interface CacheValues {
  [CacheKey.UserId]: string;
  [key: string]: any;
}

interface CacheUtil {
  set: <T extends CacheKey | string>(key: T, object: CacheValues[T]) => void;
  get: <T extends CacheKey | string>(key: T) => CacheValues[T];
  getUnParsed: (key: CacheKey) => string | null;
  remove: (key: CacheKey) => void;
  removeAll: () => void;
}

export const cacheUtil: CacheUtil = {
  set: (key, object) => {
    if (typeof object === "string") return localStorage.setItem(key, object);
    return localStorage.setItem(key, JSON.stringify(object));
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  get: (key) => JSON.parse(localStorage.getItem(key) ?? "{}"),
  getUnParsed: (key) => localStorage.getItem(key),
  remove: (key) => localStorage.removeItem(key),
  removeAll: () => localStorage.clear(),
};
