import { Flex } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useGetLLMProvidersQuery } from "../../api";
import {
  currentActiveVersion,
  getPreviewVersion,
  setActiveVersion,
  showPromptHistory,
} from "../../redux";
import { AIModel, TextGenVersion } from "../../types";

import SettingsElements from "./settings-elements";
import SettingsHeader from "./settings-header";

const LLMSettings = () => {
  const [allAIModels, setAllAIModels] = useState<AIModel[]>([]);
  const activeVersion = useAppSelector(currentActiveVersion);
  const previewVersion = useAppSelector(getPreviewVersion);
  const isHistoryPanelOpen = useAppSelector(showPromptHistory);

  const dispatch = useAppDispatch();
  const { data: LLMProviderslist, isLoading } = useGetLLMProvidersQuery();

  useEffect(() => {
    if (!LLMProviderslist) return;

    const serviceProviders = LLMProviderslist.response.data?.serviceProviders;
    if (!serviceProviders || isEmpty(serviceProviders)) {
      setAllAIModels([]);
      return;
    }

    const aiModels = serviceProviders.flatMap((sp) => sp.models);
    if (isEmpty(activeVersion?.aiModel) && !isEmpty(aiModels)) {
      const updatedActiveVersion = { ...activeVersion, aiModel: aiModels[0] };
      dispatch(setActiveVersion(updatedActiveVersion as TextGenVersion));
    }

    setAllAIModels(aiModels || []);
  }, [LLMProviderslist, activeVersion, dispatch]);

  const displayModel = useMemo(() => {
    if (isHistoryPanelOpen) {
      if (previewVersion?.aiModel) return previewVersion?.aiModel;
    }
    return activeVersion?.aiModel;
  }, [activeVersion, previewVersion, isHistoryPanelOpen]);

  return (
    <Flex className="flex-col overflow-hidden h-full" key={displayModel?.id}>
      <SettingsHeader allAIModels={allAIModels} />
      <SettingsElements allAIModels={allAIModels} isLoading={isLoading} />
    </Flex>
  );
};

export default LLMSettings;
