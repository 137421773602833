// create new slice for analysis
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserRoleSchema } from "../types";

// Define a type for the slice state

interface UserManagerState {
  userAccess: Record<string, UserRoleSchema>;
}

// Define the initial state using that type
const initialState: UserManagerState = {
  userAccess: {},
};

const userManagerSlice = createSlice({
  name: "user-manager",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserManagerState>) => {
      state.userAccess = action.payload?.userAccess;
    },
    addAccess: (
      state,
      action: PayloadAction<{ id: string; access: UserRoleSchema }>
    ) => {
      state.userAccess[action.payload.id] = action.payload.access;
    },
  },
});

export const { setCurrentUser } = userManagerSlice.actions;
// export const currentUser = (state: RootState) => state..userAccess;

export const userManagerReducer = userManagerSlice.reducer;
