import { Flex, IconProps } from "@chakra-ui/react";
import { ComponentWithAs } from "@chakra-ui/system";
import { clsx } from "clsx";
import { IconType } from "react-icons";
import { MdsChevronRightRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";

type OperationCategoryProps = {
  title: string;
  icon: ComponentWithAs<"svg", IconProps> | IconType;
  active: boolean;
  onClick: () => void;
};
export const OperationCategory = ({
  title,
  icon,
  active,
  onClick,
}: OperationCategoryProps) => {
  return (
    <Flex
      className={clsx(
        "h-[44px] w-full px-2 py-3 rounded hover:bg-gray-50 cursor-pointer text-gray-800 text-sm group",
        active && "bg-brand-50 !text-brand-700",
      )}
      onClick={onClick}
    >
      <Icon
        as={icon}
        size="md"
        className={clsx("group-hover:!text-orange-500 mr-1")}
        color={active ? "brand.500" : "gray.800"}
      />
      {title}
      <Icon
        as={MdsChevronRightRound}
        size="md"
        className={clsx("ml-auto group-hover:!text-gray-800")}
        color={active ? "brand.500" : "gray.300"}
      />
    </Flex>
  );
};
