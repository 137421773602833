import { Flex, Box, Progress, Text, Tooltip } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { clsx } from "clsx";
import { memo, useMemo, useRef } from "react";

import { IChart, nullParser } from "@/features/data-transformation";

export const LogTail = (props: {
  data: IChart[];
  count: number;
  distinctCount: number;
}) => {
  const { data, count, distinctCount } = props;

  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 32,
  });

  const items = virtualizer.getVirtualItems();

  return (
    <Flex className="flex-col h-full w-full border-l border-l-gray-100">
      <Flex className="justify-between border-b border-b-gray-100 items-center pl-3 py-3 pr-5 font-semibold text-[13px] leading-[24px] tracking-[1.04px] uppercase text-gray-700">
        <Text>Unique value</Text>
        <Text>count</Text>
      </Flex>
      <Box
        ref={parentRef}
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
          contain: "strict",
        }}
      >
        <Box
          sx={{
            height: virtualizer.getTotalSize(),
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {items.map((virtualRow) => (
              <Flex
                className={clsx(
                  virtualRow.index % 2 == 0 ? "bg-white" : "bg-gray-50",
                  "pr-5 pl-3 pb-2.5 pt-1.5 border-b border-b-gray-100 justify-between"
                )}
                key={virtualRow.key}
                ref={virtualizer.measureElement}
                data-index={virtualRow.index}
              >
                <MemoizedLogTail
                  tileInfo={data[virtualRow.index]}
                  count={count}
                />
              </Flex>
            ))}
            {data.length < distinctCount && (
              <Text className="pr-5 pl-3 py-3 text-gray-700 tracking-24 text-xs">
                +{distinctCount - data.length}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

const LogTailTile = ({
  tileInfo,
  count,
}: {
  tileInfo: IChart;
  count: number;
}) => {
  const normalized = (tileInfo.value / count) * 100;
  const { isNull, parsedValue } = useMemo(
    () => nullParser(tileInfo.label),
    [tileInfo]
  );

  return (
    <>
      <Flex
        className={clsx(
          "flex-col items-start gap-y-2 w-full truncate max-w-full break-all",
          isNull && "italic"
        )}
      >
        <Flex className="flex-wrap truncate break-all max-w-full">
          <Tooltip label={parsedValue}>
            <Text
              className="truncate text-sm leading-[16px] tracking-28 font-medium"
              sx={{ wordBreak: "break-all" }}
            >
              {`${parsedValue}`}
            </Text>
          </Tooltip>
        </Flex>
        <Progress
          className="w-full"
          colorScheme="green"
          size="xs"
          value={normalized}
        />
      </Flex>
      <Flex className="min-w-[96px] max-w-[96px] items-center justify-end">
        <Text className="text-sm leading-[16px] font-medium leading-28">
          {tileInfo.value}
        </Text>
      </Flex>
    </>
  );
};

const MemoizedLogTail = memo(LogTailTile);
