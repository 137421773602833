import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { clsx } from "clsx";
import { useDispatch } from "react-redux";

import { setPreviewAnalysis, useSearchInWsQuery } from "@/features/ws-manager";
import { AnalysesSchema, CommonItemsSchema } from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import { CATEGORY } from "@/utils/enums.ts";

import { AnalysisDetailPanel } from "../analysis";

import { SearchResultsDatatable } from "./search-table.tsx";

export const WorkspaceSearch = () => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    id: "analysisSearchSidebar",
  });

  const dispatch = useDispatch();

  const { query } = useCustomQuery();

  const { data, isFetching } = useSearchInWsQuery(
    { search: query ?? "" },
    {
      skip: !query,
    }
  );
  const count = data?.response?.pagination?.count ?? 0;

  const text = query
    ? `${count} results for “${query}”`
    : `No results found for “${query}”`;

  const openAnalysisDetailPanel = (item: CommonItemsSchema) => {
    if (item.itemType === CATEGORY.Analysis) {
      dispatch(setPreviewAnalysis(item.itemData as Partial<AnalysesSchema>));
      onOpen();
    }
  };

  return (
    <Flex
      className="w-full h-full overflow-hidden justify-stretch items-stretch"
      gap={isOpen ? "3" : 0}
    >
      <Card
        className={clsx(
          "ease-in-out transition duration-1000 overflow-hidden",
          !isOpen ? "translate-x-0 w-full" : "-translate-x-30% w-[70%]",
        )}
        style={{ height: "100%" }}
        variant="primary"
      >
        <CardHeader>
          {isFetching ? (
            <Skeleton w="10rem" h="1.5rem" />
          ) : (
            <Text className="font-semibold text-2xl tracking-tight">
              {text}
            </Text>
          )}
        </CardHeader>
        <CardBody className="!overflow-y-hidden">
          <SearchResultsDatatable onOpen={openAnalysisDetailPanel} />
        </CardBody>
      </Card>
      <Box
        className={clsx(
          "h-full max-w-[30%] ease-in-out transition-transform duration-300",
          isOpen
            ? "translate-x-0 w-[30%] overflow-auto"
            : "translate-x-full w-0 overflow-hidden",
        )}
      >
        <AnalysisDetailPanel onClose={onClose} />
      </Box>
    </Flex>
  );
};
