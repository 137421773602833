import { Flex, Input } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useState } from "react";
import { MdsCloseRound } from "react-icons-with-materialsymbols/mds";

import { Search } from "@/components/icons/search.tsx";
import { Icon } from "@/design/components/icon";

type StepsPanelSearchProps = {
  isOpen: boolean;
  onSearch: (value: string) => void;
};
export const StepsPanelSearch = ({
  isOpen,
  onSearch,
}: StepsPanelSearchProps) => {
  const [inputValue, setInputValue] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearch(value);
    setInputValue(value);
  };

  const clearSearch = () => {
    onSearch("");
    setInputValue("");
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <Flex
          className="max-w-full truncate pr-8 w-full p-3 pl-5 items-center gap-x-2 mt-0.5 bg-white relative"
          as={motion.div}
          animate={{
            height: "40px",
            opacity: 1,
          }}
          exit={{ opacity: 0, height: "0 px" }}
          initial={{ opacity: 1, height: "40px" }}
          transitionDuration="1000"
        >
          <Icon as={Search} color="gray.600" size="sm" />
          <Input
            className="placeholder-gray-600 placeholder:text-sm"
            onChange={onChange}
            placeholder="Search steps"
            value={inputValue}
            variant="unstyled"
          />
          {!isEmpty(inputValue) && (
            <Icon
              as={MdsCloseRound}
              className="absolute right-2 cursor-pointer"
              color="gray.600"
              size="sm"
              onClick={clearSearch}
            />
          )}
        </Flex>
      )}
    </AnimatePresence>
  );
};
