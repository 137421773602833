import { Flex, FormControl, Input, Box, Icon } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { MdClear, MdError } from "react-icons/md";
import { useDebounce } from "use-debounce";

import { ToastType, useShowToast } from "@/components/toast";
import { useLazyGetUserFromQueryQuery } from "@/features/user-manager";
import { UserSuggestions } from "@/features/user-manager/modals/manage-access/user-suggestions";
import { UsersSchema } from "@/features/user-manager/types";
import { USER_ROLES } from "@/utils/enums";

const SuggestedAdminList = ({
  addedUsers,
  setAddedUsers,
  isLoading,
  error,
  setError,
}: {
  addedUsers: UsersSchema[];
  isLoading: boolean;
  setAddedUsers: React.Dispatch<React.SetStateAction<UsersSchema[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [suggestedUsers, setSuggestedUsers] = useState<
    UsersSchema[] | undefined
  >();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const toast = useShowToast(undefined, undefined, true);

  const [getUsers, { isFetching }] = useLazyGetUserFromQueryQuery();

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    if (isEmpty(event.target.value)) setSuggestedUsers(undefined);
    setError(undefined);
    setInputValue(event.target.value);
  };

  const [debouncedValue] = useDebounce(inputValue, 300);

  // make api call to search for user
  useEffect(() => {
    if (!debouncedValue || debouncedValue.length === 0) {
      return;
    }
    getUsers({ query: debouncedValue })
      .unwrap()
      .then((res) => {
        const nonAdminUsers = res.response.data?.filter(
          (user) => user.orgRole === USER_ROLES.USER
        );
        setSuggestedUsers(nonAdminUsers ?? []);
      })
      .catch(() => setSuggestedUsers(undefined));
  }, [debouncedValue, getUsers]);

  const addToAddedUsers = (user: UsersSchema) => {
    if (addedUsers.findIndex((u) => u.id === user.id) === -1) {
      setAddedUsers([...addedUsers, user]);
    } else {
      setError("User already selected");
    }
    setSuggestedUsers(undefined);
    setInputValue("");
    inputRef.current?.focus();
    // setIsInputFocused(false);
    // inputRef.current?.focus();
  };

  const removeFromAddedUsers = (user: UsersSchema) => {
    if (isLoading) return;
    setError(undefined);
    setAddedUsers(addedUsers.filter((u) => u.id !== user.id));
    setInputValue("");
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsInputFocused(false);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "escape") {
      setIsInputFocused(false);
      return;
    }
    if (
      event.key === "Backspace" &&
      inputValue === "" &&
      addedUsers.length > 0
    ) {
      setAddedUsers(addedUsers.slice(0, addedUsers.length - 1));
    }
  };
  return (
    <Flex className="flex-col" align={"start"} gap={1}>
      <FormControl
        className={`flex grow border relative border-gray-500 rounded w-full h-max min-h-[3rem] pr-1 ${
          isLoading ? "bg-gray-100" : ""
        }`}
      >
        <Flex
          className="!w-full flex-wrap no-scrollbar"
          align={"center"}
          gap={2}
          overflowY={"auto"}
          maxH={"100px"}
          px={addedUsers.length > 0 ? 2 : 0}
          py={2}
          onClick={() => {
            inputRef.current?.focus();
            setIsInputFocused(true);
          }}
        >
          {addedUsers.map((user) => (
            <Flex
              className="rounded-full whitespace-pre text-xs gap-1 border border-gray-400 hover:bg-gray-100 cursor-pointer px-2 py-1 w-max"
              key={user.id}
              align={"center"}
              onClick={() => removeFromAddedUsers(user)}
            >
              <span>{user.firstName}</span>
              <Icon as={MdClear} />
            </Flex>
          ))}
          <Box className="min-w-[50px] grow" ref={wrapperRef}>
            <Input
              className={clsx(
                "!w-full !h-full !border-none ",
                addedUsers.length !== 0 && "!pl-0"
              )}
              ref={inputRef}
              autoComplete="off"
              isDisabled={isLoading}
              onChange={handleInputChange}
              onFocus={() => setIsInputFocused(true)}
              onKeyDown={handleKeyDown}
              placeholder={addedUsers.length === 0 ? "Enter name or email" : ""}
              type="text"
              value={inputValue}
            />
            {isInputFocused && !isEmpty(inputValue) && (
              <UserSuggestions
                inputValue={inputValue}
                addToAddedUsers={addToAddedUsers}
                suggestedUsers={suggestedUsers}
                isFetching={isFetching}
              />
            )}
          </Box>
        </Flex>
      </FormControl>
      {!isEmpty(error) && (
        <Flex className="text-red-600 items-center gap-1 pl-3">
          <Icon as={MdError} />
          <Box className="text-sm">{error}</Box>
        </Flex>
      )}
    </Flex>
  );
};
export default SuggestedAdminList;
