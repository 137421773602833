import { Box, Button, CloseButton, Flex, Slide } from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { Node, useReactFlow } from "reactflow";

import { TabList, TabPanel, TabPanels, Tabs } from "@/design/components/tabs";
import { DataPreviewTable } from "@/features/workflow-studio/components/data-preview/data-preview-table.tsx";
import {
  NodeDataDownloadResponse,
  NodeType,
} from "@/features/workflow-studio/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import {
  hidePanel,
  selectPanel,
  useDownloadDataMutation,
  useGetWorkflowQuery,
} from "../..";
import { addActiveDownload } from "../../redux/data-preview-slice.ts";
import { currentWorkflowId } from "../../redux/workflow-slice.ts";
import { WORKFLOW_PANELS } from "../../utils/constants";

import { DataPreviewTab } from "./data-preview-tab.tsx";

export const DataPreview = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const workflowId = useAppSelector(currentWorkflowId);
  const [downloadPreviewData, { isLoading }] = useDownloadDataMutation();
  const { data: wfData } = useGetWorkflowQuery({
    workflowId: workflowId!,
  });

  const { getNode, getEdges } = useReactFlow();
  const [currentNodeData, setCurrentNodeData] = useState<NodeType>();
  const [currentInputNodes, setCurrentInputNode] = useState<NodeType[]>();

  const dispatch = useAppDispatch();
  const dataPreviewPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataPreviewPanel)
  );

  const sliderRef = useRef<HTMLDivElement>(null);

  const tabs = useMemo(() => {
    if (!currentNodeData) return [];
    return [...(currentInputNodes ?? []), currentNodeData];
  }, [currentInputNodes, currentNodeData]);

  const ioList = useMemo(() => {
    if (!currentNodeData) return [];
    return [...currentNodeData.inputs, ...currentNodeData.outputs];
  }, [tabs]);

  useEffect(() => {
    if (!dataPreviewPanel.nodeId) return;
    const { data } = getNode(dataPreviewPanel.nodeId) as Node;
    const edges = getEdges();

    const getInputEdgesForNode = edges.filter(
      (edge) => edge.target === dataPreviewPanel.nodeId
    );
    const inputNodes = getInputEdgesForNode.map((edge) => {
      const node = getNode(edge.source) as Node;
      return node.data;
    });

    setCurrentNodeData(data as NodeType);
    setCurrentInputNode(inputNodes);
  }, [dataPreviewPanel.nodeId, getNode, getEdges]);

  const downloadData = () => {
    const isInputTab = tabIndex < currentNodeData!.inputs.length;

    const ioDetailId = isInputTab
      ? currentNodeData?.inputs[tabIndex].ioDetailId
      : currentNodeData?.outputs[currentNodeData.inputs.length - tabIndex]
          .ioDetailId;
    const payload = {
      workflowNodeId: currentNodeData?.workflowNodeId ?? "",
      nodeIoDetailId: ioDetailId ?? "",
      workflowRunId: wfData!.response.data?.workflows[0].workflowRunId ?? "",
      ioType: "csv",
    };
    downloadPreviewData(payload)
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const { data: response } = res.data
          .response as NodeDataDownloadResponse;
        if (response.conversionStatus === "completed") {
          const a = document.createElement("a");
          a.href = response.downloadLink;
          a.target = "_blank";
          a.download = currentNodeData!.displayName;
          a.click();
          a.remove();
        } else {
          dispatch(
            addActiveDownload({
              ...payload,
              id: currentNodeData!.workflowNodeId + ioDetailId!,
              nodeName: currentNodeData!.displayName,
              dataIOtype: isInputTab ? "input" : "output",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClose = () => {
    dispatch(hidePanel(WORKFLOW_PANELS.DataPreviewPanel));
  };

  return (
    <Slide
      className={clsx(
        "!absolute bottom-0 transition-[height] !right-0 !w-full !pt-3",
        isExpanded ? "h-full" : "h-64"
      )}
      ref={sliderRef}
      direction="bottom"
      in={dataPreviewPanel.isVisible}
      style={{ zIndex: 999 }}
      unmountOnExit
    >
      <Box
        className="absolute !cursor-pointer -top-2 border h-10 w-4 rotate-90 flex items-center justify-center bg-gray-100 border-gray-600 rounded-full left-1/2 -translate-x-1/2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <MdOutlineChevronRight /> : <MdOutlineChevronLeft />}
      </Box>
      <Box className="h-full bg-white border-t border-gray-400 ">
        <Tabs
          onChange={(index) => setTabIndex(index)}
          variant="line"
          size="sm"
          isLazy
          className="h-full"
        >
          <Flex className="border-b" align="center" gap={2}>
            <TabList className="grow">
              {tabs.map((tab, idx) => {
                return (
                  <DataPreviewTab key={tab.nodeId}>
                    {tabs.length === idx + 1 ? "Output" : "Input"}
                  </DataPreviewTab>
                );
              })}
            </TabList>
            <Button
              colorScheme="dark"
              isLoading={isLoading}
              onClick={downloadData}
              size="sm"
            >
              Download
            </Button>
            <CloseButton onClick={onClose} />
          </Flex>

          <TabPanels>
            {currentNodeData?.inputs?.map((ioDetails) => {
              return (
                <TabPanel key={ioDetails.ioDetailId}>
                  <DataPreviewTable
                    ioDetails={ioDetails}
                    isExpanded={isExpanded}
                    type="input"
                    ref={sliderRef}
                  />
                </TabPanel>
              );
            })}
            {currentNodeData?.outputs?.map((ioDetails) => {
              return (
                <TabPanel key={ioDetails.ioDetailId}>
                  <DataPreviewTable
                    ioDetails={ioDetails}
                    isExpanded={isExpanded}
                    type="output"
                    ref={sliderRef}
                  />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </Slide>
  );
};
