import { Flex, FlexProps } from "@chakra-ui/react";

import { useStyles } from "./configure-panel.tsx";
export const ConfigurePanelFooter = (props: FlexProps) => {
  const { children, ...rest } = props;
  const styles = useStyles();
  return (
    <Flex __css={styles.footer} {...rest}>
      {children}
    </Flex>
  );
};

ConfigurePanelFooter.displayName = "ConfigurePanelFooter";
