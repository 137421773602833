import { isEmpty } from "lodash";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice";

import ProjectForm from "./project-form";

const CreateProjectModal = (data: any) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  const title = isEmpty(data?.data?.name)
    ? "Create New Project"
    : "Edit Project Details";

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      size={"xl"}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="px-6">
          <ProjectForm data={data} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateProjectModal;
