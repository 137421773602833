import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosError } from "axios";
import { ReactNode, useEffect, useState } from "react";

import { loginRequest } from "@/auth/authConfig.ts";
import { axiosInstance } from "@/utils/axios.ts";
import { getRegionFromUrl } from "@/utils/baseUrls.ts";
import { cacheUtil } from "@/utils/localstorage";

interface AxiosProviderProps {
  children: ReactNode;
}

export const AxiosMsalAuthProvider = ({ children }: AxiosProviderProps) => {
  // Hooks here
  const [isInitialized, setIsInitialized] = useState(false);
  const { instance, inProgress } = useMsal();

  const getApiToken = async () => {
    try {
      await instance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: instance.getActiveAccount()!,
      });
    } catch (e) {
      cacheUtil.removeAll();
      instance
        .loginRedirect({
          ...loginRequest,
          extraQueryParameters: { region: getRegionFromUrl() },
        })
        .catch(function (error: any) {
          console.log(error);
        });
      // if (e instanceof InteractionRequiredAuthError) {
      // }
    }
  };

  const onRequestFulfilled = (config: any) => {
    if (config.customAuth) return config;

    config.headers.Authorization = `Bearer ${instance.getActiveAccount()
      ?.idToken}`;
    return config;
  };

  const onResponseRejected = async (error: AxiosError) => {
    const originalRequest = error.config!;
    const isAuthError =
      error.code == "ERR_BAD_REQUEST" && error.response?.status === 401;

    if (isAuthError) {
      await getApiToken();
      const updatedRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${instance.getActiveAccount()?.idToken}`,
        },
      };
      return axiosInstance(updatedRequest);
    }

    return Promise.reject(error);
  };
  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      onResponseRejected
    );

    const onRequestInterceptor = axiosInstance.interceptors.request.use(
      onRequestFulfilled,
      (error) => {
        return Promise.reject(error);
      }
    );

    setIsInitialized(true);

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
      axiosInstance.interceptors.request.eject(onRequestInterceptor);
    };
  }, [inProgress]);

  return isInitialized ? children : <></>;
};
