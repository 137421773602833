import {
  NavigationPanel,
  NavigationPanelBody,
  NavigationPanelItem,
} from "@/design/components/navigation-panel";

export const AddDatasetNavigation = (props: { setSelectedNavValue: any; sideNavBarOptions: string[]; }) => {
  const {setSelectedNavValue, sideNavBarOptions} = props;
  return (
    <NavigationPanel
      type="studio"
      className="!w-60 !basis-60 h-full"
      colorScheme="light"
      defaultIndex={0}
    >
      <NavigationPanelBody>
        {sideNavBarOptions.map((item, index) => <div key={index} onClick={(e) => { e.stopPropagation(); setSelectedNavValue(item) }}><NavigationPanelItem hasIcon={false}>{item}</NavigationPanelItem></div>)}
        {/* <NavigationPanelItem hasIcon={false}>Database</NavigationPanelItem>
        <NavigationPanelItem hasIcon={false}>
          Online Service
        </NavigationPanelItem>
        <NavigationPanelItem hasIcon={false}>Others</NavigationPanelItem> */}
      </NavigationPanelBody>
    </NavigationPanel>
  );
};
