import type { EdgeProps, Node } from "reactflow";
import { getSmoothStepPath, useStore, BaseEdge, useReactFlow } from "reactflow";

import { useAppSelector } from "@/reduxHooks.ts";

import { getNodeStatus } from "../../redux/workflow-slice";
import { NODE_STATUS } from "../../utils/constants";

import { MarkerColors } from "./custom-markers";

export function CustomEdge({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  style = {},
}: //   ...props
EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    borderRadius: 20,
    sourcePosition,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY,
  });
  const { getNode } = useReactFlow();
  // TODO : Remove unnecessary code below
  // const { markerType = NODE_STATUS.DEFAULT } = useStore(
  //   (storeHook) => {
  //     const node = storeHook.nodeInternals.get(source) as Node;
  //     // if (node?.data.error) return { markerType: NodeStates.Error };
  //     if (data.nodeType.includes("SIMPLE"))
  //       return { markerType: NODE_STATUS.SUCCESS };
  //     if (selected) return { markerType: "Selected" };
  //     return { markerType: NODE_STATUS.DEFAULT };
  //   },
  //   (a, b) => a.markerType === b.markerType
  // );
  const sourceNode = getNode(source) as Node;
  const nodeStatus = selected
    ? "Selected"
    : sourceNode.data?.nodeStatus ?? NODE_STATUS.DEFAULT;

  return (
    <>
      <BaseEdge
        id={id}
        style={{
          ...style,
          zIndex: 5,
          stroke: MarkerColors[nodeStatus],
          strokeWidth: selected ? 1.5 : 1.25,
        }}
        path={edgePath}
        markerEnd={`url(#endMarker-${nodeStatus})`}
        markerStart={`url(#startMarker-${nodeStatus})`}
      />
    </>
  );
}
