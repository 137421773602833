import { createIcon } from "@chakra-ui/react";

export const ExcelIcon = createIcon({
  displayName: "ExcelIcon",
  viewBox: "0 0 800 800",
  path: [
    <path
      d="M774.215 92.3077H492.308V184.123H585.569V246.615H492.308V307.692H584.615V369.231H492.308V430.769H584.615V492.308H492.308V553.846H584.615V615.385H492.308V707.692H774.215C788.4 707.692 800 695.661 800 680.923V119.077C800 104.338 788.4 92.3077 774.215 92.3077ZM738.462 615.385H615.385V553.846H738.462V615.385ZM738.462 492.308H615.385V430.769H738.462V492.308ZM738.462 369.231H615.385V307.692H738.462V369.231ZM738.462 246.154H615.385V184.615H738.462V246.154Z"
      fill="currentColor"
    />,
    <path
      d="M0 88.8923V711.138L461.538 800V0L0 88.8923ZM291.938 556.308L238.246 454.8C236.215 451.015 234.123 444.062 231.938 433.938H231.108C230.092 438.708 227.692 445.969 223.908 455.692L170.031 556.308H86.3692L185.692 400.492L94.8308 244.646H180.246L224.8 338.092C228.277 345.477 231.385 354.246 234.154 364.369H235.015C236.769 358.277 240.031 349.231 244.8 337.2L294.338 244.615H372.554L279.108 399.138L375.169 556.277L291.938 556.308Z"
      fill="currentColor"
    />,
  ],
});
