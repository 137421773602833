import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { hideContextMenu } from "@/slices/context-menu-slice";

import CustomContextMenu from "./context-menu";

const ContextMenuManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.rootReducer.contextMenu);
  const contextMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        show &&
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target as Node)
      ) {
        dispatch(hideContextMenu());
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, dispatch]);

  return (
    <Box ref={contextMenuRef}>
      <CustomContextMenu />
    </Box>
  );
};

export default ContextMenuManager;
