import { createContext } from "react";

import { IViewFiltersRequest } from "@/features/data-transformation";

export const FilterContext = createContext<{
  filter: IViewFiltersRequest;
  previousColumns?: string[] | undefined;
  isFilterOpen: boolean;
  editDisabled?: boolean;
  currentFilter: string | null;
  placement: string;
  appliedFilters: IViewFiltersRequest[];
  changeFilterOpenState: (arg0: boolean) => void;
  changeCurrentOpenFilter: (arg0: string | null) => void;
  changeTempFilter: (arg0: IViewFiltersRequest | null) => void;
  updateSavedFilters: (
    arg0: IViewFiltersRequest[],
    forStepForm?: boolean
  ) => void;
  originalFilters?: IViewFiltersRequest[];
} | null>(null);
