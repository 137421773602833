import { createContext } from "react";

export const FilterDetailsContext = createContext<{
  openByValue: boolean;
  onOpenByValue: () => void;
  selectedValues: any[];
  allValues: any[] | null;
  onSelectValue: (value: string) => void;
  setSelectedValues: (values: string[]) => void;
  isLoading: boolean;
  selectAll: boolean;
  onSelectAll: () => void;
  filterItems: string[] | null;
  setFilterItems: (items: string[] | null) => void;
} | null>(null);
