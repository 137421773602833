import { chakra } from "@chakra-ui/react";
import { forwardRef, HTMLChakraProps } from "@chakra-ui/system";

import { useStyles } from "./navigation-panel.tsx";
import { useNavList, UseNavListProps } from "./use-nav.ts";

export interface NavPanelBodyProps
  extends UseNavListProps,
    Omit<HTMLChakraProps<"div">, "onKeyDown" | "ref"> {}
export const NavigationPanelBody = forwardRef<NavPanelBodyProps, "div">(
  function NavPanelBody(props, ref) {
    const { children } = props;
    const navListProps = useNavList({ ...props, ref });

    const styles = useStyles();
    return (
      <chakra.div __css={styles.body} {...navListProps}>
        {children}
      </chakra.div>
    );
  },
);
