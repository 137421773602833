import { useCallback, useState } from "react";

import { cacheUtil } from "@/utils/localstorage.ts";
export const useFlowLocalSave = (flowInstance: any, editorId: string) => {
  const [isSaving, setIsSaving] = useState(false);

  const triggerLocalSave = useCallback(() => {
    setIsSaving(true);
    const flowKey = `flow-${editorId}`;

    try {
      const flow = flowInstance;
      cacheUtil.set(flowKey, JSON.stringify(flow));

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Error saving flow locally: ${error}`);
    }

    // Handle any potential errors during local save

    setIsSaving(false);
  }, [flowInstance, editorId]);

  return { isSaving, triggerLocalSave };
};
