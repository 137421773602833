import { FetchArgs, retry } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "@/utils/baseQuery.ts";
import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

export const dataTransformationAPI = createApi({
  reducerPath: "dataTransformationAPI",
  baseQuery: retry(
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    async (args: string | FetchArgs, api, extraOptions) => {
      const result = await baseQuery({
        baseUrl: getMSBaseUrl(MICRO_SERVICES.EDA),
      })(args, api, extraOptions);

      // bail out of re-tries immediately if unauthorized,
      if (result.error?.data?.response?.data?.task_status === "failed") {
        retry.fail(result.error);
      }

      return result;
    },
    { maxRetries: 3 }
  ),
  tagTypes: ["Transformations", "FilterStats"],
  endpoints: () => ({}),
});
