import { Box } from "@chakra-ui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { useOnClickOutside } from "@/hooks/useOutsideClick.ts";
import { useAppSelector } from "@/reduxHooks";

import { setTransformationPanel, transformationPanel } from "../../redux";

import { OperationsPanelBody } from "./operantions-panel-body.tsx";

export const TransformationsPannel = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const isPanelOpen = useAppSelector(transformationPanel);
  const closePanel = () => {
    dispatch(setTransformationPanel(false));
  };
  useOnClickOutside({}, ref, closePanel);

  return (
    <AnimatePresence>
      {isPanelOpen && (
        <Box
          className={clsx(
            "absolute z-50 bottom-0 left-0 w-full pb-0 border-0 bg-transparent overflow-hidden"
          )}
          ref={ref}
          as={motion.div}
          zIndex={999}
          transition={{
            layout: { duration: 0.1 },
          }}
          animate={{
            height: "calc(100vh - 118px)",
            minHeight: "calc(100vh - 118px)",
            paddingTop: "44px",
          }}
          exit={{ height: 0 }}
          initial={{ height: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            closePanel();
          }}
        >
          <OperationsPanelBody />
        </Box>
      )}
    </AnimatePresence>
  );
};
