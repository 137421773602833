import { RootState } from "@/store";

export const transformationPanel = (state: RootState) =>
  state.dataTransformationReducer.transformationPanel;
export const selectedStep = (state: RootState) =>
  state.dataTransformationReducer.selectedStep;
export const selectedColumn = (state: RootState) =>
  state.dataTransformationReducer.selectedColumn;

export const transformationSteps = (state: RootState) =>
  state.dataTransformationReducer.transformationSteps;

export const isFilterMenuOpen = (state: RootState) =>
  state.dataTransformationReducer.isFilterMenuOpen;

export const isStepFormFilterMenuOpen = (state: RootState) =>
  state.dataTransformationReducer.isStepFormFilterMenuOpen;

export const viewFilters = (state: RootState) =>
  state.dataTransformationReducer.viewFilters;

export const tempFilters = (state: RootState) =>
  state.dataTransformationReducer.tempFilter;

export const currentOpenFilter = (state: RootState) =>
  state.dataTransformationReducer.currentOpenFilter;

export const samplingTechniques = (state: RootState) =>
  state.dataTransformationReducer.samplingTechniques;

export const tableSizeMetaData = (state: RootState) =>
  state.dataTransformationReducer.tableSizeMetaData;

export const fullData = (state: RootState) =>
  state.dataTransformationReducer.fullData;

export const edaStatus = (state: RootState) =>
  state.dataTransformationReducer.edaStatus;

export const currentSampling = (state: RootState) =>
  state.dataTransformationReducer.currentSampling;

export const tableColumns = (state: RootState) =>
  state.dataTransformationReducer.columns;

export const currentlyAddedStep = (state: RootState) =>
  state.dataTransformationReducer.currentlyAddedStep;

export const tempTransformationSteps = (state: RootState) =>
  state.dataTransformationReducer.tempTransformationSteps;

export const statusRequestId = (state: RootState) =>
  state.dataTransformationReducer.statusRequestId;

export const compiledTransformationSteps = (state: RootState) =>
  state.dataTransformationReducer.compiledTransformationSteps;

export const selectedStepTempFilter = (state: RootState) =>
  state.dataTransformationReducer.stepTempFilters;

export const selectedStepFilters = (state: RootState) =>
  state.dataTransformationReducer.currentStepFilters;

export const sorting = (state: RootState) =>
  state.dataTransformationReducer.sorting;

export const tableLoading = (state: RootState) =>
  state.dataTransformationReducer.tableLoading;

export const isStepsFetched = (state: RootState) =>
  state.dataTransformationReducer.stepsFetched;

export const saveEditCalls = (state: RootState) =>
  state.dataTransformationReducer.saveEditCalls;

export const edaTitle = (state: RootState) =>
  state.dataTransformationReducer.title;

export const edaAccessMode = (state: RootState) =>
  state.dataTransformationReducer.edaAccessMode;

export const activeRequests = (state: RootState) =>
  state.dataTransformationReducer.activeRequests;

export const executeInProgress = (state: RootState) =>
  state.dataTransformationReducer.isExecuteInProgress;

export const userHasWriteAccess = (state: RootState) =>
  state.dataTransformationReducer.userHasWriteAccess;

export const triggerTableFetchEvent = (state: RootState) =>
  state.dataTransformationReducer.triggerTableFetch;

export const triggerStepsFetchEvent = (state: RootState) =>
  state.dataTransformationReducer.triggerStepsFetch;

export const triggerExploreEvent = (state: RootState) =>
  state.dataTransformationReducer.triggerExploreFetch;

export const triggerSuggestionsFetch = (state: RootState) =>
  state.dataTransformationReducer.triggerSuggestionsFetch;

export const triggerOpenStepPanel = (state: RootState) =>
  state.dataTransformationReducer.triggerOpenStepPanel;

export const triggerSaveExecuteCall = (state: RootState) =>
  state.dataTransformationReducer.triggerSaveExecuteFetch;

export const samplingAndStepsHash = (state: RootState) =>
  state.dataTransformationReducer.samplingAndStepsHash;

export const statusMessage = (state: RootState) =>
  state.dataTransformationReducer.statusMessage;

export const stepMemoryWarning = (state: RootState) =>
  state.dataTransformationReducer.stepMemoryWarning;

export const queuedToast = (state: RootState) =>
  state.dataTransformationReducer.toastMessage;
