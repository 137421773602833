import { Button, Flex, Spinner } from "@chakra-ui/react";
import { memo } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useGetNodesListQuery } from "../..";
import { categoryIcons } from "../../utils/constants";
import { BaseNode } from "../custom-node";

import { BottomBarItem } from "./bottom-bar-item";

const NoNodesComponent = memo(({ type }: { type: string }) => {
  const navigate = useNavigate();
  const { analysisId } = useParams();
  const navigateToWorkflow = (name: string) => {
    navigate(`/analysis/${analysisId}/${name}`);
  };
  const message =
    type === "SOURCE" ? (
      <Button
        className="!text-sm"
        onClick={navigateToWorkflow.bind(null, "data-manager")}
        variant={"link"}
      >
        Add a dataset
      </Button>
    ) : null;
  return (
    <Flex
      className="h-[100px] w-[160px] border text-sm"
      align={"center"}
      justify={"center"}
      direction={"column"}
    >
      No nodes available.
      {message}
    </Flex>
  );
});

const NodeCategories = () => {
  const { analysisId } = useParams();
  const {
    data: nodes,
    isFetching,
    error,
  } = useGetNodesListQuery(
    { analysisId: analysisId as string },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isFetching) return <Spinner />;
  if (error) return <div>error</div>;

  return (
    <>
      {Object.keys(nodes!).map((key) => (
        <BottomBarItem key={key} icon={categoryIcons[key].icon} title={key}>
          <Flex
            justify={"center"}
            wrap={"wrap-reverse"}
            gap={3}
            overflowY={"auto"}
            w={"100%"}
            maxW={"360px"}
            h={"100%"}
            maxH={"50vh"}
            p={3}
          >
            {
              // if nodes[key] is undefined or empty, return an different component
              nodes![key].length === 0 ? (
                <NoNodesComponent type={key} />
              ) : (
                <>
                  {nodes![key].map((node, idx) => (
                    // TODO : update to nodeUsageInstanceId
                    <BaseNode
                      node={node}
                      index={idx}
                      key={idx + node.nodeUsageInstanceId}
                    />
                  ))}
                </>
              )
            }
          </Flex>
        </BottomBarItem>
      ))}
    </>
  );
};

export default NodeCategories;
