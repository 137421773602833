import { Flex } from "@chakra-ui/react";
import { clsx } from "clsx";

import {
  IExploreTaskResponse,
  STATISTICS,
} from "@/features/data-transformation";

import TimeseriesChart from "./date-time-chart.tsx";
import { HistogramChartFullSize } from "./historgram.tsx";
import { LogTail } from "./log-tail.tsx";

export const ChartBase = ({ data }: { data: IExploreTaskResponse }) => {
  const histogram = data?.graph?.histogram;
  const barChart = data?.graph?.barChart;
  const timeSeries = data?.graph?.timeSeries;
  return (
    <Flex className={clsx("flex-col w-full flex-1", histogram && "pr-2")}>
      {barChart && (
        <LogTail
          data={barChart}
          count={Number(
            data?.statistics?.otherStats[STATISTICS.TOTAL_COUNT] ?? "100"
          )}
          distinctCount={Number(
            data?.statistics?.otherStats[STATISTICS.DISTINCT_COUNT] ?? "100"
          )}
        />
      )}
      {histogram && <HistogramChartFullSize data={histogram} />}
      {timeSeries && <TimeseriesChart data={timeSeries} />}
    </Flex>
  );
};
