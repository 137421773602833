import { Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { MdOutlineErrorOutline } from "react-icons/md";

import { ConfigurePanelBody } from "@/design/components/configure-panel";
import { useAppSelector } from "@/reduxHooks.ts";

import { selectPanel } from "../..";
import { workflowStatus } from "../../redux/workflow-slice";
import { WORKFLOW_PANELS } from "../../utils/constants";

export const LogsPanelBody = () => {
  const currentWorkflowStatus = useAppSelector(workflowStatus);

  const logsPanel = useAppSelector(selectPanel(WORKFLOW_PANELS.LogsPanel));

  const logs = useMemo(() => {
    // If workflowStatus obj is empty, return empty array
    if (!currentWorkflowStatus) return [];
    // if node is selected show logs for that node, else show logs for workflow
    let nodeStatusMessageList = [];
    if (logsPanel.nodeId) {
      nodeStatusMessageList = currentWorkflowStatus.workflowNodeStatus
        .find((node) => node.uiNodeId === logsPanel.nodeId)!
        .message.split("-");
    } else {
      nodeStatusMessageList = currentWorkflowStatus.message.split("-");
    }
    return nodeStatusMessageList;
  }, [currentWorkflowStatus, logsPanel.nodeId]);

  return (
    <ConfigurePanelBody overflowY="auto">
      {logs.length <= 0 && (
        <Flex
          className="bg-gray-100 !w-full p-2 rounded gap-3 !self-center"
          align="center"
          justify="center"
        >
          <MdOutlineErrorOutline size={20} />
          <Text className="text-center">No logs available</Text>
        </Flex>
      )}
      {logs.map((log, i) => {
        return (
          <Flex
            className="text-sm border-b py-2"
            key={i}
            direction={"column"}
            gap={3}
          >
            <Flex justify="space-between">
              <Text className="uppercase font-semibold">{log}</Text>
              <Flex gap={3} fontSize="xs">
                <Text>14/12/2023</Text>
                <Text>01:19:22</Text>
              </Flex>
            </Flex>
            <Text
              color={i % 3 == 0 ? "green.400" : "red.500"}
              fontStyle="italic"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum, quidem.
            </Text>
          </Flex>
        );
      })}
    </ConfigurePanelBody>
  );
};
