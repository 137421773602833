export function cleanData(data: any, deleteKeys: string | string[]) {
  // There is nothing to be done if `data` is not an object,
  // but for example "user01" or "MALE".
  if (typeof data != "object") return;
  if (!data) return; // null object

  for (const key in data) {
    if (deleteKeys.includes(key)) {
      delete data[key];
    } else {
      // If the key is not deleted from the current `data` object,
      // the value should be check for black-listed keys.
      cleanData(data[key], deleteKeys);
    }
  }
  return data;
}
