import { chakra, Collapse, Tooltip, useDisclosure } from "@chakra-ui/react";
import { forwardRef, HTMLChakraProps } from "@chakra-ui/system";
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
} from "react";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";

import { Icon } from "../icon";

import {
  BoolStateFunction,
  NavigationPanelContext,
  useStyles,
} from "./navigation-panel.tsx";
import { useNav, UseNavOptions, useNavsContext } from "./use-nav.ts";

export interface NavigationPanelItemProps
  extends UseNavOptions,
    HTMLChakraProps<"button"> {
  icon?: ReactElement;
  hasIcon?: boolean;
  selected?: boolean;
  label?: string;
  labelClassName?: string;
  highlight?: boolean;
}
export const NavigationPanelItemContext = createContext<ContextProps>(
  {} as ContextProps
);

interface ContextProps {
  openItem: boolean;
  toggleItem: BoolStateFunction;
}

export const NavigationPanelItem = forwardRef<
  NavigationPanelItemProps,
  "button"
>(function NavigationPanelItem(props, ref) {
  const {
    children,
    label,
    icon,
    textStyle,
    hasIcon = true,
    selected,
    highlight,
  } = props;
  const styles = useStyles();
  const { open } = useContext(NavigationPanelContext);
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const { focusedIndex } = useNavsContext();

  const { tabIndex, isExpanded, ...navProps } = useNav({
    ...props,
    onToggle: label ? onToggle : undefined,
    isParent: true,
    ref,
  });

  /* Close the item when home page is clicked */
  useEffect(() => {
    const shouldClose = focusedIndex == -1 && isOpen;
    if (shouldClose) {
      onClose();
    }
  }, [focusedIndex, onClose]);
  /* Close current item if other item is selected */
  useEffect(() => {
    if (selected && isExpanded) {
      onOpen();
    } else {
      onClose();
    }
  }, [isExpanded, selected, highlight, isOpen]);

  const hasDropdown =
    children != undefined &&
    typeof children !== "string" &&
    React.Children.count(children) > 0;

  return (
    <NavigationPanelItemContext.Provider
      value={{ openItem: isOpen, toggleItem: onToggle }}
    >
      <Tooltip
        className="w-full !text-xs"
        aria-label={label}
        arrowSize={8}
        hasArrow
        label={label}
        openDelay={400}
        placement="right"
      >
        <chakra.button
          __css={styles.item}
          {...navProps}
          aria-selected={highlight ?? selected ?? navProps["aria-selected"]}
          aria-expanded={open}
          tabIndex={-1}
        >
          <chakra.div
            __css={styles.itemContainer}
            aria-selected={highlight ?? selected ?? navProps["aria-selected"]}
            aria-expanded={isOpen}
            tabIndex={tabIndex}
          >
            {hasIcon && <chakra.span __css={styles.icon}>{icon}</chakra.span>}
            <chakra.span
              aria-expanded={open}
              __css={styles.label}
              textStyle={textStyle}
            >
              {label ?? children}
            </chakra.span>
            {hasDropdown && (
              <chakra.span __css={styles.icon}>
                <Icon
                  className="shrink-0"
                  as={isOpen ? TbChevronUp : TbChevronDown}
                  size="sm"
                  flexShrink={0}
                  sx={{ stroke: "whiteAlpha.600" }}
                />
              </chakra.span>
            )}
          </chakra.div>
        </chakra.button>
      </Tooltip>
      {label && React.Children.count(children) > 0 && (
        <Collapse animateOpacity in={isOpen}>
          <chakra.div sx={{ marginLeft: "4px" }}>{children}</chakra.div>
        </Collapse>
      )}
    </NavigationPanelItemContext.Provider>
  );
});

NavigationPanelItem.displayName = "NavigationPanelItem";
