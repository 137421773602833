import { Flex } from "@chakra-ui/react";
import { clsx } from "clsx";
import React from "react";

interface ValueInfoBarProps {
  data: {
    valid: number;
    invalid: number;
    nullValues: number;
    total: number;
  };
  className?: string;
}

export const ValueInfoBar: React.FC<ValueInfoBarProps> = ({
  data,
  className,
}) => {
  const { valid, invalid, nullValues, total } = data;
  const validPercentage = valid == 0 ? 0 : (valid / total) * 100;
  const invalidPercentage = invalid == 0 ? 0 : (invalid / total) * 100;
  const nullPercentage = nullValues == 0 ? 0 : (nullValues / total) * 100;

  return (
    <Flex className="flex flex-col items-center w-full pt-2 group absolute bottom-0 left-0">
      <Flex className={clsx("flex w-full h-0.5 group-hover:h-1.5", className)}>
        <Flex
          className="bg-green-500 h-full"
          style={{ width: `${validPercentage}%` }}
        ></Flex>
        <Flex
          className="bg-red-500 h-full"
          style={{ width: `${invalidPercentage}%` }}
        ></Flex>
        <Flex
          className="bg-gray-400 h-full"
          style={{ width: `${nullPercentage}%` }}
        ></Flex>
      </Flex>
    </Flex>
  );
};
