import {
  BreadcrumbItemProps as Props,
  BreadcrumbSeparator,
  createIcon,
  useBreadcrumbStyles,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { getValidChildren } from "@chakra-ui/react-children-utils";
import { cx } from "@chakra-ui/shared-utils";
import { chakra, forwardRef, SystemStyleObject } from "@chakra-ui/system";
import { cloneElement, ReactElement } from "react";

export type BreadcrumbItemProps = Props & {
  icon?: ReactElement;
};

// using `path`
const LinkIcon = createIcon({
  displayName: "LinkIcon",
  viewBox: "0 0 20 20",
  path: (
    <path d="M5.83333 13.7917C4.79167 13.7917 3.89583 13.4236 3.14583 12.6875C2.40972 11.9375 2.04167 11.0417 2.04167 10C2.04167 8.95833 2.40972 8.06944 3.14583 7.33333C3.89583 6.58333 4.79167 6.20833 5.83333 6.20833H8.29167C8.47222 6.20833 8.625 6.27778 8.75 6.41667C8.88889 6.55555 8.95833 6.70833 8.95833 6.875C8.95833 7.05555 8.88889 7.21528 8.75 7.35417C8.625 7.47917 8.47222 7.54167 8.29167 7.54167H5.83333C5.13889 7.54167 4.55556 7.77778 4.08333 8.25C3.61111 8.72222 3.375 9.30556 3.375 10C3.375 10.6944 3.61111 11.2778 4.08333 11.75C4.55556 12.2222 5.13889 12.4583 5.83333 12.4583H8.29167C8.47222 12.4583 8.625 12.5278 8.75 12.6667C8.88889 12.7917 8.95833 12.9444 8.95833 13.125C8.95833 13.2917 8.88889 13.4444 8.75 13.5833C8.625 13.7222 8.47222 13.7917 8.29167 13.7917H5.83333ZM7.54167 10.6667C7.36111 10.6667 7.20139 10.6042 7.0625 10.4792C6.9375 10.3403 6.875 10.1806 6.875 10C6.875 9.81944 6.9375 9.66667 7.0625 9.54167C7.20139 9.40278 7.36111 9.33333 7.54167 9.33333H12.4583C12.6389 9.33333 12.7917 9.40278 12.9167 9.54167C13.0556 9.66667 13.125 9.81944 13.125 10C13.125 10.1806 13.0556 10.3403 12.9167 10.4792C12.7917 10.6042 12.6389 10.6667 12.4583 10.6667H7.54167ZM11.7083 13.7917C11.5278 13.7917 11.3681 13.7222 11.2292 13.5833C11.1042 13.4444 11.0417 13.2917 11.0417 13.125C11.0417 12.9444 11.1042 12.7917 11.2292 12.6667C11.3681 12.5278 11.5278 12.4583 11.7083 12.4583H14.1667C14.8611 12.4583 15.4444 12.2222 15.9167 11.75C16.3889 11.2778 16.625 10.6944 16.625 10C16.625 9.30556 16.3889 8.72222 15.9167 8.25C15.4444 7.77778 14.8611 7.54167 14.1667 7.54167H11.7083C11.5278 7.54167 11.3681 7.47917 11.2292 7.35417C11.1042 7.21528 11.0417 7.05555 11.0417 6.875C11.0417 6.70833 11.1042 6.55555 11.2292 6.41667C11.3681 6.27778 11.5278 6.20833 11.7083 6.20833H14.1667C15.2083 6.20833 16.0972 6.58333 16.8333 7.33333C17.5833 8.06944 17.9583 8.95833 17.9583 10C17.9583 11.0417 17.5833 11.9375 16.8333 12.6875C16.0972 13.4236 15.2083 13.7917 14.1667 13.7917H11.7083Z" />
  ),
});

export const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  return <CustomBreadcrumbItem {...props} />;
};

/**
 * BreadcrumbItem is used to group a breadcrumb link.
 * It renders a `li` element to denote it belongs to an order list of links.
 *
 * @see Docs https://chakra-ui.com/breadcrumb
 */

const CustomBreadcrumbItem = forwardRef<BreadcrumbItemProps, "li">(
  function BreadcrumbItemRef(props, ref) {
    const {
      isCurrentPage,
      icon,
      separator,
      isLastChild,
      spacing,
      children,
      className,
      ...rest
    } = props;

    const validChildren = getValidChildren(children);

    const clones = validChildren.map((child) => {
      if (child.type === BreadcrumbLink) {
        return cloneElement(child, {
          isCurrentPage,
        });
      }

      if (child.type === BreadcrumbSeparator) {
        return cloneElement(child, {
          spacing,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          children: child.props.children || separator,
        });
      }

      return child;
    });

    const styles = useBreadcrumbStyles();
    /**
     * We need to reverse the order of the items so that the link comes first this was done to enable css selector
     * to select all the sibling after the link when link is hovered, the link how ever is rendered as second element
     * by setting the order of the seprator as -1. This is a small hack that enables css to assume the link is first
     * but when rendering its actually second.
     **/
    const itemStyles: SystemStyleObject = {
      display: "inline-flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      gap: "0.125rem",
      ...styles.item,
    };

    const _className = cx("chakra-breadcrumb__list-item", className);

    return (
      <chakra.li ref={ref} className={_className} {...rest} __css={itemStyles}>
        {clones}
        {!isLastChild && (
          <BreadcrumbSeparator spacing={spacing}>
            {separator}
          </BreadcrumbSeparator>
        )}

        {icon ? icon : <LinkIcon __css={styles.icon} />}
      </chakra.li>
    );
  },
);
BreadcrumbItem.displayName = "BreadcrumbItem";
