import { Box, Divider, Flex, Grid, Tag, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import {
  useLazyGetAnalysisListForProjectQuery,
  useLazyGetAnalysisListForWsQuery,
} from "@/features/ws-manager";
import { StyledIcon } from "@/features/ws-manager/components/styled-icon.tsx";
import { DetailsRow } from "@/features/ws-manager/layout/details-row.tsx";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { permissionsList } from "@/slices/permisson-slice";
import {
  getDateFormat,
  getLocalDateTimeFormat,
} from "@/utils/date-convertor.ts";
import { ACCESS_LEVEL, CATEGORY, PERMISSIONS } from "@/utils/enums.ts";

import { ProjectSchema, WorkspaceSchema } from "../types";

interface InfoModalProps {
  type: CATEGORY;
  data: ProjectSchema | WorkspaceSchema;
  navigate: any;
}

export const InfoModal = (props: InfoModalProps) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  const [analysisCount, setAnalysisCount] = useState(0);

  const [fetchWsAnalysisList, { data: wsData }] =
    useLazyGetAnalysisListForWsQuery();
  const [fetchProjectAnalysisList, { data: projectData }] =
    useLazyGetAnalysisListForProjectQuery();

  useEffect(() => {
    switch (props.type) {
      case CATEGORY.Workspace:
        fetchWsAnalysisList({ wsId: props.data.id });
        break;
      case CATEGORY.Project:
        fetchProjectAnalysisList({ projectId: props.data.id });
        break;
    }
  }, [props]);

  useEffect(() => {
    switch (props.type) {
      case CATEGORY.Workspace:
        setAnalysisCount(wsData?.response?.pagination?.count ?? 0);
        break;
      case CATEGORY.Project:
        setAnalysisCount(projectData?.response?.pagination?.count ?? 0);
        break;
    }
  }, [wsData, projectData]);

  const info = useMemo(() => props.data, [props]);

  const permissions =
    useAppSelector(permissionsList)[props.data.id]!.permissions;

  const getWorkspaceRole = () => {
    switch (true) {
      case permissions.includes("workspace.delete"):
        return ACCESS_LEVEL.ADMIN;
      case permissions.includes("workspace.edit"):
        return ACCESS_LEVEL.MANAGER;
      default:
        return ACCESS_LEVEL.VIEWER;
    }
  };

  const getProjectRole = () => {
    switch (true) {
      case permissions.includes("project.delete"):
        return ACCESS_LEVEL.ADMIN;
      case permissions.includes("project.edit"):
        return ACCESS_LEVEL.MANAGER;
      default:
        return ACCESS_LEVEL.VIEWER;
    }
  };

  const getRole = () => {
    switch (props.type) {
      case CATEGORY.Workspace:
        return getWorkspaceRole();
      case CATEGORY.Project:
        return getProjectRole();
    }
  };

  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: {
          object: props.data,
          type: props.type,
          navigate: props.navigate,
        },
      })
    );
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex className="items-center gap-x-2 capitalize">
            <StyledIcon
              title={props.type === CATEGORY.Workspace ? info.name : undefined}
              emoji={(info as WorkspaceSchema)?.emoji}
              icon={(info as WorkspaceSchema)?.icon}
            />
            {info?.name}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody className="!px-6 gap-y-10 flex flex-col">
          <Flex className="flex-col gap-y-4">
            <Flex className="gap-x-3">
              <Box>My role: </Box>
              <Tag colorScheme={"purple"}>{getRole()}</Tag>
            </Flex>
            <Text className="text-sm2 text-gray-700">{info?.description}</Text>
          </Flex>

          <Grid className="grid-cols-2 gap-6">
            <DetailsRow header="Total Analysis">{analysisCount}</DetailsRow>
            {props.type == CATEGORY.Workspace && (
              <DetailsRow header="Total Projects">
                {(props.data as WorkspaceSchema).projects?.length}
              </DetailsRow>
            )}

            <DetailsRow header="Last Run">
              {getLocalDateTimeFormat(info?.updatedOn)}
            </DetailsRow>
            <DetailsRow header="Created">
              {getDateFormat(info?.createdOn)}
            </DetailsRow>
            <DetailsRow header={`${props.type} Id`}>{info?.id}</DetailsRow>
          </Grid>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={info.id}
            type={props.type}
          >
            <Button
              colorScheme="secondary"
              w="fit-content"
              variant="outline"
              leftIcon={<Icon as={MdOutlineEdit} size="sm" />}
              size="sm"
              onClick={openManageAccessModal}
            >
              Manage Access
            </Button>
          </ProtectedComponent>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
