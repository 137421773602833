import { IconButton } from "@chakra-ui/react";
import { MdFavorite } from "react-icons/md";

import useManageFlowFavorites from "../../hooks/useManageFlowFavorites";
import { FlowSchema } from "../../types";
import { FLOW_TYPES } from "../../utils/constants";

export const FlowStoreFavButton = (flow: FlowSchema) => {
  const { removeFavorite, isLoading: markingFav } = useManageFlowFavorites();

  const unMarkFav = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    await removeFavorite({
      flow: flow,
      flowType: FLOW_TYPES.FAVORITES,
    });
  };

  return (
    <IconButton
      color={"red.500"}
      aria-label="Fav Flow"
      icon={<MdFavorite />}
      isDisabled={false}
      isLoading={markingFav}
      //eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={unMarkFav}
      variant={"ghost"}
    />
  );
};
