import { createContext } from "react";

import { ITransformationStep } from "@/features/data-transformation";

export const StepFormContext = createContext<{
  step: Partial<ITransformationStep> | null;
  editEnabled: boolean;
  register: any;
  setValue: any;
} | null>(null);
