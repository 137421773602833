import { createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError } from "axios";

import { createStandaloneToast, ToastType } from "@/components/toast";
import { baseQuery } from "@/utils/baseQuery.ts";
import { getMSBaseUrl } from "@/utils/baseUrls";
import { MICRO_SERVICES } from "@/utils/enums";

export const wsManagerApi = createApi({
  reducerPath: "wsManagerApi",
  baseQuery: baseQuery({
    baseUrl: getMSBaseUrl(MICRO_SERVICES.WORKSPACE_MANAGER),
    defaultHeaders: {
      "X-CLIENT-ID": import.meta.env.VITE_CLIENT_ID,
    },
    errorHandlerFn: (error) => {
      const status = (error as AxiosError).response?.status;

      const toast = createStandaloneToast();

      let errorMessage;
      if (status === 401 || status === 403) {
        errorMessage = "Unauthorized action.";
      } else if (status === 404) {
        errorMessage = "404 - Not Found";
      } else {
        if (error?.response?.data?.errors[0]?.detail) {
          errorMessage = error?.response?.data?.errors[0]?.detail;
        } else {
          console.log("error", error);
          const errors = error?.response?.data?.response?.errors;
          const defaultError =
            error?.response?.data?.message ?? "Oops! Something went wrong.";

          errorMessage = errors
            ? `${errors?.[0]?.detail} - ${errors?.[0]?.attr}`
            : defaultError;
        }
      }
      toast({
        title: errorMessage,
        status: ToastType.Error,
        duration: 10000,
      });
    },
  }),
  tagTypes: [
    "Analysis",
    "Recycle",
    "Workspaces",
    "Project",
    "Tags",
    "SearchInWs",
  ],
  endpoints: () => ({}),
});
