import { Flex, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import {
  MdOutlineArrowOutward,
  MdOutlineInfo,
  MdOutlineTune,
} from "react-icons/md";
import { Node } from "reactflow";

import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import {
  showPanel,
  toggleLLMConfigPanel,
} from "@/features/workflow-studio/redux";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice";

import { NodeType } from "../../types";
import { WORKFLOW_PANELS } from "../../utils/constants";

import { WorkflowErrorSchema } from ".";

export const ErrorItem = ({
  node,
  configErrors,
  connectionErrors,
  errors,
}: WorkflowErrorSchema) => {
  const dispatch = useAppDispatch();
  const nodeData = node.data as NodeType;

  const openNodeConfigPanel = useCallback(() => {
    dispatch(closeModal());

    if (node?.data?.displayName?.includes("LLM Prompt")) {
      dispatch(toggleLLMConfigPanel({ show: true, nodeId: node.id }));
      return;
    }
    dispatch(
      showPanel({
        panel: WORKFLOW_PANELS.NodeConfigurationPanel,
        nodeId: node.id,
      })
    );
  }, [dispatch, node.id]);

  const message = useMemo(() => {
    if (!errors) return null;

    const inputError =
      errors.inputsRequired > errors.inputsReceived &&
      errors.inputsRequired > 0;
    const outputError =
      errors.outputsRequired > errors.outputsReceived &&
      errors.outputsRequired > 0;

    return (
      <Text className="text-gray-800 text-sm text-ellipsis">
        The <b>{nodeData.displayName}</b> node in your workflow requires
        {inputError && (
          <span className="px-1">min {errors.inputsRequired} inputs</span>
        )}
        {inputError && outputError && "and"}
        {outputError && (
          <span className="px-1">min {errors.outputsRequired} outputs</span>
        )}
        <br />
        Connect it with other nodes to proceed.
      </Text>
    );
  }, [errors, nodeData.displayName]);

  return (
    <>
      {configErrors.length > 0 && (
        <Flex className="border p-2" direction="column" px={3}>
          <Flex className="w-full" align="center" justify="space-between">
            <Flex
              align="center"
              gap={2}
              overflow="hidden"
              color={"red.600"}
              textOverflow="ellipsis"
            >
              <Icon as={MdOutlineTune} />
              <Text whiteSpace="pre" textOverflow="ellipsis">
                Configuration Pending: {nodeData.displayName}
              </Text>
            </Flex>
            <Button
              rightIcon={<MdOutlineArrowOutward />}
              variant="outline"
              colorScheme="dark"
              size="sm"
              fontSize="xs"
              className="shrink-0 !py-0"
              onClick={openNodeConfigPanel}
            >
              Edit Configuration
            </Button>
          </Flex>
          <Text className="text-gray-700 text-sm whitespace-pre text-ellipsis">
            Your node needs to be configured before you can run it.
          </Text>
        </Flex>
      )}
      {errors?.isRogueNode ? (
        <Flex className="border p-2" direction="column" gap={2} px={3}>
          <Flex
            align="center"
            gap={2}
            overflow="hidden"
            color={"red.600"}
            textOverflow="ellipsis"
          >
            <Icon as={MdOutlineInfo} />
            <Text whiteSpace="pre" textOverflow="ellipsis">
              Rogue Nodes Found: {nodeData.displayName}
            </Text>
          </Flex>
          {message}
        </Flex>
      ) : (
        <>
          {connectionErrors.length > 0 && (
            <Flex className="border p-2" direction="column" gap={2} px={3}>
              <Flex
                align="center"
                gap={2}
                overflow="hidden"
                color={"red.600"}
                textOverflow="ellipsis"
              >
                <Icon as={MdOutlineInfo} />
                <Text whiteSpace="pre" textOverflow="ellipsis">
                  Connection Error: {nodeData.displayName}
                </Text>
              </Flex>
              {message}
            </Flex>
          )}
        </>
      )}
    </>
  );
};
