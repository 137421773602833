import { Flex, Icon, ModalCloseButton, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { MdInsertDriveFile } from "react-icons/md";

import {
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { IoRecord } from "../types";

const IoRecordError: React.FC = () => {
  const dispatch = useAppDispatch();
  const { ioRecord } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const message = useMemo(() => {
    return Array.isArray(ioRecord?.statusMsg)
      ? ioRecord?.statusMsg.join(".\n")
      : ioRecord?.statusMsg;
  }, [ioRecord]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      isCentered
      size="xl"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className="!p-0 rounded overflow-hidden">
        <ModalBody className="flex justify-end !p-5 gap-5">
          <Flex className="flex-col w-full grow gap-4">
            <Flex className="justify-between items-center w-full">
              <Text className="text-xl font-semibold grow">
                Failed to upload
              </Text>
              <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
            </Flex>
            <Flex className="flex-col rounded gap-2 overflow-hidden border border-red-500 text-red-500 p-3 bg-red-50">
              <Flex className="items-center gap-2">
                <Icon
                  className="text-red-600"
                  as={MdInsertDriveFile}
                  fontSize={24}
                />
                <Text className="font-medium max-w-full overflow-hidden whitespace-pre text-ellipsis">
                  {(ioRecord as IoRecord).name}
                </Text>
              </Flex>
              <Text className="text-red-800">{message} </Text>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default IoRecordError;
