import { Input } from "@chakra-ui/input";
import { Box, chakra, Flex, Spinner, Tag, Text } from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { isEmpty, orderBy } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdArrowOutward, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  MdsCloseSharp,
  MdsSearchSharp,
  MdsSwapVertRound,
} from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

import { useShowToast } from "@/components/toast";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@/design/components/data-table";
import { IconButton } from "@/design/components/icon-button";
import { AnalysisTableDropdown } from "@/features/ws-manager/components/analysis/analysis-table-dropdown.tsx";
import { CustomIcon } from "@/features/ws-manager/components/get-icon.tsx";
import { useGetCurrentWs } from "@/features/ws-manager/hooks/useGetCurrentWs.ts";
import {
  previewAnalysis,
  setPreviewAnalysis,
} from "@/features/ws-manager/redux/analysis-slice.tsx";
import { AnalysesSchema } from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { showContextMenu } from "@/slices/context-menu-slice";
import { permissionsList } from "@/slices/permisson-slice";
import { getRelativeTime } from "@/utils/date-convertor.ts";
import { CATEGORY } from "@/utils/enums";
import { closestColor } from "@/utils/get-color.ts";

import {
  useLazyGetAnalysisListForOrgQuery,
  useLazyGetAnalysisListForProjectQuery,
  useLazyGetAnalysisListForWsQuery,
} from "../../api";
import useAnalysis from "../../hooks/useAnalysis";

const columnHelper = createColumnHelper<AnalysesSchema>();

const TableLoader = ({ isFull = false }: { isFull?: boolean }) => (
  <Flex
    className={clsx(
      "justify-center shrink-0 w-full items-center gap-3 py-1",
      "absolute bottom-0",
      isFull ? "h-full" : "h-fit bg-white"
    )}
  >
    <Spinner size={"xs"} />
    <span>Loading</span>
  </Flex>
);

export function AnalysisDatatable(props: {
  onOpen: () => void;
  label: string;
}) {
  const toast = useShowToast(undefined, undefined, true);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const permissions = useSelector(permissionsList);
  const { onDelete, onEdit, openManageAccessModal, openDM, onFavorite } =
    useAnalysis();
  // const [data, setData] = React.useState<AnalysesSchema[]>([]);
  // const [pagination, setPagination] = React.useState<Pagination | null>();
  const [search, setSearch] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(true);
  const { shownWs: ws, shownProject: project } = useGetCurrentWs();
  const { removeParam } = useCustomQuery();
  const dispatch = useAppDispatch();
  const analysis = useAppSelector(previewAnalysis);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const [
    fetchAnalysis,
    {
      data: homeData,
      isLoading: isHomeDataLoading,
      isFetching: isNextHomePageLoading,
    },
  ] = useLazyGetAnalysisListForOrgQuery();
  const [
    fetchWsAnalysisList,
    {
      data: wsData,
      isLoading: isWsDataLoading,
      isFetching: isNextWsPageLoading,
    },
  ] = useLazyGetAnalysisListForWsQuery();
  const [
    fetchProjectAnalysisList,
    {
      data: projectData,
      isLoading: isProjectDataLoading,
      isFetching: isNextProjectPageLoading,
    },
  ] = useLazyGetAnalysisListForProjectQuery();

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  const [debouncedValue] = useDebounce(search, 500);

  useEffect(() => {
    getAnalysisBySearch();
  }, [debouncedValue]);

  useEffect(() => {
    getAnalysisByContext();
  }, [project, ws]);

  const isLoading = useMemo(() => {
    let loading;

    if (ws && project) {
      loading = isProjectDataLoading;
    } else if (ws) {
      loading = isWsDataLoading;
    } else {
      loading = isHomeDataLoading;
    }
    return loading;
  }, [isHomeDataLoading, isWsDataLoading, isProjectDataLoading, project, ws]);

  const isPaginating = useMemo(() => {
    let paginating;

    if (ws && project) {
      paginating = isNextProjectPageLoading;
    } else if (ws) {
      paginating = isNextWsPageLoading;
    } else {
      paginating = isNextHomePageLoading;
    }
    return paginating;
  }, [
    isNextProjectPageLoading,
    isNextWsPageLoading,
    isNextHomePageLoading,
    project,
    ws,
  ]);

  const data = useMemo(() => {
    let currentData;

    if (ws && project) {
      currentData = projectData;
    } else if (ws) {
      currentData = wsData;
    } else {
      currentData = homeData;
    }

    // return currentData?.response?.data?.analyses ?? [];
    const filteredData = currentData?.response?.data?.analyses ?? [];
    return orderBy(
      filteredData,
      [(obj: AnalysesSchema) => new Date(obj.updatedOn)],
      ["desc"]
    );
  }, [homeData, wsData, projectData, project, ws]);

  const pagination = useMemo(() => {
    let currentData;

    if (ws && project) {
      currentData = projectData;
    } else if (ws) {
      currentData = wsData;
    } else {
      currentData = homeData;
    }

    return currentData?.response?.pagination ?? null;
  }, [data, isLoading]);

  const getAnalysisBySearch = async () => {
    if (ws && project) {
      await fetchProjectAnalysisList({
        projectId: project.id,
        query: debouncedValue,
      });
    } else if (ws) {
      await fetchWsAnalysisList({
        wsId: ws.id,
        query: debouncedValue,
      });
    } else {
      await fetchAnalysis({ query: debouncedValue });
    }
  };

  const getAnalysisByContext = async () => {
    if (ws && project) {
      await fetchProjectAnalysisList({
        projectId: project.id,
      }).unwrap();
    } else if (ws) {
      await fetchWsAnalysisList({ wsId: ws.id }).unwrap();
    } else {
      await fetchAnalysis({}).unwrap();
    }
  };

  const onPaginate = async () => {
    if (ws && project) {
      await fetchProjectAnalysisList({
        projectId: project.id,
        next: pagination?.next ?? "",
      });
    } else if (ws) {
      await fetchWsAnalysisList({
        wsId: ws.id,
        next: pagination?.next ?? "",
      });
    } else {
      await fetchAnalysis({
        next: pagination?.next ?? "",
      });
    }
  };

  const paginate = useCallback(onPaginate, [project, ws, pagination]);

  const handleDoubleClick = (e: React.MouseEvent, row: AnalysesSchema) => {
    const analysisId = row.id;
    const url = `/analysis/${analysisId}/data-manager`;
    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  const handleContextMenu = useCallback(
    (e: React.MouseEvent, rowData: AnalysesSchema) => {
      e.preventDefault();
      dispatch(
        showContextMenu({
          x: e.clientX,
          y: e.clientY,
          items: [
            {
              label: "Edit In Studio",
              action: () => openDM(e, { data: rowData }),
              icon: MdArrowOutward,
            },
            // {
            //   label: "Add to Favorites",
            //   action: () => onFavorite(e, { data: rowData }),
            //   icon: rowData.favorite ? TbHeartMinus : TbHeartPlus,
            // },
            {
              label: "Manage Sharing",
              action: () =>
                openManageAccessModal(e, {
                  data: rowData,
                  type: CATEGORY.Analysis,
                }),
              icon: IoShareSocialOutline,
            },
            {
              label: "Edit Analysis Details",
              action: () => onEdit(e, { data: rowData }),
              icon: MdOutlineEdit,
            },
            {
              label: "Delete Analysis",
              action: () => onDelete(e, { data: rowData }),
              color: "red.600",
              icon: RiDeleteBin6Line,
            },
          ],
        })
      );
    },
    [dispatch, openDM, onFavorite, openManageAccessModal, onEdit, onDelete]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: ({ row: _row, getValue }) => (
          <Flex className="items-center gap-3">
            <CustomIcon
              color={"gray.500"}
              className="shrink-0"
              type={props.label}
            />
            <Text className="break-all">{getValue<string>()}</Text>
          </Flex>
        ),
        header: () => (
          <span className="select-none whitespace-nowrap">{`${props.label} Name`}</span>
        ),
        size: 50,
        enableSorting: !isSearching,
      }),
      columnHelper.accessor("tags", {
        cell: ({ row: _row, getValue }) => {
          const tags = getValue<string[]>();
          return (
            <Flex wrap={"wrap"} gap={2}>
              {tags.slice(0, 2).map((tag, idx) => (
                <Tag
                  className="break-all"
                  key={tag + idx}
                  colorScheme={closestColor(tag)}
                >
                  {tag}
                </Tag>
              ))}
              {tags.length > 2 && (
                <small className="text-gray-600 p-0.5">
                  +{tags.length - 2}
                </small>
              )}
            </Flex>
          );
        },
        enableSorting: false,
        header: () => <span className="select-none">Tags</span>,
        size: 20,
      }),
      columnHelper.accessor("updatedOn", {
        cell: (info) => (
          <Box className="whitespace-nowrap">
            {getRelativeTime(info.getValue())}
          </Box>
        ),
        header: () => (
          <span className="select-none whitespace-nowrap">Last Activity</span>
        ),
        size: 25,
      }),
      columnHelper.accessor("id", {
        cell: (info) => <AnalysisTableDropdown {...info.row.original} />,
        header: () => <span className="select-none"></span>,
        size: 5,
        enableSorting: false,
      }),
    ],
    [props, isSearching]
  );

  const table = useReactTable({
    columns: columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 100,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const setPreview = (row: AnalysesSchema) => {
    if (permissions[row.id].permissions.includes("analysis.read")) {
      dispatch(setPreviewAnalysis(row));
      props.onOpen();
    } else {
      toast({
        title: "Insufficient permission to view this analysis",
        status: "error",
        variant: "left-accent",
      });
    }
  };

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearch("");
    setIsSearching((state) => !state);
  };

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        //TODO: refactor to make this common across all tables
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollTop != 0 &&
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isLoading &&
          pagination &&
          data.length < pagination.count
        ) {
          paginate();
        }
      }
    },
    [isLoading, data, pagination]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <Flex direction={"column"} overflowX={"auto"} w={"100%"}>
      <Box
        className="overflow-auto w-full relative h-full pb-10"
        ref={tableContainerRef}
        minW={"700px"}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      >
        <Table
          width="100%"
          variant="simple"
          __css={{
            borderCollapse: "separate",
            borderSpacing: 0,
            position: "relative",
          }}
        >
          <Thead
            position="sticky"
            top={0}
            zIndex={1}
            bg={"white"}
            insetBlockStart={0}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr width={"100%"} key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta = header.column.columnDef.meta;
                  return (
                    <Th
                      overflow={"hidden"}
                      style={{
                        width: `${header.column.columnDef.size}%`,
                        maxWidth: `${header.column.columnDef.size}%`,
                        minWidth: `${header.column.columnDef.size}%`,
                      }}
                      className={clsx(
                        "!py-2 group/header hover:!bg-[#fcfcfc]",
                        idx === 0 && "!pl-4"
                      )}
                      textOverflow={"ellipsis"}
                      key={header.id}
                      isNumeric={meta?.isNumeric}
                      onClick={header.column.getToggleSortingHandler()}
                      role="button"
                    >
                      <Flex
                        className="!normal-case !shrink-0 tracking-normal font-medium text-gray-700 text-base"
                        align="center"
                      >
                        {idx === 0 && (
                          <>
                            <IconButton
                              variant={"ghost"}
                              colorScheme="dark"
                              aria-label="search"
                              icon={
                                isSearching ? (
                                  <MdsCloseSharp
                                    size={24}
                                    aria-label="search"
                                    title="search"
                                  />
                                ) : (
                                  <MdsSearchSharp
                                    strokeWidth={22}
                                    size={24}
                                    aria-label="search"
                                    title="search"
                                  />
                                )
                              }
                              size="md"
                              onClick={onSearchClick}
                              color="gray.900"
                            />
                            {isSearching ? (
                              <Input
                                className="!w-full text-gray-800 font-normal mr-2"
                                placeholder="Search by name"
                                name="search"
                                value={search}
                                onChange={handleInputChange}
                                autoFocus={true}
                              />
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            )}
                          </>
                        )}
                        {idx !== 0 && (
                          <>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </>
                        )}
                        {header.column.getCanSort() && (
                          <chakra.span
                            pl="3"
                            className="text-gray-300 group-hover/header:text-gray-800"
                          >
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown
                                  aria-label="sorted descending"
                                  title="sorted descending"
                                />
                              ) : (
                                <BsArrowUp
                                  aria-label="sorted ascending"
                                  title="sorted ascending"
                                />
                              )
                            ) : (
                              <MdsSwapVertRound
                                aria-label="sort"
                                title="sort"
                              />
                            )}
                          </chakra.span>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody
            style={{
              height: `${virtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: "relative", //needed for absolute positioning of rows
            }}
            width="100%"
            bg={"white"}
          >
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              const isSelected = analysis && analysis.id == row.original.id;
              return (
                <Tr
                  onClick={() => {
                    removeParam("analysis");
                    setPreview(row.original);
                    props.onOpen();
                  }}
                  width={"100%"}
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={(node) => virtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  bg={
                    isSelected
                      ? "linear-gradient(to right ,transparent, #FFF0E8)"
                      : "white"
                  }
                  _hover={{
                    cursor: "pointer",
                  }}
                  onContextMenu={(e) => handleContextMenu(e, row.original)}
                  onDoubleClickCapture={(e) =>
                    handleDoubleClick(e, row.original)
                  }
                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                  className={clsx(
                    "table-row-gradient",
                    "absolute group !overflow-hidden flex items-center border-b border-gray-200",
                    isSelected && "selected-table-row-gradient"
                  )}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    return (
                      <Td
                        pointerEvents={
                          cellIndex !== row.getVisibleCells().length - 1
                            ? "none"
                            : "auto"
                        }
                        className={clsx(
                          "font-medium !border-0",
                          cellIndex === row.getVisibleCells().length - 1 &&
                            "!px-0",
                          isSelected &&
                            cellIndex == 0 &&
                            "text-orange-800 underline !pl-7 highlightRow",
                          cellIndex == 0
                            ? "!py-0 pt-0 highlightRowHover"
                            : "py-4"
                        )}
                        key={cell.id}
                        style={{
                          width: `${cell.column.columnDef.size}%`,
                          minWidth: `${cell.column.columnDef.size}%`,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {table.getRowModel().rows.length === 0 && !isLoading && (
          <Flex className="justify-center w-full items-center mt-[120px]">
            {isEmpty(search) ? (
              <Text className="text-gray-500">No {props.label} found</Text>
            ) : (
              <Text className="text-gray-500">
                No results found for "{search}"
              </Text>
            )}
          </Flex>
        )}
      </Box>
      {isLoading && <TableLoader isFull={true} />}
      {!isLoading && isPaginating && <TableLoader />}
    </Flex>
  );
}
