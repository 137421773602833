import { createApi, retry } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "@/utils/baseQuery.ts";
import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

const staggeredBaseQuery = retry(
  baseQuery({
    baseUrl: getMSBaseUrl(MICRO_SERVICES.DATA_MANAGER),
    defaultHeaders: {
      "X-CLIENT-ID": import.meta.env.VITE_CLIENT_ID,
      "X-WORKSPACE-ID": "518cbce6-717f-4962-9d3b-d686829ccb9b",
    },
  }),
  {
    maxRetries: 0,
  }
);

export const dataManagerAPI = createApi({
  reducerPath: "dataManagerAPI",
  baseQuery: staggeredBaseQuery,
  tagTypes: ["IORecords", "Datasets"],
  endpoints: () => ({}),
});
