import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { IChart } from "@/features/data-transformation";

import { CustomizedLabel } from "./bar-chart-label.tsx";

export const BarChartCustom = ({ data }: { data: IChart[] }) => {
  const barData = data.slice(0, 5);
  return (
    <ResponsiveContainer width="100%" height={30 * (barData?.length ?? 1)}>
      <BarChart
        data={barData}
        layout="vertical"
        barCategoryGap={4}
        barSize={24}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis type="number" hide />
        <YAxis type="category" dataKey="label" hide />
        <Bar
          dataKey="value"
          fill="var(--chakra-colors-green-100)"
          label={<CustomizedLabel />}
        >
          {barData?.map((entry, index) => (
            <Cell key={`cell-${index}`} name={entry.label} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
