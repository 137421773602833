import { Flex } from "@chakra-ui/layout";

import {
  IExploreTaskResponse,
  STATS_MAP,
} from "@/features/data-transformation";
import { DetailItem } from "@/features/data-transformation/components/explore-data/detail-item.tsx";

export const StatsDetails = ({ data }: { data: IExploreTaskResponse }) => {
  return (
    <Flex className="flex-col gap-y-3 mt-8">
      {Object.keys(STATS_MAP)
        .map((stat, index) => {
          const statistic = data?.statistics?.otherStats[stat];
          if (!statistic) return null;
          return (
            <DetailItem
              key={index}
              title={STATS_MAP[stat]}
              value={statistic ?? ""}
            />
          );
        })
        .filter(Boolean)}
    </Flex>
  );
};
