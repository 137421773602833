import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  "title",
  "container",
  "profile",
]);

export const titleBarTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: "flex",
      width: "100%",
      paddingLeft: "var(--chakra-space-5, 0px)",
      alignItems: "center",
      gap: "var(--chakra-space-1, 0.25rem)",
      background: "white",
      borderBottom: "1px solid var(--chakra-colors-black-300)",
      height: "4rem",
    },
    title: {
      color: "var(--charka-colors-dark-400)",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "100%",
      letterSpacing: "-0.015rem",
      flex: "1",
      display: "flex",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-end",
      height: "100%",
      position: "relative",
    },
  },
});
