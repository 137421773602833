import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

interface Style {
  width: string;
  height: string;
  fontWeight: string;
}

interface StyleBadge {
  fontSize: string;
  borderWidth: string;
}

const style = (width: string, height: string): Style => {
  return {
    width: width,
    height: height,
    fontWeight: "500",
  };
};

const styleBadge = (fontSize: string, borderWidth: string): StyleBadge => {
  return {
    fontSize,
    borderWidth,
  };
};

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  badge: {
    position: "absolute",
    bottom: "0rem",
    right: "0.125rem",
  },
  container: {
    bg: "blue.300",
  },
  label: {
    color: "black",
  },
});

const xs = style("1.5rem", "1.5rem");
const sm = style("2rem", "2rem");
const md = style("2.5rem", "2.5rem");
const lg = style("3rem", "3rem");
const xl = style("3.5rem", "3.5rem");
const xxl = style("8rem", "8rem");

const xsBadge = styleBadge("0.475rem", "1px");
const smBadge = styleBadge("0.65rem", "1.5px");
const mdBadge = styleBadge("0.875rem", "2.px");
const lgBadge = styleBadge("1.05rem", "2.5px");
const xlBadge = styleBadge("1.25rem;", "3px");
const xxlBadge = styleBadge("2.75rem", "8px");

const sizes = {
  xs: definePartsStyle({
    container: defineStyle(xs),
    badge: defineStyle(xsBadge),
    label: defineStyle({ fontSize: "0.5625rem" }),
  }),
  sm: definePartsStyle({
    container: defineStyle(sm),
    badge: defineStyle(smBadge),
    label: defineStyle({ fontSize: "0.75rem" }),
  }),
  md: definePartsStyle({
    container: defineStyle(md),
    badge: defineStyle(mdBadge),
    label: defineStyle({ fontSize: "1rem" }),
  }),
  lg: definePartsStyle({
    container: defineStyle(lg),
    badge: defineStyle(lgBadge),
    label: defineStyle({ fontSize: "1.25rem" }),
  }),
  xl: definePartsStyle({
    container: defineStyle(xl),
    badge: defineStyle(xlBadge),
    label: defineStyle({ fontSize: "2.375rem" }),
  }),
  "2xl": definePartsStyle({
    container: defineStyle(xxl),
    badge: defineStyle(xxlBadge),
    label: defineStyle({ fontSize: "3rem" }),
  }),
};

export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: sizes,
});
