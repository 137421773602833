export const Logo = () => {
  return (
    <svg
      width="22"
      height="32"
      viewBox="0 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id=".wrapper">
        <g id="bulb">
          <path
            id="bulb_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9047 18.7836C18.7948 18.8986 18.6417 18.9611 18.4827 18.9611H3.51732C3.35831 18.9611 3.20522 18.8986 3.09532 18.7836C1.26235 16.8669 0.135773 14.2799 0.135773 11.4367C0.135773 5.52087 4.99358 0.727264 10.9967 0.727264C16.9999 0.727264 21.8642 5.52087 21.8642 11.4367C21.8642 14.2799 20.7377 16.8669 18.9047 18.7836ZM17.5922 25.8433H4.40112C3.75604 25.8433 3.22945 25.3309 3.22945 24.6887V22.2271C3.22945 21.8957 3.49808 21.6271 3.82945 21.6271H18.1573C18.4887 21.6271 18.7573 21.8957 18.7573 22.2271V24.6887C18.7639 25.3244 18.2373 25.8433 17.5922 25.8433ZM14.8671 31.2725H7.12625C6.48117 31.2725 5.95458 30.7601 5.95458 30.1179V29.1092C5.95458 28.7778 6.22321 28.5092 6.55458 28.5092H15.4322C15.7636 28.5092 16.0322 28.7778 16.0322 29.1092V30.1179C16.0388 30.7536 15.5122 31.2725 14.8671 31.2725Z"
            fill="#F4673B"
          />
        </g>
      </g>
    </svg>
  );
};
