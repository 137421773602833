import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdsErrorRound } from "react-icons-with-materialsymbols/mds";

import { ModalSubHeader } from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { useLLMErrors } from "../utils/llm-validation";

const LLMErrorsModal = () => {
  const dispatch = useAppDispatch();

  const { errors } = useLLMErrors();

  const onClose = () => {
    dispatch(closeModal());
  };
  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent className="overflow-hidden max-h-[80vh]">
        <ModalHeader>Unable to Run Test</ModalHeader>
        <ModalSubHeader className="!pl-6 text-sm">
          Resolve these issues and try again:
        </ModalSubHeader>
        <ModalCloseButton />
        <ModalBody className="max-h-[70vh] overflow-y-auto">
          <Flex className="!px-6 !gap-3 h-full" direction={"column"}>
            {errors.map((error) => (
              <Flex
                className="border p-3"
                key={error.error}
                direction="column"
                gap={2}
                px={3}
              >
                <Flex
                  align="center"
                  gap={2}
                  overflow="hidden"
                  color={"red.600"}
                  textOverflow="ellipsis"
                >
                  <MdsErrorRound className="stroke-[22]" size={20} />
                  <Text
                    className="font-medium text-base"
                    whiteSpace="pre"
                    textOverflow="ellipsis"
                  >
                    {error.error}
                  </Text>
                </Flex>
                <Text className="text-sm">{error.message}</Text>
              </Flex>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LLMErrorsModal;
