import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: "0.1875rem",
  },
});

const variantCustom = definePartsStyle({
  field: {
    border: "1px solid",
    borderColor: "gray.500",
    bg: "inherit",
    _invalid: {
      borderColor: "red.600 !important",
    },
  },
});
export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { variantCustom },
  defaultProps: {
    variant: "variantCustom",
  },
});
