import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "@/reduxHooks.ts";

import { closeModal, MODAL_COMPONENTS } from "../slices/modal-slice.ts";

const ModalWrapper = () => {
  const { modalType, modalProps } = useAppSelector(
    (state) => state.rootReducer.modals,
  );

  const dispatch = useDispatch();

  /**
   * Hack to close modal on back button and prevent the user from going back to the previous page
   * TODO: Make modals work with react-router
   */
  useEffect(() => {
    window.addEventListener("popstate", handleBackOnModal);
    return () => window.removeEventListener("popstate", handleBackOnModal);
  });
  const handleBackOnModal = (event: PopStateEvent) => {
    if (modalType) {
      event.preventDefault();
      history.go(1);
      dispatch(closeModal());
    }
  };

  if (!modalType) {
    return null;
  }
  const ModalComponent = MODAL_COMPONENTS[modalType];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ModalComponent {...modalProps} />;
};

export default ModalWrapper;
