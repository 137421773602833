import { Box, Spinner } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Edge, Node, ReactFlowProvider } from "reactflow";

import { CUSTOM_QUERY, resetState } from "@/features/data-transformation";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import {
  hideAllPanels,
  hidePanel,
  selectPanel,
  showLLMConfigPanel,
  useGetNodesFromUsageInstanceIdMutation,
} from "..";
import { useGetWorkflowQuery } from "../api/workflow-api";
import FlowEditor from "../components/flow-editor";
import {
  currentWorkflowId,
  setEditingAllowed,
  setReferenceRunId,
  setWorkFlowId,
  setWorkflowRunId,
  setWorkflowRunStatus,
} from "../redux/workflow-slice";
import { NodeType } from "../types";
import { NODE_STATUS, WORKFLOW_PANELS } from "../utils/constants";
import { createReactflowObjectFromWorkflow } from "../utils/transform-response";

export const Editor = () => {
  const workflowId = useAppSelector(currentWorkflowId);
  const dispatch = useAppDispatch();
  const params = useParams();
  const { currentParam, removeCurrentParam } = useCustomQuery(CUSTOM_QUERY);

  const transformPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataTransformationPanel)
  );
  const llmPanel = useAppSelector(showLLMConfigPanel);

  const [initialNodes, setInitialNodes] = useState<Node[]>([]);

  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);

  const {
    isLoading: isFetchingWorkflow,
    data,
    isError,
    error: workflowError,
  } = useGetWorkflowQuery(
    {
      workflowId: workflowId ?? params["editorId"]!,
    },
    { refetchOnMountOrArgChange: true }
  );

  const workflow = data?.response?.data?.workflows[0];

  const [getNodeList, { isLoading: loadingNodes }] =
    useGetNodesFromUsageInstanceIdMutation();

  // This removed edaid  if user has refreshed the page
  useEffect(() => {
    if (transformPanel.isVisible) return;
    if (currentParam) {
      removeCurrentParam();
      dispatch(resetState());
    }
  }, [transformPanel.isVisible, currentParam]);

  useEffect(() => {
    return () => {
      dispatch(hidePanel(WORKFLOW_PANELS.DataTransformationPanel));
    };
  }, []);

  useEffect(() => {
    dispatch(setWorkFlowId(params["editorId"]!));
  }, [params, dispatch]);

  const getNodeDetailsFromInstanceIds = useCallback(
    async (nodeUsageInstanceId: string[]) => {
      let nodeList: NodeType[] = [];
      const nodeResponse = await getNodeList({
        analysisId: params["analysisId"] as string,
        nodeUsageIdList: nodeUsageInstanceId,
      }).unwrap();
      nodeList = nodeResponse.response.data!.nodes;
      return nodeList;
    },
    [getNodeList, params.editorId]
  );

  const initialWorkflowSetup = useCallback(async () => {
    // if workflow is not loaded or has errors, return
    if (!isError && !data) return;

    if (!workflow?.workflowNodes) return;

    // extract nodeUsageInstanceId from workflowNodes
    const nodesInWorkflow = workflow.workflowNodes
      .flatMap((node) => node.nodeUsageInstanceId)
      .filter((node) => node !== null);

    // if nodes are present, fetch node details using nodeUsageInstanceId
    if (nodesInWorkflow?.length > 0) {
      const nodeDetails = await getNodeDetailsFromInstanceIds(
        nodesInWorkflow as string[]
      );
      const { configuredNodes, configuredEdges } =
        createReactflowObjectFromWorkflow(nodeDetails, workflow);
      setInitialEdges(configuredEdges);
      setInitialNodes(configuredNodes);
    } else {
      setInitialEdges([]);
      setInitialNodes([]);
    }
    dispatch(setWorkflowRunId(workflow.workflowRunId as string));
    dispatch(setReferenceRunId(workflow.referenceRunId as string));
    dispatch(setWorkflowRunStatus(workflow.workflowStatus));
    dispatch(
      setEditingAllowed(workflow.workflowStatus !== NODE_STATUS.RUNNING)
    );

    if (transformPanel.isVisible) return;

    dispatch(hideAllPanels());
  }, [workflow, dispatch, isError, getNodeDetailsFromInstanceIds]);

  useEffect(() => {
    if (llmPanel.isVisible) return;
    initialWorkflowSetup().catch((err) => {
      console.log(err);
    });
  }, [initialWorkflowSetup]);

  if (isError) {
    return (
      <div className="z-[1] flex flex-col items-center justify-center w-full h-full overflow-hidden">
        <Box className="p-3 flex items-center gap-4 text-red-500 bg-red-50 rounded">
          <MdErrorOutline size={24} />
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
          <Box>Oops! {workflowError?.data.message}</Box>
        </Box>
      </div>
    );
  }

  const isLoadingWorkflow =
    isFetchingWorkflow && !transformPanel.isVisible && !llmPanel.isVisible;
  const isLoadingNode =
    loadingNodes && !transformPanel.isVisible && !llmPanel.isVisible;

  const isLoading =
    isLoadingWorkflow || !workflowId || isLoadingNode || !initialEdges;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
        <Box className="p-3 flex items-center gap-4 border bg-gray-50 rounded">
          <Spinner color="gray" />
          <Box>
            {loadingNodes ? `Setting up editor...` : `Fetching Workflow...`}
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full h-full overflow-hidden z-[2]">
      <ReactFlowProvider>
        <FlowEditor initialEdges={initialEdges} initialNodes={initialNodes} />
      </ReactFlowProvider>
    </div>
  );
};
