import { Box, Icon } from "@chakra-ui/react";
import { MdUploadFile } from "react-icons/md";
import {
  MdsCsvRound,
  MdsDescriptionRound,
  MdsUploadFileRound,
} from "react-icons-with-materialsymbols/mds";

import { ConnectorIcon } from "@/airbyte-component-docs/components/common/ConnectorIcon";
import { BuilderPrompt } from "@/airbyte-component-docs/components/connectorBuilder/BuilderPrompt";
import { ConnectorDefinition } from "@/airbyte-component-docs/core/domain/connector";
import { UploadFileFilled } from "@/components/icons/upload-file-filled";
import { DatasetBlock } from "@/features/data-manager/modals/add-dataset/dataset-block";
import { ADD_DATASET_TAGS } from "@/utils/enums";

import styles from "./ConnectorButton.module.scss";

interface ConnectorButtonProps<T extends ConnectorDefinition> {
  onClick: (definition: any, event?: any) => void;
  definition: T;
}

export const ConnectorButton = <T extends ConnectorDefinition>({
  definition,
  onClick,
}: ConnectorButtonProps<T>) => {
  const icon = definition.iconUrl ? (
    <ConnectorIcon icon={definition.iconUrl} className={styles.icon} />
  ) : (
    <Icon as={MdsDescriptionRound} fontSize={28} />
  );
  return (
    <DatasetBlock
      icon={icon}
      tag={ADD_DATASET_TAGS.AUTH_REQUIRED}
      title={definition.displayName}
      description={definition.description}
      onClick={() => onClick(definition)}
    />
  );
};
export const ConnectorFileButton = <T extends ConnectorDefinition>({
  definition,
  onClick,
}: ConnectorButtonProps<T>) => {
  const icon = definition.iconUrl ? (
    <ConnectorIcon icon={definition.iconUrl} className={styles.icon} />
  ) : (
    <Box className="border border-gray-300 bg-gray-100 rounded p-2">
      <Icon as={UploadFileFilled} color={"gray.800"}  fontSize={24} />
    </Box>
  );
  return (
    <label htmlFor={definition.name}>
      <input
        type="file"
        accept=".csv,.xls,.xlsx"
        id={definition.name}
        style={{ display: "none" }}
        onInput={(event) => {
          void onClick(definition, event);
        }}
        key={"input-file"}
      />
      <DatasetBlock
        icon={icon}
        tag={ADD_DATASET_TAGS.FILE}
        title={definition.displayName!}
        description="Select file from local"
      />
    </label>
  );
};

export const BuilderConnectorButton: React.FC = () => {
  return (
    <BuilderPrompt
      className={styles.button}
      shortDescription
      renderAsButton
      builderRoutePath={`../../`}
    />
  );
};
