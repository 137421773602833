import { Flex, Spinner } from "@chakra-ui/react";
import { useContext } from "react";
import { MdsPivotTableChartRound } from "react-icons-with-materialsymbols/mds";

import { useGetTransformationsQuery } from "@/features/data-transformation";

import { ActiveCategoryContext } from "./active-category-context.ts";
import { OperationCategory } from "./operations-category.tsx";

export const OperationsCategoryList = () => {
  const { data, isLoading } = useGetTransformationsQuery({});

  const categories = data?.response?.data?.availableTransformation || [];
  const { activeCategory, setActive } = useContext(ActiveCategoryContext)!;

  return (
    <Flex className="border border-t-0 border-r-0 flex-[1] min-w-[260px] h-full w-full">
      <Flex className="flex-col border-r flex-1 h-full w-ful px-2 py-3 gap-y-3">
        {categories.map((category, index) => (
          <OperationCategory
            key={index}
            title={category}
            icon={MdsPivotTableChartRound}
            active={activeCategory === category}
            onClick={setActive.bind(null, category)}
          />
        ))}
        {isLoading && <Spinner />}
      </Flex>
    </Flex>
  );
};
