// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { isEmpty } from "lodash";
import React from "react";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Icon } from "@/design/components/icon";
import { hasCreatePermission } from "@/features/ws-manager/components/sidebar/utils";
import { useAppSelector } from "@/reduxHooks";
import { currentUserId, currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { CATEGORY, USER_ROLES } from "@/utils/enums";
/* eslint-disable */
export const customIconComponent = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option
      {...props}
      className="flex-row-reverse justify-between"
    >
      <div className="flex-1 flex items-center overflow-hidden gap-2">
        <span>{props.data.icon}</span>
        <div className="!capitalize overflow-hidden text-ellipsis whitespace-nowrap">
          {children}
        </div>
      </div>
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }) => {
    const value = Object.values(props.getValue())[0] as {
      icon: any;
      value: string;
    };
    return (
      <chakraComponents.SingleValue className="flex" {...props}>
        <span className="mr-2 inline-flex items-center">{value.icon}</span>
        <div className="capitalize">{children}</div>
      </chakraComponents.SingleValue>
    );
  },
  Placeholder: ({ children, ...props }) => {
    return (
      <chakraComponents.Placeholder {...props}>
        {props.selectProps.placeholderIcon ? (
          <Flex className="flex-row items-center">
            <span className="mr-1.5 mb-0.5 inlne-flex items-center">
              {props.selectProps.placeholderIcon}
            </span>
            {children}
          </Flex>
        ) : (
          children
        )}
      </chakraComponents.Placeholder>
    );
  },
};
export const NoOptions = ({ children, ...props }) => {
  return null;
};

export const LoadingMessage = ({ children, ...props }) => null;

export const CreateWorkspaceButton = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector(currentUserId);
  const navigate = useNavigate();
  const userData = useAppSelector(currentUserMetadata);

  // TODO: Remove this hardcoded user id, need to fetch user roles from the backend
  const showCreateWorkspaceButton = userData?.role === USER_ROLES.ADMIN;

  const parentModal = props?.parent;

  const reopenModal =
    parentModal === CATEGORY.Project
      ? ModalTypes.PROJECT_CREATION
      : ModalTypes.ANALYSIS_CREATION;

  const onAddClick = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKSPACE_CREATION,
        modalProps: {
          navigate,
          reopenModal,
        },
      })
    );
  };
  return (
    <chakraComponents.MenuList {...props}>
      {showCreateWorkspaceButton && (
        <Flex
          className="flex-row items-center w-full cursor-pointer py-3 px-3 text-gray-900"
          onClick={onAddClick}
        >
          <Icon className="mr-1.5" as={MdOutlineAddCircleOutline} size="md" />
          <Text className="font-normal tracking-tight">
            Create New Workspace
          </Text>
        </Flex>
      )}
      {children}
    </chakraComponents.MenuList>
  );
};
export const CreateProjectButton = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(currentUserMetadata);

  const showCreateProjectButton = userData?.role === USER_ROLES.ADMIN;

  const parentModal = props?.parent;
  const reopenModal =
    parentModal === CATEGORY.Analysis ? ModalTypes.ANALYSIS_CREATION : null;

  const onAddClick = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.PROJECT_CREATION,
        modalProps: {
          parentWsId: props?.ws.value,
          navigate,
          reopenModal: reopenModal,
        },
      })
    );
  };
  return (
    <chakraComponents.MenuList {...props}>
      {showCreateProjectButton && (
        <Flex
          className="flex-row items-center w-full cursor-pointer gap-2 py-3 px-3 text-gray-900"
          onClick={onAddClick}
        >
          <Icon as={MdOutlineAddCircleOutline} size="md" />
          <Text className="font-normal tracking-tight">Create New Project</Text>
        </Flex>
      )}
      {children}
    </chakraComponents.MenuList>
  );
};

export const MultiSelectionOption = ({ children, ...props }) => {
  return (
    <chakraComponents.Option {...props}>{children}</chakraComponents.Option>
  );
};

export const MultiValueContainer = ({ children, ...props }) => {
  return (
    <chakraComponents.MultiValueContainer
      data={props.data}
      innerProps={props.innerProps}
      selectProps={props.selectProps}
    >
      {props.selectProps.value.length > 1 ? (
        <>{children[0]},</>
      ) : (
        <>{children[0]}</>
      )}
    </chakraComponents.MultiValueContainer>
  );
};

export const MultiValue = ({ children, ...props }) => {
  return (
    <chakraComponents.MultiValue {...props}>
      {children}
    </chakraComponents.MultiValue>
  );
};

export const CustomOptions = ({ children, ...props }) => {
  return (
    <chakraComponents.Option
      {...props}
      className="flex-row-reverse justify-between max-w-full"
    >
      <Flex className="items-center gap-2 truncate max-w-full">
        <Tooltip
          className="w-full !text-xs"
          aria-label="option"
          label={children}
          openDelay={1000}
          placement="top"
        >
          <span className="truncate max-w-full">{children}</span>
        </Tooltip>
      </Flex>
    </chakraComponents.Option>
  );
};
export const ValueContainer = ({ children, ...props }) => {
  const value = props
    .getValue()
    .map((option) => option.label)
    .join(", ");
  const label = isEmpty(value) ? "No value selected" : value;

  return (
    <chakraComponents.ValueContainer
      {...props}
      className="flex-row  max-w-full truncate !flex-nowrap"
    >
      <Tooltip
        className="w-full !text-xs"
        aria-label={label}
        arrowSize={8}
        hasArrow
        label={label}
        openDelay={400}
        placement="right"
      >
        <Flex className="flex-row max-w-full gap-0.5 truncate text-ellipsis !flex-nowrap">
          {children}
        </Flex>
      </Tooltip>
    </chakraComponents.ValueContainer>
  );
};
/* eslint-enable */
