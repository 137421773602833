import { Box, Flex } from "@chakra-ui/react";
import { MdOutlineStore } from "react-icons/md";
import { MdsConversionPathRound } from "react-icons-with-materialsymbols/mds";

import { Button } from "@/design/components/button";
import { setShowFlowStore } from "@/features/workflow-studio";
import { useAppDispatch } from "@/reduxHooks.ts";

import { BottomBarItem } from "../bottom-bar-item";

import { FlowTabs } from "./flow-tabs";

const ViewFlows = () => {
  const dispatch = useAppDispatch();
  const showFlowStoreHandler = () => {
    dispatch(setShowFlowStore(true));
  };

  return (
    <BottomBarItem icon={<MdsConversionPathRound size={20} />} title={"Flows"}>
      <Flex
        direction={"column"}
        overflow="hidden"
        w={"100%"}
        minW={"25vw"}
        maxW={"50vw"}
        h={"max-content"}
        p={0}
        _focusVisible={{ outline: "none" }}
      >
        <FlowTabs />
        <Box className="w-full rounded-md border-t" p={1} bg="white">
          <Button
            className="w-full"
            size={"sm"}
            onClick={showFlowStoreHandler}
            rightIcon={<MdOutlineStore size={20} />}
          >
            Explore Flow Store
          </Button>
        </Box>
      </Flex>
    </BottomBarItem>
  );
};
export default ViewFlows;
