import { Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@/design/components/button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

const ConfirmationModal1: React.FC = () => {
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals,
  );
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
    modalProps.onClose?.();
  };

  const onPrimaryAction = () => {
    dispatch(closeModal());
    modalProps?.primaryAction();
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader>{modalProps.title}</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6">
          <Flex className="flex-col gap-y-4">
            <Text>{modalProps.description}</Text>
            {modalProps.subDescription && (
              <Text color="red.600">{modalProps.subDescription}</Text>
            )}
          </Flex>
        </ModalBody>
        {!modalProps.hideButtons && (
          <ModalFooter className="!px-6 mt-6 !flex !justify-between">
            <Button onClick={onClose} variant="ghost" colorScheme="secondary">
              Close
            </Button>
            <Button
              variant={modalProps.primaryButtonVariant ?? "solid"}
              colorScheme={modalProps.primaryButtonColorScheme ?? "customRed"}
              onClick={onPrimaryAction}
            >
              {modalProps.primaryButtonText ?? "Delete"}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal1;
