import { Flex, Text } from "@chakra-ui/react";
import { MdClear } from "react-icons/md";

import { ConfigurePanelHeader } from "@/design/components/configure-panel";
import { IconButton } from "@/design/components/icon-button";

interface LogsPanelHeaderProps {
  title?: string;
  onClose: () => void;
}

export const LogsPanelHeader = ({ title, onClose }: LogsPanelHeaderProps) => {
  return (
    <ConfigurePanelHeader p={4}>
      <Flex align="center" justify="space-between" w="100%">
        <Text className="text-lg font-semibold">
          {title ?? "Workflow"} Logs
        </Text>
        <IconButton
          variant="ghost"
          colorScheme="dark"
          aria-label="close panel"
          icon={<MdClear />}
          onClick={onClose}
        />
      </Flex>
    </ConfigurePanelHeader>
  );
};
