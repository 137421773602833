import "./index.css";
import "handsontable/dist/handsontable.full.min.css";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserTracing,
  init as SentryInit,
  reactRouterV6Instrumentation,
  ErrorBoundary as SentryErrorBoundary,
} from "@sentry/react";
import { registerAllModules } from "handsontable/registry";
import React from "react";
import ReactDOM from "react-dom/client";
import { hotjar } from "react-hotjar";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { getRegionFromUrl } from "@/utils/baseUrls.ts";

import App from "./App.tsx";
import { loginRequest, msalConfig } from "./auth/authConfig";
import neoTheme from "./design/theme";
import { worker } from "./mocks/browser.ts";
import { store, persistor } from "./store";
import { cacheUtil } from "./utils/localstorage.ts";

registerAllModules();
/*
if (process.env.NODE_ENV === "development") {
  worker
    .start({
      onUnhandledRequest: "bypass",
    })
    .catch((err) => {
      console.log(err);
    });
}
*/

if (import.meta.env.VITE_HOTJAR_ENABLED === "true") {
  hotjar.initialize({
    id: parseInt(import.meta.env.VITE_HOTJAR_ID as string, 10),
    sv: parseInt(import.meta.env.VITE_HOTJAR_VERSION as string, 10),
  });
}

SentryInit({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  normalizeDepth: 11,
  // Performance Monitoring
  tracesSampleRate: 0.2,
});
export const msalInstance = new PublicClientApplication(msalConfig);

const handleLoginRedirect = () => {
  cacheUtil.removeAll();
  msalInstance
    .loginRedirect({
      ...loginRequest,
      extraQueryParameters: { region: getRegionFromUrl() },
    })
    .catch((err) => console.log(err));
};

msalInstance
  .initialize()
  .then(() => {
    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      const account = msalInstance.getAllAccounts()[0];
      const tokenRequest = {
        scopes: loginRequest.scopes,
        loginHint: account.username,
        account,
      };

      msalInstance.setActiveAccount(account);
      msalInstance.acquireTokenSilent(tokenRequest);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    msalInstance
      .handleRedirectPromise()
      .then((_) => {
        // Check if user signed in
        const account = msalInstance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          handleLoginRedirect();
        }
      })
      .catch((err) => {
        handleLoginRedirect();
        // TODO: Handle errors
        console.log(err);
      });
  })
  .catch((err) => {
    console.log(err);
  });

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    </div>
  );
};

export const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <SentryErrorBoundary fallback={ErrorFallback}>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider cssVarsRoot="body" theme={neoTheme}>
              <App />
            </ChakraProvider>
          </PersistGate>
        </Provider>
      </MsalProvider>
    </SentryErrorBoundary>
  </React.StrictMode>
);
