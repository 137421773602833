import { Box, Flex, Spinner } from "@chakra-ui/react";
import { clsx } from "clsx";
import { useSelector } from "react-redux";

import {
  statusMessage,
  tableLoading,
  tableSizeMetaData,
} from "@/features/data-transformation";
import { TableMetaData } from "@/features/data-transformation/components/table-meta-data.tsx";

import { FilterRow } from "../filter-row";

import { CustomHotTable } from "./hot-table.tsx";

export const DataTransformationTable = () => {
  const isTableLoading = useSelector(tableLoading);
  const tableMetaData = useSelector(tableSizeMetaData);
  const loadingMessage = useSelector(statusMessage);

  return (
    <Flex className={clsx("flex-col w-full  bg-gray-50")}>
      <FilterRow />
      <Flex className="flex-col relative flex-1 w-full h-full">
        <CustomHotTable />
        {isTableLoading && (
          <Flex className="overlay absolute inset-0 flex justify-center items-center bg-transparent">
            <Box className="p-3 flex items-center gap-4 border bg-gray-50 rounded">
              <Spinner color="gray" />
              <Box>{loadingMessage ?? "Loading table.."}</Box>
            </Box>
          </Flex>
        )}
      </Flex>
      <Flex className="h-min w-full bg-white border-t border-t-gray-300 px-3 py-2">
        <Flex className="items-center">
          <TableMetaData subtitle="rows" title={tableMetaData.rows ?? "0"} />
          <Flex className="mx-2 h-5 w-[1px] bg-gray-300" />
          <TableMetaData
            subtitle="columns"
            title={tableMetaData.column ?? "0"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
