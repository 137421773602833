import { breadcrumbAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleLink = defineStyle({
  fill: "black.700",
  cursor: "pointer",
  color: "orange.500",
  paddingLeft: "2px",
  position: "relative",
  "+ .chakra-icon": {
    fill: "orange.500",
    height: "100%",
  },
  _focusVisible: {
    boxShadow: "outline",
  },
  _active: {
    backgroundColor: "orange.100",
    "~ .chakra-icon": {
      backgroundColor: "orange.100",
    },
  },
  _hover: {
    color: "orange.500",
    textDecoration: "underline",
    textDecorationThickness: "1.5px",
  },

  "&:not([aria-current=page])": {
    color: "black.700",
    _focusVisible: {
      boxShadow: "outline",
    },
    ":active": {
      backgroundColor: "black.300",
      "~ .chakra-icon": {
        backgroundColor: "black.300",
      },
    },
    "~ .chakra-icon": {
      fill: "black.700",
    },
    "~ span": {
      color: "black.700",
    },
    _hover: {
      color: "black.900",
      "~ .chakra-icon": {
        fill: "black.900",
      },
      "~ span": {
        color: "black.900",
      },
      "&::before": {
        backgroundColor: "black.900",
      },
    },
  },
});

const baseStyle = definePartsStyle({
  link: baseStyleLink,
  icon: defineStyle({
    // paddingRight: "2px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fill: "black.700",
    width: "20px",
    height: "auto",
  }),
  separator: defineStyle({
    order: -1,
  }),
});

export const breadcrumbTheme = defineMultiStyleConfig({
  baseStyle,
});
