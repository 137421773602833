// bottom bar item that takes icon and title as props

import {
  Box,
  Flex,
  ScaleFade,
  UseDisclosureProps,
  useDisclosure,
} from "@chakra-ui/react";
import clsx from "clsx";
import React, { useRef } from "react";

import { snakeCaseToCapital } from "@/utils/string-utils";

import { OpenStateProvider } from "../../hooks/useBottomBarContext";

type BottomBarItemProps = {
  icon?: React.ReactNode;
  title: string;
  children: React.ReactNode;
} & UseDisclosureProps;

export const BottomBarItem = ({
  icon,
  title,
  onClose,
  children,
}: BottomBarItemProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  const {
    isOpen,
    onOpen: openPopOver,
    onClose: closePopOver,
  } = useDisclosure();
  const handleButtonClick = () => {
    if (isOpen) {
      onClose?.();
      closePopOver();
    } else {
      openPopOver();
    }
    divRef.current!.focus();
  };

  const handleBlur = (event: React.FocusEvent) => {
    const relatedTarget = getRelatedTarget(event);
    const targetIsPopover = contains(divRef.current, relatedTarget);

    const isValidBlur = !targetIsPopover;

    if (isOpen && isValidBlur) {
      onClose?.();
      closePopOver();
    }
  };
  function contains(parent: HTMLElement | null, child: HTMLElement | null) {
    return parent === child || parent?.contains(child);
  }

  function getRelatedTarget(event: React.FocusEvent) {
    const activeEl = event.currentTarget.ownerDocument.activeElement;
    return (event.relatedTarget ?? activeEl) as HTMLElement | null;
  }

  return (
    <div ref={divRef} onBlur={handleBlur} tabIndex={0} className="relative">
      <Flex
        className="relative cursor-pointer group rounded h-fit w-fit gap-y-1"
        align="center"
        justify="space-around"
        direction="column"
        onClick={handleButtonClick}
        role="button"
      >
        <div
          className={clsx(
            `group-hover:bg-gray-100 h-10 w-10 flex justify-center items-center rounded`,
            isOpen && "bg-gray-100"
          )}
        >
          {icon}
        </div>
        <span
          className={clsx(
            "absolute left-1/2 top-4 h-fit w-fit -translate-x-1/2 rotate-90 opacity-0 transition-all group-hover:-top-5 group-hover:opacity-100",
            isOpen && "!-top-5 opacity-100"
          )}
        >
          &#10094;
        </span>
        <div className="text-xs font-medium whitespace-pre text-gray-700 px-2">
          {snakeCaseToCapital(title)}
        </div>
      </Flex>
      {isOpen && (
        <ScaleFade
          className={`bottom-[140%] !left-1/2 !-translate-x-1/2 h-fit w-fit absolute`}
          in={isOpen}
        >
          <div className="bg-white h-2 w-2 rotate-45 border-r border-b border-gray-600 absolute -bottom-1 left-1/2"></div>
          <Box className="overflow-hidden h-max w-max bg-white border border-gray-600 rounded">
            <OpenStateProvider value={{ isOpen, openPopOver, closePopOver }}>
              {children}
            </OpenStateProvider>
          </Box>
        </ScaleFade>
      )}
    </div>
  );
};
