import { Button, Flex, Switch, Text } from "@chakra-ui/react";
import { AxiosError } from "axios";
import clsx from "clsx";
import { every, isEmpty, isEqual } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { MdsSyncRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuList } from "@/design/components/menu";
import {
  ACTIVE_REQUEST_TYPE,
  activeRequests,
  EdaMetaDataContext,
  executeInProgress,
  FETCH_TYPE,
  FULL_DATA_SAMPLING,
  ISampleFailedTaskData,
  ISamplingParameters,
  ISamplingTechnique,
  ISamplingTechniquesResponse,
  isStepsFetched,
  ITableDataColumns,
  ITaskData,
  IUpdateSamplingRequest,
  NUMBER_OF_RECORDS_SHORT_NAME,
  RANDOM_DATA_SAMPLING,
  saveEditCalls,
  setCurrentSampling,
  setEdaAccessMode,
  START_PARAMETER_SHORT_NAME,
  triggerFetch,
  useGetRequestStatusQuery,
  useLazyGetSamplingQuery,
  useUpdateSamplingMutation,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { useOnClickOutside } from "@/hooks/useOutsideClick.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { POLLING_STATUS } from "@/utils/enums.ts";
import { formatNumberWithCommas } from "@/utils/string-utils.ts";

import { FilterDataSubmenu } from "./filter-data-submenu.tsx";
import { FilterSubmenuContext } from "./filter-submenu-context.ts";

const POLLING_INTERVAL = 500;

export const FilterRowDropdown = () => {
  const dispatch = useDispatch();
  const metaData = useContext(EdaMetaDataContext);
  const toast = useShowToast(undefined, undefined, true);
  const isSavedStepsFetched = useSelector(isStepsFetched);
  const unsavedChanges = useSelector(saveEditCalls);
  const isExecuting = useSelector(executeInProgress);
  const activeRequestForEda = useSelector(activeRequests);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [canUseFullData, setCanUseFullData] = useState<boolean>(true);
  const [loadFullDataActive, setLoadFullDataActive] = useState<boolean>(false);
  const [loadFullDataToggle, setLoadFullDataToggle] = useState<boolean>(false);
  const [requestId, setSampleRequestId] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<string>("0");
  const [sampleRecords, setSampleRecords] = useState<string>("0");
  const [error, setError] = useState<string>("");
  const [sampling, setSampling] = useState<ISamplingTechniquesResponse | null>(
    null
  );

  const [updateSampleEnabled, setUpdateSampleEnabled] =
    useState<boolean>(false);

  const [selectedSamplingMethod, setSelectedSamplingMethod] =
    useState<ISamplingTechnique | null>(null);

  const [currentParameters, setCurrentParameters] = useState<
    ISamplingParameters[] | null
  >(null);

  const [numberOfRows, setNumberOfRows] = useState<string>();
  const [selectedColumn, setSelectedColumn] =
    useState<Partial<ITableDataColumns> | null>(null);

  useOnClickOutside({}, dropdownRef, setMenuOpen.bind(null, false));

  const [trigger] = useLazyGetSamplingQuery();

  const { data: runStatusData } = useGetRequestStatusQuery(
    {
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      requestId: requestId!,
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: requestId == null,
    }
  );
  const [updateSample, { isLoading }] = useUpdateSamplingMutation();

  const isCurrentRandomRecord = useMemo(() => {
    const _currentSampling = sampling?.currentSampling;
    return _currentSampling?.shortName === RANDOM_DATA_SAMPLING;
  }, [sampling]);

  useEffect(() => {
    if (metaData.edaId && isSavedStepsFetched) getSampling();
  }, [metaData.edaId, isSavedStepsFetched]);

  useEffect(() => {
    if (isEmpty(activeRequestForEda)) return;

    const samplingRequest = activeRequestForEda?.find(
      (request) => request.requestType === ACTIVE_REQUEST_TYPE.SAMPLING
    );

    if (!samplingRequest) return;
    setSampleRequestId(samplingRequest.id);
  }, [activeRequestForEda]);

  /*
   * Sets the current sampling technique if no sampling technique is selected
   */
  useEffect(() => {
    if (!sampling) return;

    checkFullData();

    const _sample = sampling.currentSampling ?? {};

    if (isEmpty(_sample)) {
      updateSampling();
    } else {
      dispatch(setCurrentSampling(sampling.currentSampling));
    }
  }, [sampling]);

  useEffect(() => {
    const data = runStatusData?.response.data;
    const inProgress = data?.taskStatus === POLLING_STATUS.IN_PROGRESS;
    const completed = data?.taskStatus === POLLING_STATUS.COMPLETED;
    const isFailed = data?.taskStatus === POLLING_STATUS.FAILED;

    if (!inProgress) {
      setSampleRequestId(null);
    }

    if (isFailed) {
      const _data = runStatusData?.response.data!
        .taskData as ISampleFailedTaskData;
      setError(
        _data?.error?.message ?? "Max row count exceeded for this dataset"
      );
      return;
    }

    if (completed) {
      onTaskSuccess();
    }
  }, [runStatusData]);

  useEffect(() => {
    if (!menuOpen) {
      setSelectedSamplingMethod(sampling?.currentSampling ?? null);
      setLoadFullDataToggle(loadFullDataActive);
    }
  }, [menuOpen]);

  useEffect(() => {
    const defaultRows = getDefaultRows();
    const isUnChanged = isSamplingMethodUnchanged();
    if (isUnChanged && !isCurrentRandomRecord && defaultRows === numberOfRows)
      return;

    onSamplingChange(selectedSamplingMethod, numberOfRows, selectedColumn);
  }, [numberOfRows]);

  useEffect(() => {
    const rows = getDefaultRows();
    setNumberOfRows(isEmpty(numberOfRows) ? rows : numberOfRows);

    const isUnChanged = isSamplingMethodUnchanged();

    if (isUnChanged && !isCurrentRandomRecord) {
      setTimeout(() => {
        setUpdateSampleEnabled(false);
      }, 0);
    } else {
      validateParameters(currentParameters ?? []);
    }
  }, [selectedSamplingMethod]);

  const isSamplingMethodUnchanged = () => {
    const _currentSampling = sampling?.currentSampling;
    const isUnChanged = isEqual(selectedSamplingMethod, _currentSampling);
    return isUnChanged;
  };

  const getDefaultRows = () => {
    return (
      sampling?.currentSampling?.parameters
        ?.find((sample) => sample.shortName === NUMBER_OF_RECORDS_SHORT_NAME)
        ?.value.toString() ?? ""
    );
  };

  const updateSampling = async (data?: IUpdateSamplingRequest) => {
    try {
      const res = await updateSample({
        ...(data ?? {}),
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId!,
      }).unwrap();

      const _data = res.response.data;

      const _requestId = _data?.requestId ?? null;
      setSampleRequestId(_requestId);

      const accessMode = _data?.edaAccessMode ?? null;
      dispatch(setEdaAccessMode(accessMode));
    } catch (e) {
      console.log(e);
      if ((e as AxiosError).status === 403) {
        metaData?.closeEda?.();
      }
    }
  };

  const getSampling = async () => {
    const res = await trigger({
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
    }).unwrap();

    const _data = res.response.data as ISamplingTechniquesResponse;

    setSampling(_data);
    if (!isEmpty(_data.currentSampling ?? {})) {
      setTotalRecords(_data?.totalRecords ?? "0");
      setSampleRecords(_data?.sampleRecords ?? "0");
      setCanUseFullData(_data.canUseFullData);
      setSelectedSamplingMethod(_data.currentSampling);

      dispatch(setCurrentSampling(res.response.data!.currentSampling));
      dispatch(triggerFetch(FETCH_TYPE.TABLE));
    }
  };

  const showToast = (_samplingShortName?: string) => {
    if (_samplingShortName === FULL_DATA_SAMPLING) {
      toast({ ...TOAST_MESSAGES.sampleFulLData });
    } else {
      toast({ ...TOAST_MESSAGES.sampleUpdated });
    }
  };

  const onTaskSuccess = () => {
    const accessMode = runStatusData?.response.data?.edaAccessMode ?? null;
    const data = runStatusData?.response.data!.taskData as ITaskData;
    const _totalRecords = data.totalRecords ?? "0";
    const _currSampling = {
      ...(sampling?.currentSampling ?? {}),
      ...data.currentSampling,
    };

    showToast(data.currentSampling?.shortName);

    checkFullData(_currSampling);

    setTotalRecords(_totalRecords);
    setSampleRecords(data.sampleRecords ?? "0");

    dispatch(setCurrentSampling(_currSampling));
    dispatch(setEdaAccessMode(accessMode));
    dispatch(triggerFetch(FETCH_TYPE.TABLE));

    setSelectedSamplingMethod(_currSampling);

    const label = sampling?.samplingTechniques?.find(
      (technique) => technique.shortName === _currSampling.shortName
    )?.label;

    // Hack since label are not being returned on update
    const updatedSampling = {
      ...sampling,
      currentSampling: { ..._currSampling, label: label },
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSampling(updatedSampling);
    setSampleRequestId(null);
    setMenuOpen(false);
  };

  const checkFullData = (passedSampling?: ISamplingTechnique) => {
    const _sample = passedSampling ?? sampling!.currentSampling;

    if (isEmpty(_sample)) {
      setLoadFullDataActive(false);
      setLoadFullDataToggle(false);
      return;
    }

    if (_sample.shortName === FULL_DATA_SAMPLING) {
      setLoadFullDataActive(true);
      setLoadFullDataToggle(true);
    } else {
      setLoadFullDataActive(false);
      setLoadFullDataToggle(false);
      setSelectedSamplingMethod(_sample);
    }
  };

  const toggleFullData = () => {
    const selectFullData = !loadFullDataToggle;

    let _sampling;

    if (selectFullData) {
      _sampling = sampling!.samplingTechniques.find(
        (technique) => technique.shortName === FULL_DATA_SAMPLING
      )!;
    } else {
      const isFullDataCurrent =
        sampling!.currentSampling.shortName === FULL_DATA_SAMPLING;

      _sampling = isFullDataCurrent
        ? sampling!.samplingTechniques[1]
        : sampling!.currentSampling;

      onSamplingChange(_sampling, numberOfRows, selectedColumn);
    }

    setSelectedSamplingMethod(_sampling);
    setLoadFullDataToggle(selectFullData);
  };

  const validateParameters = (parameters: ISamplingParameters[]) => {
    const _isCurrentRandomRecord =
      selectedSamplingMethod?.shortName === RANDOM_DATA_SAMPLING;

    if (_isCurrentRandomRecord && numberOfRows !== "") {
      setUpdateSampleEnabled(true);
      return;
    }

    const isValid = every(parameters, (parameter) => parameter.value !== "");
    if (isValid) {
      setUpdateSampleEnabled(true);
    } else {
      setUpdateSampleEnabled(false);
    }
  };

  const onSamplingChange = (
    method: ISamplingTechnique | null,
    _samplingNumber: string | undefined,
    colum: Partial<ITableDataColumns> | null
  ) => {
    if (method === null) return;

    //TODO: Make this totally Dynamic, dont hardcheck STRATIFIED_SAMPLING
    const parameters = method.parameters.map((param: { shortName: string }) => {
      let _parameter = {
        ...param,
        value: _samplingNumber ?? "",
      };

      if (param.shortName == START_PARAMETER_SHORT_NAME) {
        _parameter = {
          ...param,
          value: colum?.name ?? "",
        };
      }
      return _parameter;
    });

    validateParameters(parameters);

    setCurrentParameters(parameters);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectedSamplingMethod(method);
  };

  const isCorrectSamplingSize = () => {
    if (loadFullDataToggle) return true;
    const maximumRecords = Number(totalRecords);
    const currentRecords = Number(numberOfRows);
    const isLessThanMax = currentRecords <= maximumRecords;
    const isGreaterThanZero = currentRecords > 0;

    return isLessThanMax && isGreaterThanZero;
  };

  const submitUpdateSample = () => {
    const isValid = every(
      currentParameters,
      (parameter) => parameter.value !== ""
    );
    const _currentParameters = currentParameters?.map((param) => {
      if (param.shortName == NUMBER_OF_RECORDS_SHORT_NAME) {
        return { ...param, value: Number(param.value) };
      }
      return param;
    });
    if (!isValid) return;
    const checkSize = isCorrectSamplingSize();
    if (!checkSize) {
      setError(
        `Number of rows should be greater than 0 not more than total rows (${totalRecords})`
      );
      return;
    }

    const updateSamplingFn = updateSampling.bind(null, {
      shortName: selectedSamplingMethod!.shortName,
      parameters: _currentParameters ?? [],
      useFullData: false,
    });

    if (unsavedChanges > 0) {
      dispatch(
        openModal({
          modalType: ModalTypes.SAVE_STEPS,
          modalProps: {
            onUpdate: updateSamplingFn,
            edaId: metaData.edaId!,
            analysisId: metaData.analysisId!,
            saveEda: metaData.saveEda,
          },
        })
      );
      return;
    }

    updateSamplingFn();
  };

  const { hasWriteAccess } = useReadOnlyMode();
  const canUpdate = hasWriteAccess && !isExecuting;

  return (
    <Menu isOpen={menuOpen}>
      <MenuButton
        className={clsx(
          "hover:bg-gray-200 rounded h-fit  !flex-shrink-0 w-fit",
          menuOpen && "bg-gray-200"
        )}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <Flex className="flex flex-row w-full items-center rounded-md cursor-pointer text-xs font-medium py-1.5 px-2">
          {loadFullDataActive ? (
            <>
              Full Data &nbsp;
              <Text className="font-bold">
                ({formatNumberWithCommas(totalRecords)} rows)
              </Text>
            </>
          ) : (
            <>
              Sample of &nbsp;{" "}
              <Text className="font-bold">
                {formatNumberWithCommas(sampleRecords)} &nbsp;
              </Text>
              out of &nbsp;{" "}
              <Text className="font-bold">
                {formatNumberWithCommas(totalRecords)}
              </Text>
            </>
          )}

          <Icon
            as={TbChevronDown}
            size="xs"
            className="ml-0.5 stroke-gray-900"
          />
        </Flex>
      </MenuButton>

      <MenuList
        className="relative  !border-gray-200"
        zIndex={999}
        ref={dropdownRef}
      >
        <Flex className="absolute h-3 w-3 bg-white border-t border-l border-gray-200 right-2 -top-1.5 rounded-[2px] rotate-45" />
        <Flex className="flex-col p-4 max-w-[320px] w-full">
          <Flex className="flex-row w-full justify-between items-center px-3.5 p y-2.5">
            <Text
              className={clsx(
                "text-sm text-gray-1000 font-medium",
                !canUseFullData && "text-gray-600"
              )}
            >
              Load Full Data
            </Text>
            <Switch
              id="load-full-data"
              isChecked={loadFullDataToggle}
              isDisabled={!canUseFullData}
              onChange={toggleFullData}
            />
          </Flex>
          <Text className="text-xm text-gray-700 leading-[140%] mt-1.5 px-3">
            {canUseFullData
              ? "Loading full data for large datasets can lead to performance issues"
              : "Dataset size is too big for full data to be loaded safely"}
          </Text>
          <FilterSubmenuContext.Provider
            value={{
              isOpen: !loadFullDataToggle,
              sampling,
              //eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              selectedSamplingMethod,
              onSamplingChange,
              setNumberOfRows,
              numberOfRows,
              selectedColumn,
              setSelectedColumn,
              error,
              setError,
            }}
          >
            <FilterDataSubmenu />
          </FilterSubmenuContext.Provider>
          <Flex className="flex-col w-full items-end mt-5">
            <Button
              className="!rounded-sm w-fit"
              colorScheme="primary"
              isDisabled={!canUpdate || !updateSampleEnabled}
              isLoading={isLoading || !!requestId}
              onClick={submitUpdateSample}
              rightIcon={<Icon as={MdsSyncRound} />}
              size="sm"
              variant="solid"
            >
              {loadFullDataToggle ? "Update Data" : "Update Sample"}
            </Button>
          </Flex>
        </Flex>
      </MenuList>
    </Menu>
  );
};
