import clsx from "clsx";
import { Position } from "reactflow";

import { CustomHandle } from "./custom-handle";

export const NodeHandles = ({ isConnectable }: { isConnectable: boolean }) => {
  return (
    <>
      <CustomHandle
        type="target"
        classNames={clsx("invisible group-hover/groupCard:visible")}
        position={Position.Left}
        id="b"
        isConnectable={isConnectable}
      />
      {/* <CustomHandle
        type="target"
        classNames={clsx("invisible group-hover/groupCard:visible")}
        position={Position.Bottom}
        id="c"
        isConnectable={isConnectable}
      />
      <CustomHandle
        type="target"
        classNames={clsx("invisible group-hover/groupCard:visible")}
        position={Position.Top}
        id="d"
        isConnectable={isConnectable}
      /> */}
      <CustomHandle
        id="a"
        type="source"
        classNames={clsx("invisible group-hover/groupCard:visible")}
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </>
  );
};
