import { Spinner, Flex, Text, Tooltip } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast } from "@/components/toast";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import {
  EXPLORE_STATS_TYPE,
  getExploreStats,
  IExploreTaskResponse,
  seperateFilters,
  tableColumns,
  useGetDataStatsMutation,
  viewFilters,
} from "@/features/data-transformation";
import { NullValuesRow } from "@/features/data-transformation/components/explore-data/null-values-row.tsx";
import { ValueInfoBar } from "@/features/data-transformation/components/value-info-bar.tsx";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

import { selectedColumn, tableSizeMetaData } from "../../redux/index.ts";

import { ChartBase } from "./charts/charts-base.tsx";
import { StatsDetails } from "./details.tsx";
import { ExploreDropdown } from "./explore-dropdown.tsx";

export const ExploreDataModal: React.FC = () => {
  const dispatch = useDispatch();
  const colInfo = useSelector(selectedColumn);
  const columns = useSelector(tableColumns);
  const tableMetaData = useSelector(tableSizeMetaData);
  const filters = useSelector(viewFilters);

  const toast = useShowToast(undefined, undefined, true);

  const [exploreType, setExploreType] = useState(EXPLORE_STATS_TYPE.SAMPLE);
  const [isRequesting, setIsRequesting] = useState(false);

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );
  const metaData = modalProps.metaData;

  const [fetchStatsApi, { data: fullData }] = useGetDataStatsMutation();

  const fetchStats = async () => {
    try {
      setIsRequesting(true);
      await fetchStatsApi({
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId!,
        column: colInfo!.column.name,
        viewFilters: seperateFilters(filters),
        completeDataInfo: true,
        columnType: columns[Number(colInfo!.index)].dataType,
      }).unwrap();
    } catch (error: any) {
      if (error?.status === 409)
        dispatch(
          openModal({
            modalType: ModalTypes.EXECUTION_REQUIRED,
            modalProps: modalProps,
          })
        );
      else if (error?.data.message) {
        toast({
          title: error?.data.message as string,
          status: "error",
        });
      }

      setExploreType(EXPLORE_STATS_TYPE.SAMPLE);
    } finally {
      setIsRequesting(false);
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  const onExploreChange = (type: EXPLORE_STATS_TYPE) => {
    setExploreType(type);
    if (type === EXPLORE_STATS_TYPE.FULL) fetchStats();
  };

  const data: IExploreTaskResponse = useMemo(() => {
    if (exploreType === EXPLORE_STATS_TYPE.SAMPLE) {
      return modalProps.data;
    }
    const _data = fullData?.response.data as IExploreTaskResponse;
    if (!isEmpty(_data)) {
      return _data;
    }
    return modalProps.data;
  }, [modalProps.data, exploreType, fullData]);

  const { columnData, nullPercentage, nullValues } = useMemo(() => {
    return getExploreStats(colInfo, tableMetaData, data);
  }, [colInfo, tableMetaData?.rows, data]);

  const isLoading = isRequesting;

  return (
    <Modal isOpen={true} size="3xl" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!pb-0 !max-h-[60vh] !min-h-[50vh]">
        <ModalHeader className="flex flex-row items-center !truncate !max-w-full">
          Explore Data&nbsp; ("{" "}
          <Tooltip label={colInfo?.column.name}>
            <Text className="!truncate !max-w-[240px]">
              {colInfo?.column.name}
            </Text>
          </Tooltip>
          ")
          <ExploreDropdown
            onChange={onExploreChange}
            exploreType={exploreType}
            disabled={isLoading}
          />
        </ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!flex-row !flex !p-0 !border-t !border-t-gray-100 overflow-auto">
          {!isLoading && (
            <>
              <Flex className="flex-col w-[240px] p-5">
                <Flex className="relative w-full">
                  {columnData && (
                    <ValueInfoBar
                      data={columnData}
                      className="h-[6px] hover:h-[6px]"
                    />
                  )}
                </Flex>

                <NullValuesRow
                  nullPercentage={nullPercentage}
                  nullValues={nullValues}
                />
                <StatsDetails data={data} />
              </Flex>
              <ChartBase data={data} />
            </>
          )}
          {isLoading && (
            <Flex className="flex-col w-full h-full m-auto items-center justify-center">
              <Spinner />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
