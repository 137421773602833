import { Flex, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { IoIosAddCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import { twMerge } from "tailwind-merge";

import { Icon } from "@/design/components/icon";
import { setTransformationPanel } from "@/features/data-transformation";

export const AddNewStep = ({
  isOpen,
  isFixed,
}: {
  isOpen: boolean;
  isFixed?: boolean;
}) => {
  const dispatch = useDispatch();

  return (
    <Flex
      className={twMerge(
        clsx(
          "items-center gap-x-1.5 px-3 py-2 max-w-full tracking-wider cursor-pointer group hover:text-r transition-all duration-300 ease-in-out",
          isOpen ? "justify-start" : "justify-center",
          isFixed && "py-2 px-3"
        )
      )}
      onClick={() => dispatch(setTransformationPanel(true))}
    >
      <Icon
        as={IoIosAddCircle}
        size="md"
        className="text-gray-800 group-hover:text-orange-400"
      />
      {isOpen && (
        <Text
          className={clsx(
            "text-[13px] font-semibold max-w-full truncate group-hover:text-orange-400"
          )}
          as={motion.span}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 1 }}
        >
          ADD NEW STEP
        </Text>
      )}
    </Flex>
  );
};
