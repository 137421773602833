import { Box, Collapse, Slide } from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import {
  setShowFlowStore,
  showFlowStore,
} from "../../redux/workflow-studio-slice";

import { FlowStoreCard } from "./flow-store-card.tsx";

export const FlowStore = () => {
  const isFlowStoreOpen = useAppSelector(showFlowStore);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setShowFlowStore(false));
    };
  }, [dispatch]);

  return (
    // <Slide
    //   className="!absolute !z-[999] top-0 left-0 w-full bg-white"
    //   direction="bottom"
    //   in={isFlowStoreOpen}
    //   unmountOnExit
    // >
    <Box
      className={clsx(
        "transition-all absolute z-[4] bottom-0 left-0 w-full bg-white",
        isFlowStoreOpen ? "h-full" : "h-0"
      )}
      zIndex={999}
    >
      {isFlowStoreOpen && <FlowStoreCard />}
    </Box>
    // </Slide>
  );
};
