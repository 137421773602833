import { Box, Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ConfigurePanelBody } from "@/design/components/configure-panel";

import { NodeParameter, NodeType } from "../../types";
import { PARAMETER_TYPE } from "../../utils/constants";

import { DateConfig } from "./config-forms/date-config";
import MergeConfig from "./config-forms/merge-config";
import { MultiSelectConfig } from "./config-forms/multiselect-config";
import { RangeConfig } from "./config-forms/range-config";
import { SourceNodeConfig } from "./config-forms/source-config";
import { TextConfig } from "./config-forms/text-config";
import { validateParameter } from "./config-utils";

export const NodeConfigPanelBody = ({
  node,
  parameters,
  setParameters,
}: {
  node: NodeType;
  parameters: NodeParameter[];
  setParameters: React.Dispatch<
    React.SetStateAction<NodeParameter[] | undefined>
  >;
}) => {
  const isMergeNode = node.displayName.includes("Merge") ?? false;

  const ComponentMap = useMemo(() => {
    const updateParameter = (paramId: string, value: any, error: any) => {
      const newParams = parameters.map((p) => {
        if (p.parameterId === paramId) {
          const { errors } = validateParameter({ ...p, value });
          return {
            ...p,
            value: value,
            errors: errors,
          };
        }
        return p;
      });
      setParameters(newParams);
    };
    return (param: NodeParameter) => {
      if (param.deltatablePath) {
        return <SourceNodeConfig param={param} />;
      }
      switch (param.dataType) {
        case PARAMETER_TYPE.RANGE:
          return (
            <RangeConfig param={param} updateParameter={updateParameter} />
          );
        case PARAMETER_TYPE.DROPDOWN:
          return (
            <MultiSelectConfig
              param={param}
              updateParameter={updateParameter}
            />
          );
        case PARAMETER_TYPE.TEXT:
          return <TextConfig param={param} updateParameter={updateParameter} />;
        case PARAMETER_TYPE.DATE:
          return <DateConfig param={param} updateParameter={updateParameter} />;
        default:
          return <TextConfig param={param} updateParameter={updateParameter} />;
      }
    };
  }, [parameters, setParameters]);
  return (
    <ConfigurePanelBody>
      {parameters.length === 0 ? (
        <Box className="w-full" textAlign="center">
          There are no parameters to configure.
        </Box>
      ) : (
        <Flex className="w-full" direction={"column"} gap={3}>
          {isMergeNode && <MergeConfig />}
          {parameters.map((param, idx) => {
            return (
              <Flex className="w-full" key={idx} direction={"column"}>
                {ComponentMap(param)}
              </Flex>
            );
          })}
        </Flex>
      )}
    </ConfigurePanelBody>
  );
};
