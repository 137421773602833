import { useRef } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice";

import WorkspaceForm from "./workspace-form.tsx";

const WorkspaceCreationModal = (data: any) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const onClose = () => {
    dispatch(closeModal());
  };

  const title = data?.data ? "Edit Workspace" : "Create New Workspace";
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      size={"xl"}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader ref={ref}>{title}</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="px-6">
          <WorkspaceForm ref={ref} data={data} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WorkspaceCreationModal;
