import { useMsal } from "@azure/msal-react";
import { Box, Button, Flex } from "@chakra-ui/react";

import { cacheUtil } from "@/utils/localstorage";

export const ErrorComponent = ({ error }: { error: string }) => {
  const { instance } = useMsal();
  const onLoginRedirect = () => {
    cacheUtil.removeAll();
    instance
      .logout()
      .then(() => {
        window.location.href = window.location.origin;
      })
      .catch((e) => console.log(e));
  };
  return (
    <Flex className="w-full h-screen flex-col items-center justify-center">
      <Box className="p-3 font-medium text-lg text-center whitespace-pre-wrap">
        {error}
      </Box>
      <Button color="red.600" onClick={onLoginRedirect} variant={"outline"}>
        Back to Login
      </Button>
    </Flex>
  );
};
