import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
  mb: "0.125rem",
  ml: "0.125rem",
  padding: "var(--chakra-space-0) 0.375rem;",
  color: "var(--chakra-colors-black-800)",
  _invalid: {
    color: "var(--chakra-colors-red-600)",
  },
});

export const formLabelTheme = defineStyleConfig({
  baseStyle,
});
