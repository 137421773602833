import { IconProps } from "@chakra-ui/react";
import { cloneElement } from "react";
import {
  MdDashboard,
  MdFolder,
  MdInsertDriveFile,
  MdNote,
  MdStoreMallDirectory,
} from "react-icons/md";

import { Icon } from "@/design/components/icon";
import { CATEGORY } from "@/utils/enums.ts";

const getIcon = (type: string, color?: string) => {
  switch (type) {
    case CATEGORY.Workspace:
      return (
        <Icon
          size="lg"
          zIndex={0}
          as={MdStoreMallDirectory}
          color={color ?? "gray.700"}
        />
      );
    case CATEGORY.Project:
      return (
        <Icon size="lg" zIndex={0} as={MdFolder} color={color ?? "gray.700"} />
      );
    case CATEGORY.Analysis:
      return (
        <Icon
          size="lg"
          as={MdNote}
          className="rotate-90 scale-x-[-1]"
          color={color ?? "gray.700"}
          zIndex={0}
        />
      );
    case CATEGORY.Dashboard:
      return (
        <Icon
          size="lg"
          as={MdDashboard}
          zIndex={0}
          color={color ?? "gray.700"}
        />
      );
    default:
      return (
        <Icon
          size="lg"
          zIndex={0}
          as={MdInsertDriveFile}
          color={color ?? "gray.600"}
        />
      );
  }
};

const CustomIcon = (props: IconProps & { type: string; color: string }) => {
  const { type, color, ...rest } = props;
  return cloneElement(getIcon(type, color), { ...rest });
};
export { CustomIcon };
