import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  "item",
  "itemContainer",
  "icon",
  "label",
  "labelHeader",
  "container",
  "headerIcon",
  "header",
  "body",
  "switcher",
  "subitem",
  "subitemContainer",
  "subitemLabel",
  "subitemIcon",
]);

export const navigationPanelTheme = helpers.defineMultiStyleConfig({
  defaultProps: {
    variant: "studio",
    colorScheme: "dark",
  },
  variants: {
    manager: ({ colorScheme }) => ({
      item: {
        _selected: {
          div: {
            background:
              colorScheme == "dark"
                ? "var(--chakra-colors-whiteAlpha-200)"
                : "orange.50",
          },
        },
      },
    }),
    studio: {
      item: {
        _selected: {
          "::before": {
            position: "absolute",
            top: "0.875rem",
            content: "''",
            left: "-10px",
            height: "1.5rem",
            width: "0.25rem",
            borderRadius: "0rem 0.9375rem 0.9375rem 0rem ",
            background: "var(--chakra-colors-orange-400)",
          },
          _expanded: {
            "::before": {
              width: "0.25rem",
              height: "2.25rem",
              top: "0.5rem",
            },
          },
        },
      },
    },
  },
  baseStyle: ({ colorScheme }) => ({
    labelHeader: {
      display: "flex",
      padding: "0.5rem",
      alignItems: "flex-start",
      color: "black.600",
      fontWeight: "400",
      lineHeight: "175%",
      fontSize: "0.875rem",
      letterSpacing: "-0.0175rem",
    },
    container: {
      borderRight:
        colorScheme === "dark"
          ? "1px solid var(--chakra-colors-black-1000)"
          : "1px solid var(--chakra-colors-gray-300)",
      background: colorScheme === "dark" ? "black.1000" : "white",
      display: "flex",
      width: "4rem",
      height: "100%",
      paddingBottom: "0px",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "var(--chakra-space-0)",
      flexShrink: 0,
      flex: "1",
      flexBasis: "4rem",
      transition: "ease-out 0.2s",
      _expanded: {
        flexBasis: "17.5rem",
        width: "17.5rem",
        transition: "ease-out 0.25s",
      },
    },
    header: {
      display: "flex",
      height: "4rem",
      width: "100%",
      padding: "var(--chakra-space-0, 0rem)",
      justifyContent: "center",
      alignItems: "stretch",
      gap: "0.375rem",
      flexShrink: 0,
      alignSelf: "center",
      background: "transparent",
    },
    headerIcon: {
      display: "flex",
      height: "3.5rem",
      width: "3.5rem",
      borderRadius: "3.5rem",
      padding: "var(--chakra-space-0, 0rem)",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.375rem",
      flexShrink: 0,
      alignSelf: "center",
      background: "var(--chakra-colors-whiteAlpha-50)",
      transition: "opacity 0.2s ease-in-out",
      _expanded: {
        padding: "var(--chakra-space-0, 0rem)",
        borderRadius: "0px",
        height: "100%",
        width: "100%",
      },
    },
    item: {
      padding: "2px 0px",
      position: "relative",
      display: "flex",
      width: "100%",
      overflow: "hidden",
      flexDirection: "row",
      alignItems: "flex-start",
      gap: "spacing.0",
      div: {
        _expanded: {
          svg: {
            stroke: "white",
          },
        },
        _selected: {
          background: colorScheme === "dark" ? "whiteAlpha.200" : "orange.50",
          color: colorScheme === "dark" ? "white" : "orange.600",
          _hover: {
            background: colorScheme === "dark" ? "whiteAlpha.200" : "orange.50",
          },
        },
        _hover: {
          background: colorScheme === "dark" ? "whiteAlpha.200" : "gray.50",
          ":nth-of-type(2) ": {
            color: "var(--chakra-colors-whiteAlpha-600) !important",
          },
          "last-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
        },
        _active: {
          background: colorScheme === "dark" ? "whiteAlpha.200" : "orange.50",
          ":nth-of-type(1)": {
            color:
              colorScheme === "dark"
                ? "whiteAlpha.600 !important"
                : "orange.600",
          },
          "first-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
          "last-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
        },
        _focus: {
          borderRadius: "0.3125rem",
          // outline: "3.008px solid var(--chakra-colors-whiteAlpha-300)",
          background: colorScheme === "dark" ? "whiteAlpha.200" : "orange.50",
          "last-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
        },
      },
    },
    itemContainer: {
      display: "flex",
      height: "3rem",
      padding: "0.75rem 0.5rem",
      fontWeight: "500",
      alignItems: "center",
      gap: "0.5rem",
      flex: "1 0 0",
      borderRadius: "0.1875rem",
      color: colorScheme === "dark" ? "white" : "gray.900",
      background: "transparent",
      justifyContent: "center",
      maxWidth: "100%",
      width: "100%",
      overflow: "hidden",
    },
    icon: {
      display: "flex",
      flexShrink: 0,
      alignItems: "center",
      svg: {
        fill: "auto",
      },
    },
    label: {
      color: "inherit",
      overflow: "hidden",
      display: "none",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "100%",
      fontSize: "sm",
      letterSpacing: "-0.015rem",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      _expanded: {
        display: "block",
        width: "100%",
        flex: "1 0 0",
        textAlign: "start",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
    body: {
      display: "flex,",
      flex: "1 0 0",
      flexDirection: "column",
      padding: "1.25rem 0.75rem",
      alignItems: "flex-start",
      gap: "1.5rem",
      alignSelf: "stretch",
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      button: {
        _focusVisible: {
          outline: "auto 3.008px",
          outlineColor: "whiteAlpha.300",
        },
        div: {
          _focusVisible: {
            outline: "auto 3.008px",
            outlineColor: "whiteAlpha.300",
          },
        },
      },
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "1.25rem 0.75rem",
    },
    switcher: {
      display: "flex",
      height: "3.5rem",
      width: "3.5rem",
      borderRadius: "3.5rem",
      padding: "var(--chakra-space-0, 0rem)",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.375rem",
      flexShrink: 0,
      alignSelf: "center",
      transition: "0.2s linear",
      color: "white",
      svg: {
        stroke: "white",
      },
      _expanded: {
        alignSelf: "flex-end",
        transform: "rotate(180deg)",
      },
      _hover: {
        background: "var(--chakra-colors-whiteAlpha-400)",
      },
    },
    subitem: {
      position: "relative",
      display: "flex",
      width: "100%",
      paddingLeft: "1.5rem",
      flexDirection: "row",
      alignItems: "flex-start",
      gap: "spacing.0",
      _selected: {
        div: {
          background: "var(--chakra-colors-whiteAlpha-200)",
        },
      },
      div: {
        _selected: {
          background: "var(--chakra-colors-whiteAlpha-200)",
        },
        _hover: {
          background: "var(--chakra-colors-whiteAlpha-200)",
          ":nth-of-type(2)": {
            color: "var(--chakra-colors-whiteAlpha-600) !important",
          },
          "last-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
        },
        _active: {
          background: "var(--chakra-colors-whiteAlpha-200)",
          ":nth-of-type(1)": {
            color: "var(--chakra-colors-whiteAlpha-600) !important",
          },
          "first-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
          "last-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
        },
        _focus: {
          borderRadius: "0.3125rem",
          outline: "3.008px solid var(--chakra-colors-whiteAlpha-300)",
          background: "var(--chakra-colors-whiteAlpha-200)",
          "last-child svg": {
            fill: "var(--chakra-colors-whiteAlpha-600) !important",
          },
        },
      },
    },
    subitemContainer: {
      display: "flex",
      height: "3rem",
      padding: "0.75rem 0.5rem",
      alignItems: "center",
      gap: "0.5rem",
      flex: "1 0 0",
      borderRadius: "0.1875rem",
      color: "white",
      overflow: "hidden",
      background: "transparent",
    },
    subitemIcon: {
      display: "flex",
      alignItems: "center",
      svg: {
        fill: "auto",
      },
    },
    subitemLabel: {
      flex: "1 0 0",
      textAlign: "start",
      color: "inherit",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "100%",
      fontSize: "0.875rem",
      overflow: "hidden",
      letterSpacing: "-0.015rem",
      whiteSpace: "nowrap",
      width: "100%",
      textOverflow: "ellipsis",
    },
  }),
});
