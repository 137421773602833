import { Box, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

import { ToastType, useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import {
  useLazyGetUsersListQuery,
  useRemoveOrgUserMutation,
} from "@/features/user-manager";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";
import { PaginationBody } from "@/types";

const RemoveOrgUserConfirmation: React.FC = () => {
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useAppDispatch();
  const [fetchUsers] = useLazyGetUsersListQuery();

  const [removeOrgUserApi, { isLoading: isRemoving }] =
    useRemoveOrgUserMutation();

  const removeOrgUser = async () => {
    const userId = modalProps.user.id;
    try {
      await removeOrgUserApi({ userId }).unwrap();
      onClose();
      toast({
        title: "Member Removed from Organization",
        status: ToastType.Success,
      });
      await fetchUsers({} as PaginationBody).unwrap();
    } catch (error: any) {
      console.error(error);
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader className="!font-medium">Remove Member</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6">
          <Box>
            Removing a user from this organization would remove all their
            associated privileges.
            <br />
            <br />
            Are you sure you would like to proceed?
          </Box>
          <Flex className="mt-6 !flex !justify-end">
            <Button
              variant={modalProps.primaryButtonVariant ?? "solid"}
              colorScheme={modalProps.primaryButtonColorScheme ?? "customRed"}
              isLoading={isRemoving}
              isDisabled={isRemoving}
              onClick={removeOrgUser}
              size={"sm"}
            >
              Remove Member
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RemoveOrgUserConfirmation;
