import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CATEGORY } from "@/utils/enums.ts";

import { useGetCurrentWs } from "../../hooks/useGetCurrentWs.ts";
import { isLoading } from "../../redux/analysis-slice.tsx";

import { AnalysisHeaderButton } from "./analysis-header-button.tsx";

export const AnalysisHeaderTitle = () => {
  const routeParams = useParams();
  const {
    loading: isLoadingm,
    shownWs: ws,
    shownProject: project,
  } = useGetCurrentWs();
  const loading = useSelector(isLoading);
  return (
    <Flex className="flex-1 shrink">
      <AnalysisHeaderButton
        label={ws?.name}
        icon={ws?.icon}
        emoji={ws?.emoji}
        isLast={routeParams.project == undefined}
        type={CATEGORY.Workspace}
        data={ws}
        isLoading={isLoadingm}
      />
      {routeParams.project && (
        <AnalysisHeaderButton
          label={project?.name}
          isLast={true}
          type={CATEGORY.Project}
          ws={ws}
          data={project}
          isLoading={isLoadingm}
        />
      )}
    </Flex>
  );
};
