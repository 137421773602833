import { Portal, Spinner } from "@chakra-ui/react";
import {
  MdClear,
  MdMoreVert,
  MdOutlineCable,
  MdOutlineFileDownload,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbHeartMinus, TbHeartPlus } from "react-icons/tb";

import { useShowToast } from "@/components/toast";
import { Icon } from "@/design/components/icon";
import { IconButton } from "@/design/components/icon-button";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserId } from "@/slices/auth-slice";
import { ApiErrorResponse } from "@/types";

import { useDeleteFlowMutation, useLazyGetFlowQuery } from "../../api";
import { useCreateFlowNodes } from "../../hooks/useAddFlowToWorkflow";
import useManageFlowFavorites from "../../hooks/useManageFlowFavorites";
import {
  currentFlowItem,
  getEditingAllowed,
  setCurrentFlowStoreItem,
  setShowFlowStore,
} from "../../redux";
import { FlowSchema } from "../../types";
import { FLOW_TYPES } from "../../utils/constants";

export const FlowStoreTableDropdown = (
  props: FlowSchema & { flowTab: FLOW_TYPES }
) => {
  const [deleteFlow] = useDeleteFlowMutation();

  const toast = useShowToast(undefined, undefined, false);
  const currentFlow = useAppSelector(currentFlowItem);

  const dispatch = useAppDispatch();
  const loggedInUserId = useAppSelector(currentUserId);
  const isEditingAllowed = useAppSelector(getEditingAllowed);

  const [getFlowQuery] = useLazyGetFlowQuery();
  const { addFlow } = useCreateFlowNodes();
  const {
    addToFavorite,
    removeFavorite,
    isLoading: markingFav,
  } = useManageFlowFavorites();

  const importFlow = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!isEditingAllowed) {
      toast({
        title: "Cannot add flow while workflow is running",
        status: "warning",
        variant: "subtle",
      });
      return;
    }
    try {
      toast({
        title: "Adding to workflow",
        status: "info",
        duration: 1000,
      });
      const resp = await getFlowQuery({ flowId: props.nodeId }).unwrap();
      addFlow(resp.response.data!.flow);
      dispatch(setCurrentFlowStoreItem(null));
      dispatch(setShowFlowStore(false));
    } catch (error) {
      const err = error as ApiErrorResponse;
      toast({
        title: err.data.errors![0].detail ?? "Error occured while adding flow",
        status: "error",
      });
    }
  };

  const onFavorite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (markingFav) return;
    if (props.isFavorite) {
      await removeFavorite({
        flow: props,
        flowType: props.flowTab,
      });
    } else {
      await addToFavorite({
        flow: props,
        flowType: props.flowTab,
      });
    }
  };

  const onDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (currentFlow?.flow?.nodeUsageInstanceId === props.nodeUsageInstanceId) {
      dispatch(setCurrentFlowStoreItem(null));
    }
    toast({
      id: "delete-flow-" + props.nodeId,
      isClosable: false,
      title: "Deleting flow...",
      status: "loading",
    });
    try {
      await deleteFlow({ flowId: props.nodeId, flowType: props.flowTab });
      toast.update("delete-flow-" + props.nodeId, {
        title: "Flow deleted successfully",
        status: "success",
      });
    } catch (error) {
      console.log(error);
      toast.update("delete-flow-" + props.nodeId, {
        title: "Error deleting flow",
        status: "error",
      });
    }
  };
  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    console.log("onEdit");
  };

  const getFavIcon = () => {
    if (markingFav) return Spinner;
    return props.isFavorite ? TbHeartMinus : TbHeartPlus;
  };

  const getFavText = () => {
    if (markingFav) return "Loading...";
    return props.isFavorite ? "Remove from Favorites" : "Add to Favorites";
  };

  const showDeleteButton = props.userId === loggedInUserId;

  return (
    <Menu placement="bottom-end" isLazy={true} lazyBehavior="unmount">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            icon={isOpen ? <MdClear /> : <MdMoreVert />}
            variant="ghost"
            colorScheme="dark"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          {isOpen && (
            <Portal>
              <MenuList zIndex={1000}>
                <MenuItem
                  //eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={onFavorite}
                  isDisabled={markingFav}
                  icon={<Icon as={getFavIcon()} />}
                >
                  {getFavText()}
                </MenuItem>
                <MenuItem
                  isDisabled={true}
                  icon={<Icon as={MdOutlineCable} className="rotate-90" />}
                >
                  Edit Flow Details
                </MenuItem>
                <MenuItem
                  //eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={importFlow}
                  icon={<Icon as={MdOutlineFileDownload} />}
                >
                  Import Flow
                </MenuItem>
                {showDeleteButton && (
                  <MenuItem
                    color="red.600"
                    //eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onDelete}
                    icon={<Icon as={RiDeleteBin6Line} />}
                  >
                    Delete Flow
                  </MenuItem>
                )}
              </MenuList>
            </Portal>
          )}
        </>
      )}
    </Menu>
  );
};
