import { FormErrorMessage, FormLabel, FormControl } from "@chakra-ui/react";
import {
  Select,
  Props as SelectProps,
  GroupBase,
  ChakraStylesConfig,
} from "chakra-react-select";
import { ReactNode } from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";

interface ControlledSelectProps<
  FormValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<SelectProps<Option, IsMulti, Group>, "name" | "defaultValue">,
    UseControllerProps<FormValues> {
  label?: string;
  placeholderIcon?: ReactNode;
}

/**
 * An attempt to make a reusable chakra-react-select form component
 *
 * @param props - The combined props of the chakra-react-select component and the useController hook
 */
function ControlledSelect<
  FormValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  isRequired,
  label,
  options,
  control,
  rules,
  shouldUnregister,
  placeholderIcon,
  ...selectProps
}: ControlledSelectProps<FormValues, Option, IsMulti, Group>) {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const customChakraStyles: ChakraStylesConfig = {
    menu: (provided, _state) => ({
      ...provided,
      marginTop: "1px",
    }),
    control: (provided, _state) => ({
      ...provided,
      _disabled: {
        opacity: "1",
        backgroundColor: "gray.100",
        borderColor: "gray.300",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      border: "1px solid",
      borderColor: "gray.300",
      borderRadius: "0.1875rem",
      boxShadow:
        "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
    }),
    option: (provided) => ({
      ...provided,
      padding: "var(--chakra-space-3)",
      gap: "var(--chakra-space-2)",
      color: "gray.900",
      fontSize: "15px",
      lineHeight: "20px",
      letterSpacing: "-0.3px",
      fontWeight: "400",
    }),
  };

  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        options={options}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        chakraStyles={customChakraStyles}
        {...field}
        value={field.value}
        placeholderIcon={placeholderIcon}
        {...selectProps}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

export { ControlledSelect };
