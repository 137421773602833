import { DATASET_USAGE } from "@/utils/enums";

export const DATASET_USAGE_MESSAGE: Record<DATASET_USAGE, string> = {
  [DATASET_USAGE.EDA]:
    "This dataset in being transformed. Are you sure you want to delete it permanently?",
  [DATASET_USAGE.INSIGHTS]:
    "This dataset has been enabled in Genie. Are you sure you want to delete it permanently?",
  [DATASET_USAGE.WORKFLOW]:
    "This dataset is being used in your workflow. Are you sure you want to delete it permanently?",
};
