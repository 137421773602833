import {
  Input,
  Flex,
  FormErrorMessage,
  FormControl,
  CloseButton,
  FormLabel,
  FormHelperText,
  Tooltip,
} from "@chakra-ui/react";
import clsx from "clsx";
import { ChangeEvent, KeyboardEvent, useMemo, useState } from "react";
import { MdsInfoRound } from "react-icons-with-materialsymbols/mds";

import { ModelConfig } from "@/features/llm-prompt/types";

const CustomTag = ({
  tag,
  removeTag,
}: {
  tag: string;
  removeTag: () => void;
}) => (
  <Flex className="gap-1 bg-gray-100 text-sm items-center pl-2 py-1 rounded">
    <span className="max-w-[60px] w-fit text-ellipsis overflow-hidden whitespace-pre">
      {tag}
    </span>
    <CloseButton onClick={removeTag} size="sm" />
  </Flex>
);
interface SimpleTagInputProps {
  label: string;
  maxTagLength: number;
  isRequired?: boolean;
  helpText?: string;
  config: ModelConfig;
  disabled?: boolean;
  placeholder?: string;
  onChange: (id: string | undefined, value: string) => void;
}

const SimpleTagInput = ({
  label,
  maxTagLength,
  isRequired = false,
  disabled = false,
  helpText,
  placeholder = "",
  config,
  onChange: setTags,
}: SimpleTagInputProps) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState<string | undefined>();

  const defaultValue = useMemo(() => {
    const value = config?.selectedValue || config?.defaultParameterValue;
    if (!value) return [];
    return value.split(",").map((tag) => tag.trim());
  }, [config]);

  const handleChange = (value: string[]) => {
    setTags(config.id, value.join(","));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const trimmedInput = input.trim();
    setError(undefined);
    if (
      key === "Enter" &&
      trimmedInput.length > 0 &&
      trimmedInput.length <= maxTagLength
    ) {
      if (defaultValue.find((tag) => tag === trimmedInput)) {
        setError("Tag already added");
      } else if (trimmedInput.length > maxTagLength) {
        setError(`Tag must be at most ${maxTagLength} characters long`);
      } else {
        handleChange([...defaultValue, trimmedInput]);
      }

      setInput("");
    } else if (key === "Backspace" && !input && defaultValue.length > 0) {
      const tagsCopy = [...defaultValue];
      tagsCopy.pop();
      handleChange(tagsCopy);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInput(event.target.value);

  const removeTag = (index: number) => {
    if (disabled) return;
    handleChange([
      ...defaultValue.slice(0, index),
      ...defaultValue.slice(index + 1),
    ]);
  };
  return (
    <FormControl
      isDisabled={disabled}
      isInvalid={!!error}
      isRequired={isRequired}
    >
      <FormLabel className="!flex !items-center">
        <span>{label}</span>
        {config?.description && (
          <div className="order-3 grid place-items-center ml-1">
            <Tooltip
              className="!bg-gray-900 !rounded-md text-white text-center"
              sx={{
                ".chakra-tooltip__arrow": {
                  backgroundColor: "#232323 !important",
                },
              }}
              hasArrow
              label={config?.description}
              placement="top"
              shouldWrapChildren
            >
              <MdsInfoRound size={18} className="stroke-[20] text-gray-500" />
            </Tooltip>
          </div>
        )}
      </FormLabel>
      <Flex
        className={clsx(
          "flex-wrap gap-2 border rounded-[3px] py-1 px-1 items-stretch",
          error ? "border-red-600" : "border-gray-500"
        )}
      >
        {defaultValue.map((tag, index) => (
          <CustomTag key={tag} tag={tag} removeTag={() => removeTag(index)} />
        ))}
        <Input
          className="!w-min !h-[30px] !text-sm"
          disabled={disabled}
          max={maxTagLength}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={input}
          variant="unstyled"
        />
      </Flex>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default SimpleTagInput;
