import { Route, Routes } from "react-router-dom";

import { Editor } from "./node-editor";

export const NodeEditor = () => {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<Editor />} />
      </Route>
    </Routes>
  );
};
