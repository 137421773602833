import { Box, Flex, Progress, Skeleton } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { MdsErrorOutlined } from "react-icons-with-materialsymbols/mds";

import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserId } from "@/slices/auth-slice";
import { updateProps } from "@/slices/modal-slice";
import { CATEGORY } from "@/utils/enums";

import { useGetUserListForObjectQuery } from "../../api/users-api";
import { UserRoleSchema } from "../../types";

import { reorderUserList } from "./manage-access-utils";
import UserListItem from "./user-list-item";

const UserList = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userList, setUserList] = useState<UserRoleSchema[]>();
  const dispatch = useAppDispatch();
  const { object, type, roles, navigate } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  ) as {
    object: any;
    type: CATEGORY;
    roles: string[] | undefined;
    navigate: any;
  };

  const loggedInUserId = useAppSelector(currentUserId);

  const { data, isFetching, isError } = useGetUserListForObjectQuery({
    objectType: type,
    objectId: object.id,
  });

  // Then you can call this function inside your useEffect.
  useEffect(() => {
    const users = data?.response.data;
    try {
      if (!users) return;
      const { updatedlist, currentUser } = reorderUserList(
        users,
        loggedInUserId
      );
      dispatch(
        updateProps({
          modalProps: { object, type, roles, currentUser, navigate },
        })
      );
      setUserList(updatedlist);
    } catch (error) {
      console.log(error);
    }
  }, [
    data,
    setUserList,
    type,
    roles,
    object,
    dispatch,
    loggedInUserId,
    navigate,
  ]);

  if (isFetching) {
    return (
      <Flex className="h-[60vh] overflow-auto flex-col gap-2 border rounded">
        {Array.from({ length: 10 }).map((_, i) => (
          <Skeleton key={i} h="100px" />
        ))}
      </Flex>
    );
  }
  if (isEmpty(userList) || isError)
    return (
      <Flex
        className="h-[60vh] text-gray-500 gap-1 w-full border rounded"
        align={"center"}
        justify={"center"}
      >
        <MdsErrorOutlined />
        No users found
      </Flex>
    );

  return (
    <Box className="h-[60vh] min-h-[50vh] overflow-auto border rounded relative">
      {isSubmitting && (
        <Box className="sticky !top-0 !left-0 w-full">
          <Progress colorScheme="dark" isIndeterminate size="xs" />
        </Box>
      )}
      {userList!.map((user, i) => (
        <UserListItem
          key={user.userId}
          user={user}
          userlist={userList ?? []}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          setUserList={setUserList}
        />
      ))}
    </Box>
  );
};

export default UserList;
