import { Link as ChakraLink } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdOutlineAccountTree, MdOutlineDns } from "react-icons/md";
import {
  useLocation,
  useNavigate,
  Link as ReactRouterLink,
  useParams,
} from "react-router-dom";

import { AssistantIcon } from "@/components/icons/assistant-icon.tsx";
import { LogoWithText } from "@/components/icons/logo-text.tsx";
import { Logo } from "@/components/icons/logo.tsx";
import { Icon } from "@/design/components/icon";
import {
  NavigationPanel,
  NavigationPanelFooter,
  NavigationPanelItem,
  NavigationPanelBody,
  NavigationPanelToggle,
  NavigationPanelHeader,
} from "@/design/components/navigation-panel";
import { getOnGoingAnalysisUploads } from "@/features/data-manager/redux";
import { currentWorkflowId } from "@/features/workflow-studio/redux/workflow-slice";
import { useAppSelector } from "@/reduxHooks.ts";
import { CacheKey, cacheUtil } from "@/utils/localstorage.ts";

const AskClootrackIcon = () => {
  return <AssistantIcon />;
};

export const WorkflowSideNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const params = useParams();

  const workflowId = useAppSelector(currentWorkflowId);

  const [showGenie, setShowGenie] = useState(false);
  const isDataManger = pathname.includes("data-manager");
  const navigateToWorkflow = (name: string) => {
    navigate(`${name}`, { state: state });
  };

  const uploads = useAppSelector((s) =>
    getOnGoingAnalysisUploads(s, params.analysisId!)
  );

  const tenantToken = cacheUtil.getUnParsed(CacheKey.TenantId) ?? {};

  useEffect(() => {
    if (import.meta.env.VITE_DISABLED_GENIE_TENANTS) {
      const disabledTenants = import.meta.env.VITE_DISABLED_GENIE_TENANTS.split(
        ","
      );
      if (disabledTenants.includes(tenantToken)) {
        setShowGenie(false);
      } else {
        setShowGenie(true);
      }
    } else {
      setShowGenie(true);
    }
  }, [tenantToken]);

  const navigateToAskclootrack = () => {
    console.log("Attempting to navigate using React Router");

    // Extract the analysis ID from the current URL
    const analysisId = pathname.split("/analysis/")[1]?.split("/")[0];

    if (!analysisId) {
      console.error("Unable to extract analysis ID from current URL");
    }

    // Construct the new path
    const newPath = `/analysis/${analysisId}/genie/home`;

    // Perform the navigation
    navigate(newPath);
  };

  const isActive = (name: string) => {
    return pathname.includes(name);
  };

  const allowNavigate = () => {
    if (uploads && uploads.length > 0) {
      const userInput = confirm(
        "Upload is in progress, Exiting this page would cancel the ongoing upload.\nAre you sure you want to proceed?"
      );
      if (!userInput) return false;
    }
    return true;
  };

  const LogoTextLink = () => {
    return (
      <ChakraLink
        as={ReactRouterLink}
        onClick={(e) => {
          if (!allowNavigate()) {
            e.preventDefault();
          }
        }}
        to="/home"
      >
        <LogoWithText />
      </ChakraLink>
    );
  };

  const LogoLink = () => {
    return (
      <ChakraLink
        as={ReactRouterLink}
        onClick={(e) => {
          if (!allowNavigate()) {
            e.preventDefault();
          }
        }}
        to="/home"
      >
        <Logo />
      </ChakraLink>
    );
  };

  const showWorkflow = import.meta.env.VITE_DISABLE_WORKFLOW != "true";

  return (
    <NavigationPanel
      type="studio"
      aria-expanded="false"
      defaultIndex={isDataManger ? 0 : 1}
    >
      <NavigationPanelHeader
        className="cursor-pointer"
        // onClick={navigateToHome}
        logo={<LogoLink />}
      >
        <LogoTextLink />
      </NavigationPanelHeader>
      <NavigationPanelBody>
        <NavigationPanelItem
          icon={<Icon as={MdOutlineDns} />}
          label={"Data Manager"}
          selected={isActive("/data-manager")}
          onClick={navigateToWorkflow.bind(null, "data-manager")}
        />
        {showGenie && (
          <NavigationPanelItem
            icon={<Icon as={AskClootrackIcon} />}
            label="Genie"
            selected={isActive("/genie")}
            onClick={navigateToAskclootrack}
          />
        )}
        {showWorkflow && (
          <NavigationPanelItem
            icon={<Icon as={MdOutlineAccountTree} />}
            label={"Workflow"}
            isDisabled={!workflowId}
            selected={isActive("/editor")}
            onClick={navigateToWorkflow.bind(null, `editor/${workflowId}`)}
          />
        )}
      </NavigationPanelBody>
      <NavigationPanelFooter>
        <NavigationPanelToggle />
      </NavigationPanelFooter>
    </NavigationPanel>
  );
};
