import { FlexProps, chakra } from "@chakra-ui/react";
import { ReactElement, useContext } from "react";

import { NavigationPanelContext, useStyles } from "./navigation-panel.tsx";

export interface NavigationPanelHeader extends FlexProps {
  logo: ReactElement;
}
export const NavigationPanelHeader = (props: NavigationPanelHeader) => {
  const { children, logo, ...rest } = props;
  const { open } = useContext(NavigationPanelContext);

  const styles = useStyles();
  return (
    <chakra.div __css={styles.header} {...rest}>
      <chakra.div aria-expanded={open} __css={styles.headerIcon}>
        {open && children}
        {!open && logo}
      </chakra.div>
    </chakra.div>
  );
};
