import { Step as ChakraStep, StepProps } from "@chakra-ui/react";
import { useCallback } from "react";

export interface WizardStepProps extends StepProps {
  disabled?: boolean;
}

export const WizardStep = ({
  onClick,
  disabled,
  ...props
}: WizardStepProps) => {
  // Adding condition to prevent onClick from being called when disabled
  const onClickWithEvent = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }
      return onClick && onClick(event);
    },
    [onClick, disabled],
  );

  return (
    <ChakraStep
      aria-disabled={disabled}
      {...props}
      onClick={onClickWithEvent}
    />
  );
};
