import { Button, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";

import { CancelFill } from "@/components/icons/cancel-fill.tsx";
import { Loader } from "@/components/icons/loader.tsx";
import { ToastType, useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import {
  EdaMetaDataContext,
  setEdaAccessMode,
  setIsExecuteInProgress,
  useTerminateExecutionMutation,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { STATUS } from "@/utils/enums.ts";

export const ExecutionProgress = ({
  message,
  requestId,
  setRequestId,
}: {
  message: string | null;
  requestId: string | null;
  setRequestId: (requestId: string | null) => void;
}) => {
  const dispatch = useDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const { analysisId, edaId } = useContext(EdaMetaDataContext);
  const [terminate, { isLoading }] = useTerminateExecutionMutation();

  const { canWrite } = useReadOnlyMode();

  const terminateExecutionModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.TERMINATE_EXECUTION,
        modalProps: {
          onTerminate: terminateExecution,
        },
      })
    );
  };

  const terminateExecution = async () => {
    if (!requestId) return;

    try {
      const res = await terminate({
        requestId,
        analysisId: analysisId!,
        edaId: edaId!,
      }).unwrap();
      if (res.status == STATUS.SUCCESS) {
        setRequestId(null);
        dispatch(setIsExecuteInProgress(false));
        dispatch(setEdaAccessMode(res.response.data!.edaAccessMode));
      } else {
        toast({
          title: res.response.data?.message ?? "Terminate execution failed",
          status: ToastType.Error,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({ ...TOAST_MESSAGES.terminateFailed });
    }
  };

  return (
    <>
      <Loader className="animate-spin h-4 w-4" color="gray.1000" />
      <Text className="text-sm font-medium leading-[16px]">{message}</Text>
      {canWrite && (
        <Button
          className="ml-2"
          colorScheme="customRed"
          isLoading={isLoading}
          onClick={terminateExecutionModal}
          rightIcon={<Icon as={CancelFill} size="sm" />}
          size="sm"
          variant="outline"
        >
          Terminate Execution
        </Button>
      )}
    </>
  );
};
