import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([
    ...menuAnatomy.keys,
    "itemContainer",
    "description",
  ]);

const baseStyle = definePartsStyle({
  item: {
    color: "gray.900",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "-0.01875rem",
    padding: "0.75rem",
    borderBottom: "1px solid",
    borderBottomColor: "gray.100",
  },
  itemContainer: {
    textDecoration: "none",
    color: "inherit",
    userSelect: "none",
    display: "flex",
    width: "100%",
    alignItems: "center",
    textAlign: "start",
    flex: "0 0 auto",
  },
  description: {
    color: "gray.700",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "130%",
    letterSpacing: "-0.015rem",
    whiteSpace: "pre-wrap",
  },
  list: {
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.09) !important",
    py: 0,
    "button:last-of-type": {
      borderBottom: "none !important",
      borderBottomColor: "transparent !important",
      borderRadius: "0 0 0.375rem 0.375rem",
    },
    "button:first-of-type": {
      borderRadius: "0.375rem 0.375rem 0 0",
    },
  },
});

// export the default properties in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle });
