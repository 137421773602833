import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import React, { memo, useMemo } from "react";
import { MdCheck, MdChevronRight } from "react-icons/md";
import { MdsAccountCircleRound } from "react-icons-with-materialsymbols/mds";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { ACCESS_LEVEL, CATEGORY, STATUS, USER_ROLES } from "@/utils/enums";

import { useUpdateUserAccessMutation } from "../../api";
import { UserRoleSchema } from "../../types";

import useHandleSelfModify from "./manage-access-utils";

const UserListItem = ({
  user,
  userlist,
  isSubmitting,
  setIsSubmitting,
  setUserList,
}: {
  user: UserRoleSchema;
  userlist: UserRoleSchema[];
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setUserList: React.Dispatch<
    React.SetStateAction<UserRoleSchema[] | undefined>
  >;
}) => {
  const dispatch = useAppDispatch();
  const [updateAccess] = useUpdateUserAccessMutation();
  const toast = useShowToast(undefined, undefined, true);
  const { refetchObject } = useHandleSelfModify();

  const { object, type, roles, currentUser, navigate } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  ) as {
    object: any;
    type: CATEGORY;
    roles: string[];
    currentUser: UserRoleSchema;
    navigate: any;
  };
  const AccessMenuItem = memo(({ accessLevel }: { accessLevel: string }) => {
    const isSelected = user.role === accessLevel;
    return (
      <MenuItem
        className={`${isSelected && "flex-row-reverse"} capitalize`}
        icon={isSelected ? <MdCheck /> : undefined}
        //eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => handleChange(accessLevel)}
      >
        {accessLevel}
      </MenuItem>
    );
  });

  const showRemoveButton = () => {
    const userRole = user.role as ACCESS_LEVEL;
    const currentUserRole =
      (currentUser?.role as ACCESS_LEVEL) || ACCESS_LEVEL.VIEWER;

    // Check if current user has permission to remove the user based on their roles
    switch (userRole) {
      case ACCESS_LEVEL.ADMIN:
        return currentUserRole === ACCESS_LEVEL.ADMIN;
      case ACCESS_LEVEL.EDITOR:
      case ACCESS_LEVEL.MANAGER:
        return [
          ACCESS_LEVEL.ADMIN,
          ACCESS_LEVEL.EDITOR,
          ACCESS_LEVEL.MANAGER,
        ].includes(currentUserRole);
      case ACCESS_LEVEL.VIEWER:
        return [
          ACCESS_LEVEL.ADMIN,
          ACCESS_LEVEL.EDITOR,
          ACCESS_LEVEL.MANAGER,
        ].includes(currentUserRole);
      default:
        return false;
    }
  };

  const disableOptions = () => {
    if (
      user.orgRole === USER_ROLES.ADMIN ||
      user.orgRole === USER_ROLES.CTK_ADMIN
    ) {
      return true;
    }

    const userRole = user.role as ACCESS_LEVEL;
    const currentUserRole =
      (currentUser?.role as ACCESS_LEVEL) || ACCESS_LEVEL.VIEWER;

    // Check if current user has permission to change the role of the user based on their roles
    switch (userRole) {
      case ACCESS_LEVEL.ADMIN:
        return currentUserRole !== ACCESS_LEVEL.ADMIN;
      case ACCESS_LEVEL.EDITOR:
      case ACCESS_LEVEL.MANAGER:
        return ![
          ACCESS_LEVEL.ADMIN,
          ACCESS_LEVEL.EDITOR,
          ACCESS_LEVEL.MANAGER,
        ].includes(currentUserRole);
      case ACCESS_LEVEL.VIEWER:
        return ![
          ACCESS_LEVEL.ADMIN,
          ACCESS_LEVEL.EDITOR,
          ACCESS_LEVEL.MANAGER,
        ].includes(currentUserRole);
      default:
        return true;
    }
  };

  const removeUserAccess = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.REMOVE_USER_ACCESS,
        modalProps: {
          object,
          user,
          type,
          currentUser,
          navigate,
        },
      })
    );
  };

  const updateUserList = async (value: string) => {
    const res = await updateAccess({
      data: {
        objectId: object.id,
        assigneeUserId: user.userId,
        role: value.toLowerCase(),
        scope: type,
      },
    }).unwrap();
    if (res.status === STATUS.SUCCESS) {
      if (user.userId === currentUser.userId) {
        await refetchObject({ type, object, userRole: value });
      }
      toast({
        title: "User access updated successfully",
        status: ToastType.Success,
      });
      const updatedUserList = userlist.map((u) => {
        if (u.userId === user.userId) {
          return { ...u, role: value };
        }
        return u;
      });
      setUserList(updatedUserList);
    }
  };

  const handleChange = async (value: string) => {
    setIsSubmitting(true);
    try {
      await updateUserList(value);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemove = () => {
    setIsSubmitting(true);
    try {
      removeUserAccess();
    } catch (error) {
      const err = error as AxiosError;
      toast({
        title: err.message || "Could not remove user access",
        status: ToastType.Error,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const title = useMemo(() => {
    const name = isEmpty(user.userName) ? user.email : user.userName;

    return currentUser && currentUser.userId === user.userId
      ? name + " (You)"
      : name;
  }, [currentUser, user]);
  return (
    <Flex
      className="flex justify-between items-stretch border-b p-2  hover:bg-gray-100"
      key={user.userId}
    >
      <Box className="flex items-center gap-1">
        <Avatar
          color={"gray"}
          bgColor={"transparent"}
          icon={<MdsAccountCircleRound fontSize={32} />}
        />
        <Flex direction={"column"}>
          <Box className="text-sm">{title}</Box>
          <Box className="text-xs text-gray-600">{user.email}</Box>
        </Flex>
      </Box>
      <Menu placement="bottom-end">
        <MenuButton
          className="!no-underline shrink-0"
          as={Button}
          colorScheme="dark"
          isDisabled={isSubmitting || disableOptions()}
          rightIcon={<MdChevronRight className="rotate-90" />}
          size="sm"
          variant={"link"}
        >
          {user.role}
        </MenuButton>
        <MenuList className="-mr-2 !min-w-[11rem]">
          {roles &&
            roles.map((role) => (
              <AccessMenuItem key={role} accessLevel={role} />
            ))}
          {showRemoveButton() && (
            <MenuItem
              color={"red.600"}
              //eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleRemove}
            >
              Remove
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default UserListItem;
