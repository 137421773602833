import JSZip from "jszip";

export const getExcelSheetNames = async (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      parseExcel(data)
        .then((workbook) => {
          const sheetNames = getSheetNames(workbook);
          resolve(sheetNames);
        })
        .catch(reject);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

// const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const file = event.target.files?.[0];
//   if (!file) return;

//   parseExcelFile(file)
//     .then((sheetNames) => {
//       // Do something with the sheet names
//     })
//     .catch((error) => {
//       // Handle error
//     });
// };

const parseExcel = async (
  data: Uint8Array
): Promise<{ sheetNames: string[] }> => {
  const workbook: { sheetNames: string[] } = { sheetNames: [] };
  const zip = new JSZip();
  await zip.loadAsync(data);
  const workbookXml = await zip.file("xl/workbook.xml")?.async("string");
  if (workbookXml) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(workbookXml, "text/xml");
    const sheets = xmlDoc.getElementsByTagName("sheet");
    const sheetNames: string[] = [];
    for (let i = 0; i < sheets.length; i++) {
      sheetNames.push(sheets[i].getAttribute("name") || "");
    }
    workbook.sheetNames = sheetNames;
  }
  return workbook;
};

const getSheetNames = (workbook: { sheetNames: string[] }): string[] => {
  return workbook.sheetNames || [];
};
