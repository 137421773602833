import { Button, Flex, FormControl, FormLabel, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  MdsDownloadRound,
  MdsErrorRound,
} from "react-icons-with-materialsymbols/mds";

import { ControlledSelectNoForm } from "@/components/controlled-select";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";
import { DATASET_FORMAT, DOWNLOAD_STATES } from "@/utils/enums";

import { getDatasetIconColor } from "../../components/ongoing-uploads/get-icon";
import { DatasetsSchema } from "../../types";

import { DownloadTitle } from "./download-title";

interface DownloadOption {
  label: string;
  value: DATASET_FORMAT;
}

export const DownloadDatasetBody = () => {
  const availableOptions: DownloadOption[] = [
    { label: "CSV  (comma-separated values)", value: DATASET_FORMAT.CSV },
    { label: "XLSX  (Microsoft Excel)", value: DATASET_FORMAT.XLSX },
  ];
  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);
  const dispatch = useAppDispatch();
  const dataset = modalProps?.dataset as DatasetsSchema;
  const iconColor = getDatasetIconColor(dataset?.ioRecord?.ioType || "input");

  const [downloadFormat, setdownloadFormat] = useState<DownloadOption>(
    availableOptions[0]
  );

  const [showDataLimitWarning, setShowDataLimitWarning] = useState(false);

  const handleStartDownload = () => {
    // download state of selected data format
    const datasetDownloadState = dataset.downloadState[downloadFormat.value];

    const isDownloadReady = datasetDownloadState === DOWNLOAD_STATES.COMPLETE;

    if (isDownloadReady) {
      modalProps.startDownload({
        outputFormat: downloadFormat.value,
        dataset: dataset,
      });
    } else {
      modalProps.addToActiveDownloads({
        outputFormat: downloadFormat.value,
        dataset: dataset,
      });
    }
    dispatch(closeModal());
  };

  useEffect(() => {
    if (
      downloadFormat.value === DATASET_FORMAT.XLSX &&
      dataset.rowCount > 400000
    ) {
      setShowDataLimitWarning(true);
    } else {
      setShowDataLimitWarning(false);
    }
  }, [downloadFormat, dataset.rowCount]);

  return (
    <Flex className="flex-col gap-3">
      <DownloadTitle iconColor={iconColor} title={dataset.displayName} />
      <FormControl>
        <FormLabel className="!text-sm">Select format</FormLabel>
        <ControlledSelectNoForm
          isSearchable={false}
          value={downloadFormat}
          selectedOptionStyle="check"
          options={availableOptions}
          onChange={(option) => setdownloadFormat(option as DownloadOption)}
        />
      </FormControl>
      {showDataLimitWarning && (
        <Flex className="text-red-600 items-center text-sm gap-2">
          <Icon className="!text-red-600" as={MdsErrorRound} strokeWidth={22} />
          Only 400k records will be downloaded
        </Flex>
      )}
      <Button
        className="w-fit mt-8"
        alignSelf={"flex-end"}
        colorScheme="dark"
        leftIcon={<Icon as={MdsDownloadRound} strokeWidth={22} />}
        onClick={handleStartDownload}
      >
        Download
      </Button>
    </Flex>
  );
};
