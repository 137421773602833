import { Button, Input, chakra, Flex } from "@chakra-ui/react";
import { camelCase } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import { Node, ReactFlowJsonObject } from "reactflow";
import { ZodError, z } from "zod";

import { useShowToast } from "@/components/toast";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useRunValidationsMutation } from "@/features/data-manager/api";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { VALIDATION_STATUS, VALIDATION_TYPES } from "@/utils/enums";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import { useMarkOutputMutation } from "../../api";
import { NodeType } from "../../types";
import { ConvertFlowtoWorkflowPayload } from "../../utils/transform-response";

export const markAsOutputFormSchema = z.object({
  datasetName: z
    .string()
    .regex(/^[a-zA-Z0-9\s,!?.,()'"''""]*$/, {
      message: "Name must contain only alphanumeric characters",
    })
    .max(50, { message: "Name must be at most 50 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Name cannot be empty",
    }),
});

export interface MarkAsOutputSchema {
  datasetName: string;
}

const MarkAsOutputModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, false);
  const { node, setNodes, instance, params } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );

  const [markOutput, { isLoading }] = useMarkOutputMutation();

  const [validateTitle, { isLoading: isValidating }] =
    useRunValidationsMutation();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<MarkAsOutputSchema>({
    defaultValues: { datasetName: "" },
  });

  const validateDatasetName = async (datasetName: string) => {
    try {
      const validationType = VALIDATION_TYPES.DUPLICATE_TITLE;
      const res = await validateTitle({
        analysisId: params.analysisId!,
        validationType,
        body: { dataset_title: datasetName },
      }).unwrap();

      const validationResult =
        res.response.data?.results[0][camelCase(validationType)];

      if (validationResult?.validationStatus === VALIDATION_STATUS.FAILED) {
        setError("datasetName", {
          message: validationResult?.validationMessage[0],
        });
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const saveOutput = async (data: MarkAsOutputSchema) => {
    const workflow = ConvertFlowtoWorkflowPayload(
      instance as ReactFlowJsonObject
    );
    const nodeData = (node as Node).data as NodeType;
    const updatedWF = {
      ...workflow,
      outputNodes: [
        ...(workflow.outputNodes ?? []),
        keysToSnake({
          uiNodeId: node.id,
          outputTitle: data.datasetName,
          nodeIoDetailId: nodeData.outputs[0].ioDetailId,
        }),
      ],
    };
    try {
      const res = await markOutput({
        analysisId: params.analysisId!,
        workflowId: params.editorId!,
        workflow: updatedWF,
      }).unwrap();
      const updatedNode = res.response.data?.workflows[0].workflowNodes.find(
        (n) => n.uiNodeId === node.id
      );
      console.log(updatedNode);
      if (updatedNode) {
        setNodes((prev: Node[]) => {
          return prev.map((n) => {
            if (n.id === node.id) {
              return {
                ...n,
                data: {
                  ...n.data,
                  workflowNodeId: updatedNode?.workflowNodeId,
                  isOutput: updatedNode?.isOutput,
                  outputName: updatedNode?.outputName,
                  outputState: updatedNode?.outputState,
                } as NodeType,
              };
            }
            return n;
          });
        });
        toast({
          status: "success",
          title: "Output marked successfully",
        });
      }
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to mark output",
      });
    }
  };

  const onSubmit = async (data: MarkAsOutputSchema) => {
    try {
      const validatedData = await markAsOutputFormSchema.parseAsync(data);
      const isValidName = await validateDatasetName(validatedData.datasetName);
      if (!isValidName) return;
      await saveOutput(validatedData);
      dispatch(closeModal());
    } catch (error) {
      if (error instanceof ZodError) {
        error.errors.forEach((err) => {
          setError(err.path[0] as keyof MarkAsOutputSchema, {
            message: err.message,
          });
        });
      }
    }
  };

  const onClose = () => {
    if (isLoading) return;
    dispatch(closeModal());
  };

  const disableForm = isLoading || isValidating;

  return (
    <Modal isOpen={true} isCentered size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Output Dataset</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            //  eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(onSubmit)}
            className="!px-5 gap-10 flex flex-col"
          >
            <FormControl isInvalid={!!errors.datasetName} isRequired>
              <FormLabel fontSize="sm">Output Dataset Title</FormLabel>
              <Input
                className="disabled:bg-gray-300 disabled:text-gray-900"
                isDisabled={disableForm}
                type="text"
                {...register("datasetName")}
              />
              <FormErrorMessage>
                <FormErrorIcon />
                {errors.datasetName?.message}
              </FormErrorMessage>
            </FormControl>
            <Flex justify="flex-end">
              <Button
                className="mr-2"
                colorScheme="dark"
                isDisabled={disableForm}
                onClick={onClose}
                size={"sm"}
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                className="mr-2"
                colorScheme="dark"
                isDisabled={disableForm}
                isLoading={disableForm}
                size={"sm"}
                type="submit"
              >
                Save
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MarkAsOutputModal;
