import { Slide } from "@chakra-ui/react";
import clsx from "clsx";

import { DataTransformation } from "@/features/data-transformation";
import { selectPanel } from "@/features/workflow-studio";
import { WORKFLOW_PANELS } from "@/features/workflow-studio/utils/constants.tsx";
import { useAppSelector } from "@/reduxHooks";

export const DataTransformationPanel = () => {
  const dataTransformationPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataTransformationPanel)
  );

  return (
    <Slide
      className={clsx(
        "!absolute bottom-0 transition-[height] !right-0 !w-full h-full"
      )}
      direction="bottom"
      in={dataTransformationPanel.isVisible}
      style={{ zIndex: 1 }}
      unmountOnExit
    >
      <DataTransformation />
    </Slide>
  );
};
