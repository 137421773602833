import {
  Button,
  Flex,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import { PlayArrowFilled } from "@/components/icons/play-arrow.ts";
import { Icon } from "@/design/components/icon";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import {
  compiledTransformationSteps,
  FETCH_TYPE,
  triggerFetch,
} from "@/features/data-transformation";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const ExecutionRequired = () => {
  const dispatch = useDispatch();
  const steps = useSelector(compiledTransformationSteps);

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const onClose = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.EXPLORE_DATASET,
        modalProps: modalProps,
      })
    );
  };

  const onExecute = () => {
    dispatch(triggerFetch(FETCH_TYPE.SAVE_EXECUTE));
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-440">
        <ModalHeader>Unsaved changes found </ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6 !py-2 text-sm w-fit leading-[21px] text-gray-800">
          {isEmpty(steps) ? (
            <Flex className="flex-col">
              Exploring on Full Data requires execution for the first time. This
              can take some time.
            </Flex>
          ) : (
            <Flex className="flex-col">
              Some new transformations applied on this sample are yet to be
              executed on the full dataset.
              <Flex className="mt-4">
                You must execute transformations in order to explore data on the
                Full Dataset.
              </Flex>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex className="!px-6 w-full justify-between">
            <Button
              colorScheme="secondar"
              onClick={onClose}
              size="md"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              colorScheme="secondary"
              iconSpacing="2px"
              onClick={onExecute}
              rightIcon={<Icon as={PlayArrowFilled} size="sm" />}
              size="sm"
              variant="solid"
            >
              {isEmpty(steps) ? "Execute Now " : "Save & Execute"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
