import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MdsSyncRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import {
  EdaMetaDataContext,
  executeInProgress,
  FETCH_TYPE,
  samplingAndStepsHash,
  triggerFetch,
  useGetReadonlyModeStatusQuery,
} from "@/features/data-transformation";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";

import { PopoverMessageButton } from "./popover-message.tsx";

export const ReadOnly = () => {
  const dispatch = useDispatch();

  const { edaId, analysisId } = useContext(EdaMetaDataContext);
  const isExecuting = useSelector(executeInProgress);
  const currentHash = useSelector(samplingAndStepsHash);
  const { canWrite, isMounted } = useReadOnlyMode();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showRefresh, setShowRefresh] = useState(false);

  const showReadonlyMessage = !canWrite && !isExecuting && isMounted;

  const { data } = useGetReadonlyModeStatusQuery(
    { edaId: edaId!, analysisId: analysisId! },
    {
      pollingInterval: 5000,
      skip: !showReadonlyMessage || !edaId,
    }
  );

  useEffect(() => {
    if (!data) return;
    const newHash = data.response.data?.stepAndSamplingHash;

    if (newHash) {
      const isDifferent = !!currentHash && newHash !== currentHash;
      const toggleButton = !showRefresh && isDifferent;

      if (toggleButton) setShowRefresh(true);
      else setShowRefresh(false);
    }
  }, [data, currentHash]);

  const onRefresh = () => {
    dispatch(triggerFetch(FETCH_TYPE.STEPS));
    setShowRefresh(false);
  };

  if (showReadonlyMessage)
    return (
      <Flex className="items-center">
        <Flex className="items-center mr-2 text-sm text-gray-900">
          <Text className="font-semibold mr-0.5">Someone </Text> is currently
          editing...
        </Flex>
        {showRefresh && (
          <Button
            className="mr-2"
            bgColor="green.500"
            colorScheme="secondary"
            iconSpacing="2px"
            onClick={onRefresh}
            rightIcon={<Icon as={MdsSyncRound} size="sm" />}
            size="sm"
            variant="solid"
          >
            Sync Changes
          </Button>
        )}
        <PopoverMessageButton
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
        />
      </Flex>
    );
  return null;
};
