import {
  createToastFn,
  ToastProps,
  useChakra,
  useToast,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";

import { ErrorToast, InfoToast, BaseToast, SuccessToast } from ".";

export enum TOAST_DURATION {
  SHORT = 3000,
  MEDIUM = 5000,
  LONG = 7000,
}

export interface CustomToastProps extends ToastProps {
  actionText?: string;
  action?: () => void;
  actionIcon?: React.ElementType | undefined;
}

export enum ToastType {
  Info = "info",
  Warning = "warning",
  Error = "error",
  Success = "success",
  Loading = "loading",
}

const getToastComponent = (type: ToastType | string | undefined) => {
  switch (type) {
    case ToastType.Info:
      return InfoToast;
    case ToastType.Warning:
      return InfoToast;
    case ToastType.Error:
      return ErrorToast;
    case ToastType.Success:
      return SuccessToast;
    default:
      console.log("default");
      return BaseToast;
  }
};

const defaultOptions: ToastProps = {
  position: "bottom-right",
  duration: TOAST_DURATION.SHORT,
  isClosable: true,
};

export const createStandaloneToast = (custom = true) => {
  const toastData = {
    ...defaultOptions,
  };
  if (custom) {
    toastData.render = (props) => {
      return React.createElement(BaseToast, {
        options: props,
      });
    };
  }
  return createToastFn("ltr", toastData);
};

type CustomToastFn = ((props: CustomToastProps) => void) & {
  close: (id: string | number) => void;
  closeAll: () => void;
  update: (id: string | number, options: CustomToastProps) => void;
  isActive: (id: string | number) => boolean;
};

export const useShowToast = (
  options?: CustomToastProps,
  type?: ToastType,
  custom = true
) => {
  const { theme } = useChakra();
  const chakraToast = useToast();
  const status = type || options?.status;

  return useMemo(() => {
    const toastData = { ...defaultOptions, ...options };

    if (custom) {
      toastData.render = (props) =>
        React.createElement(BaseToast, {
          options: props,
        });
    }

    const customToastFn: CustomToastFn = (props: CustomToastProps) => {
      const direction: "ltr" | "rtl" = theme.direction;
      let duration;
      if (props.duration !== undefined) {
        duration = props.duration;
      } else if (!isEmpty(props.actionText)) {
        duration = TOAST_DURATION.MEDIUM;
      } else {
        duration = TOAST_DURATION.SHORT;
      }

      const newProps = {
        ...toastData,
        ...props,
        duration,
      };
      const createToast = createToastFn(direction, toastData);

      createToast(newProps);
    };
    customToastFn.close = (id) => chakraToast.close(id);
    customToastFn.closeAll = () => chakraToast.closeAll();
    customToastFn.update = (id, data: CustomToastProps) =>
      chakraToast.update(id, data);
    customToastFn.isActive = (id) => chakraToast.isActive(id);

    return customToastFn;
  }, [options, theme.direction, custom, status]);
};
