import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { MdsTransformRound } from "react-icons-with-materialsymbols/mds";

import { ScheduleFilled } from "@/components/icons/schedule-fill.tsx";
import { Icon } from "@/design/components/icon";

type PopoverMessageButtonProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};
export const PopoverMessageButton = ({
  isOpen,
  onClose,
  onOpen,
}: PopoverMessageButtonProps) => {
  return (
    <Popover closeOnBlur={true} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          sx={{
            ".chakra-button__icon": {
              marginInlineStart: "2px !important",
            },
          }}
          colorScheme="secondary"
          isDisabled={true}
          onMouseLeave={() => {
            onClose();
          }}
          onMouseOver={() => {
            onOpen();
          }}
          rightIcon={<Icon as={MdsTransformRound} size="sm" />}
          size="sm"
          variant="solid"
        >
          Transform
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          className="!outline-0"
          sx={{
            ":focus-visible": {
              outline: "none",
            },
            width: "240px",
          }}
        >
          <PopoverArrow />
          <Flex className="p-3 flex-col gap-y-1.5">
            <Flex>
              <Flex className="items-center gap-x-1">
                <PopoverIcon />
                <Text className="text-sm text-gray-900 font-medium leading-[120%]">
                  Come back later
                </Text>
              </Flex>
            </Flex>
            <Text className="text-[13px] leading-[120%]">
              Another user is currently editing this dataset. Come back later
              once they are done.
            </Text>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const PopoverIcon = () => {
  return (
    <Flex className="items-center p-0.5 bg-yellow-50 rounded-full border border-yellow-100">
      <Icon as={ScheduleFilled} color="yellow.500" size="xs" />
    </Flex>
  );
};
