import { chakra, Box, Flex, Spinner, List, ListItem } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React from "react";

import { UsersSchema } from "../../types";

const PlaceHolder = ({ title }: { title: string }) => (
  <Box className="text-sm flex-1 flex my-6 text-gray-500 italic items-center justify-center">
    {title}
  </Box>
);

const Loader = () => (
  <Flex className="flex-1 p-3 my-6 text-sm w-full text-gray-700 items-center justify-center">
    <Spinner color="gray.700" size="sm" />
    <Box className="ml-2">Loading...</Box>
  </Flex>
);

export const UserSuggestions = ({
  isFetching,
  inputValue,
  suggestedUsers,
  addToAddedUsers,
}: {
  isFetching: boolean;
  inputValue: string;
  suggestedUsers: UsersSchema[] | undefined;
  addToAddedUsers: (user: UsersSchema) => void;
}) => {
  const isSearchEmpty = isEmpty(inputValue) && !isFetching;
  const emptyResult = !isFetching && suggestedUsers?.length === 0;

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addToAddedUsers(suggestedUsers![index]);
    }
  };

  return (
    <Flex
      className="absolute top-[101%] left-0 w-full border bg-white overflow-auto shadow-xl rounded z-50  max-h-[40vh]"
      direction={"column"}
    >
      {isSearchEmpty && <PlaceHolder title="Start typing to search users" />}
      {emptyResult && <PlaceHolder title="No users found" />}
      {isFetching ? (
        <Loader />
      ) : (
        <List>
          {suggestedUsers?.map((user, i) => {
            const name =
              isEmpty(user.firstName) && isEmpty(user.lastName)
                ? user.email
                : user.firstName;
            return (
              <ListItem
                className={`items-center gap-3 p-3 border-b hover:bg-gray-100 cursor-pointer`}
                key={i}
                onClick={() => addToAddedUsers(user)}
                onKeyDown={(e) => handleKeyDown(e, i)}
                tabIndex={0}
              >
                <Flex className="flex-col">
                  <Box className="text-sm">{name}</Box>
                  <Box className="text-xs text-gray-600">{user.email}</Box>
                </Flex>
              </ListItem>
            );
          })}
        </List>
      )}
    </Flex>
  );
};
