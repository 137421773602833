import { Flex, Spinner } from "@chakra-ui/react";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";

import { useLazyGetFlowsQuery } from "@/features/workflow-studio/api";
import { FlowSchema } from "@/features/workflow-studio/types";
import { FLOW_TYPES } from "@/features/workflow-studio/utils/constants";

import { FlowItem } from "./flow-item";
import { NoFlows } from "./flow-tabs";

const FlowList = ({ flowType }: { flowType: FLOW_TYPES }) => {
  const [fetchFlows, { data: flowList, isLoading }] = useLazyGetFlowsQuery();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination = useMemo(
    () => flowList?.pagination ?? null,
    [flowList?.pagination]
  );

  const paginate = useCallback(async () => {
    await fetchFlows({
      flowType,
      url: pagination && pagination.next ? pagination.next : null,
    });
  }, [flowType, fetchFlows, pagination]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        const totalData = flowList?.flows.length ?? 0;
        const reachedBottom = scrollHeight - scrollTop - clientHeight < 100;
        const canFetchMore =
          (!isLoading && flowList?.pagination?.next) || !flowList;
        // !isLoading && !isFetching && flowList?.pagination?.next !== null;

        const shouldFetchMore = reachedBottom && canFetchMore;
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (shouldFetchMore) {
          paginate().catch(console.error);
        }
      }
    },
    [flowList, paginate, isLoading]
  );

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const columns = React.useMemo<ColumnDef<FlowSchema>[]>(
    () => [
      {
        accessorKey: "displayName",
        cell: (info) => info.getValue(),
      },
    ],
    []
  );

  const table = useReactTable({
    data: flowList?.flows ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  if (isLoading) {
    return (
      <Flex align={"center"} justify="center" gap="2" h={"300px"}>
        <Spinner size="sm" />
        <span>Fetching flows</span>
      </Flex>
    );
  }
  //All important CSS styles are included as inline styles for this example. This is not recommended for your code.
  return (
    <div
      ref={tableContainerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      style={{
        overflow: "auto", //our scrollable table container
        position: "relative", //needed for sticky header
        height: "300px", //should be a fixed height
      }}
    >
      {!flowList || isEmpty(flowList.flows) ? (
        <NoFlows />
      ) : (
        <table style={{ display: "grid" }}>
          {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
          <tbody
            style={{
              display: "grid",
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: "relative", //needed for absolute positioning of rows
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  className="w-full flex absolute"
                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.row.original.nodeId} className="w-full p-0">
                        <FlowItem flow={row.original} flowType={flowType} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FlowList;
