/**
 * Returns the caret index within an HTML element.
 *
 * @param {HTMLElement} element - The HTML element to get the caret index from.
 * @return {number} The caret index within the element.
 */
export function getCaretIndex(element: HTMLElement): number {
  let position = 0;
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection?.rangeCount !== 0) {
      const sel = window.getSelection();
      if (sel) {
        const range = sel.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
  }
  return position;
}

/**
 * Retrieves the coordinates of the caret in the current selection.
 *
 * @return {Object} An object containing the x and y coordinates of the caret.
 */
export function getCaretCoordinates() {
  let x = 0,
    y = 0;
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0).cloneRange();
      range.collapse(true);
      const rect = range.getClientRects()[0];
      if (rect) {
        x = rect.left;
        y = rect.top;
      }
    }
  }
  return { x, y };
}

export const escapeHtml = (text: string) => {
  const map: { [key: string]: string } = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
    "`": "&#x60;",
  };
  return text.replace(/[&<>"'`]/g, (m) => map[m]);
};

export const removeHTML = (text: string) => {
  return text.replace(/<[^>]*>/g, (match) => {
    // If the tag is empty, just remove it
    if (match.trim() === "") {
      return "";
    }
    // Otherwise, return the content of the tag
    const start = match.indexOf(">");
    if (start === -1) {
      return "";
    }
    return match.slice(start + 1).trim();
  });
};

export const addColumnsStyling = (text: string, inputColumns: string[]) => {
  // Escape special characters to prevent HTML interpretation
  const escapedData = escapeHtml(text);

  // Process the pasted data
  const modifiedData = escapedData
    .replace(/\n/g, "<br/>") // Replace line breaks with <br/>
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Replace tab spaces with non-breaking spaces
    .replace(/{{([^{}]*)}}/g, (_, p1) => {
      const column: string = p1.trim(); // Extracted content inside {{...}}, already stripped of braces
      if (inputColumns.includes(column)) {
        return `<span class="promptColumn valid" contenteditable="false">{{${column}}}</span>`;
      } else {
        return `<span class="promptColumn invalid" contenteditable="false">{{${column}}}</span>`;
      }
    });
  return modifiedData;
};
