import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([]);

const baseStyle = definePartsStyle({
  stepper: {
    justifyContent: "center",
    alignItems: "center",
    borderY: "1px solid",
    borderColor: "gray.100",
    py: "8px",
    gap: "0px",
    "&:not(:has(.chakra-step__separator))": {
      gap: "24px",
    },
  },
  step: {
    userSelect: "none",
    "&:not(:has(.chakra-step__separator))": {
      flex: "0",
      flexGrow: "0",
      flexShrink: "1",
      minWidth: "fit-content",
    },
    "&:has(.chakra-step__description)": {
      pr: "25px",
    },
    py: "5px",
    pl: "5px",
    pr: "10px",
    borderRadius: "50px",
    cursor: "pointer",
    "&[data-status=active][aria-disabled=false]": {
      bg: "none",
      _hover: {
        bg: `none`,
      },
      _active: {
        bg: `none`,
      },
      ".chakra-step__indicator": {
        color: `white`,
      },
    },
    "&[data-status=complete]": {
      _hover: {
        bg: `orange.50`,
      },
      _active: {
        bg: `orange.200`,
      },
      ".chakra-step__title,.chakra-step__number": {
        color: "orange.400",
      },
      ".chakra-step__indicator": {
        border: `1px solid`,
        borderColor: `orange.50`,
        bg: "white",
        color: `orange.400`,
      },
    },
    "&[data-status=incomplete][aria-disabled=false]": {
      _hover: {
        bg: `gray.50`,
        ".chakra-step__title,.chakra-step__number": {
          color: "gray.900",
        },
      },
      _active: {
        bg: `gray.400`,
        ".chakra-step__title,.chakra-step__number": {
          color: "gray.900",
        },
      },
      ".chakra-step__title,.chakra-step__number": {
        color: "gray.700",
      },
      ".chakra-step__indicator": {
        border: `1px solid`,
        borderColor: `orange.50`,
        bg: "white",
      },
    },
    "&[aria-disabled=true]": {
      cursor: "not-allowed",
      ".chakra-step__title": {
        color: `gray.500`,
      },
      ".chakra-step__indicator": {
        border: `1px solid`,
        borderColor: `gray.50`,
        bg: "none",
      },
    },
  },
  title: {
    color: `gray.700`,
    fontFamily: `Albert Sans`,
    fontStyle: `normal`,
    fontWeight: `600`,
    lineHeight: `150%` /* 21px */,
    letterSpacing: `-0.28px`,
    "&[data-status=complete], &[data-status=active]": {
      color: `orange.400`,
    },
  },
  description: {
    fontSize: "xs",
  },
  separator: {
    border: `1px solid`,
    borderColor: `orange.400`,
  },
  number: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "gray.600",
    "&[data-status=complete]": {
      color: `orange.400`,
    },
    "&[data-status=active]": {
      color: `white`,
    },
    "&[aria-disabled=true]": {
      color: `gray.500`,
    },
  },
  indicator: {
    h: "24px",
    w: "24px",
    "&[aria-disabled=true]": {
      bg: `gray.500`,
    },
    "&[data-status=complete]": {
      bg: "orange.50",
    },
    "&[data-status=active]": {
      bg: `orange.400`,
    },
    border: `none`,
    bg: `gray.50`,
  },
});

export const stepperTheme = defineMultiStyleConfig({
  baseStyle,
});
