import { Input } from "@chakra-ui/react";
import React from "react";

import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";
import { NodeParameter } from "@/features/workflow-studio/types";

export const SourceNodeConfig = ({ param }: { param: NodeParameter }) => {
  return (
    <FormControl key={param.name}>
      <FormLabel>{param.name}</FormLabel>
      <Input
        className="disabled:bg-gray-300 disabled:text-gray-900"
        isDisabled={true}
        type="text"
        value={param.deltatablePath}
      />
      <FormErrorMessage>
        <FormErrorIcon />
        {param.errors?.map((err) => <div key={err}>{err}</div>)}
      </FormErrorMessage>
    </FormControl>
  );
};
