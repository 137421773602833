import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import {
  MdsCheckRound,
  MdsChevronLeftRound,
  MdsChevronRightRound,
  MdsExpandMoreRound,
  MdsHistoryRound,
} from "react-icons-with-materialsymbols/mds";

import { dateToRelativeString } from "@/utils/date-convertor";

import { TextGenVersion } from "../../types";

const HistoryDropdown = ({
  activeIndex,
  setActiveIndex,
  versions,
  isLoading,
}: {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  versions: TextGenVersion[] | undefined;
  isLoading: boolean;
}) => {
  const handleNextVersion = () => {
    if (!versions) return;
    if (activeIndex === versions.length - 1) return;
    setActiveIndex(activeIndex + 1);
  };

  const handlePreviousVersion = () => {
    if (!versions) return;
    if (activeIndex === 0) return;
    setActiveIndex(activeIndex - 1);
  };

  const noVersions = versions && isEmpty(versions);

  if (isLoading) return <Spinner />;

  return (
    <>
      {noVersions ? (
        <Box className="text-sm border p-1 px-3 bg-gray-50 rounded-md">
          No Versions Available
        </Box>
      ) : (
        <Flex>
          <IconButton
            fontSize={20}
            aria-label="Previous Version"
            colorScheme="dark"
            icon={<MdsChevronLeftRound className="stroke-[22]" />}
            isDisabled={activeIndex === 0}
            onClick={handlePreviousVersion}
            variant={"ghost"}
          />
          <Menu placement="bottom" size="sm">
            <MenuButton
              className="!no-underline"
              as={Button}
              colorScheme="dark"
              rightIcon={<MdsExpandMoreRound className="stroke-[22]" />}
              size={"sm"}
              variant={"link"}
            >
              {dateToRelativeString(versions?.[activeIndex]?.creationTime)}
            </MenuButton>
            <MenuList className="max-h-64 overflow-y-auto">
              {versions?.map((version, idx) => (
                <MenuItem
                  className="!text-sm"
                  key={version.versionNumber}
                  icon={
                    idx === activeIndex ? (
                      <MdsCheckRound className="stroke-[22]" size={16} />
                    ) : (
                      <MdsHistoryRound className="stroke-[22]" size={18} />
                    )
                  }
                  onClick={() => setActiveIndex(idx)}
                >
                  {dateToRelativeString(version.creationTime)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <IconButton
            fontSize={20}
            aria-label="Previous Version"
            colorScheme="dark"
            icon={<MdsChevronRightRound className="stroke-[22]" />}
            isDisabled={activeIndex === versions!.length - 1}
            onClick={handleNextVersion}
            variant={"ghost"}
          />
        </Flex>
      )}
    </>
  );
};

export default HistoryDropdown;
