import { Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useContext, useMemo, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { MdsDoneRound } from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { FilterContext, tableColumns } from "@/features/data-transformation";

type ColumnDropdownProps = {
  selectedColumn: string | null;
  setSelectedColumn: (column: string) => void;
  isSavedFilter?: boolean;
};

export const ColumnDropdown = ({
  selectedColumn,
  setSelectedColumn,
  isSavedFilter,
}: ColumnDropdownProps) => {
  const [menu, setMenu] = useState(false);
  const { appliedFilters, previousColumns } = useContext(FilterContext)!;
  const columns = useSelector(tableColumns);

  const columnList = useMemo(() => {
    if (previousColumns && !isEmpty(previousColumns)) return previousColumns;
    return columns.map((column) => column.name);
  }, [columns, previousColumns]);

  const filteredColumns = columnList?.filter((name) => {
    return !appliedFilters.find((filter) => filter.column === name);
  });

  const closeMenu = () => {
    setMenu(false);
  };

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const handleSelectMethod = (_column: string) => {
    setMenu(false);

    if (selectedColumn === _column) return;
    setSelectedColumn(_column);
  };

  return (
    <FormControl>
      <FormLabel className="!mb-1.5 !ml-1.5 text-sm leading-none">
        Column
      </FormLabel>
      <Menu isOpen={menu} placement="bottom" onClose={closeMenu}>
        <MenuButton as={Flex} onClick={toggleMenu} className="!cursor-pointer">
          <Flex className="flex flex-row w-full justify-between items-center border border-gray-500 rounded-[3px] p-2 gap-1.5  select-none">
            <Text
              className={clsx(
                "text-[13px] leading-[16px] font-medium tracking-tight truncate max-w-[10rem]",
                !selectedColumn && "opa city-60"
              )}
            >
              {selectedColumn ?? "Select a column"}
            </Text>

            <Icon as={TbChevronDown} size="sm" color="gray.600" />
          </Flex>
        </MenuButton>
        <MenuList
          display={menu ? "block" : "none"}
          className="-mt-2 w-[288px] !z-10 !max-h-[250px] !h-full overflow-auto"
        >
          {filteredColumns?.map((name, index) => {
            const isSelected = name === selectedColumn;
            return (
              <MenuItem
                key={index}
                onClick={handleSelectMethod.bind(null, name)}
                className="!p-2.5 !text-[13px] !leading-[120%]"
                position="relative"
              >
                {name}
                {isSelected && (
                  <Icon
                    as={MdsDoneRound}
                    size="sm"
                    color="gray.900"
                    className="absolute right-2"
                  />
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </FormControl>
  );
};
