import { Input } from "@chakra-ui/react";
import React from "react";

import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@/design/components/form";
import { NodeParameter } from "@/features/workflow-studio/types";

export const TextConfig = ({
  param,
  updateParameter,
}: {
  param: NodeParameter;
  updateParameter: (paramId: string, value: any, error: any) => void;
}) => {
  const defaultValue = param.value ?? param.defaultValue ?? "";
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateParameter(param.parameterId, e.target.value, undefined);
  };
  return (
    <FormControl
      key={param.name}
      isRequired={param.isMandatory}
      isInvalid={param.errors && param.errors.length > 0}
    >
      <FormLabel>{param.name}</FormLabel>
      <Input
        className="disabled:bg-gray-300 disabled:text-gray-900"
        isDisabled={!param.isModifiable}
        onChange={(e) => changeHandler(e)}
        type="text"
        value={defaultValue}
      />
      <FormHelperText>{param.description}</FormHelperText>
      <FormErrorMessage>
        <FormErrorIcon />
        {param.errors &&
          param.errors?.map((err, i) => <div key={i}>{err}</div>)}
      </FormErrorMessage>
    </FormControl>
  );
};
