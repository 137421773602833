import { Box, chakra, Flex, Spinner, Tag, Text } from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";

import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@/design/components/data-table";
import { StyledIcon } from "@/features/ws-manager/components/styled-icon.tsx";
import {
  AnalysesSchema,
  CommonItemsSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import { getDateFormat } from "@/utils/date-convertor.ts";
import { CATEGORY } from "@/utils/enums.ts";

import { useLazySearchInWsQuery, useSearchInWsQuery } from "../../api";
import { useGetColors } from "../../hooks";
import { CustomIcon } from "../get-icon.tsx";

import { AnalysisDropdown } from "./analysis-dropdown.tsx";
import { ProjectDropdown } from "./project-dropdown.tsx";
import { WorkspaceDropdown } from "./ws-dropdown.tsx";

const columnHelper = createColumnHelper<CommonItemsSchema>();

export function SearchResultsDatatable(props: {
  onOpen: (item: CommonItemsSchema) => void;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { query } = useCustomQuery();
  const { query: previewAnalysisId, setParam: _setParam } =
    useCustomQuery("analysis");
  const { getShortBgColors, getColors } = useGetColors();
  const [currentQuery, setCurrentQuery] = useState<string | null>(query);

  const [searchWS, { data, isFetching: isLoading, isLoading: isl }] =
    useLazySearchInWsQuery();
  const columns = useMemo(
    () => [
      columnHelper.accessor("itemData.name", {
        cell: ({ row: _row, getValue }) => {
          const type = _row.original?.itemType ?? CATEGORY.Workspace;
          const isSelected = previewAnalysisId === _row.original?.itemData?.id;

          return (
            <Flex className="items-center gap-1">
              <StyledIcon
                hideIcon={true}
                wrapperStyle={clsx(
                  "p-1 rounded-mg",
                  isSelected && "bg-orange-100",
                  getShortBgColors(type)
                )}
              >
                <CustomIcon
                  type={type}
                  color={isSelected ? "orange.800" : getColors(type)}
                />
              </StyledIcon>
              <Text color={isSelected ? "orange.800" : "gray.800"}>
                {getValue<string>()}
              </Text>
            </Flex>
          );
        },
        header: "Name",
        size: 50,
        enableSorting: true,
      }),
      columnHelper.accessor("itemType", {
        cell: ({ row: _row, getValue }) => (
          <Tag className="capitalize" colorScheme="gray">
            {getValue<string>()}
          </Tag>
        ),
        header: "Type",
        size: 15,
        enableSorting: true,
      }),
      columnHelper.accessor("itemData.updatedOn", {
        cell: (info) => getDateFormat(info.getValue() ?? ""),
        header: "Last Activity",
        size: 30,
      }),
      columnHelper.accessor("itemData.id", {
        cell: (info) => {
          switch (info.row.original?.itemType) {
            case CATEGORY.Workspace:
              return (
                <WorkspaceDropdown
                  {...(info.row.original?.itemData as WorkspaceSchema)}
                />
              );
            case CATEGORY.Analysis:
              return (
                <AnalysisDropdown
                  {...(info.row.original?.itemData as AnalysesSchema)}
                />
              );
            case CATEGORY.Project:
            default:
              return (
                <ProjectDropdown
                  {...(info.row.original?.itemData as ProjectSchema)}
                />
              );
          }
        },
        header: () => <span className="select-none"></span>,
        size: 5,
        enableSorting: false,
      }),
    ],
    [props]
  );

  const table = useReactTable({
    columns: columns,
    data: data?.response?.data?.results ?? [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 73,
    overscan: 5,
  });

  const pagination = useMemo(
    () => data?.response?.pagination ?? null,
    [data?.response?.pagination]
  );

  const paginate = React.useCallback(async () => {
    await searchWS({
      search: query ?? "",
      url: pagination && pagination.next ? pagination.next : null,
    });
    setIsSearching(false);
  }, [searchWS, query, pagination]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const reachedBottom = scrollHeight - scrollTop - clientHeight < 100;
        const canFetchMore =
          (!isLoading && data?.response?.pagination?.next) || !data;

        const shouldFetchMore = reachedBottom && canFetchMore;

        const shouldResetData = query !== currentQuery;
        if (shouldFetchMore || shouldResetData) {
          paginate();
        }
      }
    },
    [data, paginate, isLoading, query]
  );

  useEffect(() => {
    if (isLoading && query !== currentQuery) {
      setIsSearching(isLoading);
      setCurrentQuery(query ?? "");
    }
  }, [isLoading, query, currentQuery]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  if (isSearching) {
    return (
      <Flex className="h-full w-full items-center mt-10 justify-center gap-3">
        <Spinner size={"sm"} />
        <Box>Loading Results</Box>
      </Flex>
    );
  }

  return (
    <Flex className="overflow-auto h-full flex-col">
      <Box
        className="overflow-auto h-full grow"
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      >
        <Table
          width="100%"
          variant="simple"
          __css={{
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <Thead position="sticky" top={0} zIndex={10} bg={"white"}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, _idx) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta = header.column.columnDef.meta;
                  return (
                    <Th
                      width={`${header.column.getSize()}%`}
                      maxWidth={`${header.column.getSize()}%`}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      borderY={"1px solid"}
                      borderColor="gray.200"
                      key={header.id}
                      isNumeric={meta?.isNumeric}
                      onClick={header.column.getToggleSortingHandler()}
                      role="button"
                      paddingLeft={_idx === 0 ? "48px" : "0"}
                      className="!py-0"
                    >
                      <Flex className="justify-start items-center">
                        <chakra.span
                          className={clsx("inline-flex items-center w-fit")}
                        >
                          <Flex className="w-full py-3 pl-3 !capitalize text-gray-700 text-sm font-medium leading-[24px]">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Flex>
                        </chakra.span>
                        {header.column.getCanSort() && (
                          <chakra.span className={clsx("pl-4 mr-2 block")}>
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown
                                  aria-label="sorted descending"
                                  title="sorted descending"
                                />
                              ) : (
                                <BsArrowUp
                                  aria-label="sorted ascending"
                                  title="sorted ascending"
                                />
                              )
                            ) : (
                              <BsArrowDownUp aria-label="sort" title="sort" />
                            )}
                          </chakra.span>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          {!isl && (
            <Tbody>
              {virtualizer.getVirtualItems().map((virtualRow, index) => {
                const row = rows[virtualRow.index];
                // const type = row.original?.itemType ?? CATEGORY.Workspace;
                const isSelected =
                  previewAnalysisId === row.original?.itemData?.id;
                return (
                  <Tr
                    // onClick={() => {
                    //   props.onOpen(row.original);
                    //   _setParam(row.original?.itemData?.id);
                    // }}
                    key={row.id}
                    _hover={{
                      bg: isSelected ? "orange.50" : "gray.50",
                    }}
                    bgColor={isSelected ? "orange.50" : "white"}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${
                        virtualRow.start - index * virtualRow.size
                      }px)`,
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      return (
                        <Td
                          overflow={"hidden"}
                          whiteSpace="pre"
                          textOverflow={"ellipsis"}
                          key={cell.id}
                          paddingLeft="12px"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        {!isLoading && table.getRowModel().rows.length === 0 && (
          <div className="flex justify-center w-full items-center mt-[120px]">
            Try changing the search query for better results
          </div>
        )}
        {isLoading && (
          <Flex
            className={clsx(
              "justify-center py-1 text-gray-700 absolute bottom-0 shrink-0 w-full items-center gap-3 -translate-x-2 bg-white",
              isEmpty(data) ? "h-full" : "h-fit"
            )}
          >
            <Spinner size={"xs"} />
            <span>Loading</span>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
