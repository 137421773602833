import { CATEGORY } from "@/utils/enums.ts";

export const useGetColors = () => {
  const getShortBgColors = (type: CATEGORY) => {
    switch (type) {
      case CATEGORY.Workspace:
        return "bg-purple-50";
      case CATEGORY.Project:
        return "bg-blue-50";
      case CATEGORY.Analysis:
        return "bg-gray-50";
    }
  };

  const getColors = (type: CATEGORY) => {
    switch (type) {
      case CATEGORY.Workspace:
        return "purple.500";
      case CATEGORY.Project:
        return "blue.500";
      case CATEGORY.Analysis:
        return "gray.700";
    }
  };

  return { getShortBgColors, getColors };
};
