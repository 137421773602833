import { Flex } from "@chakra-ui/react";
import { RiDatabase2Fill } from "react-icons/ri";
import { TbChevronRight } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@/design/components/breadcrumbs";
import { Icon } from "@/design/components/icon";

export const TransformDataBreadCrumb = () => {
  const navigate = useNavigate();
  return (
    <Flex className="flex w-full bg-white px-4 py-2 border-b border-b-gray-200">
      <Breadcrumb
        separator={
          <Icon as={TbChevronRight} className="" size="sm" color="gray.600" />
        }
        className="text-xs font-medium "
      >
        <BreadcrumbItem
          icon={
            <Icon
              as={RiDatabase2Fill}
              className=""
              size="sm"
              color="gray.600"
            />
          }
        >
          <BreadcrumbLink
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            Datasets
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem
          isCurrentPage
          icon={<Icon as={RiDatabase2Fill} className="" size="sm" />}
        >
          <BreadcrumbLink href="/review">
            Radient Mobile NPS Data
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Flex>
  );
};
