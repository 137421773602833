import { Flex } from "@chakra-ui/react";

import { NavBar } from "@/features/ws-manager/components/nav-bar/nav-bar.tsx";
import { RecycleBin } from "@/features/ws-manager/components/recycle-bin";
import { WorkspaceSearch } from "@/features/ws-manager/components/workspace-search";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";

export const RecycleBinRoute = () => {
  const { query } = useCustomQuery();
  const isGlobalSearchActive = !!query;

  return (
    <Flex
      className="flex-col items-center w-full h-full overflow-hidden"
      bg="gray.50"
    >
      <NavBar />
      <Flex className="flex-col items-center w-full h-full p-4 gap-4 overflow-hidden">
        {!isGlobalSearchActive && <RecycleBin />}
        {isGlobalSearchActive && <WorkspaceSearch />}
      </Flex>
    </Flex>
  );
};
