import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { previewAnalysis } from "@/features/ws-manager";
import { AnalysisCard } from "@/features/ws-manager/components/analysis/analysis-card.tsx";
import { AnalyticsTabs } from "@/features/ws-manager/components/analysis/analytics-tabs.tsx";

import { AnalysisDatatable } from "./analysis-datatable.tsx";
import AnalysisDetails from "./analysis-details-panel.tsx";
export const Analysis = () => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    id: "analysisSidebar",
  });

  const { workspace, project } = useParams();

  const analysis = useSelector(previewAnalysis);
  useEffect(() => {
    if (analysis && !isOpen) {
      onOpen();
    }
    if (!analysis && isOpen) {
      onClose();
    }
  }, [analysis]);

  const key = `${workspace || "defaultWorkspace"}-${
    project || "defaultProject"
  }`;

  return (
    <Flex
      className="w-full h-full overflow-hidden"
      key={key}
      align="stretch"
      justify="stretch"
      gap={isOpen ? "3" : 0}
      w="100%"
    >
      <Helmet>
        <title>
          {isEmpty(analysis) ? "Clootrack" : `${analysis?.name} - Clootrack`}
        </title>
      </Helmet>
      <AnalysisCard isOpen={isOpen}>
        <AnalysisDatatable onOpen={onOpen} label="Analysis" />
        {/* <AnalyticsTabs
          tab1={<AnalysisDatatable onOpen={onOpen} label="Analysis" />}
          tab2={<AnalysisDatatable onOpen={onOpen} label="Dashboard" />}
        /> */}
      </AnalysisCard>

      <Box
        className={clsx(
          "h-full xl:max-w-[30%] 2xl:max-w-[20vw] ease-in-out transition-transform duration-300",
          isOpen && !isEmpty(analysis)
            ? "translate-x-0 w-[30%] overflow-auto"
            : "translate-x-full w-0 overflow-hidden"
        )}
      >
        {analysis && <AnalysisDetails onClose={onClose} />}
      </Box>
    </Flex>
  );
};
