import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([...parts.keys, "tsubhead"]);

const numericStyles = defineStyle({
  "&[data-is-numeric=true]": {
    textAlign: "end",
  },
});

const variantPrimary = definePartsStyle(() => {
  return {
    table: {
      border: "1px solid",
      borderColor: `gray.400`,
      borderRadius: "4px",
      borderSpacing: 0,
      borderCollapse: "separate",
      overflow: "hidden",
      position: "relative",
    },
    thead: {
      position: "sticky",
      top: 0,
      margin: 0,
      "&[role=button]": {
        cursor: "pointer",
      },
      bg: `black.50`,
      border: "none",
      th: {
        p: "10px",
        textTransform: `none`,
        borderRight: "1px solid",
        borderBottom: "1px solid",
        borderColor: `gray.400`,
      },
    },
    tsubhead: {
      p: "10px",
      textTransform: `uppercase`,
      th: {
        borderRight: "1px solid",
        borderBottom: "1px solid",
        borderColor: `gray.400`,
      },
    },
    th: {
      ...numericStyles,
      p: "10px",
      textOverflow: `ellipsis`,
      fontFamily: `Albert Sans`,
      fontSize: `16px`,
      fontStyle: `normal`,
      fontWeight: `600`,
      lineHeight: `100%`,
      letterSpacing: ` -0.64px`,
      color: "gray.700",
    },
    td: {
      p: "10px",
      borderRight: "1px solid",
      borderBottom: "1px solid",
      borderColor: `gray.200`,
      ...numericStyles,
    },
    tfoot: {
      bg: `black.50`,
      th: {
        textTransform: `none`,
        borderRight: "1px solid",
        borderTop: "1px solid",
        borderColor: `gray.400`,
      },
    },
  };
});

const variants = {
  primary: variantPrimary,
};

export const tableTheme = defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: "primary",
    size: "md",
    colorScheme: "gray",
  },
});
