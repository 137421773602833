import {
  Select,
  Props as SelectProps,
  ChakraStylesConfig,
} from "chakra-react-select";

/**
 * An attempt to make a reusable chakra-react-select form component
 *
 * @param props - The combined props of the chakra-react-select component and the useController hook
 */
function ControlledSelectNoForm({
  isRequired,
  options,
  ...selectProps
}: SelectProps) {
  const customChakraStyles: ChakraStylesConfig = {
    menu: (provided, _state) => ({
      ...provided,
      marginTop: "1px",
    }),
    control: (provided, _state) => ({
      ...provided,
      _disabled: {
        opacity: "1",
        backgroundColor: "gray.100",
        borderColor: "gray.300",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      border: "1px solid",
      borderColor: "gray.300",
      borderRadius: "0.1875rem",
      boxShadow:
        "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
    }),
    option: (provided) => ({
      ...provided,
      padding: "0.5rem",
      gap: "var(--chakra-space-2)",
      color: "gray.900",
      fontSize: "13px",
      lineHeight: "16px",
      letterSpacing: "-0.3px",
      fontWeight: "400",
    }),
  };

  return (
    <Select
      options={options}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      chakraStyles={customChakraStyles}
      {...selectProps}
    />
  );
}

export { ControlledSelectNoForm };
