import { Portal } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  MdMoreVert,
  MdOutlineEdit,
  MdOutlineGroup,
  MdStoreMallDirectory,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages.ts";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Icon } from "@/design/components/icon";
import { IconButton } from "@/design/components/icon-button";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { useDeleteProjectMutation } from "@/features/ws-manager";
import { ProjectSchema } from "@/features/ws-manager/types";
import { useAppDispatch } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { CATEGORY, PERMISSIONS } from "@/utils/enums.ts";

export const ProjectDropdown = (props: ProjectSchema) => {
  const [deleteProject] = useDeleteProjectMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useShowToast();
  const onDelete = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...MODAL_MESSAGES.deleteProject,
          primaryAction: () => {
            deleteProject({ id: props.id, wsId: props.workspaceId! });
            toast(TOAST_MESSAGES.projectDeleted(props.name));
          },
        },
      })
    );
  };
  const openWorkspace = () => {
    navigate(`/home/${props.workspaceId}`);
  };

  const onEdit = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.PROJECT_CREATION,
        modalProps: { data: { ...props, wsId: props.workspaceId! } },
      })
    );
  };

  const onViewInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.INFO,
        modalProps: { type: CATEGORY.Project, data: props, navigate },
      })
    );
  };

  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: props, type: CATEGORY.Project, navigate },
      })
    );
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        icon={<MdMoreVert />}
        variant="ghost"
        colorScheme="dark"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Portal>
        <MenuList>
          <MenuItem
            className="text-gray-900"
            icon={<Icon as={AiOutlineInfoCircle} />}
            onClick={onViewInfo}
          >
            View Project Info
          </MenuItem>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Project}
          >
            <MenuItem
              className="text-gray-900"
              icon={<Icon as={MdOutlineEdit} />}
              onClick={onEdit}
            >
              Edit Project Details
            </MenuItem>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermission={PERMISSIONS.WRITE}
            id={props.id}
            type={CATEGORY.Project}
          >
            <MenuItem
              className="text-gray-900"
              onClick={openManageAccessModal}
              icon={<Icon as={MdOutlineGroup} />}
            >
              Manage Access
            </MenuItem>
          </ProtectedComponent>
          <MenuItem
            className="text-gray-900"
            onClick={openWorkspace}
            icon={<Icon as={MdStoreMallDirectory} />}
          >
            Open Workspace
          </MenuItem>

          <ProtectedComponent
            requiredPermission={PERMISSIONS.DELETE}
            id={props.id}
            type={CATEGORY.Project}
          >
            <MenuItem
              color="red.600"
              onClick={onDelete}
              icon={<Icon as={RiDeleteBin6Line} />}
            >
              Delete Project
            </MenuItem>
          </ProtectedComponent>
        </MenuList>
      </Portal>
    </Menu>
  );
};
