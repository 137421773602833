import { extendTheme } from "@chakra-ui/react";

// import { Avatar } from "./components";
import { colors } from "./colors";
import {
  Avatar,
  Breadcrumb,
  ConfigurePanel,
  Tabs,
  Modal,
  Menu,
  NavigationPanel,
  Button,
  TitleBar,
  Table,
  Stepper,
  Input,
  TextArea,
  Switch,
  NumberInput,
  Checkbox,
  FormLabel,
  FormControl,
  FormError,
  RadioTheme,
  Card,
  Alert,
} from "./components";

// Global style overrides
// import styles from './styles'

// Foundational style overrides
// import borders from './foundations/borders'

// Component style overrides
// import Button from './components'

const overrides = {
  // styles,
  // borders,
  // Other foundational style overrides go here
  components: {
    Avatar: Avatar,
    Breadcrumb: Breadcrumb,
    ConfigurePanel,
    Tabs,
    Modal,
    Menu,
    NavigationPanel,
    Button,
    TitleBar,
    Table,
    Stepper,
    Input: Input,
    Textarea: TextArea,
    Switch,
    NumberInput,
    Checkbox,
    Radio: RadioTheme,
    FormLabel,
    Form: FormControl,
    FormError: FormError,
    Card,
    Alert,
    // Other components go here
  },
  colors: colors,
  fonts: {
    heading: `'Albert Sans', sans-serif`,
    body: `'Albert Sans', sans-serif`,
  },
};

export default extendTheme(overrides);
