import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdClear, MdSearch } from "react-icons/md";

import { IconButton } from "@/design/components/icon-button";
import { useGetFlowsQuery } from "@/features/workflow-studio";
import { FlowSchema, NodeType } from "@/features/workflow-studio/types";
import { FLOW_TYPES } from "@/features/workflow-studio/utils/constants";

import { FlowItem } from "./flow-item";

const SearchFlows = () => {
  const { data: { flows } = { flows: [] } } = useGetFlowsQuery({
    flowType: FLOW_TYPES.ALL_FLOWS,
  });

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchedFlows, setSearchedFlows] = useState<FlowSchema[]>([]);

  function searchFlows(e: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = e.target.value;
    if (searchValue === "") {
      setSearchedFlows([]);
      return;
    }
    const filteredFlows = flows.filter((flow: FlowSchema) => {
      return (
        flow.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
        flow.tags.includes(searchValue.toLowerCase())
      );
      // flow.author.toLowerCase().includes(searchValue.toLowerCase()) ||
      // flow.nodes.some((node) =>
      //   node.display_name.toLowerCase().includes(searchValue.toLowerCase())
      // )
    });
    setSearchedFlows(filteredFlows);
  }

  const closeSearch = () => {
    setIsSearchOpen(false);
    setSearchedFlows([]);
  };

  return (
    <>
      <IconButton
        onClick={() => setIsSearchOpen(true)}
        variant={"ghost"}
        px={2}
        py={5}
        rounded="sm"
        colorScheme="dark"
        aria-label="searchFlows"
        className="absolute right-0 ml-2"
        size="sm"
        icon={<MdSearch size={23} />}
      />
      {isSearchOpen && (
        <Box
          pos={"absolute"}
          zIndex={"999"}
          flexDir={"column"}
          display={"flex"}
          w="99.5%"
          h="99%"
          bg="white"
          rounded={"md"}
        >
          <Flex>
            <InputGroup>
              <InputLeftElement color={"gray.500"} pointerEvents="none">
                <MdSearch size={18} />
              </InputLeftElement>
              <Input
                className="!text-sm"
                border={"none"}
                onChange={searchFlows}
                placeholder=""
                type="text"
              />
            </InputGroup>
            <IconButton
              aria-label="Close Search"
              variant={"ghost"}
              onClick={closeSearch}
              p={3}
              icon={<MdClear size={18} />}
            />
          </Flex>
          <Divider />
          <Flex
            align={"center"}
            justify="center"
            overflow={"hidden"}
            maxW={"100%"}
            h={"100%"}
            grow={1}
          >
            {searchedFlows.length > 0 ? (
              <Box className="p-0 grow flex-wrap-reverse gap-3 h-full max-w-full overflow-x-hidden">
                {searchedFlows.map((flow) => (
                  <FlowItem
                    flowType={FLOW_TYPES.ALL_FLOWS}
                    key={flow.nodeId}
                    flow={flow}
                  />
                ))}
              </Box>
            ) : (
              <span>Search Flows by name, tags, author or nodes</span>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default SearchFlows;
