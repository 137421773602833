import { Tooltip } from "@chakra-ui/react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

import { IChart } from "@/features/data-transformation";
import { CustomizedLabel } from "@/features/data-transformation/components/explore-data/histogram/custom-label.tsx";

type CustomTickProps = {
  x: number;
  y: number;
  payload: Payload<string, number>;
  visibleTicksCount: number;
};
export const CustomTick = (props: CustomTickProps) => {
  const { x, y, payload, visibleTicksCount } = props;

  const formatTickValue = (value: string) => {
    const limitPerSide = Math.max(5, 10 - visibleTicksCount); // Minimum 5 characters per side
    const separator = " - ";

    const formatPart = (part: string) => {
      if (part.length <= limitPerSide) return part;
      const decimalIndex = part.indexOf(".");
      if (decimalIndex !== -1 && decimalIndex <= limitPerSide) {
        return part.substring(0, decimalIndex + 2) + "..";
      }
      return part.substring(0, limitPerSide - 2) + "..";
    };

    const parts = value.split(separator);
    return parts.length === 2
      ? `${formatPart(parts[0])}${separator}${formatPart(parts[1])}`
      : formatPart(value);
  };

  const formattedValue = formatTickValue(payload.value ?? "");

  return (
    <Tooltip label={payload.value}>
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          className="text-xs"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {formattedValue}
        </text>
      </g>
    </Tooltip>
  );
};

export const HistogramChartFullSize = ({ data }: { data: IChart[] }) => {
  const maxValue = Math.max(...data.map((item) => item.value));

  return (
    <ResponsiveContainer width="100%" height="100%" className="py-12">
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="label"
          type="category"
          scale="auto"
          interval={0}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          tick={<CustomTick />}
        />
        <YAxis domain={[0, maxValue]} />
        <Bar
          dataKey="value"
          fill="var(--chakra-colors-green-100)"
          label={<CustomizedLabel />}
        >
          {data?.map((_, index) => <Cell key={`cell-${index}`} />)}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
