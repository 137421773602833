import { Flex, PopoverAnchor, Text } from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { forwardRef, useContext, useMemo, useState } from "react";
import { MdsCloseRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";
import {
  FilterContext,
  getFilterIcon,
  getFilterText,
} from "@/features/data-transformation";

type FilterDisplayProps = {
  isOpen: boolean;
  onToggle: () => void;
  allFilterOptions: string[];
  removeFilter: () => void;
  hasAnchor?: boolean;
};

export const FilterDisplay = forwardRef<HTMLElement, FilterDisplayProps>(
  (props, ref) => {
    const { filter } = useContext(FilterContext)!;

    const [showClose, setShowClose] = useState(false);
    const hasValue =
      filter.value || (filter.values && filter.values?.length > 0);
    const hasColumn = !isEmpty(filter.column);

    const showCloseIcon = useMemo(
      () => showClose || props.isOpen,
      [showClose, props.isOpen]
    );

    const filterIconStyle =
      "border bg-white text-xs font-medium font-inconsolata leading-[110%] max-w-[500px] px-1 justify-center border-gray-600 rounded-[3px] tracing-[-0.12px]";

    return (
      <Flex
        className={clsx(
          "font-inconsolata items-center w-fit  border rounded px-1 shadow-tags cursor-pointer",
          props.isOpen
            ? "bg-gray-300 border-gray-700"
            : "bg-white border-gray-600"
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={ref}
        onClick={props.onToggle}
        onMouseEnter={() => setShowClose(true)}
        onMouseLeave={() => setShowClose(false)}
      >
        <Text
          className={clsx(
            "text-xs font-medium leading-[125%] truncate text-gray-900 p-1.5",
            hasColumn ? "opacity-100" : "opacity-40"
          )}
          flexGrow={1}
          flexShrink={1}
          flexBasis="auto"
          minW={0}
        >
          {hasColumn ? filter.column : "column"}
        </Text>
        {props.hasAnchor ? (
          <PopoverAnchor>
            <Flex className={filterIconStyle} bg="white">
              {getFilterIcon(filter)}
            </Flex>
          </PopoverAnchor>
        ) : (
          <Flex className={filterIconStyle} bg="white">
            {getFilterIcon(filter)}
          </Flex>
        )}

        <Text
          className={clsx(
            "font-inconsolata text-xs font-medium leading-[125%] max-w-[150px] truncate text-gray-900 p-1.5",
            hasValue ? "opacity-100" : "opacity-40"
          )}
          flexGrow={0}
          flexShrink={1}
          flexBasis="auto"
          minW="30px"
        >
          {hasValue ? getFilterText(filter, props.allFilterOptions) : "value"}
        </Text>
        {showCloseIcon && (
          <Icon
            size="sm"
            as={MdsCloseRound}
            onClick={props.removeFilter}
            flexShrink={0}
          />
        )}
      </Flex>
    );
  }
);
