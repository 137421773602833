import { Flex, Box } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useGetVersionHistoryQuery } from "../../api";
import { changePreviewVersion, getActivePlayground } from "../../redux";
import { TextGenVersion } from "../../types";

import HistoryDropdown from "./history-dropdown";
import RevertHistory from "./revert-history";

const LLMHistoryPanel = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const currentPlayground = useAppSelector(getActivePlayground);

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetVersionHistoryQuery(
    {
      playgroundId: currentPlayground?.id ?? "",
      textGenId: currentPlayground?.textGeneration[0].id ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const versions: TextGenVersion[] | undefined = useMemo(() => {
    if (!data) return [];
    return data?.response.data?.textGenerationRuns;
  }, [data]);

  useEffect(() => {
    if (versions && versions.length > 0) {
      const version = versions[activeIndex];
      dispatch(changePreviewVersion(version));
    }
  }, [activeIndex, versions, dispatch]);

  return (
    <Flex className="bg-white p-3" align={"center"} justify={"space-between"}>
      <Box className="uppercase font-semibold tracking-tight">
        Prompt History
      </Box>
      <HistoryDropdown
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        versions={versions}
        isLoading={isLoading}
      />
      <RevertHistory activeIndex={activeIndex} versions={versions} />
    </Flex>
  );
};

export default LLMHistoryPanel;
