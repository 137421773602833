import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import AnalysisForm from "./analysis-form.tsx";

const CreateAnalysisModal = (data: any) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  const title = data?.analysis?.id
    ? "Edit Analysis Details"
    : "Create New Analysis";

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      size={"xl"}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="px-6">
          <AnalysisForm
            data={data?.analysis}
            parentWs={data?.workspace}
            parentProject={data?.project}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateAnalysisModal;
