import { Flex } from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";

import { Icon } from "@/design/components/icon";
import { StepFormContext } from "@/features/data-transformation/components/step-form/step-form-context.ts";
import {
  STEP_MESSAGE,
  STEP_STATUS,
} from "@/features/data-transformation/utils";

export const StepMessage = ({ type }: { type: STEP_STATUS }) => {
  const { step } = useContext(StepFormContext)!;

  const style = useMemo(() => {
    switch (type) {
      case STEP_STATUS.Removed:
        return "bg-white border border-gra-300 rounded-sm";
      case STEP_STATUS.Failed:
      default:
        return "bg-red-60 border border-red-600 rounded-sm text-red-600";
    }
  }, [type]);

  if (type === STEP_STATUS.Active) return null;

  const messageDetails = STEP_MESSAGE[type];

  if (isEmpty(messageDetails)) return null;

  return (
    <Flex
      className={clsx(
        "p-2.5 flex-col gap-y-1 text-xs leading-[140%] tracking-[-0.24px]",
        style
      )}
    >
      <Flex className="justify-start items-center text-xm font-medium tracking-[-0.26px] gap-x-0.5">
        <Icon as={messageDetails.icon} size="sm" />
        {messageDetails?.heading}
      </Flex>
      {step?.additionalInfo?.message ?? messageDetails.description}
    </Flex>
  );
};
