import { Flex, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useContext, useMemo, useState } from "react";
import {
  MdsAddRound,
  MdsChevronRightRound,
} from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { FilterAltFilled } from "@/components/icons/filter-alt.tsx";
import { Icon } from "@/design/components/icon";
import {
  currentOpenFilter,
  executeInProgress,
  FILTER_TYPE,
  FilterContext,
  isStepFormFilterMenuOpen,
  ITransformationStep,
  IViewFiltersRequest,
  selectedStepFilters,
  selectedStepTempFilter,
  setCurrentOpenFilter,
  setCurrentStepFilters,
  setIsStepFormFilterMenuOpen,
  setSelectedStep,
  setSelectedStepTempFilter,
  tableLoading,
} from "@/features/data-transformation";
import { FilterItem } from "@/features/data-transformation/components/filter-row/filter-item.tsx";
import { StepFormContext } from "@/features/data-transformation/components/step-form/step-form-context.ts";

export const FilterForm = () => {
  const dispatch = useDispatch();

  const { step, editEnabled } = useContext(StepFormContext)!;
  const filters = useSelector(selectedStepFilters);
  const temporaryFilters = useSelector(selectedStepTempFilter);
  const isTableLoading = useSelector(tableLoading);
  const isExecuting = useSelector(executeInProgress);

  const isFilterOpen = useSelector(isStepFormFilterMenuOpen);
  const currentFilter = useSelector(currentOpenFilter);

  const filterList = useMemo(() => {
    if (!temporaryFilters) return filters;
    return [...filters, temporaryFilters];
  }, [filters, temporaryFilters]);

  const [showFilters, setShowFilters] = useState(false);

  const hasFilter = !isEmpty(filterList);

  const onToggle = () => {
    setShowFilters(!showFilters);
  };

  const onAddFilter = () => {
    const filterData: IViewFiltersRequest = {
      column: "",
      filterType: FILTER_TYPE.VALUE,
    };

    dispatch(setCurrentOpenFilter(filterData.column));
    dispatch(setSelectedStepTempFilter(filterData));

    dispatch(setIsStepFormFilterMenuOpen(true));
    setShowFilters(true);
  };

  const changeFilterOpenState = (state: boolean) => {
    dispatch(setIsStepFormFilterMenuOpen(state));
  };

  const changeCurrentOpenFilter = (filter: string | null) => {
    dispatch(setCurrentOpenFilter(filter));
  };

  const changeTempFilter = (value: IViewFiltersRequest | null) => {
    dispatch(setSelectedStepTempFilter(value));
  };

  const updateSavedStep = (updatedFilters: IViewFiltersRequest[]) => {
    const newStep = {
      ...step,
      config: {
        ...(step?.config ?? {}),
        filters: updatedFilters,
      },
    };

    dispatch(setSelectedStep(newStep as ITransformationStep));
  };

  const updateSavedFilters = (
    updatedFilters: IViewFiltersRequest[],
    forStepForm = false
  ) => {
    /**  This is a condtion for when filter is in step form and we change column for a saved filer.
     **  For such case we dont want to modify the step as a whole but just the filter value.
     ** Thus if it is closed without being applied we can revert it back to original value
     **/

    if (forStepForm) {
      dispatch(setCurrentStepFilters(updatedFilters));
      return;
    }
    updateSavedStep(updatedFilters);
  };

  if (!hasFilter) {
    return (
      <Flex className="justify-between items-center bg-gray-50 p-2 border-b border-b-gray-200">
        <Flex className="items-center text-gray-500 text-xs">
          <Icon as={FilterAltFilled} size="sm" color="color.500" />
          No Filters
        </Flex>
        {editEnabled && (
          <Button
            colorScheme="secondary"
            leftIcon={<Icon as={MdsAddRound} size="xs" />}
            onClick={onAddFilter}
            size="xs"
            variant="ghost"
          >
            Add Filter
          </Button>
        )}
      </Flex>
    );
  }

  return (
    <Flex className="flex-col  w-full bg-gray-100 p-2 border-b border-b-gray-200">
      <Flex className="justify-between items-center">
        <Flex className="items-center text-gray-800 text-xs">
          <Icon as={FilterAltFilled} size="sm" color="gray.800" />
          Filters Applied ({filterList?.length ?? 0})
        </Flex>
        <Flex
          as={motion.div}
          color="gray.500"
          cursor="pointer"
          animate={{
            rotate: !showFilters ? 90 : -90,
            transition: { duration: 0.2, type: "spring" },
          }}
          aria-label="collapse"
          onClick={onToggle}
        >
          <Icon as={MdsChevronRightRound} size="md" color="gray.900" />
        </Flex>
      </Flex>
      {showFilters && (
        <Flex className="flex-col mt-3">
          <Flex className="gap-1.5 flex-wrap">
            {filterList.map((item, index) => (
              <FilterContext.Provider
                value={{
                  filter: item,
                  appliedFilters: filters,
                  isFilterOpen: isFilterOpen,
                  currentFilter: currentFilter,
                  editDisabled: !editEnabled || isTableLoading || isExecuting,
                  placement: "right-start",
                  previousColumns:
                    step?.metadata?.onSample?.previousStepColumnInfo,
                  changeFilterOpenState,
                  changeCurrentOpenFilter,
                  changeTempFilter,
                  updateSavedFilters,
                  originalFilters: step?.config?.filters,
                }}
                key={index}
              >
                <FilterItem key={index} />
              </FilterContext.Provider>
            ))}
          </Flex>
          {editEnabled && (
            <Button
              className="self-start mt-2"
              colorScheme="secondary"
              leftIcon={<Icon as={MdsAddRound} size="xs" />}
              onClick={onAddFilter}
              size="xs"
              variant="ghost"
            >
              Add More
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};
