import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  Flex,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { MdHelp } from "react-icons/md";
import { MdsAttachMoneyRound } from "react-icons-with-materialsymbols/mds";

import { useAppSelector } from "@/reduxHooks";

import {
  currentActiveVersion,
  getPreviewVersion,
  showPromptHistory,
} from "../../redux";

const LLMCostEstimationCard = () => {
  const activeVersion = useAppSelector(currentActiveVersion);
  const previewVersion = useAppSelector(getPreviewVersion);
  const isHistoryOpen = useAppSelector(showPromptHistory);
  const estimatedCost = useMemo(() => {
    const cost = isHistoryOpen
      ? previewVersion?.estimatedCost
      : activeVersion?.estimatedCost;
    if (!isEmpty(cost?.minimumCost?.toString()) && !isEmpty(cost?.maximumCost?.toString())) {
      if (cost?.currency === "$") {
        return `~ ${cost?.currency} ${cost?.minimumCost} - ${cost?.maximumCost}`;
      } else {
        return `~ ${cost?.minimumCost} - ${cost?.maximumCost} ${cost?.currency}`;
      }
    } else if (!isEmpty(cost?.estimatedCost?.toString())) {
      return `~ ${cost?.estimatedCost} ${cost?.currency}`;
    }
    return "-";
  }, [isHistoryOpen, activeVersion, previewVersion]);

  return (
    <Popover placement="top-end">
      <PopoverTrigger>
        <Flex
          className="!text-gray-800 font-medium cursor-pointer"
          align={"center"}
          gap={2}
        >
          <Box className="text-sm">Estimated Cost</Box>
          <Box className="text-green-600 text-sm font-semibold">
            {estimatedCost}
          </Box>
          <MdHelp className="text-green-600" />
        </Flex>
      </PopoverTrigger>
      <PopoverContent className="max-w-[250px] !shadow-2xl">
        <PopoverArrow />
        <PopoverBody className="flex flex-col gap-3 text-sm font-medium !p-4 !text-gray-800">
          <Flex align={"center"} gap={2} w={"100%"}>
            <Box className="text-green-500 w-5 h-5 grid place-items-center border border-green-100 bg-green-50 rounded-full">
              <MdsAttachMoneyRound className="stroke-[22]" />
            </Box>
            <Box className="font-semibold">API Cost Estimation</Box>
            <PopoverCloseButton className="!relative !top-0 !right-0 !ml-auto" />
          </Flex>
          <Flex className="flex-col" gap={3}>
            <Box>
              The cost for executing this node is based on the costs incurred in
              your test runs.
            </Box>
            <Box>Values are indicative and actual costs may vary.</Box>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default LLMCostEstimationCard;
