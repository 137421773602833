import { Portal } from "@chakra-ui/portal";
import { Popover, PopoverAnchor } from "@chakra-ui/react";
import { forwardRef, useContext, useMemo, useRef, useState } from "react";

import { FilterContext } from "@/features/data-transformation";
import { useOnClickOutside } from "@/hooks/useOutsideClick.ts";

import { FilterDropdown } from "../filter-dropdown";

import { FilterDisplay } from "./filter-display";

export const FilterItem = forwardRef((_props, _ref) => {
  const {
    appliedFilters: filters,
    filter,
    isFilterOpen,
    currentFilter,
    placement,
    changeFilterOpenState,
    changeCurrentOpenFilter,
    changeTempFilter,
    updateSavedFilters,
    originalFilters,
  } = useContext(FilterContext)!;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const crossButtonRef = useRef<HTMLDivElement>(null);

  const isSavedFilter = useMemo(() => {
    const isSaved = filters.find((_item) => _item.column === filter.column);

    return !!isSaved;
  }, [filter, filters]);

  const [allFilterOptions, setAllFilterOptions] = useState<string[]>([]);

  const isOpen = useMemo(() => {
    return isFilterOpen && currentFilter === filter.column;
  }, [isFilterOpen, currentFilter, filter, filters]);

  const outsideClickHandler = () => {
    if (!isOpen) return;
    if (originalFilters) {
      updateSavedFilters(originalFilters);
    }

    changeTempFilter(null);
    onClose();
  };
  useOnClickOutside(
    { skipIf: crossButtonRef },
    dropdownRef,
    outsideClickHandler
  );

  const onClose = () => {
    changeFilterOpenState(false);
  };

  const onToggle = () => {
    if (isOpen) {
      changeCurrentOpenFilter(null);
    } else {
      changeCurrentOpenFilter(filter.column);
    }
    changeFilterOpenState(!isOpen);
  };

  const removeFilter = () => {
    if (isSavedFilter) {
      const updatedFilters = filters.filter(
        (_filter) => _filter.column !== filter.column
      );
      updateSavedFilters(updatedFilters);
    } else {
      changeTempFilter(null);
    }

    closeFilter();
  };

  const closeFilter = () => {
    changeCurrentOpenFilter(null);
    changeFilterOpenState(false);
  };

  return (
    <Popover
      closeOnBlur={false}
      isLazy={true}
      isOpen={isOpen}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lazyBehavior="unmount"
      onClose={onClose}
      placement={placement}
      returnFocusOnClose={false}
    >
      {placement == "bottom" ? (
        <FilterDisplay
          isOpen={isOpen}
          onToggle={onToggle}
          allFilterOptions={allFilterOptions}
          removeFilter={removeFilter}
          ref={crossButtonRef}
          hasAnchor={true}
        />
      ) : (
        <PopoverAnchor>
          <FilterDisplay
            isOpen={isOpen}
            onToggle={onToggle}
            allFilterOptions={allFilterOptions}
            removeFilter={removeFilter}
            ref={crossButtonRef}
          />
        </PopoverAnchor>
      )}
      <Portal>
        <FilterDropdown
          col={filter.column}
          setAllFilterOptions={setAllFilterOptions}
          removeFilter={removeFilter}
          closeFilter={closeFilter}
          isOpen={isOpen}
          ref={dropdownRef}
        />
      </Portal>
    </Popover>
  );
});
