import { Tooltip } from "@chakra-ui/react";
import { clsx } from "clsx";
import { useMemo } from "react";
import { LabelProps } from "recharts";

import {
  nullParser,
  tickFormatter,
} from "@/features/data-transformation/utils";

type CustomLabelProps = {
  short?: boolean;
};

export const CustomizedLabel = (props: LabelProps & CustomLabelProps) => {
  const { x = 0, y, fill, value, name, short } = props;
  const { isNull, parsedValue } = useMemo(
    () => nullParser(name),
    [name, value]
  );

  return (
    <Tooltip label={name}>
      <text
        x={x + 4}
        y={y}
        dy={16}
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="start"
        className={clsx(
          "relative w-full text-[13px] leading-[120%] text-gray-900 max-w-full break-all truncate",
          isNull && "italic",
          short && "text-[12px]"
        )}
      >
        {tickFormatter(parsedValue ?? "", value?.toString().length, short)} (
        {value})
      </text>
    </Tooltip>
  );
};
