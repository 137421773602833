import { Divider } from "@chakra-ui/layout";
import { CircularProgress, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { IDataSource } from "@/features/data-manager/types";
import { RootState } from "@/store";
import { SOURCE_FILE_TYPE } from "@/utils/enums.ts";

import { useFileUpload } from "../../hooks/useUploadFile.ts";

import { AddDatasetNavigation } from "./navigation.tsx";
import { SelectConnector } from "./select-connector.tsx";

const progressComponent = (progress: number = 0) => {
  const isIndeterminate = progress === 0 || progress >= 100;
  return (
    <CircularProgress
      color="blue.600"
      isIndeterminate={isIndeterminate}
      size={5}
      thickness="12px"
      value={progress}
    />
  ) as React.ReactNode;
};

export const AddNewDatasetModal: React.FC = () => {
  // might be needed later when we add the ability to filter by source
  const sideNavBarOptions = ["All"];
  const [selectedNavValue, setSelectedNavValue] = useState("All");

  const { modalProps } = useSelector(
    (s) => (s as RootState).rootReducer.modals
  );
  const { handleFileChange, onClose } = useFileUpload({
    progressComponent,
  });

  const handleConnectorButtonClick = (definition: any, event?: any) => {
    onClose();
    if (definition.name === SOURCE_FILE_TYPE.LOCAL_FILE) {
      handleFileChange(
        event as React.SyntheticEvent<EventTarget>,
        definition as IDataSource
      );
    } else {
      const url = `/analysis/${modalProps.analysisId}/data-manager/new-source/${definition.id}`;
      modalProps.navigate(url, {
        state: { source: definition },
      });
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent className="!max-w-[796px] w-full !pb-0 !gap-0">
        <ModalHeader>Add New Dataset</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <Divider className="mt-4" />
        <ModalBody>
          <Flex className="h-[calc(100vh/1.5)] w-full">
            <AddDatasetNavigation
              setSelectedNavValue={setSelectedNavValue}
              sideNavBarOptions={sideNavBarOptions}
            />
            <Flex className="flex-col h-full" style={{ width: "inherit" }}>
              <SelectConnector
                connectorType="source"
                onSelectConnectorDefinition={handleConnectorButtonClick}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
