import { Slide } from "@chakra-ui/react";
import React from "react";

import { Button } from "@/design/components/button";
import {
  ConfigurePanel,
  ConfigurePanelFooter,
  ConfigurePanelHeader,
} from "@/design/components/configure-panel";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { hidePanel, selectPanel } from "../..";
import { WORKFLOW_PANELS } from "../../utils/constants";

import { LogsPanelBody } from "./logs-panel-body";
import { LogsPanelHeader } from "./logs-panel-header";

export const LogsPanel = () => {
  const dispatch = useAppDispatch();
  const logsPanel = useAppSelector(selectPanel(WORKFLOW_PANELS.LogsPanel));

  const onClose = () => {
    dispatch(hidePanel(WORKFLOW_PANELS.LogsPanel));
  };

  return (
    <Slide
      className="!absolute top-0 !right-0 h-full p-5 !w-fit"
      direction="right"
      in={logsPanel.isVisible}
      style={{ zIndex: 1000 }}
      unmountOnExit
    >
      <ConfigurePanel className="max-h-full">
        <LogsPanelHeader onClose={onClose} />
        <LogsPanelBody />
      </ConfigurePanel>
    </Slide>
  );
};
