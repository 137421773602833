import { Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext, useMemo } from "react";

import { AddCircle } from "@/components/icons/add-circle.tsx";
import { ChangeCircle } from "@/components/icons/change-circle.tsx";
import { DoNotDisturbOn } from "@/components/icons/do-not-disturb.tsx";
import { FilterAltFilled } from "@/components/icons/filter-alt.tsx";
import { Icon } from "@/design/components/icon";
import {
  columnFromParameter,
  COMMA_SEPARATOR,
  FILTER_ROW_OPERATION_TYPE,
  STEP_STATUS,
} from "@/features/data-transformation";
import { formatNumberWithCommas } from "@/utils/string-utils.ts";

import { StepContext, StepContextType } from "./step-context.ts";

export const StepsRowTpe = () => {
  const { step, isOpen } = useContext(StepContext) as StepContextType;
  const column = useMemo(() => {
    const params = step.parameters;
    const getColumnFromParam = columnFromParameter(params);
    if (typeof getColumnFromParam == "string") {
      return getColumnFromParam?.replace(COMMA_SEPARATOR, ",");
    }
    return getColumnFromParam;
  }, [step]);

  const sampleImpact = useMemo(() => {
    const impact = step.metadata?.onSample;
    return impact;
  }, [step]);

  const impact = sampleImpact?.impactCount ?? -1;
  const impactType = sampleImpact?.impactRowsType;

  const filtersMap = step.config?.filters;
  const filters = filtersMap?.length ?? 0;
  const hasFiler = filters > 0;

  const type = step.activeStatus
    ? step?.status ?? STEP_STATUS.Active
    : STEP_STATUS.Removed;
  const color = useMemo(() => {
    switch (impactType) {
      case FILTER_ROW_OPERATION_TYPE.AddRow:
        return "green.500";
      case FILTER_ROW_OPERATION_TYPE.RemoveRows:
        return "red.500";
      case FILTER_ROW_OPERATION_TYPE.EditedRows:
        return "yellow.500";
      default:
        return "gray.700";
    }
  }, [step]);

  const columnColors = useMemo(() => {
    switch (type) {
      case STEP_STATUS.Removed:
        return {
          text: "gray.700",
          bg: "gray.200",
          bgHover: "gray.300",
          hoverText: "gray.900",
        };
      case STEP_STATUS.Failed:
        return { text: "red.600", bg: "red.100" };
      default:
        return { text: "gray.900", bg: "gray.50", bgHover: "gray.200" };
    }
  }, [step]);

  const icon = useMemo(() => {
    let stepIcon = null;
    switch (impactType) {
      case FILTER_ROW_OPERATION_TYPE.AddRow:
        stepIcon = AddCircle;
        break;
      case FILTER_ROW_OPERATION_TYPE.RemoveRows:
        stepIcon = DoNotDisturbOn;
        break;
      case FILTER_ROW_OPERATION_TYPE.EditedRows:
        stepIcon = ChangeCircle;
        break;
      case FILTER_ROW_OPERATION_TYPE.UNCHANGED:
        stepIcon = ChangeCircle;
        break;
      default:
        return null;
    }

    return <Icon as={stepIcon} size="sm" color={color} />;
  }, [step]);

  return (
    <>
      {isOpen && (
        <Flex
          className="items-center justify-between px-2 pb-2.5"
          as={motion.div}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 1 }}
        >
          <Flex className="items-center justify-start font-inconsolata">
            {icon}
            <Text className="ml-[1px] text-sm font-bold mr-2" color={color}>
              {impact >= 0 ? formatNumberWithCommas(impact.toString()) : ""}
            </Text>
            {hasFiler && (
              <Flex className="pr-1 rounded-[3px] bg-gray-100 font-inconsolata font-bold text-xs leading-[15px] items-center">
                <Icon as={FilterAltFilled} size="sm" />
                {filters}
              </Flex>
            )}
          </Flex>
          {column && (
            <Text
              className={`px-1 py-0.5 text-xs font-inconsolata font-medium rounded truncate max-w-[6rem]`}
              color={columnColors.text}
              bg={columnColors.bg}
              _groupHover={{
                bg: columnColors?.bgHover,
                color: columnColors.hoverText,
              }}
            >
              {column}
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};
