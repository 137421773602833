import { Portal } from "@chakra-ui/portal";
import { Text, Flex, Skeleton, Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import { useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  MdOutlineEdit,
  MdOutlineGroup,
  MdOutlineNoteAdd,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbChevronDown, TbChevronRight } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import { ProtectedComponent } from "@/components/protected-component/protected-component.tsx";
import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { StyledIcon } from "@/features/ws-manager/components/styled-icon.tsx";
import { ProjectSchema, WorkspaceSchema } from "@/features/ws-manager/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { CATEGORY, PERMISSIONS, USER_ROLES } from "@/utils/enums.ts";

import { useLazyGetWsQuery } from "../../api";
import useProjectActions from "../../hooks/useProject";
import useWorkspaceActions from "../../hooks/useWorkspace";

type AnalysisHeaderProps = {
  icon?: string;
  emoji?: string;
  isLast?: boolean;
  label?: string;
  type: CATEGORY;
  data: ProjectSchema | WorkspaceSchema | null;
  ws?: WorkspaceSchema | null;
  isLoading?: boolean;
};
export const AnalysisHeaderButton = ({
  icon,
  emoji,
  isLast = false,
  isLoading = false,
  label = "",
  type,
  ws,
  data,
}: AnalysisHeaderProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(currentUserMetadata);

  const showCreateNewButton = userData?.role !== USER_ROLES.CTK_ADMIN;

  const [fetchWS] = useLazyGetWsQuery();
  const { deleteWorkspace, editWorkspace, createNewWorkspace } =
    useWorkspaceActions();

  const {
    deleteProject: delProjectAction,
    editProject,
    createNewProject,
  } = useProjectActions();

  useEffect(() => {
    if (ws) {
      fetchWS(ws.id).catch((e) => console.log(e));
    }
  }, [ws]);

  const onDelete = () => {
    if (!data) return; //TODO handle error

    switch (type) {
      case CATEGORY.Project:
        delProjectAction(data as ProjectSchema);
        break;
      case CATEGORY.Workspace:
        deleteWorkspace(data as WorkspaceSchema);
        break;
    }
  };

  const onEdit = () => {
    if (!data) return; //TODO handle error

    switch (type) {
      case CATEGORY.Project:
        editProject(data as ProjectSchema);
        break;
      case CATEGORY.Workspace:
        editWorkspace(data as WorkspaceSchema);
        break;
    }
  };

  const onViewInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.INFO,
        modalProps: { type: type, data: data, navigate },
      })
    );
  };

  const onCreateNew = () => {
    switch (type) {
      case CATEGORY.Project:
        createNewProject(data as ProjectSchema);
        break;
      case CATEGORY.Workspace:
        createNewWorkspace();
        break;
    }
  };

  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: data, type, navigate },
      })
    );
  };

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex className="items-center">
        <StyledIcon
          title={type === CATEGORY.Workspace ? label : undefined}
          emoji={emoji}
          icon={icon}
        />
        <Flex className="items-center px-1">
          <Menu isLazy>
            {({ isOpen }) => {
              const openIcon = isOpen ? TbChevronDown : TbChevronRight;
              const dropdownIcon = isLast ? TbChevronDown : openIcon;
              return (
                <>
                  <Tooltip
                    className="!text-xs !max-w-[200px] !whitespace-pre-wrap !rounded !py-1"
                    aria-label={label}
                    arrowSize={7}
                    hasArrow
                    label={label}
                    openDelay={400}
                    placement="top"
                  >
                    <MenuButton className="group">
                      <Flex className="items-center lg:max-w-[20vw] max-w-[15vw]">
                        <Text className="capitalize overflow-hidden whitespace-pre text-ellipsis">
                          {label}
                        </Text>
                        <Icon
                          as={dropdownIcon}
                          className={clsx(
                            "pt-1 opacity-1 group-hover:opacity-100",
                            isLast && "opacity-0",
                            !isLast && !isOpen && "group-hover:rotate-90"
                          )}
                          size="md"
                          color="gray.700"
                        />
                      </Flex>
                    </MenuButton>
                  </Tooltip>

                  <Portal>
                    <MenuList sx={{ marginRight: "4px" }}>
                      {showCreateNewButton && (
                        <ProtectedComponent
                          requiredPermission={PERMISSIONS.CREATE}
                          id={data?.id}
                          type={type}
                        >
                          <MenuItem
                            className="text-gray-900"
                            icon={<Icon as={MdOutlineNoteAdd} />}
                            onClick={onCreateNew}
                          >
                            Create New {type}
                          </MenuItem>
                        </ProtectedComponent>
                      )}
                      <ProtectedComponent
                        requiredPermission={PERMISSIONS.READ}
                        id={data?.id}
                        type={type}
                      >
                        <MenuItem
                          className="text-gray-900"
                          icon={<Icon as={AiOutlineInfoCircle} />}
                          onClick={onViewInfo}
                        >
                          View {type} Info
                        </MenuItem>
                      </ProtectedComponent>
                      <ProtectedComponent
                        requiredPermission={PERMISSIONS.WRITE}
                        id={data?.id}
                        type={type}
                      >
                        <MenuItem
                          className="text-gray-900"
                          icon={<Icon as={MdOutlineEdit} />}
                          onClick={onEdit}
                        >
                          Edit {type} Details
                        </MenuItem>
                      </ProtectedComponent>
                      <ProtectedComponent
                        requiredPermission={PERMISSIONS.WRITE}
                        id={data?.id}
                        type={type}
                      >
                        <MenuItem
                          className="text-gray-900"
                          icon={<Icon as={MdOutlineGroup} />}
                          onClick={openManageAccessModal}
                        >
                          Manage Access
                        </MenuItem>
                      </ProtectedComponent>
                      <ProtectedComponent
                        requiredPermission={PERMISSIONS.DELETE}
                        id={data?.id}
                        type={type}
                      >
                        <MenuItem
                          color="red.600"
                          onClick={onDelete}
                          icon={<Icon as={RiDeleteBin6Line} />}
                        >
                          Delete {type}
                        </MenuItem>
                      </ProtectedComponent>
                    </MenuList>
                  </Portal>
                </>
              );
            }}
          </Menu>
        </Flex>
      </Flex>
    </Skeleton>
  );
};
