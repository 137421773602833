import { useRef, useCallback, useEffect } from "react";

export const useSingletonDebounce = <T extends (...args: any) => any>(
  fn: T,
  delay: number,
  id?: string
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const functionRef = useRef(fn);

  // Update the function ref on each render, so the latest version is used
  functionRef.current = fn;

  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        functionRef.current(...args);
      }, delay);
    },
    [delay]
  );

  // If id changes, clear the existing timeout
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [id]);

  return debouncedFn;
};
