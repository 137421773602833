import { Flex, Text } from "@chakra-ui/react";

import Tag from "./tag.tsx";

interface SuggestedTagsProps {
  tags: string[];
  onSelect: (tag: string) => void;
}
export const SuggestedTags = ({ tags, onSelect }: SuggestedTagsProps) => {
  return (
    <Flex className="justify-start gap-1 self-start flex-wrap">
      <Text className="text-gray-700 text-sm2">Suggested tags:</Text>
      {tags?.map((tag) => (
        <Tag
          key={tag}
          text={tag}
          disabled={true}
          onClick={onSelect}
          className="h-5"
        />
      ))}
    </Flex>
  );
};
