import useResizeObserver from "@react-hook/resize-observer";
import React, { RefObject } from "react";

export const useSize = <T extends HTMLDivElement>(target: RefObject<T>) => {
  const [size, setSize] = React.useState<DOMRectReadOnly | null>();

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
