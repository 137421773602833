import { Flex, Text } from "@chakra-ui/react";
import { MdsLabResearchRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";

export const EmptyExploreData = () => {
  return (
    <Flex className="flex-col h-full w-full items-center justify-center p-4 gap-y-4">
      <Flex className="bg-explorePanel justify-center p-12 rounded-t-[500px] w-[186px] h-[121px]">
        <Icon as={MdsLabResearchRound} size="2xl" color="brand.400" />
      </Flex>
      <Text className="text-center">
        Select a column to begin exploring data
      </Text>
    </Flex>
  );
};
