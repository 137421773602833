interface ModalMessage {
  title: string;
  description: string;
  subDescription?: string; // Optional property
}
export const MODAL_MESSAGES: { [key: string]: ModalMessage } = {
  deleteProject: {
    title: "Delete Project?",
    description:
      "Are you sure you want to move this project to the Recycle Bin? Any ongoing runs will be terminated and all users will lose access to the project.",
    subDescription:
      "Note: All items in the bin will be permanently deleted after 30 days.",
  },
  deleteAnalysis: {
    title: "Delete Analysis?",
    description:
      "Are you sure you want to move this analysis to the Recycle Bin? Any ongoing runs will be terminated and all users will lose access to the analysis.",
    subDescription:
      "Note: All items in the bin will be permanently deleted after 30 days.",
  },
  deleteWorkspace: {
    title: "Delete Workspace?",
    description:
      "Are you sure you want to move this workspace to the Recycle Bin? Any ongoing runs will be terminated and all users will lose access to the Workspace. ",
    subDescription:
      "Note: All items in the bin will be permanently deleted after 30 days.",
  },
  restoreProject: {
    title: "Restore Project?",
    description:
      "This will restore all Analyses and Dashboards. Access will also be restored for all the users that had access to the Project at the time of deletion.",
    subDescription: "Are you sure that you would like to proceed?",
  },
  restoreAnalysis: {
    title: "Restore Analysis?",
    description:
      "Restoring the Analysis will also restore all the associated Dashboards. Access will also be restored for all the Users that had access to the Analysis at the time of deletion.",
    subDescription: "Are you sure that you would like to proceed?",
  },
  restoreWorkspace: {
    title: "Restore Workspace?",
    description:
      "This will restore all Projects, Analyses and Dashboards. Access will also be restored for all the users that had access to the Workspace at the time of deletion.",
    subDescription: "Are you sure that you would like to proceed?",
  },
};
