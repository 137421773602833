import { Box, Button, Flex } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState } from "react";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppSelector } from "@/reduxHooks";

import { useCreateUserAccessMutation, useInviteUsersMutation } from "../../api";
import { UserProps } from "../manage-access/add-user-input";

const InviteAndShareBody = ({ onClose }: { onClose: () => void }) => {
  const toast = useShowToast(undefined, undefined, true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);

  const [inviteUsers] = useInviteUsersMutation();
  const [createAccess] = useCreateUserAccessMutation();

  const handleShare = async (userIds: string[]) => {
    const allIds = [
      ...userIds,
      ...modalProps.selectedUsers.flatMap((u: UserProps) => u.userId),
    ];
    try {
      const promises = allIds.map((u) => {
        return createAccess({
          assigneeUserId: u,
          objectId: modalProps.object.id,
          role: modalProps.selectedRole.toLowerCase(),
          scope: modalProps.type,
        }).unwrap();
      });
      await Promise.all(promises);
      toast({
        title: `${allIds.length} ${modalProps.selectedRole}(s) added`,
        status: ToastType.Success,
      });
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
    onClose();
  };

  const onInvite = async () => {
    setIsLoading(true);
    try {
      const emails = modalProps.newUserMails;
      const host = window.location.hostname.split(".");
      const orgRegion = host[1];
      const orgDomainPrefix = host[0];

      const res = await inviteUsers({
        invitedByUserName: modalProps.userName,
        emailsToInvite: emails,
        redirectUri: `${window.location.origin}`,
        orgRegion,
        orgDomainPrefix,
      }).unwrap();

      const sentMails = res.response.data?.emails as string[];
      if (!isEmpty(sentMails)) {
        await handleShare(res.response.data?.userIds as string[]);
      }
      const hasErrors =
        res.response.data?.errors && !isEmpty(res.response.data.errors);
      if (hasErrors) {
        const errMails = res.response.data?.errors as string[];
        toast({
          title: `${errMails.length} members already exist`,
          description: `Cannot send invites to existing members`,
          status: ToastType.Error,
        });
        setIsLoading(false);
        return;
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <Box className="!px-6">
      <Flex className="flex-col gap-2">
        <Box>
          Access to {modalProps.type} can only be shared with existing members.
        </Box>
        <Box>
          Would you like to invite the following users to join this
          organization?
        </Box>
        <ul className="list-disc list-inside max-h-[200px] overflow-y-auto">
          {modalProps.newUserMails.map((email: string, idx: number) => (
            <li key={email}>{email}</li>
          ))}
        </ul>
      </Flex>
      <Flex className="mt-10 !flex !justify-between">
        <Button
          colorScheme="secondary"
          isDisabled={isLoading}
          onClick={onClose}
          size={"sm"}
          variant="ghost"
        >
          Cancel
        </Button>
        <Button
          colorScheme={"dark"}
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={onInvite}
          size={"sm"}
          variant={"solid"}
        >
          Yes, Invite new members
        </Button>
      </Flex>
    </Box>
  );
};

export default InviteAndShareBody;
