import { Box, Flex, ModalCloseButton } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@/design/components/button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

const AdminWarningModal: React.FC = () => {
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader className="!font-medium">{modalProps.title}</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6">
          <Box className="whitespace-pre-wrap">{modalProps.message}</Box>
          <Flex className="mt-6 !flex !justify-end">
            <Button
              variant={"solid"}
              colorScheme={"dark"}
              onClick={onClose}
              size={"sm"}
            >
              Close
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdminWarningModal;
