import { Flex, Tag, Text } from "@chakra-ui/react";
import { useMemo } from "react";

import { ADD_DATASET_TAGS } from "@/utils/enums.ts";

type DatasetBlockProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  tag: ADD_DATASET_TAGS;
  onClick?: () => void;
};
export const DatasetBlock = ({
  icon,
  title,
  description,
  tag,
  onClick,
}: DatasetBlockProps) => {
  const tagColor = useMemo(() => {
    switch (tag) {
      case ADD_DATASET_TAGS.FILE:
        return "pink";
      case ADD_DATASET_TAGS.AUTH_REQUIRED:
        return "teal";
    }
  }, [tag]);
  return (
    <Flex
      className="flex w-[256px] h-fit gap-3 p-4 items-center border border-transparent hover:border-gray-200 hover:bg-[#fcfcfc] rounded cursor-pointer"
      onClick={onClick}
    >
      <Flex className="items-center p-[5px] justify-center h-10 w-10">
        {icon}
      </Flex>
      <Flex className="flex-col gap-1 pb-0.5 justify-start">
        <Text className="font-medium leading-tigh text-gray-800">
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};
