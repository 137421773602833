import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define a custom variant
const primaryVariant = definePartsStyle(() => {
  return {
    tab: {
      // use colorScheme to change background color with dark and light mode options
      borderBottom: "2px solid",
      borderBottomColor: "transparent",
      fontsize: "12px",
      padding: "13px 20px",
      _hover: { bg: "black.50" },
      _active: { bg: "black.200" },
      _selected: {
        _hover: { bg: "orange.50" },
        borderBottomColor: "orange.400",
        bg: "transparent",
        color: "orange.400",
      },
    },
    tablist: {
      fontsize: "inherit",
      borderBottom: "2x solid",
      borderColor: "inherit",
    },
  };
});

const roundedVariant = definePartsStyle(() => {
  return {
    tab: {
      // use colorScheme to change background color with dark and light mode options
      border: "transparent",
      borderRadius: "full",
      padding: "13px 20px",
      font: "inherit",
      _hover: { bg: "black.50" },
      _selected: {
        _hover: { bg: "orange.50" },
        bg: "orange.50",
        color: "orange.400",
      },
    },
  };
});

const iconVariant = definePartsStyle(() => {
  return {
    ...primaryVariant,
    tab: {
      // use colorScheme to change background color with dark and light mode options
      borderBottom: "2px solid",
      borderBottomColor: "transparent",
      display: "flex",
      alignItems: "center",
      padding: "13px 20px",
      gap: "12px",
      justifyContent: "around",
      fontsize: "12px",
      _hover: { bg: "black.50" },
      _selected: {
        _hover: { bg: "orange.50" },
        borderBottomColor: "orange.400",
        bg: "transparent",
        color: "orange.400",
      },
    },
  };
});

const variants = {
  line: primaryVariant,
  iconTabs: iconVariant,
  "soft-rounded": roundedVariant,
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants });
