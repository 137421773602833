import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "@/reduxHooks.ts";
import { currentUserId, currentUserMetadata } from "@/slices/auth-slice.ts";
import { USER_ROLES } from "@/utils/enums";

export const AdminRouteLayout = () => {
  const userData = useAppSelector(currentUserMetadata);

  const isAdmin =
    userData?.role === USER_ROLES.ADMIN ||
    userData?.role === USER_ROLES.CTK_ADMIN;

  if (!isAdmin) {
    // Redirect to home or an unauthorized page if the user is not an admin
    return <Navigate to="/home" replace />;
  }

  // If the user is an admin, render the child routes
  return <Outlet />;
};
