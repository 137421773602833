import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "@/utils/baseQuery.ts";
import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

export const llmAPI = createApi({
  reducerPath: "llmAPI",
  baseQuery: baseQuery({
    baseUrl: getMSBaseUrl(MICRO_SERVICES.AI_PLAYGROUND),

    defaultHeaders: {
      "X-CLIENT-ID": import.meta.env.VITE_CLIENT_ID,
      "X-WORKSPACE-ID": "518cbce6-717f-4962-9d3b-d686829ccb9b",
    },
  }),
  tagTypes: ["llm"],
  endpoints: () => ({}),
});
