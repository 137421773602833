import { isEmpty } from "lodash";

import { ISuggestion } from "@/features/data-transformation";

import { SuggestionTile } from "./suggestion-tile.tsx";
import { SuggestionsEmpty } from "./suggestions-empty.tsx";

export const SuggestionsItems = ({
  suggestions,
}: {
  suggestions: ISuggestion[] | undefined;
}) => {
  if (!suggestions || isEmpty(suggestions)) return <SuggestionsEmpty />;

  return suggestions.map((suggestion, index) => (
    <SuggestionTile key={index} {...suggestion} />
  ));
};
