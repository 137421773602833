import { Tooltip } from "@chakra-ui/react";
import { clsx } from "clsx";
import { useMemo } from "react";
import { LabelProps } from "recharts";

import {
  nullParser,
  tickFormatter,
} from "@/features/data-transformation/utils";

type CustomLabelProps = {
  short?: boolean;
};

export const CustomizedLabel = (props: LabelProps & CustomLabelProps) => {
  const { x = 0, y = 0, height = 0, fill, value, name, short } = props;
  const { isNull, parsedValue } = useMemo(
    () => nullParser(name),
    [name, value]
  );

  const formattedValue = tickFormatter(
    parsedValue ?? "",
    value?.toString().length,
    short
  );
  const displayValue = `${formattedValue}${value}`;

  return (
    <Tooltip label={name}>
      <text
        x={Number(x) + 16} // 16px space from left
        y={Number(y) + Number(height) - 12} // Start from the bottom of the bar
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="start" // Align the text to the bottom of the bar
        transform={`rotate(-90, ${Number(x) + 16}, ${
          Number(y) + Number(height) - 12
        })`}
        className={clsx(
          "text-[13px] leading-[120%] text-gray-900",
          isNull && "italic",
          short && "text-[12px]"
        )}
      >
        {displayValue}
      </text>
    </Tooltip>
  );
};
