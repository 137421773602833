import {
  Button,
  Flex,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const CloseEdaDiscardChanges = () => {
  const dispatch = useDispatch();

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const goBack = () => {
    modalProps.onConfirm?.();
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-440 !pb-0">
        <ModalHeader className="!font-semibold !text-lg">
          Discard Changes?
        </ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6 !py-0 !-mt-2 text-sm w-fit leading-[21px] text-gray-800">
          <Flex className="flex-col text-sm leading-[140%] text-gray-800">
            Transformations applied to this data will be discarded. Are you sure
            you would like to proceed?
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex className="flex-row-reverse !p-6 w-full justify-between">
            <Button
              colorScheme="secondar"
              onClick={onClose}
              size="sm"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              bgColor="red.600"
              colorScheme="primary"
              onClick={goBack}
              size="sm"
              variant="solid"
            >
              Discard Changes
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
