// create new slice for analysis
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state

interface SettingsState {}

// Define the initial state using that type
const initialState: SettingsState = {};

const orgSettingsSlice = createSlice({
  name: "org-settings-slice",
  initialState,
  reducers: {},
});

// export const {  } = orgSettingsSlice.actions;
// export const currentUser = (state: RootState) => state..orgAccess;

export const orgSettingsReducer = orgSettingsSlice.reducer;
