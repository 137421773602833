import { useClickable, UseClickableProps } from "@chakra-ui/clickable";
import { createDescendantContext } from "@chakra-ui/descendant";
import { createContext } from "@chakra-ui/react-context";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { callAllHandlers } from "@chakra-ui/shared-utils";
import React, { useCallback, useEffect, useState, useId } from "react";

/* -------------------------------------------------------------------------------------------------
 * Create context to track descendants and their indices
 * -----------------------------------------------------------------------------------------------*/

export const [
  NavsDescendantsProvider,
  useNavsDescendantsContext,
  useNavsDescendants,
  useNavsDescendant,
] = createDescendantContext<HTMLButtonElement>();

/* -------------------------------------------------------------------------------------------------
 * useNavs - The root react hook that manages all tab items
 * -----------------------------------------------------------------------------------------------*/

export interface UseNavsProps {
  /**
   * If `true`, the tabs will be manually activated and
   * display its panel by pressing Space or Enter.
   *
   * If `false`, the tabs will be automatically activated
   * and their panel is displayed when they receive focus.
   *
   * @default false
   */
  isManual?: boolean;
  /**
   * Callback when the index (controlled or un-controlled) changes.
   */
  onChange?: (index: number) => void;
  /**
   * Callback when the index (controlled or un-controlled) changes.
   */
  onChangeExpanded?: (index: number) => void;
  /**
   * The index of the selected tab (in controlled mode)
   */
  index?: number;
  /**
   * The initial index of the selected tab (in uncontrolled mode)
   */
  defaultIndex?: number;
  /**
   * The index of the selected tab (in controlled mode)
   */
  indexExpanded?: number;
  /**
   * The initial index of the selected tab (in uncontrolled mode)
   */
  defaultExpandedIndex?: number;
  /**
   * The id of the tab
   */
  id?: string;
}

/**
 * Navigation hook that provides all the states, and accessibility
 * helpers to keep all things working properly.
 *
 * Its returned object will be passed unto a Context Provider
 * so all child components can read from it.
 * There is no document link yet
 */
export function useNavs(props: UseNavsProps) {
  const {
    defaultIndex,
    onChange,
    onChangeExpanded,
    index,
    indexExpanded,
    defaultExpandedIndex,
    isManual,
    ...htmlProps
  } = props;

  /**
   * We use this to keep track of the index of the focused tab.
   *
   * Tabs can be automatically activated, this means selection follows focus.
   * When we navigate with the arrow keys, we move focus and selection to next/prev tab
   *
   * Tabs can also be manually activated, this means selection does not follow focus.
   * When we navigate with the arrow keys, we only move focus NOT selection. The user
   * will need not manually activate the tab using `Enter` or `Space`.
   *
   * This is why we need to keep track of the `focusedIndex` and `selectedIndex`
   */
  const [focusedIndex, setFocusedIndex] = useState(defaultIndex ?? -1);
  const [expandedIndex, setExpandedIndex] = useControllableState({
    defaultValue: defaultExpandedIndex ?? 0,
    value: indexExpanded,
    onChange: onChangeExpanded,
  });

  const [selectedIndex, setSelectedIndex] = useControllableState({
    defaultValue: defaultIndex ?? -1,
    value: index,
    onChange,
  });

  /**
   * Sync focused `index` with controlled `selectedIndex` (which is the `props.index`)
   */
  useEffect(() => {
    if (index != null) {
      setFocusedIndex(index);
    }
  }, [index]);

  /**
   * Think of `useDescendants` as a register for the tab nodes.
   */
  const descendants = useNavsDescendants();

  /**
   * Generate a unique id or use user-provided id for the tabs widget
   */
  const uuid = useId();
  const uid = props.id ?? uuid;
  const id = `navs-${uid}`;

  return {
    id,
    selectedIndex,
    focusedIndex,
    expandedIndex,
    setExpandedIndex,
    setSelectedIndex,
    setFocusedIndex,
    isManual,
    descendants,
    htmlProps,
  };
}

export type UseNavsReturn = Omit<
  ReturnType<typeof useNavs>,
  "htmlProps" | "descendants"
>;

export const [NavsProvider, useNavsContext] = createContext<UseNavsReturn>({
  name: "NavsContext",
  errorMessage:
    "useNavsContext: `context` is undefined. Seems you forgot to wrap all tabs components within <NavigationPanel />",
});

export interface UseNavListProps {
  children?: React.ReactNode;
  onKeyDown?: React.KeyboardEventHandler;
  ref?: React.Ref<any>;
}

/**
 * Tabs hook to manage multiple tab buttons,
 * and ensures only one tab is selected per time.
 *
 * @param props props object for the tablist
 */
export function useNavList<P extends UseNavListProps>(props: P) {
  const { focusedIndex } = useNavsContext();

  const descendants = useNavsDescendantsContext();

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const nextTab = () => {
        const next = descendants.nextEnabled(focusedIndex);
        if (next) (next.node?.children[0] as HTMLElement).focus();
      };
      const prevTab = () => {
        const prev = descendants.prevEnabled(focusedIndex);
        if (prev) (prev.node?.children[0] as HTMLElement).focus();
      };
      const firstTab = () => {
        const first = descendants.firstEnabled();
        if (first) (first.node?.children[0] as HTMLElement).focus();
      };
      const lastTab = () => {
        const last = descendants.lastEnabled();
        if (last) (last.node?.children[0] as HTMLElement).focus();
      };

      const eventKey = event.key;

      const keyMap: Record<string, React.KeyboardEventHandler> = {
        ArrowDown: () => nextTab(),
        ArrowUp: () => prevTab(),
        Home: firstTab,
        End: lastTab,
      };

      const action = keyMap[eventKey];

      if (action) {
        event.preventDefault();
        action(event);
      }
    },
    [descendants, focusedIndex],
  );

  return {
    ...props,
    role: "navlist",
    onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
  };
}

export type UseTabListReturn = ReturnType<typeof useNavList>;

export interface UseNavOptions {
  /**
   * If `true`, the `Tab` won't be toggleable
   * @default false
   */
  isDisabled?: boolean;
  /**
   * If `true` and `isDisabled`, the `Tab` will be focusable but not interactive.
   * @default false
   */
  isFocusable?: boolean;
  highlightOnSelect?: boolean;
}

export interface UseTabProps
  extends Omit<UseClickableProps, "color">,
    UseNavOptions {
  onToggle?: () => void;
  /**
   * used to reset the expanded state of item
   */
  isParent?: boolean;
}

/**
 * Hook to manage each tab button.
 *
 * A item can be disabled and focusable, or both,
 * hence the use of `useClickable` to handle this scenario
 */
export function useNav<P extends UseTabProps>(props: P) {
  const {
    isDisabled = false,
    isFocusable = false,
    highlightOnSelect = true,
    onToggle,
    isParent = false,
    ...htmlProps
  } = props;

  const {
    setSelectedIndex,
    setExpandedIndex,
    expandedIndex,
    isManual,
    id,
    setFocusedIndex,
    selectedIndex,
  } = useNavsContext();

  const { index, register } = useNavsDescendant({
    disabled: isDisabled && !isFocusable,
  });

  const isSelected = index === selectedIndex;
  const isExpanded = index === expandedIndex;

  const onClick = () => {
    setSelectedIndex(index);
    if (onToggle) {
      onToggle();
      const isAlreadyExpanded = isParent && index === expandedIndex;
      setExpandedIndex(isAlreadyExpanded ? -1 : index);
    }
  };

  const onFocus = () => {
    setFocusedIndex(index);
    const isDisabledButFocusable = isDisabled && isFocusable;
    const shouldSelect = !isManual && !isDisabledButFocusable;
    if (shouldSelect) {
      setSelectedIndex(index);
    }
  };

  const clickableProps = useClickable({
    ...htmlProps,
    ref: mergeRefs(register, props.ref),
    isDisabled,
    isFocusable,
    onClick: callAllHandlers(props.onClick, onClick),
  });

  const type: "button" | "submit" | "reset" = "button";

  return {
    ...clickableProps,
    id: makeNavId(id, index),
    role: "nav",
    tabIndex: isSelected ? 0 : -1,
    type,
    isExpanded,
    "aria-selected": highlightOnSelect ? isSelected : false,
    onFocus: isDisabled ? undefined : callAllHandlers(props.onFocus, onFocus),
  };
}

function makeNavId(id: string, index: number) {
  return `${id}--nav-${index}`;
}
