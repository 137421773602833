import { Divider, Flex, Text } from "@chakra-ui/react";
import { clsx } from "clsx";
import { useContext, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { MdsDoneRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import {
  CONDITONS_SET_1,
  CONDITONS_SET_2,
  CONDITONS_SET_3,
  CONDITONS_SET_4,
  CONDITONS_SET_5,
  FilterCondition,
  FilterContext,
} from "@/features/data-transformation";

type FilterByConditionProps = {
  col?: string;
  selectedCondition: FilterCondition | null;
  onChange: (condition: FilterCondition) => void;
};

export const FilterByCondition = ({
  col,
  selectedCondition,
  onChange,
}: FilterByConditionProps) => {
  const { appliedFilters: _filters, filter } = useContext(FilterContext)!;

  const [menu, setMenu] = useState(false);

  const closeMenu = () => {
    setMenu(false);
  };

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const onSelect = (condition: FilterCondition) => {
    onChange(condition);
    closeMenu();
  };

  return (
    <Menu isOpen={menu} placement="bottom" onClose={closeMenu}>
      <MenuButton as={Flex} onClick={toggleMenu} className="!cursor-pointer">
        <Flex className="flex flex-row w-full justify-between items-center border border-gray-500 rounded-[3px] p-2 gap-1.5 select-none">
          <Text className="text-[13px] leading-[16px] font-medium tracking-tight truncate max-w-[10rem]">
            {selectedCondition?.key ?? "None"}
          </Text>
          <Icon as={TbChevronDown} size="sm" color="gray.600" />
        </Flex>
      </MenuButton>
      <MenuList
        className="-mt-2 !z-10 w-[288px] !max-h-[300px] h-[100px] overflow-y-auto"
        display={menu ? "block" : "none"}
      >
        {CONDITONS_SET_1.map((condition, index) => {
          const isSelected = condition.key === selectedCondition?.key;
          const isLast = index === CONDITONS_SET_1.length - 1;
          return (
            <MenuItem
              key={index}
              onClick={onSelect.bind(null, condition)}
              className={clsx(
                "!p-2.5 !text-[13px] !leading-[120%]",
                isLast && "!border-b-2 !border-b-gray-100"
              )}
              position="relative"
            >
              {condition.key}
              {isSelected && (
                <Icon
                  as={MdsDoneRound}
                  size="sm"
                  color="gray.900"
                  className="absolute right-2"
                />
              )}
            </MenuItem>
          );
        })}
        <Divider />
        <Divider />
        {CONDITONS_SET_2.map((condition, index) => {
          const isSelected = condition.key === selectedCondition?.key;
          return (
            <MenuItem
              key={index}
              onClick={onSelect.bind(null, condition)}
              className="!p-2.5 !text-[13px] !leading-[120%]"
              position="relative"
            >
              {condition.key}
              {isSelected && (
                <Icon
                  as={MdsDoneRound}
                  size="sm"
                  color="gray.900"
                  className="absolute right-2"
                />
              )}
            </MenuItem>
          );
        })}
        <Divider />
        <Divider />
        {CONDITONS_SET_3.map((condition, index) => {
          const isSelected = condition.key === selectedCondition?.key;
          return (
            <MenuItem
              key={index}
              onClick={onSelect.bind(null, condition)}
              className="!p-2.5 !text-[13px] !leading-[120%]"
              position="relative"
            >
              {condition.key}
              {isSelected && (
                <Icon
                  as={MdsDoneRound}
                  size="sm"
                  color="gray.900"
                  className="absolute right-2"
                />
              )}
            </MenuItem>
          );
        })}
        <Divider />
        <Divider />
        {CONDITONS_SET_4.map((condition, index) => {
          const isSelected = condition.key === selectedCondition?.key;
          return (
            <MenuItem
              key={index}
              onClick={onSelect.bind(null, condition)}
              className="!p-2.5 !text-[13px] !leading-[120%]"
              position="relative"
            >
              {condition.key}
              {isSelected && (
                <Icon
                  as={MdsDoneRound}
                  size="sm"
                  color="gray.900"
                  className="absolute right-2"
                />
              )}
            </MenuItem>
          );
        })}
        <Divider />
        <Divider />
        {CONDITONS_SET_5.map((condition, index) => {
          const isSelected = condition.key === selectedCondition?.key;
          return (
            <MenuItem
              key={index}
              onClick={onSelect.bind(null, condition)}
              className="!p-2.5 !text-[13px] !leading-[120%]"
              position="relative"
            >
              {condition.key}
              {isSelected && (
                <Icon
                  as={MdsDoneRound}
                  size="sm"
                  color="gray.900"
                  className="absolute right-2"
                />
              )}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
