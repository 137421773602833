import { createSlice } from "@reduxjs/toolkit";
import { ReactFlowJsonObject } from "reactflow";

import { RootState } from "@/store";

interface FlowAutoSaveState {
  flowInstance: ReactFlowJsonObject | null;
  interval: number;
  timerCountdown: number;
  isSaving: boolean;
  triggerAutoSave: boolean;
}

const initialState: FlowAutoSaveState = {
  flowInstance: null,
  interval: 15, // Time in seconds for autosave
  timerCountdown: 0,
  isSaving: false,
  triggerAutoSave: false,
};

const flowAutoSaveSlice = createSlice({
  name: "flowAutoSave",
  initialState,
  reducers: {
    setFlowInstance(state, action) {
      state.flowInstance = action.payload;
    },
    setTimerCountdown(state, action) {
      state.timerCountdown = action.payload;
    },
    setIsSaving(state, action) {
      state.isSaving = action.payload;
    },
    triggerAutoSave(state, action: { payload?: ReactFlowJsonObject | null }) {
      state.triggerAutoSave = true;
      // state.flowInstance = action.payload;
    },
    resetTriggerAutoSave(state) {
      state.triggerAutoSave = false;
    },
  },
});

export const currentFlowInstance = (state: RootState) =>
  state.flowAutoSaveReducer.flowInstance;
export const timerInterval = (state: RootState) =>
  state.flowAutoSaveReducer.interval;
export const currentCountdown = (state: RootState) =>
  state.flowAutoSaveReducer.timerCountdown;
export const isAutoSaving = (state: RootState) =>
  state.flowAutoSaveReducer.isSaving;
export const shouldTriggerAutoSave = (state: RootState) =>
  state.flowAutoSaveReducer.triggerAutoSave;

export const { setIsSaving, resetTriggerAutoSave, triggerAutoSave } =
  flowAutoSaveSlice.actions;

export const flowAutoSaveReducer = flowAutoSaveSlice.reducer;
