import { Flex, Spinner } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EdaMetaDataContext,
  FETCH_TYPE,
  ISuggestionsList,
  resetFetchTrigger,
  selectedColumn,
  triggerSuggestionsFetch,
  useGetSuggestionsMutation,
} from "@/features/data-transformation";
import { PanelStateContext } from "@/features/data-transformation/components";
import { useStatusApi } from "@/features/data-transformation/hooks";

import { SuggestionsItems } from "./suggestions-items.tsx";

export const SuggestionsList = () => {
  const dispatch = useDispatch();
  const { isOpen } = useContext(PanelStateContext);
  const metaData = useContext(EdaMetaDataContext);

  const colInfo = useSelector(selectedColumn);
  const refetchSuggestions = useSelector(triggerSuggestionsFetch);
  const [getSuggestions] = useGetSuggestionsMutation();
  const [isLoading, setIsLoading] = useState(false);

  const memoizedColumnName = useMemo(
    () => colInfo?.column.name,
    [colInfo?.column.name]
  );

  const { setRequestId, clearCache, data } = useStatusApi<ISuggestionsList>({
    onSuccess: () => setIsLoading(false),
  });

  useEffect(() => {
    if (!isOpen) return;
    if (!colInfo) {
      clearCache();
      setIsLoading(false);
      return;
    }

    handleGetSuggestions(colInfo.column.name);
  }, [memoizedColumnName, isOpen]);

  useEffect(() => {
    if (!refetchSuggestions) return;
    dispatch(resetFetchTrigger(FETCH_TYPE.SUGGESTIONS));

    if (!isOpen || !colInfo) return;

    clearCache();
    handleGetSuggestions(colInfo.column.name);
  }, [refetchSuggestions]);

  const handleGetSuggestions = async (name: string) => {
    setIsLoading(true);

    try {
      const res = await getSuggestions({
        column: name,
        edaId: metaData.edaId!,
        analysisId: metaData.analysisId!,
      }).unwrap();
      setRequestId(res.response.data?.requestId ?? null);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Flex className="flex-col flex-1 p-2 gap-1 h-full overflow-auto">
      {isLoading && (
        <Flex className="flex items-center justify-center py-24">
          <Spinner />
        </Flex>
      )}
      {!isLoading && <SuggestionsItems suggestions={data?.data?.suggestions} />}
    </Flex>
  );
};
