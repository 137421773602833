import {
  Flex,
  FlexProps,
  createStylesContext,
  useMultiStyleConfig,
} from "@chakra-ui/react";

const [StylesProvider, useStyles] = createStylesContext("TitleBar");

export const TitleBar = (props: FlexProps) => {
  const { children, ...rest } = props;

  const styles = useMultiStyleConfig("TitleBar");

  return (
    <Flex __css={styles.container} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  );
};

TitleBar.displayName = "TitleBar";

// eslint-disable-next-line react-refresh/only-export-components
export { useStyles };
