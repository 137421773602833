import { Box, Flex } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

interface ResizableRowsProps {
  children: React.ReactNode[];
}

const ResizableRows: React.FC<ResizableRowsProps> = ({ children }) => {
  const [topHeight, setTopHeight] = useState<number>(50);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // Calculate height based on ref height
  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;

      const container = document.querySelector(
        ".resizable-rows-container"
      ) as HTMLDivElement;
      const containerRect = container.getBoundingClientRect();
      const mouseY = e.clientY - containerRect.top;
      const containerHeight = container.clientHeight;

      const newTopHeight = (mouseY / containerHeight) * 100;
      setTopHeight(newTopHeight);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  return (
    <Flex className="flex-col resizable-rows-container overflow-hidden h-full">
      <Box
        className="overflow-hidden"
        style={{
          height: `${topHeight}%`,
          minHeight: "15%",
        }}
      >
        {children[0]}
      </Box>
      <Box
        className="z-[999] h-2 hover:h-4 transition-all group grid place-items-center cursor-ns-resize hover:bg-gray-50"
        onMouseDown={handleMouseDown}
      >
        <Box className="w-8 group-hover:w-14 h-1 transition-all group-hover:h-1 rounded-full bg-gray-300"></Box>
      </Box>
      <Box
        className="overflow-hidden"
        style={{
          height: `${100 - topHeight}%`,
          minHeight: "80px",
        }}
      >
        {children[1]}
      </Box>
    </Flex>
  );
};

export default ResizableRows;
