import { Flex, useDisclosure } from "@chakra-ui/react";
import { clsx } from "clsx";

import { RecycleCard } from "./recycle-card.tsx";
import { RecycleDatatable } from "./recycle-datatable.tsx";
import RecycleDetailsPanel from "./recycle-details-panel.tsx";

export const RecycleBin = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Flex
      className="w-full h-full overflow-hidden items-stretch justify-stretch"
      gap={isOpen ? "3" : 0}
    >
      <RecycleCard isOpen={isOpen}>
        <RecycleDatatable onOpen={onOpen} />
      </RecycleCard>

      <div
        className={clsx(
          "h-full max-w-[30%] ease-in-out transition-transform duration-300",
          isOpen
            ? "translate-x-0 w-[30%] overflow-auto"
            : "translate-x-full w-0 overflow-hidden",
        )}
      >
        <RecycleDetailsPanel onClose={onClose} />
      </div>
    </Flex>
  );
};
