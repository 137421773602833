import { Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext } from "react";
import { MdsChevronRightRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";

import { FilterByValue } from "./filter-by-value.tsx";
import { FilterDetailsContext } from "./filter-details-context.ts";

export const FilterByValueRow = () => {
  const { openByValue, onOpenByValue, onSelectAll, selectAll } =
    useContext(FilterDetailsContext)!;
  return (
    <>
      <Flex className="items-center justify-between gap-x-2">
        <Flex className="items-center gap-x-2 text-[13px] leading-none font-semibold uppercase">
          <Flex
            as={motion.div}
            color="gray.500"
            cursor="pointer"
            animate={{
              rotate: !openByValue ? 0 : 90,
              transition: { duration: 0.2, type: "spring" },
            }}
            aria-label="collapse"
            onClick={onOpenByValue}
          >
            <Icon as={MdsChevronRightRound} size="md" />
          </Flex>
          Filter By Values
        </Flex>
        <Text
          className="text-brand-400 text-xs font-medium cursor-pointer"
          onClick={onSelectAll}
        >
          {selectAll ? "Deselect All " : "Select All"}
        </Text>
      </Flex>
      {openByValue && <FilterByValue />}
    </>
  );
};
