import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { SelectSheetBody } from "./select-sheet-body";

interface SelectSheetModalProps {}

export const SelectSheetModal: React.FC<SelectSheetModalProps> = () => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isCentered isOpen={true} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent className="!pb-0 !gap-0">
        <ModalHeader>Select Excel Sheet</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!flex !flex-col !p-5 gap-5">
          <SelectSheetBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
