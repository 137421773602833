import { Icon, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { ErrorFill } from "@/components/icons/error-fill.tsx";
import { stepMemoryWarning } from "@/features/data-transformation";

export const MemoryLimitMessage = () => {
  const warningStep = useSelector(stepMemoryWarning);
  if (!warningStep) return null;

  return (
    <Flex className="items-center h-9 bg-red-50 border-b border-b-red-400 text-sm px-3">
      <Flex className="items-center space-x-2">
        <Icon as={ErrorFill} color="red.600" />
        <Text className="text-red-600 text-sm leading-[120%] font-medium">
          Memory limit exceeded for step {warningStep.name}
        </Text>
      </Flex>
    </Flex>
  );
};
