import {
  Alert,
  AlertIcon,
  CloseButton,
  Flex,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { IconType } from "react-icons";
import { GoCheckCircleFill } from "react-icons/go";
import { MdInfo, MdWarning } from "react-icons/md";

import { CustomToastProps, ToastType } from "./show-toast";

export const InfoToast = ({ options }: { options: CustomToastProps }) => {
  return (
    <Alert className="!items-start !pt-2" status="info" variant="bottom-accent">
      <Flex className="flex-col w-full">
        <Flex className="grow">
          <div className="mr-3 overflow-hidden shrink-0">
            {options.icon ? options.icon : <AlertIcon />}
          </div>
          <div className="flex flex-col grow flex-wrap">
            <div className="font-medium text-sm grow break-all">
              {options.title}
            </div>
            <div className="text-sm grow break-all">{options.description}</div>
          </div>
          {options.isClosable && (
            <CloseButton onClick={options.onClose} size="sm" />
          )}
        </Flex>
        {options.actionText && (
          <button
            className="text-sm underline hover:bg-gray-500 p-1 rounded hover:bg-opacity-20 font-medium !self-end w-fit cursor-pointer"
            onClick={options.action}
          >
            {options.actionText}
          </button>
        )}
      </Flex>
    </Alert>
  );
};

const ToastStyle = (status: ToastType | string | undefined) => {
  switch (status) {
    case ToastType.Info:
      return {
        icon: MdInfo,
        bg: "bg-blue-600",
      };
    case ToastType.Warning:
      return {
        icon: MdWarning,
        bg: "bg-yellow-600",
      };
    case ToastType.Error:
      return {
        icon: MdInfo,
        bg: "bg-red-600",
      };
    case ToastType.Success:
      return {
        icon: GoCheckCircleFill,
        bg: "bg-green-600",
      };
    case ToastType.Loading:
      return {
        icon: Spinner,
        bg: "bg-gray-900",
      };
    default:
      return {
        icon: MdInfo,
        bg: "bg-gray-900",
      };
  }
};

export const BaseToast = ({ options }: { options: CustomToastProps }) => {
  const { bg, icon } = ToastStyle(options.status);
  return (
    <Flex className={`flex-col w-full text-white rounded-lg p-3 ${bg}`}>
      <Flex className="flex-col">
        <Flex className="grow items-start gap-2">
          <div className="overflow-hidden shrink-0">
            {options?.icon ? (
              options?.icon
            ) : (
              <Icon as={icon} fontSize={"20px"} />
            )}
          </div>
          <div className="font-medium grow break-words line-clamp-2">
            {options?.title}
          </div>
          {options?.isClosable && (
            <CloseButton
              className={`hover:!bg-white hover:!bg-opacity-20`}
              onClick={options?.onClose}
              size="sm"
            />
          )}
        </Flex>
        <Flex className="items-end gap-3 w-full flex-wrap">
          {!isEmpty(options?.description) && (
            <div className="text-sm flex-1 break-all mt-2">
              {options?.description}
            </div>
          )}
          {options?.actionText && (
            <button
              className="shrink-0 text-sm mt-2 ml-auto whitespace-nowrap flex items-center py-0 gap-1 hover:!bg-white p-1 rounded hover:!bg-opacity-20 font-medium !self-end cursor-pointer"
              onClick={options?.action}
            >
              {options?.actionText}
              {options?.actionIcon && (
                <Icon as={options?.actionIcon as IconType} fontSize={16} />
              )}
            </button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
