import { chakra, Tooltip } from "@chakra-ui/react";
import { forwardRef, HTMLChakraProps } from "@chakra-ui/system";
import { ReactElement, useContext } from "react";

import { NavigationPanelItemContext } from "./navigation-panel-item.tsx";
import { NavigationPanelContext, useStyles } from "./navigation-panel.tsx";
import { useNav, UseNavOptions } from "./use-nav.ts";

export interface NavigationPanelItemProps
  extends UseNavOptions,
    HTMLChakraProps<"button"> {
  icon: ReactElement;
  label?: string;
  selected?: boolean;
}

export const NavigationPanelSubItem = forwardRef<
  NavigationPanelItemProps,
  "button"
>(function NavigationPanelSubItem(props, ref) {
  const { children, icon, label, selected } = props;
  const styles = useStyles();
  const { open } = useContext(NavigationPanelContext);
  const { openItem } = useContext(NavigationPanelItemContext);

  const { isExpanded: _, ...navProps } = useNav({
    ...props,
    isDisabled: !openItem,
    ref,
  });

  return (
    <Tooltip
      className="w-full !text-xs"
      aria-label={label ?? (children as string)}
      arrowSize={8}
      hasArrow
      label={label ?? children}
      openDelay={400}
      placement="right"
    >
      <chakra.button
        __css={styles.subitem}
        {...navProps}
        aria-selected={selected ?? navProps["aria-selected"]}
        aria-expanded={open}
      >
        <chakra.div __css={styles.subitemContainer}>
          <chakra.span __css={styles.subitemIcon}>{icon}</chakra.span>
          <chakra.span aria-expanded={open} __css={styles.subitemLabel}>
            {label ?? children}
          </chakra.span>
        </chakra.div>
      </chakra.button>
    </Tooltip>
  );
});

NavigationPanelSubItem.displayName = "NavigationPanelSubItem";
