import { Flex, FlexProps, chakra } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";

import { Icon } from "../icon";

import { useStyles } from "./configure-panel.tsx";
export interface ConfigurePanelHeaderProps extends FlexProps {
  header?: string;
  onClose?: () => void;
}
//TODO replace Icon with IconButton
const HeaderRow = (props: ConfigurePanelHeaderProps) => {
  const styles = useStyles();
  return (
    <>
      <chakra.span __css={styles.headerText}>{props.header}</chakra.span>
      <Icon
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        as={AiOutlineClose}
        sx={{ cursor: "pointer" }}
        // onClick={props.onClose}
      />
    </>
  );
};
export const ConfigurePanelHeader = (props: ConfigurePanelHeaderProps) => {
  const { children, header, ...rest } = props;
  if (header && children) {
    throw new Error("Invalid should have either header or children props");
  }

  const styles = useStyles();
  const textOnly = typeof children === "string";
  const hasChildren = !!children;
  return (
    <Flex __css={styles.header} {...rest}>
      {hasChildren && !textOnly ? (
        children
      ) : (
        <HeaderRow header={(children as string) ?? header} {...rest} />
      )}
    </Flex>
  );
};

ConfigurePanelHeader.displayName = "ConfigurePanelHeader";
