import { AnyAction, current, ThunkDispatch } from "@reduxjs/toolkit";

import { ApiResponse } from "@/types";

import { FlowListResponse } from "../types";
import { FLOW_TYPES } from "../utils/constants";

import { nodeManagerApi } from "./node-manager-api";

export const updateFlowCache = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  body: any,
  data: ApiResponse<any>
) => {
  dispatch(
    nodeManagerApi.util.updateQueryData(
      "getFlows",
      { flowType: body.flowType },
      (draft) => {
        const currentState: FlowListResponse = current(draft);
        const currentFlows = currentState.flows ?? [];
        const isFavorite = body.action === "favorite";
        let updatedFlows;
        if (body.flowType === FLOW_TYPES.FAVORITES && !isFavorite) {
          updatedFlows = currentFlows.filter(
            (flow) =>
              flow.nodeId !== body.flowId &&
              flow.nodeVersionId !== body.flowVersionId
          );
        } else {
          updatedFlows = currentFlows.map((flow) => {
            if (
              flow.nodeId === body.flowId &&
              flow.nodeVersionId === body.flowVersionId
            ) {
              return {
                ...flow,
                isFavorite: isFavorite,
              };
            }
            return flow;
          });
        }
        return {
          ...currentState,
          flows: updatedFlows,
        } as FlowListResponse;
      }
    )
  );
};

export const deleteFlowFromCache = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  body: { flowId: string; flowType: FLOW_TYPES },
  data: ApiResponse<any>
) => {
  dispatch(
    nodeManagerApi.util.updateQueryData(
      "getFlows",
      { flowType: body.flowType },
      (draft) => {
        const currentState: FlowListResponse = current(draft);
        const currentFlows = currentState.flows ?? [];
        const updatedFlows = currentFlows.filter(
          (flow) => flow.nodeId !== body.flowId
        );
        return {
          ...currentState,
          flows: updatedFlows,
        } as FlowListResponse;
      }
    )
  );
};
