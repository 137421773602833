import { Flex } from "@chakra-ui/react";

import { Analysis } from "@/features/ws-manager/components/analysis";
import { NavBar } from "@/features/ws-manager/components/nav-bar/nav-bar.tsx";
import { QuickStartTemplates } from "@/features/ws-manager/components/quick-start-templates.tsx";
import { WorkspaceSearch } from "@/features/ws-manager/components/workspace-search";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";

export const Home = () => {
  const { query } = useCustomQuery();
  const isGlobalSearchActive = !!query;

  return (
    <Flex
      className="flex-col items-center w-full h-full overflow-hidden"
      bg="gray.100"
    >
      <NavBar />
      <div className="flex flex-col items-center w-full h-full p-4 gap-4 overflow-hidden">
        {isGlobalSearchActive && <WorkspaceSearch />}
        {!isGlobalSearchActive && (
          <>
            {/* <QuickStartTemplates /> */}
            <Analysis />
          </>
        )}
      </div>
    </Flex>
  );
};
