import { Link as ChakraLink } from "@chakra-ui/react";
import { useMemo } from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

import { UserDropdown } from "@/components/user-dropdown";
import { TitleBar, TitleBarHeader } from "@/design/components/title-bar";
import { TitleBarProfile } from "@/design/components/title-bar/title-bar-profile.tsx";
export const SettingsNavbar = () => {
  const isUserSettings = window.location.pathname.includes("settings/user");

  const { pathname } = useLocation();

  const title = useMemo(() => {
    if (pathname.includes("settings/user")) {
      return "Settings";
    }
    return "Organization Settings";
  }, [pathname]);

  // const title = isUserSettings ? "Settings" : "Organization Settings";

  return (
    <TitleBar className="z-[99]">
      <TitleBarHeader w="100%" className="justify-between flex">
        <ChakraLink
          className="!no-underline select-none"
          as={ReactRouterLink}
          to="/home"
        >
          {title}
        </ChakraLink>
      </TitleBarHeader>
      <TitleBarProfile
        sx={{
          "::before": {
            content: '""',
            position: "absolute",
            left: "0",
            width: "1px",
            height: "50%",
            background: "gray.200",
          },
        }}
      >
        <UserDropdown />
      </TitleBarProfile>
    </TitleBar>
  );
};
