import { Flex, chakra, Text, Tooltip } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import {
  MdsContentCopyRound,
  MdsDoneRound,
} from "react-icons-with-materialsymbols/mds";

import { useAppSelector } from "@/reduxHooks";

import {
  getInputPrompt,
  showPromptHistory,
  getPreviewVersion,
} from "../../redux";

import PromptInputBox from "./input-box";
import PromptFooter from "./prompt-footer";

const LLMPromptInput = () => {
  const [isCopying, setIsCopying] = useState(false);
  const inputPromptText = useAppSelector(getInputPrompt);
  const isHistoryOpen = useAppSelector(showPromptHistory);
  const previewVersion = useAppSelector(getPreviewVersion);

  const visiblePrompt = useMemo(() => {
    if (isHistoryOpen) {
      return previewVersion?.messages[0].content[0].text || "";
    }
    return inputPromptText || "";
  }, [inputPromptText, isHistoryOpen, previewVersion]);

  const copyPromptText = () => {
    navigator.clipboard
      .writeText(visiblePrompt)
      .then(() => {
        setIsCopying(true);
        setTimeout(() => setIsCopying(false), 1000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Flex className="flex-col px-3">
      <Flex align={"center"} gap={1} py={2} pl={2}>
        <Text className="font-semibold uppercase">Prompt</Text>
        <Tooltip
          className="top-2 !bg-black-900"
          label={isCopying ? "Copied!" : "Copy Prompt"}
          placement="top"
        >
          <chakra.button
            className="hover:bg-gray-50 p-1"
            aria-label="Copy Prompt"
            fontSize={18}
            onClick={copyPromptText}
          >
            {isCopying ? (
              <MdsDoneRound className="stroke-[18]" />
            ) : (
              <MdsContentCopyRound />
            )}
          </chakra.button>
        </Tooltip>
      </Flex>
      <PromptInputBox />
      <PromptFooter />
    </Flex>
  );
};

export default LLMPromptInput;
