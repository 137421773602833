import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

import {
  ghostThemes,
  linkThemes,
  outlineThemes,
  solidTheme,
} from "../buttonThemes";

const baseStyle = defineStyle({
  borderRadius: "2px",
  _disabled: {
    cursor: "not-allowed",
  },
  fontFamily: `'Albert Sans', sans-serif`,
  fontSize: `18px`,
  fontStyle: `normal`,
  fontWeight: `500`,
  letterSpacing: `-0.36px`,
  boxSize: "border-box",
});

const xs = defineStyle({
  h: "min-content",
  px: "1.5 !important",
  py: "1.5 !important",
  fontSize: "12px",
  borderRadius: "2px",
  lineHeight: "12px",
  letterSpacing: "-0.24px",
});

const sm = defineStyle({
  h: "min-content",
  px: "2 !important",
  py: "2 !important",
  fontSize: "14px",
  borderRadius: "2px",
  letterSpacing: "-0.27px",
  lineHeight: "16px",
});

const md = defineStyle({
  h: "min-content",
  px: "2.5 !important",
  py: "2.5 !important",
  fontSize: "16px",
  lineHeight: "20px",
  borderRadius: "2px",
  letterSpacing: "-0.32px",
});

const lg = defineStyle({
  h: "min-content",
  px: "3 !important",
  py: "3 !important",
  fontSize: "18px",
  lineHeight: "24px",
  borderRadius: "2px",
});

const lgMin = defineStyle({
  h: "min-content",
  px: "1 !important",
  py: "1 !important",
  fontSize: "24px",
  lineHeight: "32px",
  borderRadius: "2px",
  letterSpacing: "-0.48px",
});

const xl = defineStyle({
  h: "min-content",
  px: "3 !important",
  py: "3 !important",
  fontSize: "32px",
  borderRadius: "2px",
});

const active = {
  // transform: "scale(0.95, 0.95)",
  boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)`,
  _disabled: {
    transform: "none",
    boxShadow: "none",
  },
};

// Defining a custom variant
const primaryVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  const currentStyle = solidTheme[c];
  if (currentStyle) return currentStyle;
  return {
    bg: `${c}.400`,
    color: `white`,
    _hover: {
      bg: `${c}.500`,
      _disabled: {
        bg: `gray.500`,
        color: `black.50`,
      },
    },
    _active: {
      ...active,
      bg: `${c}.800`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      bg: `gray.500`,
    },
  };
});
// Defining a custom variant
const outlineVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  const currentStyle = outlineThemes[c];
  if (currentStyle) return currentStyle;
  return {
    bg: `transparent`,
    color: `${c}.400`,
    _hover: {
      color: `${c}.400`,
      bg: `${c}.50`,
      _disabled: {
        color: `gray.500`,
        bg: `gray.100`,
      },
    },
    _active: {
      ...active,
      color: `${c}.800`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      color: `gray.500`,
      bg: `gray.100`,
    },
  };
});
const ghostVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  const currentStyle = ghostThemes[c];
  if (currentStyle) return currentStyle;
  return {
    bg: `transparent`,
    color: `${c}.400`,
    _hover: {
      color: `${c}.400`,
      _disabled: {
        color: `gray.500`,
        bg: `gray.100`,
      },
    },
    _active: {
      ...active,
      color: `${c}.800`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      color: `gray.500`,
      bg: `gray.100`,
    },
  };
});
const linkVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  const currentStyle = linkThemes[c];
  if (currentStyle) return currentStyle;
  return {
    bg: `transparent`,
    color: `${c}.400`,
    textDecoration: `underline`,
    _hover: {
      color: `${c}.400`,
      _disabled: {
        color: `gray.500`,
      },
    },
    _active: {
      color: `${c}.800`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      color: `gray.500`,
      textDecoration: `none`,
    },
  };
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes: { xs: xs, sm, md, lg, xl, lgSm: lgMin },
  variants: {
    solid: primaryVariant,
    outline: outlineVariant,
    ghost: ghostVariant,
    link: linkVariant,
  },
  defaultProps: {
    variant: "solid",
    colorScheme: "orange",
  },
});
