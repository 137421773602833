// STRING = "string"
// INTEGER = "int"
// FLOAT = "float"
// BOOLEAN = "boolean"
// DICT = "dict"
// DATE = "date"
// DATETIME = "datetime"
// ARRAY = "list"

export enum AIMODEL_DATATYPES {
  STRING = "string",
  INTEGER = "int",
  FLOAT = "float",
  BOOLEAN = "boolean",
  DICT = "dict",
  DATE = "date",
  DATETIME = "datetime",
  ARRAY = "list",
}
