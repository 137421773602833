import { combineReducers } from "redux";

import contextMenuReducer from "../slices/context-menu-slice.ts";
import modalReducer from "../slices/modal-slice.ts";

const rootReducer = combineReducers({
  modals: modalReducer,
  contextMenu: contextMenuReducer,
});

export default rootReducer;
