import { useMsal } from "@azure/msal-react";
import { Input } from "@chakra-ui/input";
import {
  Button,
  Flex,
  FormControl,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ToastType, useShowToast } from "@/components/toast";
import {
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextIcon,
} from "@/design/components/form";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { useInviteUsersMutation } from "@/features/user-manager";
import { closeModal } from "@/slices/modal-slice.ts";

import { EmailErrors } from "./email-errors";

function isValidEmail(email: string) {
  const regex = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regex.test(email.toLowerCase());
}

function areValidEmails(text: string) {
  const emails = text.split(",");
  return emails.every(isValidEmail);
}

export const InviteMembersModal = () => {
  const toast = useShowToast(undefined, undefined, true);

  const dispatch = useDispatch();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const [inviteUsers] = useInviteUsersMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState("");
  const [errorEmails, setErrorEmails] = useState<string[]>([]);

  useEffect(() => {
    if (account) {
      const { idTokenClaims } = account;
      setUsername(idTokenClaims!.name!);
    }
  }, [account]);

  const onClose = () => {
    dispatch(closeModal());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emails = e.target.value ?? "";
    setError(null);
    setErrorEmails([]);
    setValue(emails);
  };

  const onInvite = async () => {
    const val = value.trim().replace(/\s+/g, "");
    if (!areValidEmails(val)) {
      setError("Please enter valid emails");
      return;
    }
    setErrorEmails([]);
    setIsLoading(true);
    try {
      const emails = val.split(",");
      const host = window.location.hostname.split(".");
      const orgRegion = host[1];
      const orgDomainPrefix = host[0];

      const res = await inviteUsers({
        invitedByUserName: username,
        emailsToInvite: emails,
        redirectUri: `${window.location.origin}`,
        orgRegion,
        orgDomainPrefix,
      }).unwrap();

      const sentMails = res.response.data?.emails as string[];
      if (!isEmpty(sentMails)) {
        toast({
          title: `Invites sent to ${sentMails.length} users`,
          status: ToastType.Success,
        });
      }
      const hasErrors =
        res.response.data?.errors && !isEmpty(res.response.data.errors);
      if (hasErrors) {
        const errMails = res.response.data?.errors as string[];
        toast({
          title: `${errMails.length} members already exist`,
          description: `Cannot send invites to existing members`,
          status: ToastType.Error,
        });
        setErrorEmails(errMails);
        setIsLoading(false);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-[560px] !w-[560px]">
        <ModalHeader className="!text-lg !font-medium">
          Invite Members
        </ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6 !py-2 text-sm w-full leading-[21px] text-gray-800">
          {/* <EmailErrors emails={errorEmails} /> */}
          <FormControl isInvalid={!!error} size="lg">
            <Input
              type="text"
              className="font-medium w-full"
              onChange={handleInputChange}
              value={value}
              placeholder="Enter email"
            />
            {!error && (
              <FormHelperText className="pl-3 text-[13px]">
                <FormHelperTextIcon className="!self-start !text-gray-700" />
                You can enter comma separated emails to invite multiple members
                at once
              </FormHelperText>
            )}
            <FormErrorMessage className="pl-3 text-[13px]">
              <FormErrorIcon className="!self-start !mt-0.5" />
              {error}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex className="!px-6 w-full justify-end mt-7">
            <Button
              colorScheme="dark"
              isLoading={isLoading}
              loadingText="Sending Invites"
              onClick={onInvite}
              size="md"
              variant="solid"
            >
              Send Invites
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
