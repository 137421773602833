import { Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  getExploreStats,
  IExploreTaskResponse,
  selectedColumn,
  STATS_MAP,
  tableSizeMetaData,
} from "@/features/data-transformation";

import { ValueInfoBar } from "../value-info-bar.tsx";

import { DetailItem } from "./detail-item.tsx";
import { EmptyExploreData } from "./empty-explore-data.tsx";
import { ExploreDataChart } from "./explore-data-chart.tsx";
import { NullValuesRow } from "./null-values-row.tsx";

type ExploreDataDetailsPanelProps = {
  data: IExploreTaskResponse | undefined;
  isRequesting: boolean;
};

export const ExploreDataDetailsPanel = ({
  data,
  isRequesting,
}: ExploreDataDetailsPanelProps) => {
  const colInfo = useSelector(selectedColumn);
  const tableMetaData = useSelector(tableSizeMetaData);

  const { columnData, nullPercentage, nullValues } = useMemo(() => {
    return getExploreStats(colInfo, tableMetaData, data);
  }, [colInfo, tableMetaData?.rows, data]);

  if (!colInfo) return <EmptyExploreData />;
  if (isRequesting || !data)
    return (
      <Flex className="flex-col w-full h-full items-center justify-center">
        <Spinner />
      </Flex>
    );

  return (
    <Flex className="flex-col w-full h-full overflow-auto p-4">
      <Flex className="flex-col h-fit w-full">
        <ExploreDataChart data={data?.graph} />
      </Flex>
      <Flex className=" flex-col w-full h-full mt-2">
        <Flex className="relative w-full">
          {columnData && (
            <ValueInfoBar data={columnData} className="h-[6px] hover:h-[6px]" />
          )}
        </Flex>

        <NullValuesRow
          nullPercentage={nullPercentage}
          nullValues={nullValues}
        />
        <SimpleGrid alignItems="strech" columns={2} spacingX={3} spacingY={3.5}>
          {Object.keys(STATS_MAP)
            .map((stat, index) => {
              const statistic = data?.statistics?.otherStats[stat];
              if (!statistic) return null;
              return (
                <DetailItem
                  key={index}
                  title={STATS_MAP[stat]}
                  value={statistic ?? ""}
                />
              );
            })
            .filter(Boolean)}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};
