export function getEmptyRowIndexAfterVisible(
  hot: any,
  visibleRowIndex: number
): number {
  // No data found before visible row or no empty rows after visible row
  let index = visibleRowIndex;

  while (!hot.isEmptyRow(index)) {
    index++;
  }
  return index;
}
