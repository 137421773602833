import { Input } from "@chakra-ui/input";
import { Box, chakra, Flex, Spinner, Tag, Text } from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import React, { useEffect, useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import {
  MdsArrowDownwardRound,
  MdsArrowUpwardRound,
  MdsSwapVertRound,
} from "react-icons-with-materialsymbols/mds";
import { useDebounce } from "use-debounce";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@/design/components/data-table";
import { IconButton } from "@/design/components/icon-button";
import {
  previewRecycleBinItem,
  setCurrentRecycleItem,
} from "@/features/ws-manager/redux/analysis-slice.tsx";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { getDateFormat } from "@/utils/date-convertor.ts";
import { CATEGORY } from "@/utils/enums.ts";

import { useLazyGetRecycleListQuery } from "../../api";
import { CommonItemsSchema } from "../../types/index.ts";
import { CustomIcon } from "../get-icon.tsx";

const columnHelper = createColumnHelper<CommonItemsSchema>();

export function RecycleDatatable(props: { onOpen: () => void }) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [search, setSearch] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const [fetchRecycleBin, { data, isLoading }] = useLazyGetRecycleListQuery();

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  const [debouncedValue] = useDebounce(search, 500);

  useEffect(() => {
    fetchRecycleBin({ query: debouncedValue }).catch((e) => {
      console.error(e);
    });
  }, [debouncedValue]);

  useEffect(() => {
    fetchRecycleBin({}).catch((e) => {
      console.error(e);
    });
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("itemData.name", {
        cell: ({ row: _row, getValue }) => (
          <Flex className="items-center gap-3">
            <CustomIcon
              color={"inherit"}
              type={_row.original?.itemType ?? CATEGORY.Workspace}
            />
            <Text>{getValue<string>()}</Text>
          </Flex>
        ),
        header: "Name",
        size: 50,
      }),
      columnHelper.accessor("itemType", {
        cell: ({ row: _row, getValue }) => (
          <Tag
            className="capitalize"
            colorScheme={(function () {
              switch (getValue<string>()) {
                case CATEGORY.Workspace:
                  return "purple";
                case CATEGORY.Project:
                  return "blue";
                case CATEGORY.Analysis:
                  return "whatsapp";
              }
            })()}
          >
            {getValue<string>()}
          </Tag>
        ),
        header: "Type",
        size: 20,
        enableSorting: false,
      }),
      columnHelper.accessor("itemData.deletedOn", {
        cell: (info) => getDateFormat(info.getValue()),
        header: "Deletion Date",
        size: 30,
      }),
    ],
    [props]
  );

  const table = useReactTable({
    columns: columns,
    data: data?.response?.data?.items ?? [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 100,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const dispatch = useAppDispatch();
  const recycleBinItem = useAppSelector(previewRecycleBinItem);

  const setPreview = (row: CommonItemsSchema) => {
    dispatch(setCurrentRecycleItem(row));
  };

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsSearching((state) => !state);
  };

  return (
    <>
      <Flex direction={"column"} w={"100%"}>
        <Box
          className="overflow-y-auto w-full relative h-full pb-20"
          ref={tableContainerRef}
        >
          <Table width="100%" variant="unstyled">
            <Thead
              position="sticky"
              top={0}
              zIndex={1}
              h={"65px"}
              bg={"white"}
              className="border-b-2"
            >
              <Flex
                className={clsx(
                  "!absolute top-1/2 -translate-y-1/2 left-2 !z-[99]",
                  isSearching ? "w-[40%]" : "w-fit"
                )}
              >
                <IconButton
                  color="gray.900"
                  aria-label="search"
                  colorScheme="blackAlpha"
                  icon={
                    isSearching ? (
                      <MdClear aria-label="search" title="search" />
                    ) : (
                      <AiOutlineSearch
                        aria-label="search"
                        title="search"
                        size={20}
                      />
                    )
                  }
                  onClick={onSearchClick}
                  size="md"
                  variant={"ghost"}
                />
                {isSearching && (
                  <Input
                    className="text-gray-800 !bg-white w-[65%] font-normal mr-2"
                    placeholder="Search by name"
                    name="search"
                    value={search}
                    onChange={handleInputChange}
                    autoFocus={true}
                  />
                )}
              </Flex>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} w={"100%"}>
                  {headerGroup.headers.map((header, idx) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta = header.column.columnDef.meta;
                    return (
                      <Th
                        overflow={"hidden"}
                        style={{
                          width: `${header.column.columnDef.size}%`,
                          minWidth: `${header.column.columnDef.size}%`,
                        }}
                        className="group/header"
                        textOverflow={"ellipsis"}
                        key={header.id}
                        isNumeric={meta?.isNumeric}
                        onClick={header.column.getToggleSortingHandler()}
                        role="button"
                      >
                        <Flex
                          className="!normal-case !shrink-0 tracking-normal font-medium text-gray-700 text-base"
                          align="center"
                          pl={idx == 0 ? 8 : 0}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() && (
                            <chakra.span
                              pl="3"
                              className="text-gray-300 group-hover/header:text-gray-800"
                            >
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === "desc" ? (
                                  <MdsArrowUpwardRound
                                    strokeWidth={22}
                                    fontSize={20}
                                    title="sorted descending"
                                  />
                                ) : (
                                  <MdsArrowDownwardRound
                                    strokeWidth={22}
                                    fontSize={20}
                                    title="sorted ascending"
                                  />
                                )
                              ) : (
                                <MdsSwapVertRound
                                  strokeWidth={22}
                                  fontSize={20}
                                  title="sort"
                                />
                              )}
                            </chakra.span>
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody
              style={{
                height: `${virtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                position: "relative", //needed for absolute positioning of rows
              }}
              width="100%"
              bg={"white"}
            >
              {virtualizer.getVirtualItems().map((virtualRow, index) => {
                const row = rows[virtualRow.index];
                const isSelected =
                  recycleBinItem !== null &&
                  recycleBinItem.itemData.id === row.original.itemData.id;
                return (
                  <Tr
                    onClick={() => {
                      setPreview(row.original);
                      props.onOpen();
                    }}
                    width={"100%"}
                    data-index={virtualRow.index} //needed for dynamic row height measurement
                    ref={(node) => virtualizer.measureElement(node)} //measure dynamic row height
                    key={row.id}
                    bg={
                      isSelected
                        ? "linear-gradient(to right ,transparent, #FFF0E8)"
                        : "white"
                    }
                    _hover={{
                      cursor: "pointer",
                      background: isSelected
                        ? "linear-gradient(to right ,transparent, #FFF0E8)"
                        : "linear-gradient(to right ,transparent, #f5f5f5)",
                    }}
                    style={{ transform: `translateY(${virtualRow.start}px)` }}
                    className={clsx(
                      "absolute py-2 !overflow-hidden flex items-center border-b border-gray-200",
                      isSelected && "border-b border-orange-300"
                    )}
                  >
                    {row.getVisibleCells().map((cell, cellIndex) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      return (
                        <Td
                          className={clsx(
                            "font-medium border-b-0",
                            isSelected && "text-orange-800"
                          )}
                          key={cell.id}
                          style={{
                            width: `${cell.column.columnDef.size}%`,
                            minWidth: `${cell.column.columnDef.size}%`,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        {isLoading && (
          <Flex className="justify-center w-full items-center h-full flex-1 gap-3">
            <Spinner size="sm" />
            Loading...
          </Flex>
        )}
        {table.getRowModel().rows.length === 0 && !isLoading && (
          <div className="flex justify-center w-full items-center mt-[120px]">
            No Data
          </div>
        )}
      </Flex>
    </>
  );
}
