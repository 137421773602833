import { Portal } from "@chakra-ui/portal";
import { Button, Flex, IconButton } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { IoIosAddCircle } from "react-icons/io";
import {
  MdOutlineAddBusiness,
  MdOutlineCreateNewFolder,
  MdOutlineNoteAdd,
} from "react-icons/md";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import { Home } from "@/components/icons/home.tsx";
import { Icon } from "@/design/components/icon";
import { MenuItem, Menu, MenuButton, MenuList } from "@/design/components/menu";
import { useGetAccessListQuery } from "@/features/user-manager";
import {
  setCurrentProject,
  setCurrentWs,
  setPreviewAnalysis,
} from "@/features/ws-manager";
import useElementDimensions from "@/hooks/useDimentions.tsx";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserId, currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { USER_ROLES } from "@/utils/enums";

import { WorkspaceSchema } from "../../types";

import { hasCreatePermission } from "./utils";

interface CreateModalProps {
  navigate: NavigateFunction;
  parentWsId?: string;
  workspace?: { id: string } | WorkspaceSchema | null;
  project?: { id: string } | WorkspaceSchema | null;
}

export const HeaderButtons = ({
  setIndex,
}: {
  setIndex: (arg0: number) => void;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const loggedInUser = useAppSelector(currentUserId);
  const userData = useAppSelector(currentUserMetadata);

  const dispatch = useAppDispatch();
  const navigateHome = () => {
    setIndex(-1);
    dispatch(setCurrentWs(null));
    dispatch(setCurrentProject(null));
    dispatch(setPreviewAnalysis(null));
    navigate("/home");
  };

  const { data: analysisData, isLoading: isAnalysisAccessLoading } =
    useGetAccessListQuery(
      {
        scope: "analysis",
        permission: "create",
      }
      // { skip: true }
    );
  const { data: projectData, isLoading: isProjectAccessLoading } =
    useGetAccessListQuery(
      {
        scope: "project",
        permission: "create",
      }
      // { skip: true }
    );

  const showCreateAnalysisButton = hasCreatePermission(
    analysisData?.response.data
  );

  const showCreateProjectButton = hasCreatePermission(
    projectData?.response.data
  );

  // TODO: Remove this hardcoded user id, need to fetch user roles from the backend
  const showCreateWorkspaceButton = userData?.role === USER_ROLES.ADMIN;
  const { dimensions, ref, refresh } = useElementDimensions();
  const { width } = dimensions ?? {};

  const openWorkspaceCreation = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKSPACE_CREATION,
        modalProps: { navigate },
      })
    );
  };
  const openProjectModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.PROJECT_CREATION,
        modalProps: { navigate, parentWsId: params?.workspace },
      })
    );
  };

  const openAnalysesModal = () => {
    const props: CreateModalProps = {
      navigate,
      workspace: undefined,
      project: undefined,
    };
    if (params?.workspace) {
      props["workspace"] = { id: params?.workspace };
    }
    if (params?.project) {
      props["project"] = { id: params?.project };
    }

    dispatch(
      openModal({
        modalType: ModalTypes.ANALYSIS_CREATION,
        modalProps: props,
      })
    );
  };
  //Note: 24 is the padding of the total menu button - 1 px for border
  const dropdownWidth = width ? width + 23 : 0;

  const showMenu = useMemo(() => {
    if (userData?.role === USER_ROLES.CTK_ADMIN) {
      return false;
    }

    return (
      showCreateAnalysisButton ||
      showCreateProjectButton ||
      showCreateWorkspaceButton
    );
  }, [
    userData,
    showCreateAnalysisButton,
    showCreateProjectButton,
    showCreateWorkspaceButton,
  ]);

  useEffect(() => {
    refresh();
  }, [showMenu, refresh]);

  return (
    <Flex className="items-center self-stretch justify-between gap-2 mx-4 mt-5 mb-1">
      <IconButton
        aria-label="home"
        colorScheme="invert"
        icon={<Home style={{ fontSize: "20px" }} />}
        onClick={navigateHome}
        size="md"
        variant="outline"
      />
      {showMenu && (
        <Menu>
          {() => {
            return (
              <>
                <MenuButton
                  className="flex-1"
                  as={Button}
                  isDisabled={!showMenu}
                  aria-label="home"
                  colorScheme="invert"
                  size="md"
                  title={
                    showMenu ? "Add New" : "You do not have create permission"
                  }
                  variant="outline"
                  animation={"none"}
                >
                  <Flex
                    className="w-full items-center justify-center"
                    ref={ref}
                  >
                    Add New
                    <Icon as={IoIosAddCircle} size="md" className="ml-1.5" />
                  </Flex>
                </MenuButton>
                <Portal>
                  <MenuList
                    className="-mt-2"
                    minWidth={dropdownWidth}
                    borderRadius="3px"
                    border="1px solid"
                    borderColor="gray.300"
                  >
                    {showCreateAnalysisButton && (
                      <MenuItem
                        className="text-gray-900 w-full"
                        maxWidth={dropdownWidth}
                        minWidth={dropdownWidth}
                        icon={<Icon as={MdOutlineNoteAdd} />}
                        onClick={openAnalysesModal}
                      >
                        New Analysis
                      </MenuItem>
                    )}
                    {showCreateProjectButton && (
                      <MenuItem
                        className="text-gray-900"
                        maxWidth={dropdownWidth}
                        minWidth={dropdownWidth}
                        icon={<Icon as={MdOutlineCreateNewFolder} />}
                        onClick={openProjectModal}
                      >
                        New Project
                      </MenuItem>
                    )}
                    {showCreateWorkspaceButton && (
                      <MenuItem
                        className="text-gray-900"
                        maxWidth={dropdownWidth}
                        minWidth={dropdownWidth}
                        icon={<Icon as={MdOutlineAddBusiness} />}
                        onClick={openWorkspaceCreation}
                      >
                        New Workspace
                      </MenuItem>
                    )}
                  </MenuList>
                </Portal>
              </>
            );
          }}
        </Menu>
      )}
    </Flex>
  );
};
