import { RunResults } from "../../types";

export function addMissingColumnsToRunResults(
  ipColumns: string[],
  results: RunResults
): RunResults {
  const fixedColumns = ["llmResponse", "llmError"];
  // Create copies of the headers and result arrays

  let modifiedHeaders = results.headers.slice();
  const modifiedResult = results.result.map((res) => ({ ...res }));
  // Merge columns with fixedColumns to ensure they are always present

  const allColumns = [...new Set([...ipColumns, ...fixedColumns])];

  // Check for missing columns in headers and add them at the beginning

  allColumns.forEach((column) => {
    if (!modifiedHeaders.includes(column)) {
      modifiedHeaders = [column, ...modifiedHeaders];

      modifiedResult.forEach((result) => {
        result[column] = "";
      });
    }
  });

  // Check for extra columns in headers and remove them

  const extraColumns = modifiedHeaders.filter(
    (header) => !fixedColumns.includes(header) && !allColumns.includes(header)
  );

  extraColumns.forEach((extraColumn) => {
    const index = modifiedHeaders.indexOf(extraColumn);

    modifiedHeaders.splice(index, 1);

    modifiedResult.forEach((res) => {
      delete res[extraColumn];
    });
  });
  // Return the modified results object

  return { headers: modifiedHeaders, result: modifiedResult };
}

export function clearRunResultsRows(results: RunResults): RunResults {
  for (const row of results.result) {
    for (const column of results.headers) {
      row[column] = "";
    }
  }
  return results;
}
