import { Icon as ChakraIcon, IconProps } from "@chakra-ui/react"; // Import IconType from react-icons
import { ComponentWithAs, forwardRef } from "@chakra-ui/system";
import { IconType } from "react-icons/lib";

import { IconSizes } from "../../theme/components";

export type CustomIconProps = {
  size?: keyof typeof IconSizes; // Define available size options
  as?: ComponentWithAs<"svg", IconProps> | IconType;
  customSize?: number;
};

type CustomProps = IconProps & CustomIconProps;

export const Icon = forwardRef(
  ({ size = "md", as, color, customSize = 0, ...props }: CustomProps, ref) => {
    return (
      <ChakraIcon
        as={as}
        color={color}
        {...props}
        ref={ref}
        boxSize={customSize > 0 ? customSize : IconSizes[size]}
      />
    );
  },
);
