import { useNavigate, useSearchParams } from "react-router-dom";

/**
 * Custom hook to manage query parameters with enhanced functionalities.
 *
 * While `react-router-dom` offers `useSearchParams` for managing URL search query parameters,
 * this custom hook provides a more unified interface for:
 *
 * - **Simplified parameter setting:** The `setParam` function directly accepts the key
 *   and value of the parameter you want to set.
 * - **Convenient parameter removal:** The `removeParam` function allows you to easily
 *   remove a specific query parameter by its key.
 *
 * These capabilities can streamline query parameter management in your React components.
 *
 * @param {string} query (Optional) The query parameter name. Defaults to "search".
 * @returns {object} An object containing properties and functions:
 *   - `query`: The current value of the specified query parameter.
 *   - `setParam`: Function to set a query parameter value.
 *   - `removeParam`: Function to remove a specific query parameter.
 *   - `removeCurrentParam`: Function to remove the current query parameter.
 *   - `currentParam`: The current value of the specified query parameter.
 */
export const useCustomQuery = (query: string = "search") => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const customQuery = search.get(query);

  /**
   * Sets the value of the specified query parameter without affecting the browser history.
   * @param {string} value - The value to set for the query parameter.
   */
  const setParam = (value: string) => {
    setSearch((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ [query ?? "search"]: value },
      });
    });
  };

  /**
   * Sets the value of the specified query parameter and replaces the current browser history entry.
   * @param {string} value - The value to set for the query parameter.
   */
  const setParamWithHistory = (value: string) => {
    const newParams = new URLSearchParams(search);
    newParams.set(query, value);

    // Replace the current entry in the history stack
    navigate({ search: newParams.toString() }, { replace: true });
  };

  /**
   * @deprecated Use `removeCurrentParam` instead.
   * Removes a specified query parameter.
   * @param {string} key - The key of the query parameter to remove.
   */
  const removeParam = (key: string) => {
    search.delete(key);
    setSearch(search);
  };

  const removeCurrentParam = () => {
    search.delete(query);
    setSearch(search);
  };

  const currentParam = search.get(query);

  return {
    query: customQuery,
    params: search,
    setParam: setParam,
    setParamWithHistory,
    removeParam,
    removeCurrentParam,
    currentParam,
  };
};
