import { Card, CardBody, CardHeader } from "@chakra-ui/react";

import { FlowStoreHeader } from "./flow-store-header.tsx";
import { FlowStoreTabs } from "./flow-store-tabs.tsx";

export const FlowStoreCard = () => {
  return (
    <Card className="h-full w-full" variant="primary">
      <CardHeader>
        <FlowStoreHeader />
      </CardHeader>
      <CardBody className="!overflow-y-hidden flex w-full h-full overflow-hidden items-stretch">
        <FlowStoreTabs />
      </CardBody>
    </Card>
  );
};
