import { Button, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import { camelCase } from "lodash";
import React, { useMemo } from "react";

import { ToastType, useShowToast } from "@/components/toast";
import {
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { VALIDATION_TYPES } from "@/utils/enums";

import {
  useDeleteDatasetMutation,
  useDeleteIoRecordMutation,
  useRunValidationsMutation,
} from "../api";
import { DatasetsSchema, IoRecord } from "../types";

const DeleteIoRecordConfirmationModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const {
    dataset,
    type,
    onClose: onModalClose,
  } = useAppSelector((state) => state.rootReducer.modals.modalProps);

  const [validateUsage, { isLoading: isValidating }] =
    useRunValidationsMutation();
  const [deleteIoRecord, { isLoading: isDeletingIoRecord }] =
    useDeleteIoRecordMutation();
  const [deleteDataset, { isLoading: isDeletingDataset }] =
    useDeleteDatasetMutation();

  const datasetName = useMemo(() => {
    if (type === "ioRecord") return (dataset as IoRecord).name;
    return (dataset as DatasetsSchema).displayName;
  }, [type, dataset]);

  const onClose = () => {
    if (isDeleting) return;
    dispatch(closeModal());
  };

  const handleIoRecordDelete = async () => {
    try {
      await deleteIoRecord({
        analysisId: dataset.analysisId,
        ioRecordId: dataset.id,
      }).unwrap();
      toast({
        title: "File deleted",
        status: ToastType.Success,
      });
    } catch (error) {
      toast({
        title: "Failed to delete file",
        status: ToastType.Error,
      });
    }
    if (onModalClose) {
      onModalClose();
    }
    dispatch(closeModal());
  };

  const openDatasetErrorModal = (status: any) => {
    dispatch(
      openModal({
        modalType: ModalTypes.USAGE_CONFIRMATION,
        modalProps: {
          dataset,
          errType: status.validationMessage?.[0],
        },
      })
    );
  };

  const validateDatasetUsage = async () => {
    const validationType = VALIDATION_TYPES.DATASET_USAGE;
    const resp = await validateUsage({
      analysisId: dataset.analysisId,
      validationType,
      body: { dataset_id: dataset.id },
    }).unwrap();

    const status =
      resp?.response?.data?.results?.[0]?.[
        camelCase(VALIDATION_TYPES.DATASET_USAGE)
      ];
    return status;
  };

  const handleDatasetDelete = async () => {
    try {
      const status = await validateDatasetUsage();
      if (status?.validationStatus === "Failed") {
        openDatasetErrorModal(status);
      } else {
        await deleteDataset({
          analysisId: dataset.analysisId,
          datasetId: dataset.id,
        }).unwrap();
        toast({
          title: "Dataset deleted",
          status: "success",
        });
        dispatch(closeModal());
      }
    } catch (e) {
      const errMsg =
        (e as any).data?.errors[0].detail || "Failed to delete dataset";
      toast({
        title: "Error: Failed to delete dataset",
        description: errMsg,
        status: "error",
      });
    }
  };

  const onDelete = async () => {
    if (type === "ioRecord") {
      await handleIoRecordDelete();
    } else if (type === "dataset") {
      await handleDatasetDelete();
    }
  };

  const isDeleting = isDeletingIoRecord || isDeletingDataset || isValidating;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      isCentered
      size="lg"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="flex flex-col justify-end !px-5 gap-3">
          <Flex className="justify-between items-center w-full">
            <Text className="text-xl font-semibold grow">Delete Dataset ?</Text>
            <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
          </Flex>
          <Text className="">
            Are you sure you want to delete
            <span className="font-bold px-1">"{datasetName}"</span> permanently?
          </Text>
          <Flex className={"justify-between w-full mt-7"}>
            <Button
              mr={2}
              colorScheme="secondary"
              isDisabled={isDeleting}
              onClick={onClose}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              colorScheme="customRed"
              isDisabled={isDeleting}
              isLoading={isDeleting}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onDelete}
            >
              Delete
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteIoRecordConfirmationModal;
