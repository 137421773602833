import clsx from "clsx";
import type { HandleProps } from "reactflow";
import { Handle, Position } from "reactflow";

const positionClasses = {
  [Position.Left]: "!-left-1",
  [Position.Right]: "!-right-1",
  [Position.Top]: "!-top-2 !rotate-90",
  [Position.Bottom]: "!-bottom-2 !rotate-90",
};

export const CustomHandle = ({
  id,
  type,
  position,
  onConnect,
  isConnectable,
  classNames,
}: HandleProps & {
  classNames?: string;
}) => {
  return (
    <Handle
      id={id}
      type={type}
      position={position}
      className={clsx(
        classNames,
        "!h-4 !w-[9px] !rounded-lg !border-2 !border-zinc-600 !bg-white",
        positionClasses[position]
      )}
      onConnect={onConnect}
      isConnectable={isConnectable}
    />
  );
};

export default CustomHandle;
