import { Flex, Text } from "@chakra-ui/react";

import { formatNumberWithCommas } from "@/utils/string-utils.ts";

type MetaDataProps = {
  title: string;
  subtitle: string;
};

export const TableMetaData = ({ title, subtitle }: MetaDataProps) => {
  return (
    <Flex className="items-center">
      <Text className="text-gray-900 text-sm font-medium leading-[100%]">
        {formatNumberWithCommas(title)}&nbsp;
      </Text>
      <Text className="text-gray-700 text-sm leading-[100%]">{subtitle}</Text>
    </Flex>
  );
};
