import { Flex, Button } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useRevertToVersionMutation } from "../../api";
import {
  currentActiveVersion,
  getPreviewVersion,
  setActiveVersion,
  setShowHistory,
} from "../../redux";
import { TextGenVersion } from "../../types";

const RevertHistory = ({
  activeIndex,
  versions,
}: {
  activeIndex: number;
  versions: TextGenVersion[] | undefined;
}) => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, false);
  const activeVersion = useAppSelector(currentActiveVersion);
  const previewVersion = useAppSelector(getPreviewVersion);
  const currentPlayground = useAppSelector(
    (state) => state.llmReducer.activePlayground
  );

  const [revertToVersion, { isLoading: isReverting }] =
    useRevertToVersionMutation();

  const hideHistoryPanel = () => {
    dispatch(setShowHistory(false));
  };

  const handleRevert = async () => {
    const version = versions?.[activeIndex];
    if (!version) return;

    try {
      const resp = await revertToVersion({
        playgroundId: currentPlayground?.id ?? "",
        textGenId: currentPlayground?.textGeneration[0].id ?? "",
        versionId: version?.id ?? "",
      }).unwrap();

      const newVersion = resp?.response?.data?.textGeneration[0].version;
      if (!newVersion) return;

      dispatch(setActiveVersion(newVersion));
      dispatch(setShowHistory(false));
    } catch (err) {
      toast({
        description: "Failed to Revert Version.",
        status: "error",
      });
      console.log(err);
    }
  };

  const isCurrentVersion =
    activeVersion?.versionNumber === previewVersion?.versionNumber;

  const noVersions = versions && isEmpty(versions);

  return (
    <Flex gap={4}>
      {!noVersions && (
        <Button
          colorScheme="dark"
          isDisabled={isCurrentVersion}
          isLoading={isReverting}
          //eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleRevert}
          size={"sm"}
        >
          Revert to this Prompt
        </Button>
      )}
      <Button
        colorScheme="dark"
        onClick={hideHistoryPanel}
        size={"sm"}
        variant={"ghost"}
      >
        Cancel
      </Button>
    </Flex>
  );
};

export default RevertHistory;
