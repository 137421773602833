import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const variantBottomAccent = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    container: {
      background: `${c}.50`,
      pt: "1.5",
      pb: "1.5",
      borderBottomWidth: "1px",
      borderBottomColor: `${c}.400`,
    },
    title: {
      color: `${c}.400`,
    },
    icon: {
      color: `${c}.600`,
    },
  };
});

export const alertTheme = defineMultiStyleConfig({
  variants: { "bottom-accent": variantBottomAccent },
});
