import {
  Flex,
  Input,
  chakra,
  Textarea,
  Image,
  Box,
  FormErrorMessage,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Avatar,
} from "@chakra-ui/react";
import { randomColor } from "@chakra-ui/theme-tools";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { init } from "emoji-mart";
import { isEmpty, PartialObject, pickBy, random } from "lodash";
import { useState, forwardRef, RefObject, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";

import { useShowToast } from "@/components/toast";
import { UploadWidget } from "@/components/upload-widget";
import { TOAST_MESSAGES, ToastFunction } from "@/constants/toast-constants.ts";
import { Button } from "@/design/components/button";
import { FormControl, FormLabel } from "@/design/components/form";
import {
  setPreviewAnalysis,
  useCreateWsMutation,
  useUpdateWsMutation,
} from "@/features/ws-manager";
import {
  WorkspaceCreateSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { closeModal, openModal } from "@/slices/modal-slice.ts";
import { avatarBgColors } from "@/utils/get-color";

init({ data });

type WorkspaceFormProps = { data: any };
const Workspace = z.object({
  name: z
    .string({ required_error: "Workspace name is required" })
    .min(1, { message: "Workspace name is required" })
    .regex(/^[a-zA-Z0-9 _ -]+$/, {
      message:
        "Name must only contain letters, numbers, spaces, underscores, and hyphens",
    })
    .max(50, { message: "Name must be at most 50 characters long" })
    .refine((n) => n.trim() !== "", {
      message: "Workspace name cannot be empty",
    }),
  description: z
    .string()
    .regex(/^[a-zA-Z0-9 _ -]*$/, {
      message:
        "Description must only contain English letters, numbers, spaces, underscores, and hyphens",
    })
    .max(500, { message: "Description must be at most 500 characters long" })
    .optional(),
  emoji: z.string().emoji().optional().or(z.literal("")),
});

type WorkspaceFormSchema = z.infer<typeof Workspace>;

enum PREVIEW_TYPE {
  image = "image",
  emoji = "emoji",
}

const WorkspaceForm = forwardRef(function WorkspaceFormFunc(
  { data: modalProps }: WorkspaceFormProps,
  ref
) {
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useDispatch();
  const [iconBg, setIconBg] = useState<string>("");
  const defaultPreview = "";
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState<{ src: string; type: PREVIEW_TYPE }>({
    src: defaultPreview,
    type: PREVIEW_TYPE.image,
  });

  const [createWs, { isLoading }] = useCreateWsMutation();
  const [updateWs, { isLoading: isUpdating }] = useUpdateWsMutation();

  const {
    id: wsId,
    name,
    description,
    emoji,
    icon,
  } = (modalProps?.data ?? {}) as Partial<WorkspaceSchema>;

  const isEditing = modalProps?.data !== undefined;

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    watch,
    getValues,
  } = useForm<WorkspaceFormSchema>({
    resolver: zodResolver(Workspace),
    defaultValues: {
      name: name ?? "",
      description: description ?? "",
      emoji: emoji ?? "",
    },
  });

  useEffect(() => {
    if (isEditing) {
      setIconBg((icon as string) ?? randomColor({ colors: avatarBgColors }));
      setPreview({
        src: emoji ?? defaultPreview,
        type: emoji ? PREVIEW_TYPE.emoji : PREVIEW_TYPE.image,
      });
    } else {
      setIconBg(randomColor({ colors: avatarBgColors }));
    }
  }, [modalProps]);

  const handleFormSubmit: SubmitHandler<WorkspaceFormSchema> = (
    values: WorkspaceFormSchema
  ) => {
    if (isEditing) {
      handleEditFormSubmit();
    } else {
      handleNewFormSubmit(values);
    }
  };

  const handleEditFormSubmit = async () => {
    if (isDirty) {
      try {
        const sanitizedValues: Partial<WorkspaceCreateSchema> = Object.keys(
          dirtyFields
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ).reduce((o, key) => ({ ...o, [key]: getValues(key) }), {});
        await updateWs({ ...sanitizedValues, id: wsId! }).unwrap();
        toast({ ...TOAST_MESSAGES.workspaceDetailsUpdated });
      } catch {
        console.error("issue");
        return;
      }
    }

    dispatch(closeModal());
  };

  const handleNewFormSubmit: SubmitHandler<WorkspaceFormSchema> = async (
    _values: WorkspaceFormSchema
  ) => {
    try {
      const sanitizedValues: PartialObject<WorkspaceFormSchema> = pickBy(
        _values,
        (item) => item.length > 0
      );

      const bodyValues = sanitizedValues as WorkspaceFormSchema;

      await createWorkspace(bodyValues);
    } catch {
      console.error("issue");
    }
  };

  const createWorkspace = async (values: WorkspaceFormSchema) => {
    const wsData: WorkspaceCreateSchema = {
      ...values,
    };

    if (file) {
      wsData.icon = file;
    } else {
      wsData.icon = iconBg;
    }
    await createWs(wsData)
      .unwrap()
      .then((res) => {
        toast(TOAST_MESSAGES.workspaceCreated(values.name));

        // if navigated from a previous modal like create project -> create workspace,
        // then reopen the create project modal
        const wkId = res?.response?.data?.workspaces[0]?.id;
        if (modalProps?.reopenModal) {
          dispatch(
            openModal({
              modalType: modalProps?.reopenModal,
              modalProps: {
                workspace: { id: wkId },
                data: { wsId: wkId! },
                navigate: modalProps.navigate,
              },
            })
          );
          return;
        }

        // open the newly created workspace
        if (modalProps.navigate && wkId) {
          dispatch(setPreviewAnalysis(null));
          modalProps.navigate(`/home/${wkId}`);
        }
        dispatch(closeModal());
      });
  };

  const onEmojiClick = (emojiObject: { native: string | undefined }) => {
    setValue("emoji", emojiObject.native, { shouldDirty: true });
    setPreview({ src: emojiObject.native!, type: PREVIEW_TYPE.emoji });
    setFile(undefined);
  };

  const setImage = (imageFile: File | undefined) => {
    const reader = new FileReader();
    reader.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setFile(reader.result);
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }

    setPreview({
      src: imageFile ? URL.createObjectURL(imageFile) : defaultPreview,
      type: PREVIEW_TYPE.image,
    });

    setValue("emoji", undefined);
  };

  // const handleEmojiClick = () => {
  //   setValue("emoji", null, { shouldDirty: true });
  //   setPreview({ src: "", type: PREVIEW_TYPE.image });
  // };

  const wsName = watch("name");
  const wsEmoji = watch("emoji");
  const hasIcon = !isEmpty(wsName) || !isEmpty(wsEmoji);

  const getIcon = () => {
    if (!isEmpty(wsEmoji)) {
      return wsEmoji;
    }
    return <Avatar bg={iconBg} name={wsName} size={"sm"} />;
  };

  return (
    <form
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex items-center justify-between flex-col mt-4 mb-2 gap-4 w-full rounded px-6"
      noValidate={true}
    >
      <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
        <FormLabel>Workspace Title</FormLabel>
        <Input {...register("name")} />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl id="emoji">
        <FormLabel>Workspace Icon</FormLabel>
        <Flex align="flex-start" gap={4} mt={2}>
          {/* <UploadWidget
            variant="small"
            title="Upload a image"
            file={file}
            allowedTypes={["png", "jpg", "jpeg"]}
            onFileChange={setImage}
          />
          <Box>or</Box> */}
          <Popover>
            <PopoverTrigger>
              <Input
                className="!w-min cursor-pointer"
                placeholder="😎 Use an emoji"
                {...register("emoji")}
                readOnly={true}
                type="text"
              />
            </PopoverTrigger>
            <Portal containerRef={ref as RefObject<HTMLElement | null>}>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <Picker data={data} onEmojiSelect={onEmojiClick} />
              </PopoverContent>
            </Portal>
          </Popover>
          {hasIcon && (
            <Flex align="center" direction="column">
              <chakra.div
                className={clsx(
                  "w-10 h-10 p-1 items-center justify-center flex bg-gray-100 !rounded-lg text-2xl"
                )}
                borderRadius="sm"
              >
                {getIcon()}
              </chakra.div>
              <small>Preview</small>
            </Flex>
          )}
        </Flex>
      </FormControl>
      <FormControl id="description" isInvalid={!!errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea variant="customOutlined" {...register("description")} />
        <FormErrorMessage>
          {errors.description && errors.description?.message}
        </FormErrorMessage>
      </FormControl>

      <Button
        variant="solid"
        colorScheme="secondary"
        className="pb-0 mt-8 self-end"
        type="submit"
        isLoading={isEditing ? isUpdating : isLoading}
      >
        {isEditing ? "Save Details" : "Create Workspace"}
      </Button>
    </form>
  );
});

export default WorkspaceForm;
