import { Box } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import {
  WizardStep,
  WizardIndicator,
  WizardNumber,
  WizardStatus,
  WizardTitle,
  Wizard,
  useSteps,
} from "@/design/components/wizard";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { FLOW_SCOPE } from "../../utils/constants";

import { FlowSharingForm } from "./sharing-form";
import { UploadForm } from "./upload-form";

const steps = [{ title: "General" }, { title: "Sharing" }];

export const flowUploadSchema = z.object({
  displayName: z
    .string()
    .max(100, { message: "Name must be at most 100 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Name cannot be empty",
    }),
  description: z
    .string()
    .max(500, { message: "Description must be at most 500 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Description cannot be empty",
    }),
  tags: z.array(z.string()).max(5, { message: "Maximum of 5 tags allowed" }),
  scope: z.string().optional(),
});

export interface FlowUploadData {
  displayName: string;
  description: string;
  tags?: string[];
  scope?: FLOW_SCOPE | string | undefined;
}

const UploadToFlowStoreModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { node } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );
  const formState = useForm<FlowUploadData>({
    defaultValues: {
      displayName: node?.data.displayName || "",
      description: node?.data.description || "",
      tags: node.data.tags || [],
      scope: FLOW_SCOPE.ORGANIZATION,
    },
  });

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} size="lg" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload to Flow Store</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH={"300px"} className="flex flex-col h-full">
          <Wizard
            size="sm"
            index={activeStep}
            justifyContent="flex-start"
            className="p-3"
          >
            {steps.map((step, index) => (
              <WizardStep key={index}>
                <WizardIndicator>
                  <WizardStatus
                    active={<WizardNumber />}
                    complete={<WizardNumber />}
                    incomplete={<WizardNumber />}
                  />
                </WizardIndicator>

                <Box flexShrink="0">
                  <WizardTitle>{step.title}</WizardTitle>
                </Box>
              </WizardStep>
            ))}
          </Wizard>
          <Box className="pt-3 grow flex flex-col h-full">
            {
              {
                0: (
                  <UploadForm
                    formState={formState}
                    setActiveStep={setActiveStep}
                  />
                ),
                1: (
                  <FlowSharingForm
                    formState={formState}
                    setActiveStep={setActiveStep}
                  />
                ),
              }[activeStep]
            }
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadToFlowStoreModal;
