import { Flex, Icon, Text } from "@chakra-ui/react";
import { MdAdd, MdArrowBack, MdStore } from "react-icons/md";

import { Button } from "@/design/components/button";
import { useAppDispatch } from "@/reduxHooks.ts";
import { PRIVACY_STATUS } from "@/utils/enums";

import {
  setShowFlowStore,
  setCurrentFlowStoreItem,
} from "../../redux/workflow-studio-slice";

export const FlowStoreHeader = () => {
  const dispatch = useAppDispatch();

  //   if (!isFlowStoreOpen) return null;

  const setShowFlowStoreHandler = () => {
    dispatch(setShowFlowStore(false));
    dispatch(setCurrentFlowStoreItem(null));
  };

  return (
    <Flex className="w-full" align="center" justify={"space-between"}>
      <Flex align="center" justify="end" gap={2}>
        <Icon
          className="rounded-full bg-orange-50"
          as={MdStore}
          boxSize={8}
          p={1.5}
          color={"orange.400"}
        />
        <Text className="font-semibold text-xl tracking-tight">Flow Store</Text>
      </Flex>
      <Flex justify="end" gap={2}>
        <Button
          variant={"outline"}
          size="sm"
          colorScheme="dark"
          onClick={setShowFlowStoreHandler}
          leftIcon={<MdArrowBack />}
        >
          Back to Workflow
        </Button>
      </Flex>
    </Flex>
  );
};
