import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import { clsx } from "clsx";
import { ReactElement } from "react";

import { AnalysisHeader } from "./analysis-header.tsx";

export const AnalysisCard = (props: {
  children: ReactElement;
  isOpen: boolean;
}) => {
  return (
    <Card
      className={clsx(
        "ease-in-out transition duration-1000 overflow-hidden flex-1",
        !props.isOpen ? "translate-x-0 w-full" : "-translate-x-30%"
      )}
      style={{ height: "100%" }}
      variant="primary"
    >
      <CardHeader className="!w-full">
        <AnalysisHeader />
      </CardHeader>
      <CardBody className="border-t flex flex-1 h-full w-full !overflow-hidden">
        {props.children}
      </CardBody>
    </Card>
  );
};
