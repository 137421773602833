import { Flex, Text } from "@chakra-ui/react";
import { MdClear } from "react-icons/md";

import { ConfigurePanelHeader } from "@/design/components/configure-panel";
import { IconButton } from "@/design/components/icon-button";

interface NodeConfigHeaderProps {
  title: string;
  onClose: () => void;
}

export const NodeConfigPanelHeader = ({
  title,
  onClose,
}: NodeConfigHeaderProps) => {
  return (
    <ConfigurePanelHeader p={4}>
      <Flex align="center" justify="space-between" w="100%">
        <Text className="text-lg font-semibold">Configure {title}</Text>
        <IconButton
          variant="ghost"
          colorScheme="dark"
          aria-label="close panel"
          icon={<MdClear />}
          onClick={onClose}
        />
      </Flex>
    </ConfigurePanelHeader>
  );
};
