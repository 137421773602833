import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export const showPromptHistory = createSelector(
  (state: RootState) => state.llmReducer.showHistory,
  (showHistory) => showHistory
);

export const getInputPrompt = (state: RootState) => {
  const activeVersion = state.llmReducer.activeVersion;
  if (!activeVersion) return "";
  return activeVersion.messages[0].content[0].text;
};

export const getInputColumns = (state: RootState) =>
  state.llmReducer.inputColumns;

export const getPreviewVersion = (state: RootState) =>
  state.llmReducer.previewVersion;

export const currentActiveVersion = (state: RootState) =>
  state.llmReducer.activeVersion;

export const getActivePlayground = (state: RootState) =>
  state.llmReducer.activePlayground;

export const isExecuteEnabled = (state: RootState) =>
  state.llmReducer.isExecuteEnabled;

export const selectInputPrompt = createSelector(
  (state: RootState) => state.llmReducer.activeVersion,
  (activeVersion) => activeVersion?.messages[0].content[0].text ?? ""
);

export const selectColumnsInPrompt = createSelector(
  selectInputPrompt,
  (inputPrompt) =>
    inputPrompt
      .match(/{{\s{0,10}\w+\s{0,10}}}/g)
      ?.map((m) => m.slice(2, -2).trim())
      .filter(Boolean)
);

export const selectColumnsWithError = createSelector(
  selectColumnsInPrompt,
  (state: RootState) => getInputColumns(state),
  (columnsInPrompt, inputColumns) => {
    if (!columnsInPrompt || columnsInPrompt.length === 0) return undefined;
    const columns =
      inputColumns?.filter((c) => columnsInPrompt.includes(c)) ?? [];
    const errorColumns = columnsInPrompt.filter(
      (c) => !inputColumns?.includes(c)
    );
    return {
      columns,
      errorColumns: errorColumns.length > 0 ? errorColumns : undefined,
    };
  }
);

export const promptErrorColumns = (state: RootState) =>
  selectColumnsWithError(state)?.errorColumns;

export const promptColumns = (state: RootState) =>
  selectColumnsWithError(state)?.columns;

export const getCurrentRunResults = createSelector(
  (state: RootState) => state.llmReducer.currentRunResults,
  (currentRunResults) => currentRunResults
);

export const selectRunDetailsById = (runId?: string) =>
  createSelector(
    (state: RootState) => state.llmReducer.runResults,
    (runResults) => (runId === undefined ? undefined : runResults?.[runId])
  );

export const getAllRunResults = createSelector(
  (state: RootState) => state.llmReducer.runResults,
  (runResults) => runResults
);

export const selectResultRowCount = createSelector(
  (state: RootState) => state.llmReducer.resultRowCount,
  (currentResultRowCount) => currentResultRowCount
);

export const getActiveRunStatus = createSelector(
  (state: RootState) => state.llmReducer.activeVersion,
  (activeVersion) => activeVersion?.runs?.runStatus
);

export const isLLMEditable = createSelector(
  [showPromptHistory, getActiveRunStatus],
  (isHistoryOpen: boolean, runStatus: string | null | undefined) => {
    return isHistoryOpen || runStatus === "running";
  }
);
