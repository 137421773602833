import { Box, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { useMemo } from "react";
import { MdInfo } from "react-icons/md";

import { useAppSelector } from "@/reduxHooks";

import { promptErrorColumns, showPromptHistory } from "../../redux";

const PromptFooter = () => {
  const columnsWithError = useAppSelector(promptErrorColumns);
  const isHistoryOpen = useAppSelector(showPromptHistory);

  const showColumnsWithErrors = useMemo(() => {
    if (isHistoryOpen) {
      return undefined;
    }
    return columnsWithError;
  }, [columnsWithError, isHistoryOpen]);

  return (
    <Flex
      className={clsx(
        " border border-t-0 gap-1 py-2 px-2 rounded-b-md text-sm",
        showColumnsWithErrors
          ? "border-red-600 bg-red-50 text-red-600"
          : "text-gray-800 border-[#ececec] bg-[#FCFCFC]"
      )}
    >
      <MdInfo size={16} className="shrink-0 mt-0.5" />
      {showColumnsWithErrors ? (
        <Box className="break-all">
          Column
          {showColumnsWithErrors &&
            ` {{${showColumnsWithErrors.join("}}, {{")}}} `}
          could not be found
        </Box>
      ) : (
        <Box className="break-all">
          Type {`{{ }}`} to insert input columns in your prompt
        </Box>
      )}
    </Flex>
  );
};

export default PromptFooter;
