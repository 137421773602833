import { AccountInfo } from "@azure/msal-browser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserMetadata } from "@/features/user-manager/types";
import { RootState } from "@/store";

type AuthState = {
  account: AccountInfo | null;
  claim: string | null;
  accountUserId: string | null;
  currentUserMetadata: IUserMetadata | null;
};

const initialState: AuthState = {
  account: null,
  claim: null,
  accountUserId: null,
  currentUserMetadata: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<AccountInfo | null>) => {
      state.account = action.payload;
    },
    setClaim: (state, action: PayloadAction<string | null>) => {
      state.claim = action.payload;
    },
    setAccountUserId: (state, action: PayloadAction<string | null>) => {
      state.accountUserId = action.payload;
    },
    setCurrentUserMetadata: (
      state,
      action: PayloadAction<IUserMetadata | null>
    ) => {
      state.currentUserMetadata = action.payload;
    },
  },
});

export const {
  setAccount,
  setClaim,
  setAccountUserId,
  setCurrentUserMetadata,
} = authSlice.actions;
export default authSlice.reducer;

export const currentUserId = (state: RootState) =>
  state.authReducer.accountUserId;

export const currentUserMetadata = (state: RootState) =>
  state.authReducer.currentUserMetadata;
