import { Box, ButtonProps, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  MdOutlineCopyAll,
  MdOutlineDelete,
  MdOutlineMessage,
  MdOutlineRemoveRedEye,
  MdOutlineReplay,
  MdOutlineSettings,
} from "react-icons/md";
import { MdsDatabaseRound } from "react-icons-with-materialsymbols/mds";
import { useReactFlow } from "reactflow";

import { useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { useAppDispatch } from "@/reduxHooks.ts";

import { useCreateCopyNode } from "../../hooks/useCreateNodeCopy";
import useManageOutput from "../../hooks/useManageOutput";
import { hidePanel, showPanel } from "../../redux";
import { NodeParameter, NodeType } from "../../types";
import { WORKFLOW_PANELS } from "../../utils/constants";

export interface CTXMenuProps {
  id: string;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ListItemButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      variant="ghost"
      justifyContent="flex-start"
      colorScheme="dark"
      sx={{
        ".chakra-button__icon *": { height: "20px", width: "20px" },
      }}
      className="border-b !font-normal !text-sm"
      {...props}
    >
      {children}
    </Button>
  );
};

const ContextMenu: React.FC<
  CTXMenuProps & {
    setMenu: React.Dispatch<React.SetStateAction<CTXMenuProps | null>>;
  }
> = ({ id, top, left, right, bottom, setMenu, ...props }) => {
  const { getNode, deleteElements } = useReactFlow();
  const toast = useShowToast(undefined, undefined, false);
  const ctxNode = getNode(id);
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const { createNodeCopy } = useCreateCopyNode();
  const { isUnMarking, removeOutput, openOutputModal } = useManageOutput();

  const hasParent = useMemo(() => getNode(id)?.parentNode, [getNode, id]);
  const isFlowNode = useMemo(
    () =>
      (ctxNode?.data as NodeType).nodeType === "CUSTOM_FLOW_NODE" ||
      ctxNode?.type === "group-node",
    [ctxNode]
  );
  const hasConfig = useMemo(() => {
    const node = getNode(id);
    if (node?.data.parameters) return false;
    return (
      (node?.data?.parameters as NodeParameter[])?.filter((f) => !f.isHidden)
        .length > 0
    );
  }, [getNode, id]);

  const isSourceNode = useMemo(
    () =>
      (ctxNode?.data as NodeType).nodeType
        .toUpperCase()
        .includes("SOURCE".toUpperCase()),
    [ctxNode]
  );

  const onClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    // event.stopPropagation();
  };

  const showLogsPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
  };

  const showDataPreviewPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(
      showPanel({ panel: WORKFLOW_PANELS.DataPreviewPanel, nodeId: id })
    );
  };

  const openConfig = useCallback(() => {
    if (!hasConfig) {
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
    } else {
      dispatch(
        showPanel({
          panel: WORKFLOW_PANELS.NodeConfigurationPanel,
          nodeId: id,
        })
      );
    }
  }, [dispatch, hasConfig, id]);

  const duplicateNode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      if (ctxNode) {
        createNodeCopy(ctxNode);
      }
    } catch (e) {
      toast({
        description: "Failed to duplicate node",
        status: "error",
        variant: "subtle",
      });
    }
  };

  const removeNode = () => {
    if (!ctxNode) return;
    deleteElements({ nodes: [{ id: ctxNode.id }] });
  };

  // useeffect to handle click outside and hide menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenu]);

  if (!ctxNode || isFlowNode || !ctxNode.data) return null;
  return (
    <Box
      className={clsx(
        "z-[10] absolute shadow-noblur !rounded-none bg-white overflow-hidden",
        "w-min border border-gray-300 max-w-[250px]",
        bottom && "-translate-y-1/2"
      )}
      ref={menuRef}
      style={{
        top,
        left,
        right,
        bottom,
      }}
      {...props}
    >
      <Flex direction="column">
        <Box className="px-3 py-2 overflow-hidden text-xs border-b bg-gray-50 whitespace-pre text-ellipsis">
          {ctxNode.data.displayName}
        </Box>
        {!isSourceNode &&
          (ctxNode.data.isOutput ? (
            <ListItemButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => removeOutput({ id })}
              isLoading={isUnMarking}
              isDisabled={isUnMarking}
              leftIcon={<MdsDatabaseRound className="stroke-[20]" />}
            >
              Remove Output Dataset
            </ListItemButton>
          ) : (
            <ListItemButton
              onClick={() => openOutputModal({ id })}
              isLoading={isUnMarking}
              isDisabled={isUnMarking}
              leftIcon={<MdsDatabaseRound className="stroke-[20]" />}
            >
              Create Output Dataset
            </ListItemButton>
          ))}

        <ListItemButton
          isDisabled={true}
          onClick={onClickHandler}
          leftIcon={<MdOutlineReplay />}
        >
          Run Again
        </ListItemButton>
        {hasConfig && (
          <ListItemButton onClick={openConfig} leftIcon={<MdOutlineSettings />}>
            Edit Configuration
          </ListItemButton>
        )}
        <ListItemButton
          onClick={showDataPreviewPanel}
          leftIcon={<MdOutlineRemoveRedEye />}
        >
          View Data
        </ListItemButton>
        {!hasParent && (
          <ListItemButton
            onClick={duplicateNode}
            leftIcon={<MdOutlineCopyAll />}
          >
            Duplicate Node
          </ListItemButton>
        )}
        <ListItemButton onClick={showLogsPanel} leftIcon={<MdOutlineMessage />}>
          View Run Logs
        </ListItemButton>
        {!hasParent && (
          <ListItemButton leftIcon={<MdOutlineDelete />} onClick={removeNode}>
            Remove Node
          </ListItemButton>
        )}
      </Flex>
    </Box>
  );
};

export default ContextMenu;
