import { Flex, Text } from "@chakra-ui/react";
import { IconType } from "react-icons/lib";
import { MdsDoneRound } from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";

type CustomSubDropdownRenderProps = {
  title: string;
  icon: IconType;
  isSelected?: boolean;
  iconClassName?: string;
};

export const CustomSubDropdownRender = ({
  title,
  icon,
  iconClassName,
  isSelected = false,
}: CustomSubDropdownRenderProps) => {
  return (
    <Flex className="justify-between gap-x-1 cursor-pointer px-2 py-2.5 pr-1 hover:bg-gray-50 border-b border-b-gray-100">
      <Flex className="flex-row items-center gap-x-1">
        <Icon as={icon} size="sm" className={iconClassName} />
        <Text className="text-gray-900 font-[13px] leading-[120%] tracking-[-0.26px]">
          {title}
        </Text>
      </Flex>
      {isSelected && <Icon as={MdsDoneRound} size="sm" />}
    </Flex>
  );
};
