import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages";
import { TOAST_MESSAGES } from "@/constants/toast-constants";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { ACTION_TYPE, CATEGORY } from "@/utils/enums";

import { useDeleteAnalysisMutation, useUpdateAnalysesMutation } from "../api";
import { previewAnalysis, setPreviewAnalysis } from "../redux";
import { AnalysesSchema } from "../types";

const useAnalysis = () => {
  const analysis = useAppSelector(previewAnalysis);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useShowToast();

  const [deleteAnalysis] = useDeleteAnalysisMutation();
  const [updateAnalysis] = useUpdateAnalysesMutation();

  const onDelete = (
    e: React.MouseEvent<Element, MouseEvent>,
    { data }: { data: AnalysesSchema }
  ) => {
    e.stopPropagation();
    e.preventDefault();

    const { project, workspace } = params;
    let parent: string;
    if (project) {
      parent = "project";
    } else if (workspace) {
      parent = "workspace";
    }

    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...MODAL_MESSAGES.deleteAnalysis,
          primaryAction: () => {
            void deleteAnalysis({
              id: data.id,
              parent: parent,
              parentId: project || workspace,
            }).then(() => {
              toast(TOAST_MESSAGES.analysisDeleted(data.name));
              if (analysis?.id === data.id) {
                dispatch(setPreviewAnalysis(null));
              }
            });
          },
        },
      })
    );
  };
  const onEdit = (
    e: React.MouseEvent<Element, MouseEvent>,
    { data }: { data: AnalysesSchema }
  ) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      openModal({
        modalType: ModalTypes.ANALYSIS_CREATION,
        modalProps: { analysis: data },
      })
    );
  };

  const onFavorite = async (
    e: React.MouseEvent<Element, MouseEvent>,
    { data }: { data: AnalysesSchema }
  ) => {
    e.stopPropagation();
    e.preventDefault();

    const action = data.favorite
      ? ACTION_TYPE.UNMARK_FAVORITE
      : ACTION_TYPE.MARK_FAVORITE;
    await updateAnalysis({
      id: data.id,
      action: action,
    })
      .unwrap()
      .then(() => {
        const toastMessage = data.favorite
          ? TOAST_MESSAGES.removeFromFavorites
          : TOAST_MESSAGES.addToFavorites;
        toast({
          ...toastMessage,
        });
      });
  };

  const openManageAccessModal = (
    e: React.MouseEvent,
    { data, type }: { data: AnalysesSchema; type: CATEGORY }
  ) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: {
          object: data,
          type: type,
          navigate: navigate,
        },
      })
    );
  };

  const openDM = (e: React.MouseEvent, { data }: { data: AnalysesSchema }) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setPreviewAnalysis(null));
    const analysisId = data.id;
    const url = `/analysis/${analysisId}/data-manager`;

    // Open the URL in a new tab
    window.open(url, "_blank");
  };
  return { openDM, openManageAccessModal, onFavorite, onEdit, onDelete };
};

export default useAnalysis;
