import { Text, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { IconType } from "react-icons";
import { FaRegFile } from "react-icons/fa";
import {
  MdsEditRound,
  MdsExpandMoreRound,
  MdsGroupRound,
} from "react-icons-with-materialsymbols/mds";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ProtectedComponent } from "@/components/protected-component/protected-component";
import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { AnalysesSchema } from "@/features/ws-manager/types";
import usePermissions from "@/hooks/usePermissions";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { CATEGORY, PERMISSIONS } from "@/utils/enums.ts";

type AnalysisHeaderProps = {
  icon?: IconType | null;
  isLast?: boolean;
  analysis?: AnalysesSchema;
};

export const NavbarTitle = ({
  icon,
  isLast = false,
  analysis,
}: AnalysisHeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkPermission } = usePermissions();

  const openManageAccessModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: analysis, type: CATEGORY.Analysis, navigate },
      })
    );
  };

  const editAnalysis = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.ANALYSIS_CREATION,
        modalProps: { analysis: analysis },
      })
    );
  };

  const showMenu = useMemo(() => {
    return checkPermission({
      id: analysis?.id,
      requiredPermission: PERMISSIONS.WRITE,
      type: CATEGORY.Analysis,
    });
  }, [analysis, checkPermission]);

  return (
    <Flex className="items-center" flex="1">
      <Helmet>
        <title>
          {isEmpty(analysis) ? "Clootrack" : `${analysis?.name} - Clootrack`}
        </title>
      </Helmet>
      <Flex className="p-1.5 bg-gray-50 items-center justify-center mr-0.5">
        <Icon as={icon ?? FaRegFile} size="md" className="fill-gray-800" />
      </Flex>
      <Flex className="items-center px-1">
        <Menu isLazy placement="bottom-end">
          {({ isOpen }) => {
            return (
              <>
                <MenuButton className="group" disabled={!showMenu}>
                  <Flex className="items-center gap-1">
                    <Text className="capitalize text-xl">
                      {analysis?.name ?? "Analysis"}
                    </Text>
                    {showMenu && (
                      <Icon
                        size="lg"
                        as={MdsExpandMoreRound}
                        className={clsx(
                          "opacity-1 text-gray-700 transition",
                          isLast && "opacity-0",
                          isOpen && "rotate-180"
                        )}
                      />
                    )}
                  </Flex>
                </MenuButton>
                {showMenu && (
                  <MenuList sx={{ marginTop: "0px" }}>
                    <ProtectedComponent
                      requiredPermission={PERMISSIONS.WRITE}
                      type={CATEGORY.Analysis}
                      id={analysis?.id}
                    >
                      <MenuItem
                        icon={<Icon as={MdsGroupRound} />}
                        onClick={openManageAccessModal}
                      >
                        Manage Access
                      </MenuItem>
                    </ProtectedComponent>
                    {/*<MenuItem
                    icon={<Icon as={MdOutlineTroubleshoot} />}
                    isDisabled={true}
                  >
                    Publish Analysis
                  </MenuItem>
                  <MenuItem
                    icon={<Icon as={MdOutlineSettings} />}
                    isDisabled={true}
                  >
                    Version History
                  </MenuItem>
                  */}
                    <ProtectedComponent
                      requiredPermission={PERMISSIONS.WRITE}
                      id={analysis?.id}
                      type={CATEGORY.Analysis}
                    >
                      <MenuItem
                        icon={<Icon as={MdsEditRound} />}
                        onClick={editAnalysis}
                      >
                        Edit Analysis Details
                      </MenuItem>
                    </ProtectedComponent>
                    {/*
                  <MenuItem
                    icon={<Icon as={MdOutlineEdit} />}
                    isDisabled={true}
                  >
                    Discard this Draft
                  </MenuItem>
                  <MenuItem
                    color="red.600"
                    icon={<Icon as={RiDeleteBin6Line} />}
                    isDisabled={true}
                  >
                    Delete Analysis
                  </MenuItem>*/}
                  </MenuList>
                )}
              </>
            );
          }}
        </Menu>
      </Flex>
    </Flex>
  );
};
