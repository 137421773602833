import {
  Menu,
  MenuList,
  MenuItem,
  Icon,
  Tooltip,
  PlacementWithLogical,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { IconType } from "react-icons";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { hideContextMenu } from "@/slices/context-menu-slice";

import { ProtectedComponent } from "../protected-component/protected-component";

const ContextMenu: React.FC = () => {
  const { x, y, items, show } = useAppSelector(
    (state) => state.rootReducer.contextMenu
  );
  const dispatch = useAppDispatch();

  const { menuX, menuY } = useMemo(() => {
    const menuWidth = 200;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const xVal = x + (menuWidth > windowWidth - x ? -menuWidth : 10);
    const yVal = y + (menuWidth > windowHeight - y ? -menuWidth : 10);

    return { menuX: xVal, menuY: yVal };
  }, [x, y]);

  if (!show) return null;

  const onRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleItemClick = (action: any) => {
    if (action) {
      action();
    }
    dispatch(hideContextMenu());
  };

  return (
    <Menu isOpen={true}>
      <MenuList
        pos="fixed"
        zIndex={100}
        top={`${menuY}px`}
        left={`${menuX}px`}
        minW="200px"
        bg="white"
        borderRadius="md"
        shadow="md"
      >
        {items
          .filter((item) => item.isVisible !== false)
          .map((item, index) => {
            const { extraOptions, isProtected, isDisabled } = item;
            if (isProtected) {
              return (
                <ProtectedComponent
                  key={"menuItem" + index}
                  requiredPermission={extraOptions.permission}
                  id={extraOptions.data.id}
                  type={extraOptions.data.type}
                >
                  <Tooltip
                    hasArrow={item?.toolTip?.hasArrow ?? false}
                    isDisabled={!item?.toolTip?.label}
                    label={item?.toolTip?.label}
                    placement={
                      (item?.toolTip?.placement as PlacementWithLogical) ??
                      "top"
                    }
                  >
                    <MenuItem
                      color={item.color ? item.color : ""}
                      _hover={{ bg: "gray.100" }}
                      icon={<Icon as={item?.icon as IconType} fontSize={20} />}
                      isDisabled={isDisabled}
                      onClick={() => handleItemClick(item.action)}
                      onContextMenu={onRightClick}
                    >
                      {item.label}
                    </MenuItem>
                  </Tooltip>
                </ProtectedComponent>
              );
            }
            return (
              <Tooltip
                key={"menuItem-tt-" + index}
                hasArrow={item?.toolTip?.hasArrow ?? false}
                isDisabled={!item?.toolTip?.label}
                label={item?.toolTip?.label}
                placement={
                  (item?.toolTip?.placement as PlacementWithLogical) ?? "top"
                }
              >
                <MenuItem
                  key={"menuItem" + index}
                  color={item.color ? item.color : ""}
                  _hover={{ bg: "gray.100" }}
                  icon={<Icon as={item?.icon as IconType} fontSize={20} />}
                  isDisabled={isDisabled}
                  onClick={() => handleItemClick(item.action)}
                  onContextMenu={onRightClick}
                >
                  {item.label}
                </MenuItem>
              </Tooltip>
            );
          })}
      </MenuList>
    </Menu>
  );
};

export default ContextMenu;
