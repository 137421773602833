import { EndpointBuilder } from "@reduxjs/toolkit/query";

import {
  ProjectCreateSchema,
  ProjectListSchema,
} from "@/features/ws-manager/types";
import { ApiResponse } from "@/types";
import { ACTION_TYPE } from "@/utils/enums.ts";

import { wsManagerApi } from "./api";
import { WsManagerEndpoints } from "./api-endpoints.ts";
import {
  addProjectToWorkspace,
  removeProjectFromWorkspace,
  updateProjectInWorkspace,
} from "./ws-api-utils.ts";

export const projectAPI = wsManagerApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    createProject: build.mutation<
      ApiResponse<ProjectListSchema>,
      ProjectCreateSchema & { workspaceId: string }
    >({
      query: (body) => {
        return {
          url: WsManagerEndpoints.createProject(body.workspaceId),
          method: "POST",
          data: body,
        };
      },
      // TODO : Updating local cache here because of pagination issue, need to fix this to updating using invalidatesTags
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        addProjectToWorkspace(dispatch, body, res);
      },
      // invalidatesTags: (res, err, args) => [
      //   { type: "Workspaces", id: args.workspaceId },
      //   { type: "Project", id: "LIST" },
      // ],
    }),
    updateProject: build.mutation<
      ApiResponse<ProjectListSchema>,
      Partial<ProjectCreateSchema & { wsId: string; action: ACTION_TYPE }> & {
        id: string;
      }
    >({
      query: (body) => {
        return {
          url: WsManagerEndpoints.project(body.id),
          method: "PUT",
          data: body,
        };
      },
      // TODO : Updating local cache here because of pagination issue, need to fix this to updating using invalidatesTags
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        updateProjectInWorkspace(
          dispatch,
          { id: body.id, wsId: body.wsId! },
          res
        );
      },
      // invalidatesTags: (_apiRes, _error, { id, wsId }) => [
      //   { type: "Workspaces", id: "LIST" },
      //   { type: "Workspaces", id: wsId },
      //   { type: "Project", id: "LIST" },
      //   { type: "Project", id: id },
      // ],
    }),
    deleteProject: build.mutation<
      ApiResponse<ProjectListSchema>,
      { id: string; wsId: string }
    >({
      query({ id }) {
        return {
          url: WsManagerEndpoints.project(id),
          method: "DELETE",
        };
      },
      // TODO : Updating local cache here because of pagination issue, need to fix this to updating using invalidatesTags
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        removeProjectFromWorkspace(dispatch, body);
      },
      // invalidatesTags: (_apiRes, _error, { id, wsId }) => [
      //   { type: "Project", id: id },
      //   { type: "Workspaces", id: wsId },
      // ],
    }),
    getProjectList: build.query<
      ApiResponse<ProjectListSchema>,
      { workspaceId: string }
    >({
      query: (body) => {
        return {
          url: WsManagerEndpoints.getProjectList(body.workspaceId),
        };
      },
      providesTags: (result = { response: { data: null } }) => [
        ...(result.response.data?.projects ?? []).map(
          ({ id }) => ({ type: "Project", id }) as const
        ),
        { type: "Project" as const, id: "LIST" },
      ],
    }),
    getProject: build.query<ApiResponse<ProjectListSchema>, string>({
      query: (id) => {
        return {
          url: WsManagerEndpoints.project(id),
        };
      },
      providesTags: (res, err, body) => [{ type: "Project", id: body }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useLazyGetProjectQuery,
} = projectAPI;
