export const colors = {
  brand: {
    50: "#FFF0E8",
    100: "#FFDECC",
    200: "#FFC8A6",
    300: "#FFAC80",
    400: "#F4673B",
    500: "#E54D2F",
    600: "#C53B25",
    700: "#9E2D1D",
    800: "#791F15",
    900: "#5B1610",
  },
  orange: {
    50: "#FFF0E8",
    100: "#FFDECC",
    200: "#FFC8A6",
    300: "#FFAC80",
    400: "#F4673B",
    500: "#E54D2F",
    600: "#C53B25",
    700: "#9E2D1D",
    800: "#791F15",
    900: "#5B1610",
  },
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },
  dark: {
    50: "#F5F5F5",
    400: "#232323",
    500: "#404040",
    800: "#666666",
  },
  black: {
    50: "#F5F5F5",
    100: "#F1F1F1",
    200: "#ECECEC",
    300: "#D9D9D9",
    400: "#C6C6C6",
    500: "#A9A9A9",
    600: "#8C8C8C",
    700: "#666666",
    800: "#404040",
    900: "#232323",
    1000: "#101010",
  },
  gray: {
    50: "#FCFCFC",
    100: "#F1F1F1",
    200: "#ECECEC",
    300: "#D9D9D9",
    400: "#C6C6C6",
    500: "#A9A9A9",
    600: "#8C8C8C",
    700: "#666666",
    800: "#404040",
    900: "#232323",
    1000: "#101010",
  },
  red: {
    50: "#FFF5F5",
    100: "#FED7D7",
    200: "#FEB2B2",
    300: "#FC8181",
    400: "#F56565",
    500: "#E53E3E",
    600: "#C53030",
    700: "#9B2C2C",
    800: "#822727",
    900: "#63171B",
  },

  yellow: {
    50: "#FFFFF0",
    100: "#F9F2CB",
    200: "#FAF089",
    300: "#F6E05E",
    400: "#ECC94B",
    500: "#EAA51C",
    600: "#B7791F",
    700: "#975A16",
    800: "#744210",
    900: "#5F370E",
  },

  green: {
    50: "#F0FFF4",
    100: "#C6F6D5",
    200: "#9AE6B4",
    300: "#68D391",
    400: "#48BB78",
    500: "#38A169",
    600: "#25855A",
    700: "#276749",
    800: "#22543D",
    900: "#1C4532",
  },
  teal: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#81E6D9",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#319795",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044",
  },
  blue: {
    50: "#F0F6FF",
    100: "#D1E3FF",
    200: "#94B4FF",
    300: "#6391F2",
    400: "#4371F0",
    500: "#2750D9",
    600: "#2345A8",
    700: "#264291",
    800: "#253566",
    900: "#142354",
  },
  cyan: {
    50: "#FAF5FF",
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    500: "#805AD5",
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659",
  },
  purple: {
    50: "#FAF5FF",
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    500: "#805AD5",
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659",
  },
  pink: {
    50: "#FFF5F7",
    100: "#FED7E2",
    200: "#FBB6CE",
    300: "#F687B3",
    400: "#ED64A6",
    500: "#D53F8C",
    600: "#B83280",
    700: "#97266D",
    800: "#702459",
    900: "#521B41",
  },
  airbyteblue: {
    30: "#f5f5ff",
    40: "#f1f0ff",
    50: "#ecebff",
    100: "#cac7ff",
    200: "#a5a3ff",
    300: "#8080ff",
    400: "#5f5cff",
    500: "#463cfb",
    600: "#3f2fee",
    700: "#3623e1",
    800: "#300ad6",
    900: "#2900bd",
  },
  airbytedarkblue: {
    30: "#eff4f9",
    40: "#ebf1f7",
    50: "#e4eef6",
    100: "#bfd7e8",
    200: "#92aec8",
    300: "#7490ad",
    400: "#52708c",
    500: "#405c75",
    600: "#3b546a",
    700: "#344b5f",
    800: "#2d4153",
    900: "#1f3a3b",
    1000: "#10181f",
  },
  airbytegrey: {
    30: "#fafcfc",
    40: "#f5f9fa",
    50: "#f5f7f9",
    100: "#ebeef1",
    200: "#d9e1e6",
    300: "#b8c3ce",
    400: "#98a6b9",
    500: "#7f91a0",
    600: "#666d80",
    700: "#575e67",
    800: "#464a59",
    900: "#2e3130",
  },
  airbyteorange: {
    50: "#fbeee8",
    100: "#ffe4d5",
    200: "#ffddb9",
    300: "#ffd39e",
    400: "#ffc785",
    500: "#ffbb7a",
    600: "#ffa46f",
    700: "#ff8e65",
    800: "#ff7a5b",
    900: "#ff6140",
  },
  airbyteoverlaybackgrond: "352B3E80",
  airbytebackdrop: "FAFAFA",
  airbyteforeground: "FFFFFF",
  airbyteinverse: "000000",
  ansibluebg: "#57C0E8",
  ansicyanbg: "#3AB5A8",
  ansiyellowbg: "#F2D94E",
  ansicyanfg: "#5AB17D",
  ansigreenfg: "#2BA34D",
  ansiyellowfg: "#FFA000",
  ansiredfg: "#FF3F3F",
};
