import { Outlet } from "react-router-dom";

import { MainSideNav } from "../components/sidebar/sidebar.tsx";

export const HomeLayout = () => {
  return (
    <div className="flex flex-row w-screen max-h-screen h-screen">
      <div className="flex w-full h-full overflow-hidden">
        <MainSideNav />
        <Outlet />
      </div>
    </div>
  );
};
