import React from "react";

import usePermissions from "@/hooks/usePermissions";
import { CATEGORY } from "@/utils/enums";

interface ProtectedComponentProps {
  requiredPermission: string;
  id?: string;
  type?: CATEGORY;
  children: React.ReactNode;
}

export const ProtectedComponent = ({
  requiredPermission,
  id,
  type,
  children,
}: ProtectedComponentProps) => {
  const { checkPermission } = usePermissions();
  const hasPermissions = checkPermission({
    id,
    requiredPermission,
    type,
  });
  return hasPermissions ? children : null;
};
