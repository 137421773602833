import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/react";
import { MdRestoreFromTrash } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages.ts";
import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import { DetailsRow } from "@/features/ws-manager/layout/details-row.tsx";
import {
  previewRecycleBinItem,
  setCurrentProject,
  setCurrentRecycleItem,
  setCurrentWs,
  setPreviewAnalysis,
} from "@/features/ws-manager/redux/analysis-slice.tsx";
import {
  ProjectSchema,
  CommonItemsSchema,
  WorkspaceSchema,
  AnalysesSchema,
} from "@/features/ws-manager/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";
import { ApiError } from "@/types";
import {
  getDateFormat,
  getLocalDateTimeFormat,
} from "@/utils/date-convertor.ts";
import { CATEGORY, categoryToType, PERMISSIONS } from "@/utils/enums.ts";

import { useRestoreItemMutation } from "../../api";

const AnalysisDetails = (props: { onClose: () => void }) => {
  const navigate = useNavigate();
  const recycleItem = useAppSelector(
    previewRecycleBinItem
  ) as CommonItemsSchema;
  const dispatch = useAppDispatch();

  const hasRestorePermission = recycleItem?.itemData?.permissions?.includes(
    `${recycleItem.itemType}.delete` as PERMISSIONS
  );

  const [restoreItem] = useRestoreItemMutation();
  const onClose = () => {
    dispatch(setPreviewAnalysis(null));
    dispatch(setCurrentRecycleItem(null));
    props.onClose();
  };
  const getModalMessage = () => {
    switch (recycleItem?.itemType) {
      case CATEGORY.Workspace:
        return MODAL_MESSAGES.restoreWorkspace;
      case CATEGORY.Analysis:
        return MODAL_MESSAGES.restoreAnalysis;
      case CATEGORY.Project:
        return MODAL_MESSAGES.restoreProject;
      default:
        return {};
    }
  };
  const navigateToWorkspace = (value: Partial<WorkspaceSchema>) => {
    navigate(`../${value.id}`);
    dispatch(setCurrentWs(value as WorkspaceSchema));
    dispatch(setCurrentProject(null));
  };
  const navigateToProject = (value: Partial<ProjectSchema>) => {
    dispatch(setCurrentProject(value as ProjectSchema));
    navigate(`/home/${value.workspaceId}/project/${value.id}`);
  };

  const navigateToAnalysis = (value: Partial<AnalysesSchema>) => {
    dispatch(setPreviewAnalysis(value as AnalysesSchema));
    navigate(`/home/${value.workspaceId}/project/${value.projectId}`);
  };
  const onRestoreSuccess = () => {
    switch (recycleItem?.itemType) {
      case CATEGORY.Workspace:
        navigateToWorkspace(recycleItem?.itemData);
        break;
      case CATEGORY.Analysis:
        navigateToAnalysis(recycleItem?.itemData as Partial<AnalysesSchema>);
        break;
      case CATEGORY.Project:
        navigateToProject(recycleItem?.itemData as Partial<ProjectSchema>);
        break;
    }
    onClose();
  };

  const onRestoreError = (error: any) => {
    const data = error.data;
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          title: `Unable to Restore ${categoryToType(recycleItem?.itemType)}`,
          description: data?.errors?.[0].detail ?? "An error occurred",
          hideButtons: true,
        },
      })
    );
  };

  const onRestore = () => {
    const item = {
      item_type: recycleItem?.itemType,
      item_pk: recycleItem?.itemData?.id,
    };

    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...getModalMessage(),
          primaryButtonText: `Restore ${categoryToType(recycleItem?.itemType)}`,
          primaryButtonColorScheme: "secondary",
          primaryAction: () => {
            restoreItem({ items: [item] })
              .unwrap()
              .then(onRestoreSuccess)
              .catch(onRestoreError);
          },
        },
      })
    );
  };

  return (
    <Card h="100%" variant="primary">
      <CardHeader>
        <Flex className="flex-col justify-start w-full gap-2">
          <Flex className="w-full justify-between">
            <Heading className="capitalize self-center">
              {recycleItem?.itemData?.name}
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
          <Tag
            className="w-fit capitalize"
            colorScheme={(function () {
              switch (recycleItem?.itemType) {
                case "workspace":
                  return "purple";
                case "project":
                  return "blue";
                case "analysis":
                  return "whatsapp";
              }
            })()}
          >
            {recycleItem?.itemType}
          </Tag>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody flexDir="column" gap={4} display="flex" p={4}>
        <Text className="text-sm2 text-gray-700">
          {recycleItem?.itemData?.description}
        </Text>

        <DetailsRow header="Deletion Date">
          {getLocalDateTimeFormat(recycleItem?.itemData?.deletedOn)}
        </DetailsRow>
        <DetailsRow header="Last Updated">
          {getLocalDateTimeFormat(recycleItem?.itemData?.updatedOn)}
        </DetailsRow>
        <DetailsRow header="Created">
          {getDateFormat(recycleItem?.itemData?.createdOn)}
        </DetailsRow>
      </CardBody>
      <Divider />
      {hasRestorePermission && (
        <CardFooter>
          <Button
            onClick={onRestore}
            colorScheme="secondary"
            size="lg"
            variant="solid"
            className="self-stretch w-full capitalize"
            rightIcon={<Icon as={MdRestoreFromTrash} color="white" />}
          >
            Restore {recycleItem?.itemType}
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default AnalysisDetails;
