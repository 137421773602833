// create new slice for analysis
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { projectAPI, workspaceAPI } from "@/features/ws-manager";
import {
  AnalysesSchema,
  ProjectSchema,
  CommonItemsSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { RootState } from "@/store";
// Define a type for the slice state
interface AnalysisState {
  currentAnalysis: Partial<AnalysesSchema> | null;
  currentRecycleItem: CommonItemsSchema | null;
  currentWs: WorkspaceSchema | null;
  currentProject: ProjectSchema | null;
  isLoading: boolean;
}

// Define the initial state using that type
const initialState: AnalysisState = {
  currentAnalysis: null,
  currentWs: null,
  currentProject: null,
  currentRecycleItem: null,
  isLoading: false,
};

const analysisSlice = createSlice({
  name: "ws-manager",
  initialState,
  reducers: {
    clearAll: (state) => {
      state.currentAnalysis = null;
      state.currentWs = null;
      state.currentProject = null;
    },
    setPreviewAnalysis: (
      state,
      action: PayloadAction<Partial<AnalysesSchema> | null>,
    ) => {
      state.currentAnalysis = action.payload;
    },
    setCurrentWs: (state, action: PayloadAction<WorkspaceSchema | null>) => {
      if (state.currentWs?.id !== action.payload?.id) {
        state.currentWs = action.payload;
      }

      state.currentProject = null;

    },
    setCurrentProject: (state, action: PayloadAction<ProjectSchema | null>) => {
      state.currentProject = action.payload;
      //To hide analysis Details pannel when we switch workspace
    },
    setCurrentRecycleItem: (
      state,
      action: PayloadAction<CommonItemsSchema | null>,
    ) => {
      state.currentRecycleItem = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      workspaceAPI.endpoints.updateWs.matchFulfilled,
      (state, { payload }) => {
        const data = payload.response.data?.workspaces?.[0] ?? null;

        if (state.currentWs?.id == data?.id) {
          state.currentWs = data as WorkspaceSchema;
        }
      },
    );
    builder.addMatcher(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      workspaceAPI.endpoints.deleteWs.matchFulfilled,
      (state) => {
        state.currentProject = null;
        state.currentWs = null;
      },
    );
    builder.addMatcher(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      projectAPI.endpoints.deleteProject.matchFulfilled,
      (state) => {
        state.currentProject = null;
      },
    );
    builder.addMatcher(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      projectAPI.endpoints.updateProject.matchFulfilled,
      (state, { payload }) => {
        const data = payload.response.data?.projects?.[0] ?? null;

        if (state.currentProject?.id == data?.id) {
          state.currentProject = data as ProjectSchema;
        }
      },
    );
  },
});

export const {
  setPreviewAnalysis,
  setCurrentRecycleItem,
  setCurrentWs,
  setCurrentProject,
  clearAll,
  setIsLoading,
} = analysisSlice.actions;
export const previewAnalysis = (state: RootState) =>
  state.wsManagerReducer.analysis.currentAnalysis;
export const previewRecycleBinItem = (state: RootState) =>
  state.wsManagerReducer.analysis.currentRecycleItem;
export const currentProject = (state: RootState) =>
  state.wsManagerReducer.analysis.currentProject;
export const currentWs = (state: RootState) =>
  state.wsManagerReducer.analysis.currentWs;
export const isLoading = (state: RootState) =>
  state.wsManagerReducer.analysis.isLoading;

export const analysisReducer = analysisSlice.reducer;
