import { createContext } from "react";

import { ITransformationStep } from "@/features/data-transformation/types";

export type StepContextType = {
  step: ITransformationStep;
  isOpen: boolean;
  hasWriteAccess: boolean;
};

export const StepContext = createContext<StepContextType | null>(null);
