import React, { useMemo } from "react";
import { MdOutlinePlayArrow, MdOutlineRemoveRedEye } from "react-icons/md";
import {
  MdsContentCopyRound,
  MdsDeleteRound,
  MdsPlaylistAddRound,
  MdsPlaylistRemoveRound,
} from "react-icons-with-materialsymbols/mds";
import { NodeToolbar, useReactFlow } from "reactflow";

import { Button } from "@/design/components/button";
import { useAppDispatch } from "@/reduxHooks.ts";

import { showPanel } from "../..";
import { useCreateCopyNode } from "../../hooks/useCreateNodeCopy";
import useManageOutput from "../../hooks/useManageOutput";
import { NodeType } from "../../types";
import { WORKFLOW_PANELS } from "../../utils/constants";

type DataNodeToolbarProps = {
  onDelete: (e: React.MouseEvent) => void;
  id: string;
};

export const DataNodeToolbar = ({ onDelete, id }: DataNodeToolbarProps) => {
  const dispatch = useAppDispatch();
  const { getNode } = useReactFlow();
  const { createNodeCopy } = useCreateCopyNode();
  const { isUnMarking, removeOutput, openOutputModal } = useManageOutput();

  const currentNode = getNode(id);
  const hasParent = currentNode?.parentNode;

  const isSourceNode = useMemo(
    () =>
      (currentNode?.data as NodeType).nodeType
        .toUpperCase()
        .includes("SOURCE".toUpperCase()),
    [currentNode]
  );
  const isOutput = useMemo(
    () => currentNode?.data.isOutput,
    [currentNode?.data]
  );

  const showDataPreviewPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(
      showPanel({ panel: WORKFLOW_PANELS.DataPreviewPanel, nodeId: id })
    );
  };

  const duplicateNode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (currentNode) {
      createNodeCopy(currentNode);
    }
  };

  return (
    <div className="absolute -z-10 -top-8 left-1/2 -translate-x-1/2 w-full px-3 h-full">
      <NodeToolbar
        isVisible={true}
        className="flex gap-0 w-max rounded bg-zinc-700 p-1"
      >
        {!hasParent && (
          <Button
            variant={"link"}
            colorScheme="black"
            className="!p-2 rounded !text-white hover:bg-zinc-600"
            title="Run till this Node"
          >
            <MdOutlinePlayArrow size={24} />
          </Button>
        )}
        <Button
          variant={"link"}
          colorScheme="black"
          className="!p-2 rounded !text-white hover:bg-zinc-600"
          onClick={showDataPreviewPanel}
          title="Node Data Preview"
        >
          <MdOutlineRemoveRedEye size={20} />
        </Button>
        {!hasParent && (
          <Button
            variant={"link"}
            colorScheme="black"
            className="!p-2 rounded !text-white hover:bg-zinc-600"
            title="Duplicate node"
            onClick={duplicateNode}
          >
            <MdsContentCopyRound className="stroke-[22]" size={20} />
          </Button>
        )}
        {!isSourceNode &&
          (isOutput ? (
            <Button
              variant={"link"}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => removeOutput({ id })}
              colorScheme="black"
              isDisabled={isUnMarking}
              className="!p-2 rounded !text-white hover:bg-zinc-600"
              title="Remove Output Dataset"
            >
              <MdsPlaylistRemoveRound className="stroke-[24]" size={24} />
            </Button>
          ) : (
            <Button
              variant={"link"}
              onClick={() => openOutputModal({ id })}
              colorScheme="black"
              isDisabled={isUnMarking}
              className="!p-2 rounded !text-white hover:bg-zinc-600"
              title="Create Output Dataset"
            >
              <MdsPlaylistAddRound className="stroke-[24]" size={24} />
            </Button>
          ))}
        {!hasParent && (
          <Button
            variant={"link"}
            colorScheme="black"
            className="!p-2 rounded !text-white hover:bg-zinc-600"
            onClick={onDelete}
            title="Delete node"
          >
            <MdsDeleteRound className="stroke-[22]" size={22} />
          </Button>
        )}
      </NodeToolbar>
    </div>
  );
};

export default DataNodeToolbar;
