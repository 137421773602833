import { Text, Flex, IconButton } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useMemo } from "react";
import {
  MdsChevronRightRound,
  MdsOpenInFullRound,
} from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import {
  EdaMetaDataContext,
  executeInProgress,
  FETCH_TYPE,
  IExploreTaskResponse,
  resetFetchTrigger,
  selectedColumn,
  seperateFilters,
  tableColumns,
  tableSizeMetaData,
  triggerExploreEvent,
  useGetDataStatsMutation,
  viewFilters,
} from "@/features/data-transformation";
import { PanelStateContext } from "@/features/data-transformation/components";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { useRequestStatus } from "@/features/data-transformation/hooks/useRequestStatus.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";

import { ExploreDataDetailsPanel } from "./explore-data-details.tsx";

export const ExploreDataPanel = () => {
  const dispatch = useDispatch();
  const { isOpen, onToggle } = useContext(PanelStateContext);

  const { hasWriteAccess } = useReadOnlyMode();

  const isExecuting = useSelector(executeInProgress);
  const tableMetaData = useSelector(tableSizeMetaData);

  const metaData = useContext(EdaMetaDataContext);

  const colInfo = useSelector(selectedColumn);

  const columns = useSelector(tableColumns);
  const filters = useSelector(viewFilters);
  const fetchExploreData = useSelector(triggerExploreEvent);

  const memoizedColumnName = useMemo(
    () => colInfo?.column.name,
    [colInfo?.column.name]
  );
  const memoizedColumnType = useMemo(
    () => colInfo?.column.dataType,
    [colInfo?.column.dataType]
  );
  const memoizedEdaId = useMemo(() => metaData.edaId, [metaData.edaId]);

  const { setRequestId, setIsRequesting, isRequesting, data } =
    useRequestStatus<IExploreTaskResponse>();
  const [fetchStatsApi] = useGetDataStatsMutation();

  useEffect(() => {
    if (!isOpen) return;
    if (!metaData.edaId || !colInfo) return;

    fetchStats();
  }, [memoizedColumnName, memoizedEdaId, memoizedColumnType, isOpen]);

  useEffect(() => {
    if (!fetchExploreData) return;
    dispatch(resetFetchTrigger(FETCH_TYPE.EXPLORE));

    if (!isOpen) return;
    if (!metaData.edaId || !colInfo) return;
    fetchStats();
  }, [fetchExploreData, memoizedColumnType]);

  const fetchStats = async () => {
    setIsRequesting(true);
    const selectedColIndex = columns.findIndex(
      (col) => col.name === colInfo?.column.name
    );
    const res = await fetchStatsApi({
      analysisId: metaData.analysisId!,
      edaId: metaData.edaId!,
      column: colInfo!.column.name,
      viewFilters: seperateFilters(filters),
      completeDataInfo: false,
      columnType: columns[selectedColIndex].dataType,
    }).unwrap();
    setRequestId(res.response.data?.requestId);
  };

  const hideIcon = hasWriteAccess && !isExecuting;
  const disableExploreButton = tableMetaData?.rows == "0";

  if (!isOpen)
    return (
      <>
        {!hideIcon && (
          <Flex className="items-center mx-auto mt-1.5">
            <IconButton
              as={motion.button}
              color="gray.500"
              animate={{
                rotate: !isOpen ? 180 : 0,
                transition: { duration: 0.2, type: "spring" },
              }}
              aria-label="collapse"
              colorScheme="secondary"
              icon={<MdsChevronRightRound />}
              onClick={onToggle}
              size="lgSm"
              variant="ghost"
            />
          </Flex>
        )}
      </>
    );

  const showModal = () => {
    if (!colInfo || isRequesting || !data) return;
    dispatch(
      openModal({
        modalType: ModalTypes.EXPLORE_DATASET,
        modalProps: { data: data, metaData: metaData },
      })
    );
  };

  return (
    <AnimatePresence>
      <Flex className="flex-col border-t border-t-gray-100 gap-x-1 rounded-[3px] flex-1 h-full overflow-auto">
        <Flex className="font-medium  leading-none justify-between items-center p-0.5 shadow-exploreData">
          <Flex className="items-center">
            {!hideIcon && (
              <IconButton
                as={motion.button}
                color="gray.500"
                animate={{
                  rotate: !isOpen ? 180 : 0,
                  transition: { duration: 0.2, type: "spring" },
                }}
                aria-label="collapse"
                colorScheme="secondary"
                icon={<MdsChevronRightRound />}
                onClick={onToggle}
                size="lgSm"
                variant="ghost"
              />
            )}
            <Text className="text-[15px] tracking-[-0.3px] leading-[16px] p-2 pl-2.5">
              Explore Data
            </Text>
          </Flex>

          <IconButton
            color="gray.500"
            aria-label="Expand Expore Data"
            colorScheme="secondary"
            icon={<Icon as={MdsOpenInFullRound} size="md" />}
            isDisabled={disableExploreButton}
            onClick={showModal}
            size="lgSm"
            variant="ghost"
          />
        </Flex>
        <ExploreDataDetailsPanel isRequesting={isRequesting} data={data} />
      </Flex>
    </AnimatePresence>
  );
};
