import { Avatar, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { IconType } from "react-icons";
import { AiFillFolder } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

import { Icon } from "@/design/components/icon";

type StyledIconProps = {
  emoji?: string;
  icon?: string;
  customIcon?: React.ReactNode | IconType | null;
  wrapperStyle?: string;
  overrideStyle?: boolean;
  children?: React.ReactNode;
  hideIcon?: boolean;
  title?: string;
};
export const StyledIcon = (props: StyledIconProps) => {
  const {
    emoji,
    icon,
    hideIcon = false,
    customIcon,
    wrapperStyle,
    overrideStyle = false,
    title,
    children,
  } = props;

  const defaultIcon = isEmpty(icon) ? AiFillFolder : icon;
  const renderedIcon = customIcon ?? defaultIcon;
  const defaultStyle: string =
    "w-8 h-8 rounded-full items-center justify-center mr-0.5 bg-gray-100";
  const mergedStyle = twMerge(clsx(defaultStyle, wrapperStyle));

  return (
    <Flex className={overrideStyle ? wrapperStyle : mergedStyle}>
      {emoji}
      {/* {!emoji && !hideIcon && (
        <Icon as={renderedIcon} size="md" className="fill-gray-800" />
        )} */}
      {!emoji && !hideIcon && title && (
        <Avatar bgColor={icon ?? "brand.300"} name={title} size="xs" />
      )}
      {!title && !hideIcon && (
        <Icon as={renderedIcon} size="md" className="fill-gray-800" />
      )}
      {children}
    </Flex>
  );
};
