import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Text,
  Tag,
  Divider,
  TagLeftIcon,
  TagLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlineDownload, MdOutlinePerson } from "react-icons/md";
import {
  MdsCrosswordOutlined,
  MdsHeartMinusRound,
  MdsHeartPlusRound,
} from "react-icons-with-materialsymbols/mds";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  FitBounds,
  Instance,
  Node,
  ReactFlowInstance,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";

import { useShowToast } from "@/components/toast";
import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { ApiErrorResponse } from "@/types";
import { getRelativeTime } from "@/utils/date-convertor";
import { closestColor } from "@/utils/get-color";

import { useGetFlowQuery } from "../../api";
import { edgeTypes, nodeTypes } from "../../components/flow-editor";
import useManageFlowFavorites from "../../hooks/useManageFlowFavorites";
import {
  getEditingAllowed,
  setCurrentFlowStoreItem,
  setShowFlowStore,
} from "../../redux";
import { FlowSchema } from "../../types";
import { FLOW_TYPES } from "../../utils/constants";
import { getDistinctAnalyticsNodes } from "../../utils/flow-utils";
import { createReactflowNodesFromFlow } from "../../utils/transform-response";

const FlowDetailModal: React.FC = () => {
  const { flow, flowType, addFlow } = useAppSelector(
    (state) =>
      state.rootReducer.modals.modalProps as {
        flow: FlowSchema;
        flowType: FLOW_TYPES;
        workflowNodes: Node[];
        workflowEdges: Edge[];
        setWorkflowNodes: Instance.SetNodes<any>;
        setWorkflowEdges: Instance.SetEdges<any>;
        fitBounds: FitBounds;
        addFlow: (newFlow: FlowSchema) => void;
      }
  );
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, false);
  const [isFavorite, setIsFavorite] = useState<boolean>(flow.isFavorite);
  const [previewFlowInstance, setPreviewFlowInstance] =
    useState<ReactFlowInstance>();

  const {
    data,
    isLoading: isFlowLoading,
    isError,
  } = useGetFlowQuery({ flowId: flow ? flow.nodeId : "" }, { skip: !flow });

  const {
    addToFavorite,
    removeFavorite,
    isLoading: isAdding,
  } = useManageFlowFavorites();

  const defaultPreviewEdgeOptions = {
    type: "custom-edge",
    zIndex: 1001,
  };

  const [flowNodes, setFlowNodes, onFlowNodeChange] = useNodesState<Node[]>([]);
  const [flowEdges, setFlowEdges, onFlowEdgesChange] = useEdgesState<Edge[]>(
    []
  );
  const isEditingAllowed = useAppSelector(getEditingAllowed);

  useEffect(() => {
    if (!data?.response.data) return;
    const { parentNode, nodesinFlow, edgesInFlow } =
      createReactflowNodesFromFlow(data.response.data.flow);
    setFlowNodes([parentNode, ...nodesinFlow]);
    setFlowEdges([...edgesInFlow]);
    setTimeout(() => {
      previewFlowInstance?.fitView({
        padding: 0.8,
        duration: 50,
        minZoom: 0.2,
      });
    }, 100);
  }, [
    data,
    isError,
    toast,
    setFlowEdges,
    setFlowNodes,
    previewFlowInstance,
    flow,
  ]);

  const analyticsNodes = getDistinctAnalyticsNodes(flowNodes);

  const importFlow = () => {
    if (!isEditingAllowed) {
      toast({
        title: "Cannot add flow while workflow is running",
        status: "warning",
        variant: "subtle",
      });
      return;
    }
    if (!data) return;
    try {
      addFlow(data.response.data!.flow);
      dispatch(closeModal());
    } catch (error) {
      const err = error as ApiErrorResponse;
      toast({
        title: err.data.errors![0].detail ?? "Error occured while adding flow",
        status: "error",
      });
    }
  };

  const onFavorite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (isAdding) return;
    if (isFavorite) {
      await removeFavorite({ flow, flowType });
      setIsFavorite(false);
    } else {
      await addToFavorite({ flow, flowType });
      setIsFavorite(true);
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  const openInFlowStore = () => {
    dispatch(closeModal());
    dispatch(
      setCurrentFlowStoreItem({
        flow: { ...flow, isFavorite: isFavorite },
        flowType: flowType,
      })
    );
    dispatch(setShowFlowStore(true));
  };

  const isLoading = !data || isFlowLoading;

  return (
    <Modal isOpen={true} isCentered size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="!w-[80vw] max-h-[90vh] overflow-hidden">
        <ModalHeader className=" flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit max-h-[100px] line-clamp-2 overflow-y-auto text-lg text-gray-800 font-medium break-all">
            {flow.displayName}
          </Box>
          <ModalCloseButton className="!relative !top-0 !right-0" />
        </ModalHeader>
        <ModalBody className=" py-3 min-h-[50vh] flex items-center justify-center">
          {isLoading ? (
            <Flex className="gap-3 h-full" align={"center"} justify={"center"}>
              <Spinner size="sm" />
              <Text className="font-medium">Fetching Flow Details</Text>
            </Flex>
          ) : (
            <Flex className="flex-col gap-3 w-full max-h-[80vh] overflow-y-auto ">
              <Flex className="flex-col grow gap-3 w-full overflow-y-auto !px-4">
                <ReactFlowProvider>
                  <Flex className="group !w-[100%] h-[30vh] shrink-0 rounded border border-black-500 overflow-hidden relative">
                    <ReactFlow
                      nodes={flowNodes}
                      edges={flowEdges}
                      onNodesChange={onFlowNodeChange}
                      onEdgesChange={onFlowEdgesChange}
                      onInit={setPreviewFlowInstance}
                      elementsSelectable={false}
                      defaultEdgeOptions={defaultPreviewEdgeOptions}
                      nodeTypes={nodeTypes}
                      edgeTypes={edgeTypes}
                      nodesDraggable={false}
                      maxZoom={2}
                      minZoom={0.2}
                      zoomOnPinch={true}
                      zoomOnScroll={true}
                      panOnDrag={true}
                      proOptions={{ hideAttribution: true }}
                    >
                      <Background
                        id="bg-preview-flow"
                        variant={BackgroundVariant.Dots}
                        className="bg-slate-50"
                      />
                    </ReactFlow>
                    <Flex
                      className="group-hover:opacity-100 opacity-0 absolute h-full w-full top-0 left-0 z-[999] bg-black-600 bg-opacity-30"
                      align={"center"}
                      justify={"center"}
                    >
                      <Button colorScheme="dark" onClick={openInFlowStore}>
                        Open in flow Store
                      </Button>
                    </Flex>
                  </Flex>
                </ReactFlowProvider>
                <Flex
                  align={"center"}
                  justify={"space-between"}
                  gap={2}
                  w={"100%"}
                >
                  <Flex align={"center"} flex={0.5} gap={2} shrink={0}>
                    <MdOutlinePerson className="bg-gray-50 rounded-full w-7 h-7 p-1.5 shrink-0" />
                    <Text className="font-medium" flexShrink={0}>
                      Anurag Deore
                    </Text>
                  </Flex>
                  <Flex gap={2}>
                    {flow.tags.map((tag, index) => (
                      <Tag
                        className="!cursor-default"
                        key={tag + index.toString()}
                        colorScheme={closestColor(tag)}
                        size="sm"
                        variant="subtle"
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Flex>
                </Flex>
                <Flex
                  className="text-sm"
                  align="center"
                  justify={"space-between"}
                  gap={2}
                  w={"100%"}
                >
                  <Flex className="shrink-0">
                    <div>
                      <span className="text-gray-600">Last Used : </span>
                      <span className="font-medium">
                        {getRelativeTime(flow.uploadDate)}{" "}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-600">Uploaded: </span>
                      <span className="font-medium">
                        {getRelativeTime(flow.lastUsedAt)}
                      </span>
                    </div>
                  </Flex>
                  <Flex className="px-4 gap-2 pb-2 shrink" wrap={"wrap"}>
                    {analyticsNodes.map((i, index) => (
                      <Tag
                        className="text-xs break-all whitespace-pre rounded-lg"
                        key={i + index.toString()}
                        colorScheme="orange"
                      >
                        <TagLeftIcon
                          className="stroke-[22]"
                          as={MdsCrosswordOutlined}
                        />
                        <TagLabel>{i}</TagLabel>
                      </Tag>
                    ))}
                  </Flex>
                </Flex>
                <Box className="grow break-all" pt={2}>
                  {flow.description}
                </Box>
              </Flex>
              <Divider />
              <Flex className="shrink-0 justify-end grow-0 px-4" gap={3}>
                {isFavorite ? (
                  <Button
                    className="flex-[0.3] items-center justify-center"
                    colorScheme="orange"
                    iconSpacing={2}
                    isLoading={isAdding}
                    //eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onFavorite}
                    rightIcon={<Icon as={MdsHeartMinusRound} size="sm" />}
                    variant={"outline"}
                  >
                    Remove from Favorites
                  </Button>
                ) : (
                  <Button
                    className="flex-[0.3] items-center justify-center"
                    colorScheme="orange"
                    iconSpacing={2}
                    isLoading={isAdding}
                    //eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onFavorite}
                    rightIcon={<Icon as={MdsHeartPlusRound} size="sm" />}
                    variant={"outline"}
                  >
                    Add to Favorites
                  </Button>
                )}
                <Button
                  className="flex-[0.3] items-center justify-center"
                  colorScheme="orange"
                  iconSpacing={2}
                  //eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={importFlow}
                  rightIcon={<Icon as={MdOutlineDownload} size="sm" />}
                >
                  Import
                </Button>
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FlowDetailModal;
