import { SaveRunPayload, TextGenVersion } from "../types";
import { AIMODEL_DATATYPES } from "../types/enums";

export const convertTextGenVersionToSaveRunPayload = (
  version: TextGenVersion,
  inputSampleCount: number
): SaveRunPayload => {
  const inputVars =
    version.messages[0].content[0].text.match(/{{\s*(\w+)\s*}}/g);

  const inputVarList = inputVars
    ? inputVars?.map((m) => m.slice(2, -2).trim()).filter(Boolean)
    : [];

  return {
    title: "version.title",
    description: "version.description",
    version: {
      title: "version.title",
      inputSampleCount: inputSampleCount,
      description: "version.description",
      aiModel: {
        id: version.aiModel?.id ?? "",
        modelConfigs:
          version.aiModel?.modelConfigs.map((mc) => {
            let value: any = mc.selectedValue ?? mc.defaultParameterValue;
            if (
              mc.datatype === AIMODEL_DATATYPES.INTEGER ||
              mc.datatype === AIMODEL_DATATYPES.FLOAT
            ) {
              value = Number(value);
            } else if (mc.datatype === AIMODEL_DATATYPES.ARRAY) {
              if (value) {
                value = Array.isArray(value) ? value.join(",") : value;
              }
            }
            return {
              globalParameterId: mc.globalParameterId ?? "",
              id: mc.id,
              selectedValue: value ?? "",
            };
          }) ?? [],
      },
      inputVariables: inputVarList.map((iv) => ({
        variableName: iv,
        columnName: iv,
      })),
      messages: version.messages.map((m) => ({
        role: m.role,
        content: m.content.map((c) => ({
          type: c.type,
          text: c.text,
          order: 1,
        })),
      })),
    },
  };
};
