import { EndpointBuilder } from "@reduxjs/toolkit/query";

import {
  ExportDatasetSchema,
  NodeDataDownloadPayload,
  NodeDataDownloadResponse,
  NodeDataDownloadStatusPayload,
  NodeDataDownloadStatusResponse,
  NodeDataSchema,
  NodeDataTransformedSchema,
} from "@/features/workflow-studio/types";
import { ApiResponse } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import { workflowEditorApi } from "./api";
import { NodeDataPreviewEndpoints } from "./api-endpoints";

const nodeDataApi = workflowEditorApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    getNodeData: build.query<
      ApiResponse<NodeDataTransformedSchema>,
      {
        page: string;
        nodeId?: string;
        workflowNodeId: string;
        nodeIoDetailsId?: string;
        workflowRunId?: string;
        previewType?: string;
        index?: number;
      }
    >({
      query: ({
        page,
        previewType,
        nodeIoDetailsId,
        workflowRunId,
        nodeId,
        workflowNodeId,
        index = 0,
      }) => {
        return {
          url: NodeDataPreviewEndpoints.getNodeDataPreview(),
          method: "POST",
          ignoreBaseUrl: true,
          data: {
            nodeId: nodeId,
            workflow_node_id: workflowNodeId,
            node_io_detail_id: nodeIoDetailsId,
            workflow_run_id: workflowRunId,
            page_number: page,
            preview_type: previewType,
            index: index,
          },
        };
      },
      providesTags: (result, err, args) => {
        return [{ type: "NodeData", id: args.workflowNodeId }];
      },
      transformResponse: (res: ApiResponse<NodeDataSchema>) => {
        const seralizedRawData = JSON.parse(
          res.response.data!.previewRows.rowData
        );
        const newResponse = {
          ...res,
          response: {
            data: {
              rowData: seralizedRawData,
              hasNext: res.response.data!.previewRows.rowData.length > 0,
              headers: res.response.data!.previewRows.headers,
            },
          },
        } as ApiResponse<NodeDataTransformedSchema>;
        return newResponse;
      },
      merge: (newData, existingData, req) => {
        if (req.arg.page == "1") return newData;

        const mergedData = {
          headers: newData.response.data!.headers,
          rowData: [
            ...newData.response.data!.rowData,
            ...existingData.response.data!.rowData,
          ],
        };

        return {
          ...newData,
          response: {
            data: mergedData,
          },
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };

        if (newQueryArgs?.page) {
          Object.assign(newQueryArgs, { ...newQueryArgs, page: "1" });
        }

        return newQueryArgs;
      },
    }),
    exportDataset: build.mutation<
      ApiResponse<ExportDatasetSchema>,
      ExportDatasetSchema
    >({
      query: (payload: ExportDatasetSchema) => {
        return {
          url: NodeDataPreviewEndpoints.exportDataset(),
          method: "POST",
          ignoreBaseUrl: true,
          data: keysToSnake(payload),
        };
      },
    }),
    downloadData: build.mutation<ApiResponse<NodeDataDownloadResponse>, any>({
      query: (payload: NodeDataDownloadPayload) => {
        return {
          url: NodeDataPreviewEndpoints.downloadData(),
          method: "POST",
          ignoreBaseUrl: true,
          data: keysToSnake(payload),
        };
      },
    }),
    downloadStatus: build.mutation<
      ApiResponse<NodeDataDownloadStatusResponse>,
      NodeDataDownloadStatusPayload
    >({
      query: (payload: NodeDataDownloadStatusPayload) => {
        return {
          url: NodeDataPreviewEndpoints.downloadStatus(),
          method: "POST",
          ignoreBaseUrl: true,
          data: keysToSnake(payload),
        };
      },
    }),
  }),
  overrideExisting: false,
});
export const {
  useLazyGetNodeDataQuery,
  useGetNodeDataQuery,
  useExportDatasetMutation,
  useDownloadDataMutation,
  useDownloadStatusMutation,
} = nodeDataApi;
