import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  _invalid: {
    borderColor: "red.600 !important",
  },
  borderRadius: "0.1875rem",
  ":focus-visible": { borderColor: "gray.500" },
});

const customOutlined = defineStyle({
  border: "1px solid",
  borderColor: "gray.500",
  bg: "inherit",
  _invalid: {
    borderColor: "red.600 !important",
  },
});

export const textareaTheme = defineStyleConfig({
  baseStyle: baseStyle,
  variants: { customOutlined },
  defaultProps: {
    size: "md",
    variant: "customOutlined",
  },
});
