import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import isoWeek from "dayjs/plugin/isoWeek";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import RelativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(RelativeTime);
dayjs.extend(calendar);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export const getLocalDateTimeFormat = (inputDateString: string) => {
  const formattedTime = getLocalTimeFormat(inputDateString);

  const formattedDate = getDateFormat(inputDateString);

  const outputString = `${formattedTime}  |  ${formattedDate}`;
  return outputString;
};

export const getLocalTimeFormat = (input: string) => {
  const date = new Date(input);
  const formattedTime = dayjs(date).format("h:mm a");

  return formattedTime;
};

export const getDateFormat = (input: string) => {
  const date = new Date(input);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return formattedDate;
};

export const getRelativeTime = (input: string) => {
  let time;

  if (isWithin(input, 2)) {
    const relativeTime = dayjs(input).fromNow();
    time = relativeTime; // 12 hours ago
  } else {
    time = dayjs(input).format("DD MMM");
  }

  return time;
};

export const isWithin = (input: string, day: number) => {
  const currentDate = new Date();
  return (
    dayjs(input).isBefore(dayjs(currentDate).add(12, "hours")) &&
    dayjs(input).isAfter(dayjs(currentDate).subtract(day, "day"))
  );
};
export function getRelativeDate(date: string | Date | undefined): string {
  if (!date) return "";

  const today = dayjs();
  const inputDate = dayjs(date);

  if (inputDate.year() === today.year()) {
    return inputDate.format("DD MMM"); // Format as DD MMM for this year
  } else {
    return inputDate.format("DD/MM/YYYY"); // Format as DD/MM/YYYY otherwise
  }
}

const getDaySuffix = (day: number): string => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const dateToRelativeString = (dateStr: string | undefined | null) => {
  if (!dateStr) return "";
  const date = dayjs.utc(dateStr).local();
  const now = dayjs();

  if (date.isYesterday()) return `Yesterday, at ${date.format("h:mm A")}`;
  if (date.isTomorrow()) return `Tomorrow, at ${date.format("h:mm A")}`;
  if (date.isSame(now, "day")) return `Today, at ${date.format("h:mm A")}`;

  const dayOfMonth = date.format("D");
  const daySuffix = getDaySuffix(parseInt(dayOfMonth));
  return `${dayOfMonth}${daySuffix} ${date.format("MMM YYYY, [at] h:mm A")}`;
};


export const convertUTCDateString = (input: string) => {
  const date = dayjs.utc(input).local();
  const formattedDate = date.format("D MMMM YYYY");
  return formattedDate;
};