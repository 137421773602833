import { usePrevious } from "@chakra-ui/react";
import { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import {
  EdaMetaDataContext,
  ITransformationStep,
  setRequestId,
  setTableLoading,
  setTempTransformationSteps,
  setTransformationSteps,
  tempTransformationSteps,
  transformationSteps,
  useEditStepMutation,
} from "@/features/data-transformation";

export const useRestoreRemoveStep = (step: ITransformationStep) => {
  const dispatch = useDispatch();
  const metaData = useContext(EdaMetaDataContext);

  const toast = useShowToast(undefined, undefined, true);

  const savedSteps = useSelector(transformationSteps);
  const tempSteps = useSelector(tempTransformationSteps);

  const previousSteps = usePrevious(savedSteps);
  const previousTempSteps = usePrevious(tempSteps);

  const [editStepApi] = useEditStepMutation();

  const isSaved = useMemo(
    () =>
      savedSteps.some(
        (_step) => _step.transformationStepId === step.transformationStepId
      ),
    [savedSteps, step]
  );

  const updateApiCall = async (updatedStep: ITransformationStep) => {
    try {
      const res = await editStepApi({
        analysisId: metaData.analysisId!,
        edaId: metaData.edaId!,
        ...updatedStep,
      }).unwrap();
      const reqId = res.response.data?.requestId ?? null;
      dispatch(setRequestId(reqId));
    } catch (e) {
      if (isSaved) {
        dispatch(setTransformationSteps(previousSteps));
      } else {
        dispatch(setTempTransformationSteps(previousTempSteps));
      }
      dispatch(setTableLoading(false));
      toast({
        id: "deleteError",
        position: "top-right",
        variant: "left-accent",
        status: "error",
        isClosable: true,
        description: updatedStep.activeStatus
          ? "Could not restore the step, something went wrong."
          : "Could not delete the step, something went wrong.",
      });
    }
  };

  const onRestoreRemoveClicked = useCallback(
    async (activeStatus: boolean) => {
      let index, newStep;
      if (isSaved) {
        index = savedSteps.findIndex(
          (s) => s.transformationStepId === step.transformationStepId
        );
        newStep = {
          ...savedSteps[index],
          activeStatus: activeStatus,
        };
      } else {
        index = tempSteps.findIndex(
          (s) => s.transformationStepId === step.transformationStepId
        );
        newStep = {
          ...tempSteps[index],
          activeStatus: activeStatus,
        };
      }
      await updateApiCall(newStep);
      const message = !activeStatus
        ? TOAST_MESSAGES.stepDeleted
        : TOAST_MESSAGES.recoveredStep;

      toast({ ...message });
      // updateStep(newStep, index);
    },
    [step, savedSteps, tempSteps]
  );

  return { onRestoreRemoveClicked };
};
