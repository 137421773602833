export enum ButtonThemeVariants {
  primary = "primary",
  secondary = "secondary",
  invert = "invert",
}

const active = {
  // transform: "scale(0.95, 0.95)",
  boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)`,
  _disabled: {
    transform: "none",
    boxShadow: "none",
  },
};

type StyleProps = {
  [key: string]: any;
  bg: string;
  color: string;
  _hover: {
    bg: string;
    color: string;
    [key: string]: any;
    _disabled: {
      bg: string;
      color: string;
      [key: string]: any;
    };
  };
  _active: {
    bg: string;
    color: string;
    [key: string]: any;
  };
  _disabled: {
    [key: string]: any;
    bg: string;
    color: string;
  };
};

export const solidTheme: Record<string, StyleProps> = {
  primary: {
    bg: `orange.400`,
    color: `white`,
    _hover: {
      color: `white`,
      bg: `orange.500`,
      _disabled: {
        bg: `gray.500`,
        color: `white`,
      },
    },
    _active: {
      ...active,
      bg: `orange.800`,
      color: `white`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      bg: `gray.500`,
      color: `white`,
    },
  },
  secondary: {
    bg: `gray.900`,
    color: `gray.50`,
    _hover: {
      bg: `gray.800`,
      color: `white`,
      _disabled: {
        bg: `gray.600`,
        color: `gray.200`,
      },
    },
    _active: {
      ...active,
      bg: `gray.700`,
      color: `white`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      bg: `gray.600`,
      color: `gray.200`,
    },
  },
  invert: {
    color: `gray.900`,
    bg: `gray.50`,
    _hover: {
      color: `gray.900`,
      bg: `gray.300`,
      _disabled: {
        opacity: 1,
        bg: `gray.700`,
        color: `gray.500`,
      },
    },
    _active: {
      ...active,
      color: `gray.900`,
      bg: `gray.500`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      bg: `gray.700`,
      color: `gray.500`,
    },
  },
  customRed: {
    bg: `red.600`,
    color: `white`,
    _hover: {
      bg: `red.800`,
      color: "white",
      _disabled: {
        bg: `gray.500`,
        color: `gray.100`,
      },
    },
    _active: {
      ...active,
      bg: `red.800`,
      color: `white`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      bg: `red.400`,
      color: `white`,
    },
  },
};
export const outlineThemes: Record<string, StyleProps> = {
  primary: {
    bg: `transparent`,
    color: `orange.400`,
    _hover: {
      bg: `orange.50`,
      color: `orange.500`,
      _disabled: {
        bg: `gray.500`,
        color: `gray.100`,
      },
    },
    _active: {
      ...active,
      color: `orange.800`,
      bg: `orange.200`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      bg: `gray.500`,
      color: `gray.100`,
    },
  },
  secondary: {
    bg: `transparent`,
    color: `gray.900`,
    _hover: {
      bg: `gray.50`,
      color: `gray.800`,
      _disabled: {
        opacity: 1,
        bg: `gray.500`,
        color: `gray.50`,
      },
    },
    _active: {
      ...active,
      color: `gray.700`,
      bg: `gray.200`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      borderColor: `gray.600`,
      bg: `gray.200`,
      color: `gray.600`,
    },
  },
  invert: {
    bg: `transparent`,
    color: `gray.50`,
    borderColor: `gray.700`,
    _hover: {
      color: `gray.200`,
      borderColor: `gray.500`,
      bg: `gray.800`,
      _disabled: {
        opacity: 1,
        bg: `gray.500`,
        color: `gray.700`,
        borderColor: `gray.700`,
      },
    },
    _active: {
      ...active,
      bg: `gray.900`,
      color: `gray.200`,
      borderColor: `gray.500`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      bg: `gray.500`,
      color: `gray.700`,
      borderColor: `gray.700`,
    },
  },
  customRed: {
    bg: `transparent`,
    color: `red.600`,
    _hover: {
      bg: `red.50`,
      color: `red.600`,
      _disabled: {
        bg: `gray.500`,
        color: `gray.100`,
      },
    },
    _active: {
      ...active,
      color: `red.700`,
      bg: `red.200`,
    },
    _disabled: {
      transform: `none`,
      boxShadow: `none`,
      bg: `red.400`,
      color: `red.100`,
    },
  },
};
export const ghostThemes: Record<string, StyleProps> = {
  primary: {
    bg: `transparent`,
    color: `orange.400`,
    _hover: {
      color: `orange.500`,
      bg: `orange.50`,
      _disabled: {
        color: `gray.500`,
        bg: `gray.100`,
      },
    },
    _active: {
      ...active,
      bg: `orange.200`,
      color: `orange.800`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      color: `gray.500`,
      bg: `gray.100`,
    },
  },
  secondary: {
    bg: `transparent`,
    color: `gray.900`,
    _hover: {
      color: `gray.800`,
      bg: `gray.50`,
      _disabled: {
        color: `gray.600`,
        bg: `gray.200`,
      },
    },
    _active: {
      ...active,
      bg: `gray.200`,
      color: `gray.700`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      color: `gray.600`,
      bg: `gray.200`,
    },
  },
  invert: {
    bg: `transparent`,
    color: `gray.50`,
    _hover: {
      color: `gray.200`,
      bg: `gray.800`,
      _disabled: {
        color: `gray.700`,
        bg: `gray.500`,
      },
    },
    _active: {
      ...active,
      color: `gray.200`,
      bg: `gray.900`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      color: `gray.700`,
      bg: `gray.500`,
    },
  },
};
export const linkThemes: Record<string, StyleProps> = {
  primary: {
    bg: `transparent`,
    color: `orange.400`,
    textDecoration: `underline`,
    _hover: {
      bg: `transparent`,
      color: `orange.500`,
      _disabled: {
        color: `gray.500`,
        bg: `transparent`,
        textDecoration: `none`,
      },
    },
    _active: {
      bg: `transparent`,
      color: `orange.800`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      color: `gray.500`,
      bg: `transparent`,
      textDecoration: `none`,
    },
  },
  secondary: {
    bg: `transparent`,
    textDecoration: `underline`,
    color: `gray.900`,
    _hover: {
      color: `gray.800`,
      bg: `transparent`,
      _disabled: {
        color: `gray.600`,
        bg: `transparent`,
      },
    },
    _active: {
      color: `gray.700`,
      bg: `transparent`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      color: `gray.600`,
      bg: `transparent`,
      textDecoration: `none !important`,
    },
  },
  invert: {
    bg: `transparent`,
    textDecoration: `underline`,
    color: `gray.50`,
    _hover: {
      bg: `transparent`,
      color: `gray.200`,
      _disabled: {
        color: `gray.700`,
        bg: `transparent`,
      },
    },
    _active: {
      color: `gray.400`,
      bg: `transparent`,
    },
    _disabled: {
      opacity: 1,
      transform: `none`,
      boxShadow: `none`,
      color: `gray.700`,
      bg: `transparent`,
      textDecoration: `none`,
    },
  },
};
