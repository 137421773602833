import { useCallback, useRef, useState } from "react";

import useSimpleEventListener from "./useSimpleEventListener.tsx";

const useElementDimensions = <T extends HTMLDivElement>() => {
  const ref = useRef<T>(null);
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);

  const refresh = useCallback(() => {
    setTimeout(() => {
      const domRect = ref.current?.getBoundingClientRect();
      if (domRect) {
        setDimensions(domRect);
      }
    }, 1);
  }, []);

  useSimpleEventListener("resize", refresh);
  useSimpleEventListener("scroll", refresh, true);

  return { dimensions, ref, refresh };
};

export default useElementDimensions;
