import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdOutlineEdit } from "react-icons/md";

import { Avatar } from "@/design/components/avatar";
import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

export const ProfileSettings = () => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="min-w-[640px]">
        <ModalHeader>
          <Flex className="items-center gap-x-2">Profile Settings</Flex>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody className="!px-6 flex gap-x-5 w-full">
          <Flex className="flex-col gap-y-2">
            <Avatar size="2xl" />
            <Button variant="ghost" colorScheme="secondary">
              Edit
            </Button>
          </Flex>
          <Flex className="flex-col gap-y-6 w-full">
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <InputGroup
                className="items-center p-2.5 hover:border hover:border-gray-600 hover:bg-gray-50 rounded"
                size="xl"
              >
                <Input
                  pr="4.5rem"
                  isReadOnly={true}
                  value="Keanu Reeves"
                  variant="unstyled"
                />
                <InputRightElement
                  className="!relative cursor-pointer"
                  h="24px"
                >
                  <Icon as={MdOutlineEdit} color="gray.600" size="sm" />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <InputGroup
                className="items-center p-2.5 hover:border hover:border-gray-600 hover:bg-gray-50 rounded"
                size="xl"
              >
                <Input
                  pr="4.5rem"
                  isReadOnly={true}
                  value="test@gmail.com"
                  variant="unstyled"
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup
                className="items-center p-2.5 hover:border hover:border-gray-600 hover:bg-gray-50 rounded"
                size="xl"
              >
                <Input
                  pr="4.5rem"
                  isReadOnly={true}
                  type="password"
                  value="Keanu Reeves"
                  variant="unstyled"
                />
                <InputRightElement
                  className="!relative cursor-pointer !w-full"
                  h="24px"
                >
                  Change Password
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
