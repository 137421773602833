import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

import { ApiResponse } from "@/types";
import { RUN_STATUS } from "@/utils/enums";

import {
  setActivePlayground,
  setActiveVersion,
  setCurrentRunResults,
  setIsExecuteEnabled,
  setRunResults,
} from "../redux";
import { AIPlaygroundResponse, RunResultsResponse } from "../types";

export function updateAIPlaygroundInStore(
  data: ApiResponse<AIPlaygroundResponse>,
  dispatch: ThunkDispatch<any, any, AnyAction>
) {
  const ai_playground = data.response.data?.aiPlayground[0];
  const currentVersion = ai_playground?.textGeneration[0].version;
  if (!ai_playground || !currentVersion) return;

  if (Object.keys(currentVersion).length === 0) {
    const newVersion = { ...currentVersion };
    newVersion.aiModel = undefined;
    newVersion.inputVariables = [];
    newVersion.description = "";
    newVersion.runs = undefined;
    newVersion.title = "";
    newVersion.estimatedCost = undefined;
    newVersion.messages = [
      {
        role: "user",
        content: [
          {
            type: "text",
            text: "",
            order: 1,
          },
        ],
      },
    ];
    dispatch(setIsExecuteEnabled(false));
    dispatch(setActiveVersion(newVersion));
  } else {
    dispatch(setIsExecuteEnabled(!isEmpty(currentVersion.runs?.runId)));
    dispatch(setActiveVersion(currentVersion));
  }
  dispatch(setActivePlayground(ai_playground));
}

export function updateRunDetailsInStore(
  data: ApiResponse<RunResultsResponse>,
  body: { runId: string; textGenId: string; isActiveVersionResult: boolean },
  dispatch: ThunkDispatch<any, any, AnyAction>
) {
  const runDetails = data.response.data?.text_generation[0];

  if (!runDetails?.run_results) return;
  if (
    body.isActiveVersionResult &&
    runDetails.run_status !== RUN_STATUS.RUNNING
  ) {
    dispatch(setCurrentRunResults(runDetails.run_results));
  }
  dispatch(
    setRunResults({ results: runDetails.run_results, runId: body.runId })
  );
}
