import { Tag, TagLabel, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import { MdOutlineStorage } from "react-icons/md";
import { MdsDatabaseRound } from "react-icons-with-materialsymbols/mds";

import { NODE_STATUS } from "../../utils/constants";

const OutputColorScheme: Record<NODE_STATUS, string> = {
  [NODE_STATUS.SUCCESS]: "green",
  [NODE_STATUS.FAILED]: "red",
  [NODE_STATUS.DEFAULT]: "gray",
  [NODE_STATUS.PENDING]: "yellow",
  [NODE_STATUS.RUNNING]: "blue",
  [NODE_STATUS.SKIPPED]: "gray",
  [NODE_STATUS.MANUAL_INTERVENTION]: "gray",
  [NODE_STATUS.WAITING]: "gray",
  [NODE_STATUS.CANCELLED]: "red",
};

export const NodeOutputTag = ({
  title = "Output dataset",
  status = NODE_STATUS.DEFAULT,
}: {
  title: string | null | undefined;
  status: NODE_STATUS;
}) => {
  return (
    <Tooltip
      className="!bg-gray-800 !text-xs wrap !rounded !py-1"
      aria-label={title!}
      label={title}
      openDelay={500}
      placement="bottom"
    >
      <Tag
        className="w-full"
        alignItems="center"
        gap={1}
        colorScheme={OutputColorScheme[status]}
        rounded="base"
        variant="subtle"
      >
        <TagLeftIcon
          className="!m-0"
          as={MdsDatabaseRound}
          __css={{ strokeWidth: 22 }}
        />
        <TagLabel className="font-medium text-xs tracking-tight">
          {title}
        </TagLabel>
      </Tag>
    </Tooltip>
  );
};
