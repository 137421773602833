import { Flex } from "@chakra-ui/layout";
import { Input, Textarea } from "@chakra-ui/react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ZodError } from "zod";

import { TagsInput } from "@/components/tag-input";
import { Button } from "@/design/components/button";
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
} from "@/design/components/form";

import { FlowUploadData, flowUploadSchema } from ".";

export const UploadForm = ({
  setActiveStep,
  formState,
}: {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formState: UseFormReturn<FlowUploadData, any, undefined>;
}) => {
  // Destructure the formState object
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = formState;

  const onSubmit = async (data: FlowUploadData) => {
    try {
      await flowUploadSchema.parseAsync(data);
      setActiveStep(1);
    } catch (error) {
      if (error instanceof ZodError) {
        error.errors.forEach((err) => {
          setError(err.path[0] as keyof FlowUploadData, {
            message: err.message,
          });
        });
      }
    }
  };

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      className="!px-5 gap-4 flex flex-col"
    >
      <FormControl isRequired isInvalid={!!errors.displayName}>
        <FormLabel fontSize="sm">Flow Name</FormLabel>
        <Input
          className="disabled:bg-gray-300 disabled:text-gray-900"
          size={"sm"}
          type="text"
          {...register("displayName")}
        />
        <FormErrorMessage>
          <FormErrorIcon />
          {errors.displayName?.message}
        </FormErrorMessage>
      </FormControl>
      <TagsInput
        name="tags"
        control={control}
        size="sm"
        setError={setError}
        clearErrors={clearErrors}
        errorKey="tags"
        label="Add Tags"
        placeholder="Enter Tags"
        helperText="Organise your flows with tags like “Testing”, “Volume check”, “Internal”, “PoC”"
      />
      <FormControl isRequired isInvalid={!!errors.description}>
        <FormLabel fontSize="sm">Description</FormLabel>
        <Textarea size={"sm"} {...register("description")} name="description" />
        <FormErrorMessage>
          <FormErrorIcon />
          {errors.description?.message}
        </FormErrorMessage>
      </FormControl>
      <Flex justifyContent={"flex-end"} gap={3}>
        <Button type="submit" size="sm" colorScheme="dark">
          Next
        </Button>
      </Flex>
    </form>
  );
};
