import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { clsx } from "clsx";
import { motion } from "framer-motion";
import { useContext } from "react";

import { StepContext, StepContextType } from "./step-context.ts";
import { StepFullData } from "./step-full-data.tsx";
import { StepIcon } from "./step-icon.tsx";
import { StepsRowTpe } from "./steps-row-type.tsx";

export const StepActive = ({ hovered }: { hovered: boolean }) => {
  const { step, isOpen } = useContext(StepContext) as StepContextType;

  return (
    <>
      <Tooltip label={step.name} openDelay={600}>
        <Flex
          className={clsx(
            "items-center max-w-full",
            isOpen && "pt-2.5 px-2 mb-1.5"
          )}
          as={motion.div}
        >
          <StepIcon />
          {isOpen && (
            <Flex className="item-center text-xs font-medium ml-1 max-w-full truncate break-words gap-x-1 cursor-pointer">
              <Text className="truncate break-words cursor-pointer">
                {step.name}
              </Text>
            </Flex>
          )}
        </Flex>
      </Tooltip>
      <StepsRowTpe />
      {isOpen && <StepFullData hovered={hovered} />}
    </>
  );
};
