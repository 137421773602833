import { ApiResponse } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import {
  FlowCreatePayloadSchema,
  FlowCreateResponse,
  FlowDetailsResponse,
  FlowListResponse,
  FlowSchema,
  NodeDetailsResponse,
  NodeGroups,
  NodeListResponse,
} from "../types";
import { FLOW_TYPES } from "../utils/constants";
import { NodeListToNodeGroups } from "../utils/transform-response";

import { workflowEditorApi } from "./api";
import { NodeManagerEndpoints } from "./api-endpoints";
import { deleteFlowFromCache, updateFlowCache } from "./node-manager-api-utils";

export const nodeManagerApi = workflowEditorApi.injectEndpoints({
  endpoints: (build) => ({
    getNodesList: build.query<NodeGroups, { analysisId: string }>({
      query: ({ analysisId }) => ({
        url: NodeManagerEndpoints.getNodelistWithAnalysisId(analysisId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
      providesTags: (result) => {
        const tags = [];
        for (const key in result) {
          tags.push(
            ...(result[key] ?? []).map(
              ({ nodeVersionId }) =>
                ({ type: "Nodes", id: nodeVersionId }) as const
            )
          );
        }
        tags.push({ type: "Nodes" as const, id: "LIST" });
        return tags;
      },
      transformResponse: (response: ApiResponse<NodeListResponse>) =>
        NodeListToNodeGroups(response),
    }),
    getNode: build.query<
      ApiResponse<NodeDetailsResponse>,
      { nodeId: string; nodeVersionId: string }
    >({
      query: ({ nodeId, nodeVersionId }) => ({
        url: NodeManagerEndpoints.getNode(nodeId, nodeVersionId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
      providesTags: (_result, _err, node) => [
        { type: "Nodes", id: node.nodeVersionId },
      ],
    }),
    getNodesFromUsageInstanceId: build.mutation<
      ApiResponse<NodeListResponse>,
      { analysisId: string; nodeUsageIdList: string[] }
    >({
      query: ({ analysisId, nodeUsageIdList }) => ({
        url: NodeManagerEndpoints.getNodesFromUsageInstanceIdWithAnalysisId(
          analysisId
        ),
        method: "POST",
        ignoreBaseUrl: true,

        data: { node_usage_instance_ids: nodeUsageIdList },
      }),
    }),
    getFlows: build.query<
      FlowListResponse,
      { flowType: FLOW_TYPES; url?: string | null }
    >({
      query: ({ flowType, url }) => {
        let modifiedUrl;
        if (url) modifiedUrl = import.meta.env.VITE_NODE_MANAGER_BASE_URL + url;
        return {
          url: modifiedUrl ?? NodeManagerEndpoints.getFlows(flowType, 10, 0),
          method: "GET",
          ignoreBaseUrl: true,
        };
      },
      keepUnusedDataFor: 60,
      providesTags: (result, error, arg) => {
        return [{ type: "Flows" as const, id: arg.flowType }];
      },
      transformResponse: (response: ApiResponse<FlowListResponse>) => {
        return {
          flows: response.response.data?.flows ?? [],
          pagination: response.response.pagination!,
        };
      },
      merge: (existingData, newData, req) => {
        if (!existingData || req.arg.url === null) {
          return newData;
        }
        return {
          ...newData,
          pagination: {
            count: newData.pagination!.count,
            next: newData.pagination!.next,
            previous: newData.pagination!.previous,
          },
          flows: [...(existingData?.flows ?? []), ...(newData.flows ?? [])],
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return queryArgs.flowType;
      },
    }),
    getFlow: build.query<ApiResponse<FlowDetailsResponse>, { flowId: string }>({
      query: ({ flowId }) => ({
        url: NodeManagerEndpoints.getFlow(flowId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
      providesTags: (_result, _err, flow) => {
        return [{ type: "Flows", id: flow.flowId }];
      },
    }),
    createFlow: build.mutation<
      ApiResponse<FlowCreateResponse>,
      { data: FlowCreatePayloadSchema }
    >({
      query: ({ data }) => {
        return {
          url: NodeManagerEndpoints.createFlow(),
          method: "POST",
          ignoreBaseUrl: true,
          data: keysToSnake(data),
        };
      },
      invalidatesTags: [{ type: "Flows" as const }],
    }),
    editFlow: build.mutation<
      ApiResponse<FlowCreateResponse>,
      { flowId: string; data: Partial<FlowSchema> }
    >({
      query: ({ flowId, data }) => {
        return {
          url: NodeManagerEndpoints.editFlow(flowId),
          method: "PUT",
          ignoreBaseUrl: true,
          data,
        };
      },
      invalidatesTags: [{ type: "Flows" as const }],
    }),
    deleteFlow: build.mutation<
      ApiResponse<FlowCreateResponse>,
      { flowId: string; flowType: FLOW_TYPES }
    >({
      query: ({ flowId }) => {
        return {
          url: NodeManagerEndpoints.deleteFlow(flowId),
          method: "DELETE",
          ignoreBaseUrl: true,
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        deleteFlowFromCache(dispatch, body, res);
      },
      // invalidatesTags: [{ type: "Flows" as const }],
    }),
    markFlowAsFavorite: build.mutation<
      ApiResponse<FlowCreateResponse>,
      {
        flowId: string;
        flowVersionId: string;
        flowType: string;
        action: "favorite" | "unfavorite";
      }
    >({
      query: ({ flowId, flowVersionId, action, flowType }) => {
        const url =
          action === "favorite"
            ? NodeManagerEndpoints.markFlowAsFavorite(flowId, flowVersionId)
            : NodeManagerEndpoints.unMarkFlowAsFavorite(flowId, flowVersionId);
        return {
          url,
          method: "POST",
          ignoreBaseUrl: true,
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        updateFlowCache(dispatch, body, res);
      },
      invalidatesTags: (res, err, body) => [
        { type: "Flows" as const, id: "favourites" },
      ],
    }),
    shareFlow: build.mutation<
      ApiResponse<FlowCreateResponse>,
      { flowId: string; flowVersionId: string }
    >({
      query: ({ flowId, flowVersionId }) => {
        return {
          url: NodeManagerEndpoints.shareFlow(flowId, flowVersionId),
          method: "PUT",
          ignoreBaseUrl: true,
        };
      },
      invalidatesTags: [{ type: "Flows" as const }],
    }),
  }),
});

export const {
  useGetNodesListQuery,
  useGetNodeQuery,
  useGetFlowsQuery,
  useGetFlowQuery,
  useLazyGetFlowsQuery,
  useLazyGetNodeQuery,
  useLazyGetFlowQuery,
  useGetNodesFromUsageInstanceIdMutation,
  useCreateFlowMutation,
  useDeleteFlowMutation,
  useMarkFlowAsFavoriteMutation,
} = nodeManagerApi;
