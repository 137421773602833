import { useState, useEffect } from "react";

/**
 * A hook that returns whether the current tab is in the background.
 * @returns {boolean} True if the tab is in the background, false otherwise.
 */
export const useIsBackground = (): boolean => {
  const [isBackground, setIsBackground] = useState<boolean>(document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsBackground(document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return isBackground;
};
