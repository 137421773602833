const colors = {
  whiteAlpha: "ffffff80",
  blackAlpha: "00000080",
  gray: "808080",
  red: "ff0000",
  orange: "ffa500",
  yellow: "ffff00",
  green: "008000",
  teal: "008080",
  blue: "0000ff",
  cyan: "00ffff",
  purple: "800080",
  pink: "ffc0cb",
  linkedin: "0077b5",
  facebook: "3b5998",
  messenger: "00f273",
  whatsapp: "25d366",
  twitter: "1da1f2",
  telegram: "0088cc",
};

export function closestColor(string: string) {
  const hexCode = toHex(string);

  // Calculate the distance between the hex code and each of the colors in the set.
  const distances = Object.entries(colors).map(([name, color]) => {
    const distance = hexDistance(hexCode, color);
    return { name, distance };
  });

  // Sort the distances by ascending distance.
  distances.sort((a, b) => a.distance - b.distance);
  // Return the name of the color with the closest distance.
  return distances[0].name;
}

// Converts a number to a hex string.
const toHex = (str: string) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

// Calculates the distance between two hex codes.
function hexDistance(hex1: string, hex2: string) {
  const r1 = parseInt(hex1.substring(0, 2), 16);
  const g1 = parseInt(hex1.substring(2, 4), 16);
  const b1 = parseInt(hex1.substring(4, 6), 16);

  const r2 = parseInt(hex2.substring(0, 2), 16);
  const g2 = parseInt(hex2.substring(2, 4), 16);
  const b2 = parseInt(hex2.substring(4, 6), 16);

  const distance = Math.sqrt(
    Math.pow(r1 - r2, 2) + Math.pow(g1 - g2, 2) + Math.pow(b1 - b2, 2)
  );
  return distance;
}
/* eslint-enable no-bitwise */

export const avatarBgColors = [
  "red.300",
  "orange.300",
  "yellow.300",
  "green.300",
  "teal.300",
  "blue.300",
  "cyan.300",
  "purple.300",
  "pink.300",
  "linkedin.300",
  "facebook.300",
  "messenger.300",
  "whatsapp.300",
  "twitter.300",
  "telegram.300",
];
