import { isEmpty } from "lodash";

import { AccessListSchema } from "../../types";

export const hasCreatePermission = (
  accesslist?: AccessListSchema | undefined | null
) => {
  if (!accesslist) return false;
  if (
    isEmpty(accesslist.analysis) &&
    isEmpty(accesslist.project) &&
    isEmpty(accesslist.workspace)
  ) {
    return false;
  }
  return true;
};
