import { Flex, Text } from "@chakra-ui/react";
import { clsx } from "clsx";
import { useMemo } from "react";

import { FilterAltFilled } from "@/components/icons/filter-alt.tsx";
import { Icon } from "@/design/components/icon";
import { MASK_DATATYPE } from "@/features/data-transformation";
import { ValueInfoBar } from "@/features/data-transformation/components/value-info-bar.tsx";

export const CustomHeaderRenderer = (props: any) => {
  const { col, totalRecords } = props;

  const columData = useMemo(() => {
    const _data = {
      valid: col?.validValues,
      invalid: col?.invalidValues,
      nullValues: col?.nullValues,
      total: totalRecords,
    };
    return _data;
  }, [totalRecords, col]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const displayDataType = MASK_DATATYPE[col?.dataType] || col?.dataType;

  return (
    <Flex className="flex-col items-start max-w-full h-full w-full justify-between custom-header-container">
      <Flex
        className={clsx("px-1.5 pt-1 truncate overflow-hidden justify-between")}
        style={{
          maxWidth: "calc(100% - 38px)",
          width: "100%",
        }}
      >
        <Text className="text-sm leading-[140%] font-medium truncate">
          {col?.name}
        </Text>
        {props?.hasFilter && (
          <Icon
            as={FilterAltFilled}
            size="md"
            className="!text-gray-600 !h-6 !w-6 !flex-shrink-0"
            id="filterIcon"
            onClick={props?.onClick}
          />
        )}
      </Flex>
      <Text className="data-type text-[11px] px-1.5 leading-[140%] text-gray-600">
        {displayDataType}
      </Text>
      <ValueInfoBar data={columData} />
    </Flex>
  );
};
