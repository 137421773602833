import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  Node,
  ReactFlowInstance,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";

import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { edgeTypes, nodeTypes } from "../../components/flow-editor";
import { FlowSchema } from "../../types";

const FlowPreviewModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [previewFlowInstance, setPreviewFlowInstance] =
    useState<ReactFlowInstance>();

  const {
    currentFlow,
    nodes,
    edges,
  }: { currentFlow: FlowSchema; nodes: Node[]; edges: Edge[] } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps as any
  );

  const defaultPreviewEdgeOptions = {
    type: "custom-edge",
    zIndex: 1001,
  };

  const [flowNodes, setFlowNodes, onFlowNodeChange] = useNodesState<Node[]>([]);
  const [flowEdges, setFlowEdges, onFlowEdgesChange] = useEdgesState<Edge[]>(
    []
  );

  useEffect(() => {
    setFlowEdges(edges ?? []);
    setFlowNodes(nodes ?? []);
    if (previewFlowInstance) {
      previewFlowInstance.fitView({ padding: 0.2 });
    }
  }, [previewFlowInstance, edges, nodes, setFlowEdges, setFlowNodes]);

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} isCentered size="4xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="!w-[80vw]">
        <ModalHeader className="flex gap-3 items-start justify-between border-b !ml-0 !pb-3 !pl-5">
          <Box className="w-fit text-lg text-gray-800 font-medium break-all">
            {currentFlow.displayName}
          </Box>
          <ModalCloseButton className="!relative !top-0 !right-0" />
        </ModalHeader>
        <ModalBody className="!px-4 py-3">
          <ReactFlowProvider>
            <Flex className="!w-[100%] h-[65vh] border border-black-400">
              <ReactFlow
                nodes={flowNodes}
                edges={flowEdges}
                onNodesChange={onFlowNodeChange}
                onEdgesChange={onFlowEdgesChange}
                onInit={setPreviewFlowInstance}
                elementsSelectable={false}
                defaultEdgeOptions={defaultPreviewEdgeOptions}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesDraggable={false}
                maxZoom={2}
                minZoom={0.2}
                zoomOnPinch={true}
                zoomOnScroll={true}
                panOnDrag={true}
                proOptions={{ hideAttribution: true }}
              >
                <Background
                  id="bg-preview-flow"
                  variant={BackgroundVariant.Dots}
                  className="bg-slate-50"
                />
                <Controls showInteractive={false} />
              </ReactFlow>
            </Flex>
          </ReactFlowProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FlowPreviewModal;
