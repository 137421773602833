import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { ControlledSelectNoForm } from "@/components/controlled-select";
import { ExcelIcon } from "@/components/icons/excel-icon";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { useFileUpload } from "../../hooks/useUploadFile";
import { IDataSource, IFileExtraOptions } from "../../types";
import { getExcelSheetNames } from "../../utils/excel-helper";

interface SheetOption {
  label: string;
  value: string;
}

export const SelectSheetBody = () => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);
  const { handleFileUpload } = useFileUpload({});
  const [sheetNames, setsheetNames] = useState([] as SheetOption[]);
  const [selectedSheet, setSelectedSheet] = useState<SheetOption>();

  useEffect(() => {
    getExcelSheetNames(modalProps.selectedFile as File)
      .then((sheets) => {
        setsheetNames(sheets.map((name) => ({ label: name, value: name })));
        setSelectedSheet({ label: sheets[0], value: sheets[0] });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [modalProps.selectedFile]);

  const handleExcelUpload = () => {
    if (!selectedSheet) return;
    handleFileUpload({
      selectedFile: modalProps.selectedFile as File,
      fileName: modalProps.fileName as string,
      source: modalProps.source as IDataSource,
      options: { sheetName: selectedSheet?.value } as IFileExtraOptions,
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Flex className="flex-col gap-3">
      <Flex className="items-center p-2 gap-3 border rounded">
        <Box className="bg-gray-50 p-2 rounded">
          <Icon
            className="!text-green-600 rounded"
            as={ExcelIcon}
            fontSize={22}
          />
        </Box>
        <Box className="font-medium">{modalProps.fileName}</Box>
      </Flex>
      <FormControl>
        <FormLabel className="!text-sm !text-gray-700">
          Choose the sheet you want to import
        </FormLabel>
        <ControlledSelectNoForm
          selectedOptionStyle="check"
          isDisabled={sheetNames.length === 0}
          onChange={(value) => setSelectedSheet(value as SheetOption)}
          options={sheetNames}
          value={selectedSheet}
          placeholder="Select Sheet"
          selectedOptionColorScheme="gray"
          maxMenuHeight={200}
        />
      </FormControl>
      <Button
        className="w-fit"
        alignSelf={"flex-end"}
        colorScheme="dark"
        isDisabled={!selectedSheet}
        onClick={handleExcelUpload}
      >
        Proceed
      </Button>
    </Flex>
  );
};
