import { Flex, Text } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useMemo } from "react";

import { AddCircle } from "@/components/icons/add-circle.tsx";
import { ChangeCircle } from "@/components/icons/change-circle.tsx";
import { DoNotDisturbOn } from "@/components/icons/do-not-disturb.tsx";
import { Icon } from "@/design/components/icon";
import {
  FILTER_ROW_OPERATION_TYPE,
  STEP_STATUS,
} from "@/features/data-transformation";
import { formatNumberWithCommas } from "@/utils/string-utils.ts";

import { StepContext, StepContextType } from "./step-context.ts";

export const StepFullData = ({ hovered }: { hovered: boolean }) => {
  const { step, isOpen } = useContext(StepContext) as StepContextType;
  const fullDataImpact = useMemo(() => {
    const impact = step.metadata?.onCompleteData;
    return impact;
  }, [step]);

  const impact = fullDataImpact?.impactCount ?? -1;
  const impactType = fullDataImpact?.impactRowsType;

  const type = step.activeStatus ? STEP_STATUS.Active : STEP_STATUS.Removed;

  const color = useMemo(() => {
    if (type !== STEP_STATUS.Active) return "gray.700";

    switch (impactType) {
      case FILTER_ROW_OPERATION_TYPE.AddRow:
        return "green.600";
      case FILTER_ROW_OPERATION_TYPE.RemoveRows:
        return "red.600";
      case FILTER_ROW_OPERATION_TYPE.UNCHANGED:
        return "gray.700";
      default:
        return "yellow.600";
    }
  }, [step]);

  const icon = useMemo(() => {
    let stepIcon = null;
    switch (impactType) {
      case FILTER_ROW_OPERATION_TYPE.AddRow:
        stepIcon = AddCircle;
        break;
      case FILTER_ROW_OPERATION_TYPE.RemoveRows:
        stepIcon = DoNotDisturbOn;
        break;
      default:
        stepIcon = ChangeCircle;
    }

    return <Icon as={stepIcon} size="sm" color={color} />;
  }, [step]);

  const shouldShow = impact >= 0 && isOpen && hovered;
  return (
    <AnimatePresence>
      {shouldShow && (
        <Flex
          className="justify-start px-2 bg-gray-50 items-center rounded-b"
          as={motion.div}
          animate={{ height: "24px", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          initial={{ height: 0, opacity: 0 }}
        >
          <Flex className="items-center justify-start font-inconsolata">
            {icon}
            <Text
              className="ml-[1px] text-sm leading-[15px] font-bold"
              as={motion.span}
              color={color}
            >
              {formatNumberWithCommas(impact.toString())}
              <Text
                className="text-[11px] ml-1 font-sans font-normal text-gray-800 self-baseline leading-[14px] tracking-[-0.22px]"
                as={motion.span}
              >
                rows impacted in full dataset
              </Text>
            </Text>
          </Flex>
        </Flex>
      )}
    </AnimatePresence>
  );
};
