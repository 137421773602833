import { Flex, Icon, IconButton, Spinner, Tooltip } from "@chakra-ui/react";
import {
  MdsConversionPathOutlined,
  MdsInfoOutlined,
} from "react-icons-with-materialsymbols/mds";
import { useReactFlow } from "reactflow";

import { useShowToast } from "@/components/toast";
import { useLazyGetFlowQuery } from "@/features/workflow-studio";
import { useCreateFlowNodes } from "@/features/workflow-studio/hooks/useAddFlowToWorkflow";
import { useIsOpenContext } from "@/features/workflow-studio/hooks/useBottomBarContext";
import { getEditingAllowed } from "@/features/workflow-studio/redux";
import { FlowSchema } from "@/features/workflow-studio/types";
import { FLOW_TYPES } from "@/features/workflow-studio/utils/constants";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { ApiErrorResponse } from "@/types";

export const FlowItem = ({
  flow,
  flowType,
}: {
  flow: FlowSchema;
  flowType: FLOW_TYPES;
}) => {
  // const [flowId, setFlowId] = useState<string>();
  const toast = useShowToast(undefined, undefined, false);
  const { getNodes, getEdges, setNodes, setEdges, fitBounds } = useReactFlow();
  const randomString = Math.random().toString(36).substring(7);
  const [triggerFetchFlow, { isFetching: fetchingFlow }] =
    useLazyGetFlowQuery();

  const isEditingAllowed = useAppSelector(getEditingAllowed);

  const { addFlow } = useCreateFlowNodes();

  const fetchFlow = async (flowId: string) => {
    if (!isEditingAllowed) {
      toast({
        title: "Cannot add flow while workflow is running",
        status: "warning",
        variant: "subtle",
      });
      return;
    }
    try {
      const flowResponse = await triggerFetchFlow({ flowId }).unwrap();
      addFlow(flowResponse.response.data!.flow);
    } catch (error) {
      const err = error as ApiErrorResponse;
      const errrMsg = err.data.errors![0].detail;
      toast({
        title: errrMsg ?? "Error occured while adding flow",
        status: "error",
        variant: "subtle",
      });
    }
  };

  const dispatch = useAppDispatch();

  const { closePopOver } = useIsOpenContext();

  const openFlowDetails = () => {
    closePopOver();
    dispatch(
      openModal({
        modalType: ModalTypes.FLOW_DETAILS,
        modalProps: {
          flow,
          flowType,
          workflowNodes: getNodes(),
          workflowEdges: getEdges(),
          setWorkflowNodes: setNodes,
          setWorkflowEdges: setEdges,
          fitBounds: fitBounds,
          addFlow,
        },
      })
    );
  };

  return (
    <Flex
      className="cursor-pointer hover:bg-orange-50 border-b max-w-full border-gray-200 group overflow-hidden"
      key={randomString}
      align={"center"}
      justify="space-between"
    >
      <Flex
        className=" hover:text-orange-800 overflow-hidden"
        align="center"
        flex="1"
        gap={2}
        px={2}
        py={3}
        onClick={() => void fetchFlow(flow.nodeId)}
      >
        {fetchingFlow ? (
          <Spinner size="xs" />
        ) : (
          <Icon as={MdsConversionPathOutlined} />
        )}
        <Tooltip
          className="!bg-gray-800 !text-xs wrap !rounded !py-2"
          label={flow.displayName}
          placement="top"
        >
          <span className="text-sm break-all font-medium capitalize overflow-hidden text-ellipsis whitespace-pre">
            {flow.displayName}
          </span>
        </Tooltip>
      </Flex>
      <IconButton
        className="invisible group-hover:visible hover:!bg-white flowListItem"
        aria-label="Flow Details"
        icon={<MdsInfoOutlined className="h-5 w-5" />}
        onClick={openFlowDetails}
        variant={"ghost"}
      />
    </Flex>
  );
};
