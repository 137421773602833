import { useNavigate, useParams } from "react-router-dom";

import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages";
import { TOAST_MESSAGES } from "@/constants/toast-constants";
import { useAppDispatch } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { CATEGORY } from "@/utils/enums";

import { useDeleteWsMutation } from "../api";
import { WorkspaceSchema } from "../types";

const useWorkspaceActions = () => {
  const [deleteWs] = useDeleteWsMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useShowToast();

  const deleteWorkspace = (data: WorkspaceSchema) => {
    if (!data) return; //TODO handle error
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...MODAL_MESSAGES.deleteWorkspace,
          primaryAction: () => {
            deleteWs(data.id)
              .then(() => {
                navigate(`/home`);
                toast(TOAST_MESSAGES.workspaceDeleted(data.name));
              })
              .catch((e) => console.log(e));
          },
        },
      })
    );
  };

  const editWorkspace = (data: WorkspaceSchema) => {
    if (!data) return; //TODO handle error
    dispatch(
      openModal({
        modalType: ModalTypes.WORKSPACE_CREATION,
        modalProps: { data: data },
      })
    );
  };

  const viewWorkspaceInfo = (
    e: React.MouseEvent<Element, MouseEvent>,
    data: WorkspaceSchema
  ) => {
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.INFO,
        modalProps: { type: CATEGORY.Workspace, data: data, navigate },
      })
    );
  };

  const createNewWorkspace = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKSPACE_CREATION,
        modalProps: {},
      })
    );
  };

  const editWorkspaceAccess = (data: WorkspaceSchema) => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: data, type: CATEGORY.Workspace, navigate },
      })
    );
  };

  return {
    deleteWorkspace,
    editWorkspace,
    viewWorkspaceInfo,
    createNewWorkspace,
    editWorkspaceAccess,
  };
};

export default useWorkspaceActions;
