import { Text, Flex } from "@chakra-ui/react";

export const RecycleHeader = () => {
  return (
    <Flex className="flex flex-col items-start gap-0.5">
      <Text className="font-semibold text-2xl tracking-tight">Recycle Bin</Text>
      <Text className="tracking-tight text-red-600">
        Note: Items in the bin will be permanently removed after 30 days after
        deletion
      </Text>
      {/*<Flex justify="end" gap={2}>
        <Button
          size="sm"
          colorScheme="secondary"
          rightIcon={<MdDeleteForever />}
        >
          Empty Recycle Bin
        </Button>
      </Flex>*/}
    </Flex>
  );
};
