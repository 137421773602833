import { Flex, IconButton } from "@chakra-ui/react";

import { Notification } from "@/components/icons/notification.tsx";
import { UserDropdown } from "@/components/user-dropdown";
import { TitleBar, TitleBarHeader } from "@/design/components/title-bar";
import { TitleBarProfile } from "@/design/components/title-bar/title-bar-profile.tsx";
import { ResultsDropdown } from "@/features/ws-manager/components/nav-bar/results-dropdown.tsx";

export const NavBar = () => {
  return (
    <TitleBar>
      <TitleBarHeader>
        <Flex className="flex-row items-center gap-x-4 w-full justify-between z-10">
          <ResultsDropdown />
          {/* <IconButton
            sx={{ alignSelf: "center" }}
            fontSize={"24px"}
            aria-label="Notification"
            colorScheme="dark"
            icon={<Notification />}
            isDisabled={true}
            variant="ghost"
          /> */}
        </Flex>
      </TitleBarHeader>
      <TitleBarProfile
        sx={{
          "::before": {
            content: '""',
            position: "absolute",
            left: "0",
            width: "1px",
            height: "50%",
            background: "gray.200",
          },
        }}
      >
        <UserDropdown />
      </TitleBarProfile>
    </TitleBar>
  );
};
