import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";
import { DATASET_FORMAT } from "@/utils/enums";

import { ExportDatasetSchema } from "../types";

export interface ActiveDownloadSchema extends ExportDatasetSchema {
  downloadId: string;
  name: string;
  outputFormat?: DATASET_FORMAT;
  category?: string;
}

// Define a type for the slice state
interface DataPreviewState {
  showDownloadPanel: boolean;
  activeDownloads: ActiveDownloadSchema[];
}

// Define the initial state using that type
const initialState: DataPreviewState = {
  showDownloadPanel: false,
  activeDownloads: [],
};

const dataPreviewSlice = createSlice({
  name: "data-preview",
  initialState,
  reducers: {
    addActiveDownload: (state, action: PayloadAction<ActiveDownloadSchema>) => {
      state.activeDownloads.push(action.payload);
    },
    setActiveDownloads: (
      state,
      action: PayloadAction<ActiveDownloadSchema[]>
    ) => {
      state.activeDownloads = action.payload;
    },
    removeActiveDownload: (state, action: PayloadAction<string>) => {
      state.activeDownloads = state.activeDownloads.filter(
        (download) => download.downloadId !== action.payload
      );
    },
    resetActiveDownloads: (state) => {
      state.activeDownloads = [];
    },
    setShowDownloadPanel: (state, action: PayloadAction<boolean>) => {
      state.showDownloadPanel = action.payload;
    },
  },
});

export const {
  addActiveDownload,
  setActiveDownloads,
  removeActiveDownload,
  resetActiveDownloads,
} = dataPreviewSlice.actions;

export const selectActiveDownloads = (state: RootState) =>
  state.dataPreviewReducer.activeDownloads;

export const selectShowDownloadPanel = (state: RootState) =>
  state.dataPreviewReducer.showDownloadPanel;

export const dataPreviewReducer = dataPreviewSlice.reducer;
