import { Box, Flex, Image } from "@chakra-ui/react";

import { useAppSelector } from "@/reduxHooks.ts";

import { getNodeStatus } from "../../redux/workflow-slice";
import { NodeType } from "../../types";
import { WorkflowNodeStatusSchema } from "../../types/workflow-types";
import {
  NODE_STATUS,
  NODE_STATUS_ICONS,
  categoryIcons,
} from "../../utils/constants";

export const NodeMetaData = ({
  id,
  nodeData,
  isLoading,
}: {
  id: string;
  nodeData: NodeType;
  isLoading: boolean;
}) => {
  const nodeStatus =
    useAppSelector(getNodeStatus(id)) ??
    ({
      nodeStatus: nodeData.nodeStatus,
    } as WorkflowNodeStatusSchema);

  const statusIconData = isLoading
    ? NODE_STATUS_ICONS[NODE_STATUS.RUNNING]
    : NODE_STATUS_ICONS[nodeStatus.nodeStatus as NODE_STATUS];

  return (
    <>
      <Flex align={"center"} justify="space-between" pr={1}>
        <Image
          boxSize="2rem"
          p={2}
          bg={categoryIcons[nodeData.nodeCategory].theme}
          alt={nodeData.displayName + " icon"}
          rounded="sm"
          src={nodeData.imageUrl}
        />
        <Box
          className="h-5 w-5 grid place-items-center"
          color={statusIconData.theme}
        >
          {statusIconData.icon}
        </Box>
      </Flex>
      <span className="text-xs capitalize font-semibold">
        {nodeData.displayName}
      </span>
    </>
  );
};
