import { Flex, Skeleton } from "@chakra-ui/react";
import { HotColumn, HotTable } from "@handsontable/react";
import React from "react";
import { renderToString } from "react-dom/server";

function CustomLoadingRenderer() {
  return <Skeleton h="20px" />;
}

function CustomHeaderLoadingRenderer() {
  return (
    <Flex className="items-stretch h-full min-w-[150px] w-full justify-between bg-white z-10" />
  );
}

const LoadingTableSkeleton = () => {
  const handleTierGaterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Flex className="w-full h-full" onClickCapture={handleTierGaterClick}>
      <HotTable
        data={[]}
        readOnly={true}
        disableVisualSelection={true}
        minRows={200}
        rowHeaders={false}
        width="100%"
        height="100%"
        rowHeights="28px"
        autoWrapRow={true}
        autoRowSize={false}
        colHeaders={() => {
          const str = renderToString(<CustomHeaderLoadingRenderer />);
          return str;
        }}
        stretchH="all"
        colWidths={150}
        editor={false}
        licenseKey="non-commercial-and-evaluation"
      >
        <HotColumn>
          <CustomLoadingRenderer hot-renderer />
        </HotColumn>
        <HotColumn>
          <CustomLoadingRenderer hot-renderer />
        </HotColumn>
        <HotColumn>
          <CustomLoadingRenderer hot-renderer />
        </HotColumn>
        <HotColumn>
          <CustomLoadingRenderer hot-renderer />
        </HotColumn>
      </HotTable>
    </Flex>
  );
};

export const LoadingTable = React.memo(LoadingTableSkeleton);
