import dayjs from "dayjs";

import {
  IDataPoint,
  IExploreTaskResponse,
  SelectedColumn,
  STATISTICS,
  TimeFrame,
} from "@/features/data-transformation";
import { safeNumber } from "@/utils/string-utils.ts";

export const formatTooltipLabel = (label: string, timeFrame: string) => {
  const date = dayjs(label);
  switch (timeFrame) {
    case "week":
      return `${date.format("MMM D")} - ${date
        .endOf("week")
        .format("MMM D, YYYY")} (Week ${date.week()})`;
    case "month":
      return date.format("MMMM YYYY");
    case "year":
      return date.format("YYYY");
    default:
      return date.format("MMMM D, YYYY");
  }
};

export const groupFormattedData = (
  data: Record<string, { value: number; count: number; firstDate: dayjs.Dayjs }>
) => {
  return Object.entries(data).map(([_label, { value, firstDate }]) => ({
    label: firstDate.format("YYYY-MM-DD"),
    value: value, // Use the actual value, not the count
  }));
};

export const groupTimeSeriesData = (
  data: IDataPoint[],
  timeFrame: TimeFrame
) => {
  return data.reduce(
    (acc, point) => {
      const date = dayjs(point.label);
      let key: string;

      switch (timeFrame) {
        case "week":
          key = date.startOf("week").format("YYYY-MM-DD");
          break;
        case "month":
          key = date.startOf("month").format("YYYY-MM");
          break;
        case "year":
          key = date.startOf("year").format("YYYY");
          break;
        default:
          key = point.label;
      }

      if (!acc[key]) {
        acc[key] = { value: 0, count: 0, firstDate: date };
      }
      acc[key].value += parseFloat(point.value); // Parse string to float
      acc[key].count += 1;
      return acc;
    },
    {} as Record<
      string,
      { value: number; count: number; firstDate: dayjs.Dayjs }
    >
  );
};

export const nullParser = <T>(
  value: T | null
): { isNull: boolean; parsedValue: T | string } => {
  const isNull = value === null;
  const parsedValue = isNull ? "null" : value;
  return { parsedValue, isNull };
};

function calculatePercentage(part: number, total: number): number {
  if (total === 0 || part === 0) return 0;
  return (part / total) * 100;
}

export const getExploreStats = (
  colInfo: SelectedColumn | null,
  tableMetaData: any,
  data: IExploreTaskResponse | undefined
) => {
  const stats = data?.statistics?.otherStats ?? {};

  const fallbackData = {
    nullValues: colInfo?.column.nullValues ?? 0,
    valid: colInfo?.column.validValues ?? 0,
    total: safeNumber(tableMetaData?.rows, 0),
  };

  const total = safeNumber(stats[STATISTICS.TOTAL_COUNT], fallbackData.total);
  const nonNullCount = safeNumber(
    stats[STATISTICS.NON_NULL_COUNT],
    total - fallbackData.nullValues
  );
  const nullValues = total - nonNullCount;

  const columnData = {
    valid: nonNullCount,
    invalid: colInfo?.column.invalidValues ?? 0,
    nullValues: nullValues,
    total: total,
  };

  const nullPercentage = calculatePercentage(nullValues, total);

  return { columnData, nullPercentage, nullValues };
};

export const tickFormatter = (
  value: string | number,
  valuesLength = 0,
  short = false
) => {
  const limit = short ? 3 : 33 - valuesLength; // put your maximum character

  const convertedVal = typeof value !== "string" ? `${value}` : value;

  if (convertedVal?.length < limit) return convertedVal;
  return `${convertedVal.substring(0, limit)}...`;
};
