import { Flex, Text } from "@chakra-ui/react";

import { CtkLoader } from "../icons/ctk-logo";

export const InitLoader = () => {
  return (
    <Flex className="w-full h-screen items-center justify-center gap-3">
      <Flex className="w-16 h-16">
        <CtkLoader />
      </Flex>
      <Text className="text-xl">Loading...</Text>
    </Flex>
  );
};
