import { AIModel, TextGenVersion } from "../../types";

export const configureModelWithCurrentValues = (
  currentConfig?: AIModel | null,
  newModel?: AIModel | null
): AIModel => {
  if (!newModel) return currentConfig ?? ({} as AIModel);
  if (!currentConfig?.modelConfigs) return newModel ?? {};

  const updatedModelConfigs = newModel.modelConfigs.map((mc) => {
    const matchingConfig = currentConfig.modelConfigs.find(
      ({ globalParameterId }) => globalParameterId === mc.globalParameterId
    );

    if (!matchingConfig?.selectedValue) return mc;

    const selectedValueAsNumber = Number(matchingConfig.selectedValue);
    const { minimum, maximum } = mc;

    if (minimum !== null && selectedValueAsNumber < minimum) {
      return { ...mc, selectedValue: minimum.toString() };
    }

    if (maximum !== null && selectedValueAsNumber > maximum) {
      return { ...mc, selectedValue: maximum.toString() };
    }

    return { ...mc, selectedValue: matchingConfig.selectedValue };
  });

  return { ...newModel, modelConfigs: updatedModelConfigs };
};

export const setValueInVersion = (
  activeVersion: TextGenVersion | null,
  id: string | undefined,
  value: number | string
) => {
  if (!id || !activeVersion) return null;
  return {
    ...activeVersion,
    aiModel: {
      ...activeVersion?.aiModel,
      modelConfigs: activeVersion?.aiModel?.modelConfigs.map((mc) => {
        if (mc.id === id) {
          return {
            ...mc,
            selectedValue: value.toString(),
          };
        }
        return mc;
      }),
    },
  };
};
